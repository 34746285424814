<template>
  <div>
    <b-row>
      <b-col cols="12" xl="8">
        <div class="form-container separator">

          <pcg-text-input class="col-12"
                          :label="$t('views.dictionaries.labels.name')"
                          :error-text="getError('name')"
                          :show-error="errorExist('name')"
                          v-model="dictionary.name"/>

          <pcg-text-input class="col-12" type="textarea"
                          :label="$t('views.dictionaries.labels.description')"
                          :error-text="getError('description')"
                          :show-error="errorExist('description')"
                          v-model="dictionary.description"/>

          <pcg-btn v-if="dictionary" size="small" @click="updateDictionary(true)" variant="additional" class="action-btn">{{ $t('views.common.save') }}</pcg-btn>
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import ApiDictionaryAcceptances from '../../api/dictionary_acceptances'

export default {
  name: 'DictionaryAcceptancesEdit',
  components: {
  },
  data () {
    return {
      id: this.$route.params.id,
      errors: {},
      dictionary: {
        name: null,
        description: null
      }
    }
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.getDictionary()
  },
  methods: {
    getDictionary () {
      ApiDictionaryAcceptances.getDictionaryAcceptance(this.id)
        .then(response => {
          console.log(JSON.stringify(response.data.data))
          this.dictionary = response.data.data.attributes
        }).catch(error => {
          this.$toastr.e(error)
          console.log(error)
        })
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.dictionaries.edit.title'),
        subtitle: this.$t('views.dictionaries.edit.subtitle'),
        capitalizeSubtitle: false
      })
    },
    updateDictionary () {
      this.loading = true
      ApiDictionaryAcceptances.updateDictionaryAcceptance(this.id, { dictionary_acceptance: this.dictionary })
        .then(response => {
          this.errors = {}
          this.getDictionary()
          setTimeout(() => { this.$toastr.s(this.$t('general.dictionary_updated')) }, 1)
        }).catch(error => {
          this.errors = error.response.data
          setTimeout(() => { this.$toastr.e(this.$t('general.check_errors')) }, 1)
        }).finally(() => {
          this.loading = false
        })
    },
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>
