export default {
  methods: {
    range (start, end, step = 1) {
      const result = []
      for (let val = start; val <= end; val += step) {
        result.push(val)
      }
      return result
    }
  }
}
