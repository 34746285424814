import Vue from 'vue'
import { store } from './store'
import router from './router'
import { i18n } from './translations'
import BootstrapVue from 'bootstrap-vue'
import Vuex from 'vuex'
import App from './layout/app.vue'
import VueToastr from 'vue-toastr'
import VueProgressBar from 'vue-progressbar'
import Paginate from 'vuejs-paginate'
import VueSweetAlert2 from 'vue-sweetalert2'
import VueDisqus from 'vue-disqus'
import VueMq from 'vue-mq'
import ActionCableVue from 'actioncable-vue'

import PcgCoreVue from 'pcg-core-vue'

import VueCookies from 'vue-cookies'

import './assets/stylesheets/application.scss'
import 'sweetalert2/dist/sweetalert2.min.css'

const sweetAlertOptions = {
  cancelButtonText: i18n.messages[i18n.locale].general.cancel,
  buttonsStyling: false,
  customClass: {
    popup: 'dn-sweetalert-popup',
    header: 'dn-sweetalert-header',
    title: 'dn-sweetalert-title',
    content: 'dn-sweetalert-content',
    confirmButton: 'pcg-button pcg-main-btn pcg-small-btn',
    cancelButton: 'pcg-button pcg-additional-btn pcg-small-btn'
  }
}

Vue.use(BootstrapVue)
Vue.use(VueSweetAlert2, sweetAlertOptions)
Vue.use(VueDisqus)
Vue.component('paginate', Paginate)
Vue.use(PcgCoreVue)
Vue.use(VueCookies)
Vue.use(VueMq, {
  breakpoints: {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: Infinity
  }
})
Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'all',
  connectImmediately: true
})

moment.locale(i18n.locale)

let app
document.addEventListener('DOMContentLoaded', () => {
  Vue.use(VueToastr)
  Vue.use(VueProgressBar, { color: '#00B4EB', failedColor: '#FC4747', thickness: '4px' })

  app = new Vue({
    store,
    router,
    i18n,
    render: h => h(App)
  })

  store.$app = app
  app.$mount('#app-root')
})

export { app }
