import Cookies from 'js-cookie'
import ApiEvaluations from '../../api/evaluations'

const period = {
  namespaced: true,
  state: {
    allYears: [],
    years: []
  },
  getters: {
    years: state => state.years,
    allYears: state => state.allYears
  },
  mutations: {
    setYears (state, years) {
      state.years = years
    },
    setAllYears (state, years) {
      let allYears = state.allYears
      allYears = years.reduce((all, ys) => {
        return all.concat(ys)
      })
      allYears = allYears.filter((value, index, self) => {
        return self.indexOf(value) === index
      })
      state.allYears = allYears.sort()
    }
  },
  actions: {
    setYears ({ commit }, evalId) {
      ApiEvaluations.getEvaluation(evalId)
        .then(result => {
          const evaluation = result.data.evaluation
          const years = evaluation.years
          Cookies.set('evaluationId', evalId)
          commit('setYears', years)
        })
        .catch(() => {
          console.log('Error')
          return []
        })
    },
    setInitialYears ({ commit, dispatch }) {
      const evaluationId = parseInt(Cookies.get('evaluationId'))
      ApiEvaluations.getEvaluations()
        .then(result => {
          const evaluations = result.data.datas
          const defaultEvaluation = result.data.defaultEvaluation
          const currYear = new Date().getFullYear()
          const currEval = evaluationId
            ? evaluations.find(e => e.id === evaluationId)
            : defaultEvaluation
              ? evaluations.find(e => e.id === defaultEvaluation)
              : evaluations.find(e => e.years.includes(currYear)) || evaluations[0]
          dispatch('setYears', currEval.id)
          const years = evaluations.map(e => e.years)
          commit('setAllYears', years)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}

export {
  period
}
