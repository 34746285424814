import api from './index'

const resourceParent = 'dictionary_acceptances'
const resource = 'dictionary_acceptance_words'

export default {
  getDictionaryAcceptanceWords (dictionaryId, params = {}) {
    return api.get(`${resourceParent}/${dictionaryId}/${resource}`, { params: params })
  },
  getDictionaryAcceptanceWord (dictionaryId, wordId) {
    return api.get(`${resourceParent}/${dictionaryId}/${resource}/${wordId}`)
  },
  deleteDictionaryAcceptanceWord (dictionaryId, wordId) {
    return api.delete(`${resourceParent}/${dictionaryId}/${resource}/${wordId}`)
  },
  updateDictionaryAcceptanceWord (dictionaryId, wordId, payload) {
    return api.patch(`${resourceParent}/${dictionaryId}/${resource}/${wordId}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  createDictionaryAcceptanceWord (dictionaryId, payload) {
    return api.post(`${resourceParent}/${dictionaryId}/${resource}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}
