import api from './index'

const resource = 'dictionary_acceptances'

export default {
  getDictionaryAcceptances (params = {}) {
    return api.get(`${resource}`, { params: params })
  },
  getDictionaryAcceptance (dictionaryId) {
    return api.get(`${resource}/${dictionaryId}`)
  },
  getDictionaryAcceptanceWords (dictionaryAcceptanceKey) {
    return api.get(`${resource}/${dictionaryAcceptanceKey}/words`)
  },
  updateDictionaryAcceptance (dictionaryId, payload) {
    return api.patch(`${resource}/${dictionaryId}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}
