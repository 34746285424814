export default {
  watch: {
    '$i18n.locale': function (newLocale, oldLocale) {
      this.setTitles()
    }
  },
  methods: {
    // $data.headerTitle : String or Object { id: 1, text: 'tekst', text_en: 'text' }
    // $data.headerSubtitle : String or Object { id: 1, text: 'tekst', text_en: 'text' }
    setTitles () {
      let title = ''
      let subtitle = ''

      if (typeof this.headerTitle === 'string') {
        title = this.$t(this.headerTitle)
      } else {
        if (this.$i18n.locale === 'pl') {
          title = this.headerTitle.text
        } else {
          title = this.headerTitle.text_en
        }
      }

      if (typeof this.headerSubtitle === 'string') {
        subtitle = this.$t(this.headerSubtitle)
      } else {
        if (this.$i18n.locale === 'pl') {
          subtitle = this.headerSubtitle.text
        } else {
          subtitle = this.headerSubtitle.text_en
        }
      }

      this.$store.dispatch('header/setHeader', {
        title: title,
        subtitle: subtitle
      })
    }
  }
}
