import api from './index'

const resource = 'project_priorities'

export default {
  getPriorities (key, allPriorities) {
    let url = ''
    url = resource
    return api.get(url, { params: { key: key, all_priorities: allPriorities } })
  }
}
