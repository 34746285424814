export default {
  methods: {
    tf (value) {
      if (typeof value === 'boolean') {
        if (value) {
          return this.$t('general.yes')
        } else {
          return this.$t('general.no')
        }
      } else {
        return value
      }
    }
  }
}
