import api from './index'

const resource = 'art_import'

export default {
  getXlsFiles (page = null) {
    let url = ''
    if (page) {
      url = resource + '?page=' + page
    } else {
      url = resource
    }
    return api.get(url)
  },
  putXlsFile (form) {
    return api.post(resource, form)
  },
  updateAddEmployee (id, addEmployee) {
    let url = resource
    url += '/' + id + '/add_employee'
    const body = {
      add_employee: addEmployee
    }
    return api.put(url, body)
  },
  getXlsFile (id) {
    let url = resource
    url += '/' + id
    return api.get(url)
  },
  deleteXls (id) {
    let url = resource
    url += '/' + id
    return api.delete(url)
  },
  parseXlsFile (id) {
    let url = resource
    url += '/' + id + '/parse'
    return api.get(url)
  }
}
