export default {
  methods: {
    // dictionary : Array of Objects like
    //   [
    //     { id: 1, text: 'text1', text_en: 'texten1' },
    //     { id: 2, text: 'text2', text_en: 'texten2' }
    //   ]
    //
    // return Array of Objects like
    //   [
    //     { value: 1, text: 'text1' },
    //     { value: 2, text: 'text2' }
    //   ]
    tDictionary (dictionary) {
      const _that = this
      return dictionary.map(d => {
        if (_that.$i18n.locale === 'pl') {
          return { value: d.id, text: d.text }
        } else {
          return { value: d.id, text: d.text_en }
        }
      })
    }
  }
}
