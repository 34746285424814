<template>
  <base-beam>
    <dn-beam mobile-active-class="justify-content-start" mobile-class="justify-content-between">
      <template>
        <span class="text-lowercase selected-elements ml-2 mr-4">
          {{ count }} {{ $t('views.achievement.list.selected_elements') }}
        </span>

        <span @click="cancelMultiCheckbox" class="cancel-checkbox">{{ $t('general.cancel') }}</span>
        <!--<pcg-btn round :icon="removeIcon" @click="archivePublicationsModal" :text="$t('views.achievement.list.remove_publication')" variant="danger"></pcg-btn>-->
        <pcg-btn v-for="(option, index) in pcgOptions.slice(0,3)" :key="index" size="small" variant="additional" @click="option.action()">
          {{ option.text }}
        </pcg-btn>
        <pcg-options v-show="numberOfOptions > 3" class="action-btn" :options="additionalOptions" v-if="numberOfOptions > 3" :text="$t('components.publication_element.actions')"/>
        <spinner v-if="spinnerAll" class="my-spinner"></spinner>
        <span v-if="cableStatusLive" class="live-cable">({{ cableStatusLive }})</span>
      </template>

      <template #mobile_beam>
        <div class="ml-4">
          {{ count }}
          <span class="text-lowercase mr-4">
            {{ $t('views.achievement.list.selected_elements') }}
          </span>
        </div>
      </template>

      <template #options>
        <span @click="cancelMultiCheckbox" class="cancel-checkbox">{{ $t('general.cancel') }}</span>
        <!--<pcg-btn round :icon="removeIcon" @click="archivePublicationsModal" :text="$t('views.achievement.list.remove_publication')" variant="danger"></pcg-btn>-->
        <pcg-btn v-for="(option, index) in pcgOptions.slice(0,3)" :key="index" size="small" variant="additional" @click="option.action()">
          {{ option.text }}
        </pcg-btn>
        <pcg-options v-show="numberOfOptions > 3" class="action-btn" :options="additionalOptions" v-if="numberOfOptions > 3" :text="$t('components.publication_element.actions')"/>
        <spinner v-if="spinnerAll" class="my-spinner"></spinner>
        <span v-if="cableStatusLive" class="live-cable">({{ cableStatusLive }})</span>
      </template>
      <b-modal ref="assignDisciplineConfirm"
               :hide-header="true"
               footer-class="my-modal-footer"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="assignDiscipline"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               content-class="my-modal-content"
      >
        <div class="confirm-header">{{ $t('views.achievement.list.assign_discipline') }}</div>
        <div class="confirm-text">{{ $tc('views.achievement.list.assign_discipline_confirmation', count) }}</div>
      </b-modal>
      <b-modal ref="countPointsConfirm"
               :hide-header="true"
               footer-class="my-modal-footer"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="countPoints"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               content-class="my-modal-content"
      >
        <div class="confirm-header">{{ $t('views.achievement.list.count_points') }}</div>
        <div class="confirm-text">{{ $tc('views.achievement.list.count_points_confirmation', count) }}</div>
      </b-modal>
      <b-modal ref="consentToEvaluationConfirm"
               :hide-header="true"
               footer-class="my-modal-footer"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="consentToEvaluation"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               content-class="my-modal-content"
      >
        <div class="confirm-header">{{ $t('views.achievement.list.evaluation') }}</div>
        <div class="confirm-text">{{ $tc('views.achievement.list.evaluation_confirmation', count) }}</div>
        <b-row>
          <b-col cols="5">
            <pcg-select
              class="my-select-short"
              :options="tDictionary(yesNo)"
              :default-option="{ text: $t('general.select'), id: '' }"
              v-model="evaluation"
            />
          </b-col>
        </b-row>
      </b-modal>
      <b-modal ref="archivePublicationsConfirm"
               :hide-header="true"
               footer-class="my-modal-footer"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="archivePublications"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               content-class="my-modal-content"
      >
        <div class="confirm-header">{{ $t('views.achievement.list.archiving') }}</div>
        <div class="confirm-text">{{ $tc('views.achievement.list.archiving_confirmation', count) }}</div>
      </b-modal>
      <b-modal ref="restorePublicationsConfirm"
               :hide-header="true"
               footer-class="my-modal-footer"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="restorePublications"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               content-class="my-modal-content"
      >
        <div class="confirm-header">{{ $t('views.achievement.list.restoring') }}</div>
        <div class="confirm-text">{{ $tc('views.achievement.list.restoring_confirmation', count) }}</div>
      </b-modal>
      <b-modal ref="setStatusConfirm"
               :hide-header="true"
               footer-class="my-modal-footer"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="setStatus"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               content-class="my-modal-content"
      >
        <div class="confirm-header">{{ $t('views.achievement.list.set_status') }}</div>
        <div class="confirm-text">{{ $tc('views.achievement.list.set_status_confirmation', count) }}</div>
        <dn-select
          v-if="statuses.length > 0"
          :options="statuses"
          :default-option="{ text: statuses[0].class, value: statuses[0].value, class: statuses[0].class }"
          color="aqua"
          class="statuses discipline my-dn-select"
          v-model="status"
          style="margin-bottom: 3rem"
        >
          <template v-slot:button-text="{ text, className }">
            <dn-status :status="text" :class-name="className"/>
          </template>
          <template v-slot:item-text="{ option }">
            <dn-status :status="option.text" :class-name="option.class" />
          </template>
        </dn-select>
        <div class="confirm-text" v-if=" status=== 4">{{ $t('components.publication_element.default_status_pbn') }}</div>
        <dn-select
          v-if="additionalStatuses.length > 0 && status === 4"
          :options="additionalStatuses"
          :default-option="{ text: additionalStatuses[0].class, value: additionalStatuses[0].value, class: additionalStatuses[0].class }"
          color="aqua"
          class="statuses"
          v-model="additionalStatus"
          style="margin-bottom: 3rem;"
        >
          <template v-slot:button-text="{ text, className }">
            <dn-status :status="text" :class-name="className" :pbn="true"/>
          </template>
          <template v-slot:item-text="{ option }">
            <dn-status :status="option.text" :class-name="option.class" :pbn="true" />
          </template>
        </dn-select>
      </b-modal>
      <b-modal ref="exportAchievementsConfirm"
               :hide-header="true"
               footer-class="my-modal-footer"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="exportAchievements"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               content-class="my-modal-content"
      >
        <div class="confirm-header">{{ $t('views.achievement.list.export_achievements') }}</div>
        <div class="confirm-text">{{ $tc('views.achievement.list.export_achievements_confirmation', count) }}</div>
        <pcg-select
            class="my-select"
            style="margin-top: 3rem; margin-bottom: 3rem;"
            :label="$t('views.achievement.list.report_type')"
            :options="tDictionary(exportTypes)"
            v-model="exportType"
        />
      </b-modal>
      <b-modal ref="exportAchievementsPdfConfirm"
               :hide-header="true"
               footer-class="my-modal-footer"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="exportAchievementsPdf"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               content-class="my-modal-content"
      >
        <div class="confirm-header">{{ $t('views.achievement.list.export_achievements_pdf') }}</div>
        <div class="confirm-text">{{ $tc('views.achievement.list.export_achievements_confirmation', count) }}</div>
      </b-modal>
      <b-modal ref="exportPbnApiConfirm"
               :hide-header="true"
               footer-class="my-modal-footer"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="exportPbnApi"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               content-class="my-modal-content"
      >
        <div class="confirm-header">{{ $t('views.achievement.list.export_pbn_api') }}</div>
        <div class="confirm-text">{{ $tc('views.achievement.list.export_achievements_confirmation', count) }}</div>
      </b-modal>
      <b-modal ref="exportPolonApiConfirm"
               :hide-header="true"
               footer-class="my-modal-footer"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="exportPolonApi"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               content-class="my-modal-content"
      >
        <div class="confirm-header">{{ $t('views.achievement.list.export_polon_api') }}</div>
        <div class="confirm-text">{{ $tc('views.achievement.list.export_achievements_confirmation', count) }}</div>
        <b-row class="d-flex align-items-center" style="margin-bottom: 1rem">
          <b-col cols="12" style="margin-right: .5rem">
            <label class="my-label">Nazwa użytkownika</label>
            <pcg-text-input v-model="params.username" :showLabel="false" :showInfo="false" :showError="false" placeholder="Nazwa użytkownika" />
          </b-col>
        </b-row>
        <b-row class="d-flex align-items-center" style="margin-bottom: 1rem">
          <b-col cols="12" style="margin-right: .5rem">
            <label class="my-label">Hasło</label>
            <pcg-text-input v-model="params.password" type="password" :showLabel="false" :showInfo="false" :showError="false" placeholder="Hasło" />
          </b-col>
        </b-row>
        <b-row class="d-flex align-items-center" style="margin-bottom: 2rem">
          <b-col cols="12" style="margin-right: .5rem">
            <label class="my-label">ID instytucji</label>
            <pcg-text-input v-model="params.institution" :showLabel="false" :showInfo="false" :showError="false" placeholder="ID instytucji" />
          </b-col>
        </b-row>
      </b-modal>
      <b-modal ref="exportOrcidModal"
               :hide-header="true"
               footer-class="my-modal-footer"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="exportOrcid"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               content-class="my-modal-content"
      >
        <div class="confirm-header" style="margin-bottom: 3rem">{{ $t('components.publication_element.export_orcid') }}</div>
        <div class="confirm-text" style="margin-bottom: 1.5rem">{{ $t('components.publication_element.export_orcid_token') }}</div>
        <div>
          <label class="my-label">{{ $t('components.publication_element.orcid_token') }}</label>
        </div>
        <pcg-text-input
            class="my-text-input"
            style="margin-bottom: 1rem"
            :showLabel="false"
            v-model="orcidParams.token"
        />
        <div>
          <label class="my-label">{{ $t('components.publication_element.orcid_user_id') }}</label>
        </div>
        <pcg-text-input
            class="my-text-input"
            style="margin-bottom: 3rem"
            :showLabel="false"
            v-model="orcidParams.orcid"
        />
      </b-modal>

    </dn-beam>
  </base-beam>
</template>

<script>
import DnBeam from './DnBeam'
import BaseBeam from './BaseBeam'
import Spinner from '../Spinner'
import DnSelect from '../DnSelect'
import DnStatus from '../DnStatus'
import hasRight from '../../mixins/has_right'
import translateDictionary from '../../mixins/translate_dictionary'

export default {
  name: 'DnBeamAchievementsList',
  mixins: [hasRight, translateDictionary],
  components: {
    BaseBeam,
    DnBeam,
    Spinner,
    DnSelect,
    DnStatus
  },
  channels: {
    ChatChannel: {
      connected () {
        console.log('connected')
      },
      rejected () {
        console.log('rejected')
      },
      received (data) {
        console.log(data)
        this.cableStatus = data.body
      },
      disconnected () {
        console.log('dc')
      }
    }
  },
  data: function () {
    return {
      removeIcon: {
        type: 'component',
        value: 'icon-remove',
        width: '11',
        height: '13',
        color: '#00B4EB',
        hoverColor: '#a90000'
      },
      pcgOptions: [],
      status: null,
      statuses: [],
      currentUser: null,
      evaluation: true,
      params: {
        username: null,
        password: null,
        institution: null
      },
      orcidParams: {
        orcid: null,
        token: null
      },
      exportType: 'achievements',
      exportTypes: [
        { text: 'Raport osiągnięć', text_en: 'Achievement report', id: 'achievements' },
        { text: 'Raport osiągnięć z perspektywy pracownika', text_en: 'Achievement report from the employee\'s perspective', id: 'authors' }
      ],
      additionalStatus: null,
      additionalStatuses: [
        { text: 'do_not_send', value: 'do_not_send', class: 'do_not_send' },
        { text: 'to_send', value: 'to_send', class: 'to_send' },
        { text: 'to_resend', value: 'to_resend', class: 'to_resend' },
        { text: 'sent_manually', value: 'sent_manually', class: 'sent_manually' }
      ],
      cableStatus: null,
      cableSubscribed: false
    }
  },
  props: {
    count: {
      type: Number,
      default: 0
    },
    spinnerAll: Boolean,
    filterStatus: String,
    filterPublicationType: String
  },
  beforeMount () {
    this.currentUser = this.$store.getters['auth/id']
  },
  mounted () {
    this.initializeStatuses()
    this.initializeOptions()
  },
  beforeDestroy () {
    this.$cable.unsubscribe({
      channel: 'ChatChannel',
      userId: this.currentUser
    })
  },
  computed: {
    cableStatusLive () {
      return this.cableStatus
    },
    yesNo () {
      return [
        { text: 'Zgoda', text_en: 'Consent', id: true },
        { text: 'Brak zgody', text_en: 'No consent', id: false }
      ]
    },
    additionalOptions () {
      return this.pcgOptions.slice(3)
    },
    numberOfOptions () {
      let num = 0
      if (this.hasRight('multiple_archive') && this.filterStatus !== 'archive') {
        num += 2
      }
      if (this.hasRight('multiple_eval') && this.filterPublicationType !== '45') {
        num += 1
      }
      if (this.hasRight('multiple_discipline') && this.filterPublicationType !== '45') {
        num += 1
      }
      if (this.hasRight('export_achievements')) {
        num += 2
      }
      if (this.hasRight('export_orcid')) {
        num += 1
      }
      if (this.hasRight('export_pbn') && this.filterStatus === 'accepted') {
        num += 2
      }
      if (this.hasRight('multiple_status') && this.statuses.length > 0) {
        num += 1
      }
      if (this.hasRight('multiple_calculate_points')) {
        num += 1
      }
      return num
    }
  },
  watch: {
    filterStatus () {
      this.initializeOptions()
    },
    filterPublicationType () {
      this.initializeOptions()
    },
    statuses () {
      this.initializeOptions()
    }
  },
  methods: {
    archivePublications () {
      this.$emit('archivePublications')
    },
    restorePublications () {
      this.$emit('restorePublications')
    },
    assignDiscipline () {
      this.$emit('assignDiscipline')
    },
    consentToEvaluation () {
      this.$emit('consentToEvaluation', this.evaluation)
    },
    setStatus () {
      if (this.status === null) {
        this.status = this.statuses[0].value
      }
      if (this.additionalStatus === null && this.status === 4) {
        this.additionalStatus = this.additionalStatuses[0].value
      }
      this.$emit('setStatus', this.status, this.additionalStatus)
    },
    countPoints () {
      this.$emit('countPoints')
    },
    exportAchievements () {
      if (!this.cableSubscribed) {
        this.$cable.subscribe({
          channel: 'ChatChannel',
          userId: this.currentUser
        })
        this.cableSubscribed = true
      }
      this.$emit('exportAchievements', this.exportType)
    },
    exportAchievementsPdf () {
      if (!this.cableSubscribed) {
        this.$cable.subscribe({
          channel: 'ChatChannel',
          userId: this.currentUser
        })
        this.cableSubscribed = true
      }
      this.$emit('exportAchievementsPdf')
    },
    exportOrcid () {
      this.$emit('exportOrcid', this.orcidParams)
    },
    exportPbnApi () {
      this.$emit('exportPbnApi')
    },
    exportPolonApi () {
      this.$emit('exportPolonApi', this.params)
    },
    countPointsModal () {
      this.$refs.countPointsConfirm.show()
    },
    assignDisciplineModal () {
      this.$refs.assignDisciplineConfirm.show()
    },
    consentToEvaluationModal () {
      this.$refs.consentToEvaluationConfirm.show()
    },
    archivePublicationsModal () {
      this.$refs.archivePublicationsConfirm.show()
    },
    restorePublicationsModal () {
      this.$refs.restorePublicationsConfirm.show()
    },
    exportAchievementsModal () {
      this.$refs.exportAchievementsConfirm.show()
    },
    exportAchievementsPdfModal () {
      this.$refs.exportAchievementsPdfConfirm.show()
    },
    exportOrcidModal () {
      this.$refs.exportOrcidModal.show()
    },
    exportPbnApiModal () {
      this.$refs.exportPbnApiConfirm.show()
    },
    exportPolonApiModal () {
      this.$refs.exportPolonApiConfirm.show()
    },
    setStatusModal () {
      this.$refs.setStatusConfirm.show()
    },
    initializeStatuses () {
      const events = [
        { text: 'to_draft', value: 0, class: 'draft' },
        { text: 'check', value: 1, class: 'for_approval' },
        { text: 'to_improve', value: 2, class: 'improve' },
        { text: 'verify', value: 3, class: 'verified' },
        { text: 'accept', value: 4, class: 'accepted' }
      ]
      events.forEach(e => {
        if (this.hasRight(`event_${e.text}`)) {
          this.statuses.push({
            text: e.class, value: e.value, class: e.class
          })
        }
      })
    },
    initializeOptions () {
      this.pcgOptions = []
      if (this.hasRight('export_achievements')) {
        this.pcgOptions.push({
          text: this.$t('views.achievement.list.export_achievements'),
          action: () => { this.exportAchievementsModal() }
        })
        this.pcgOptions.push({
          text: this.$t('views.achievement.list.export_achievements_pdf'),
          action: () => { this.exportAchievementsPdfModal() }
        })
      }
      if (this.hasRight('export_pbn') && this.filterStatus === 'accepted') {
        this.pcgOptions.push({
          text: this.$t('views.achievement.list.export_pbn_api'),
          action: () => { this.exportPbnApiModal() }
        })
        this.pcgOptions.push({
          text: this.$t('views.achievement.list.export_polon_api'),
          action: () => { this.exportPolonApiModal() }
        })
      }
      if (this.hasRight('export_orcid')) {
        this.pcgOptions.push({
          text: this.$t('views.achievement.list.export_orcid'),
          action: () => { this.exportOrcidModal() }
        })
      }
      if (this.hasRight('multiple_status') && this.statuses.length > 0) {
        this.pcgOptions.push({
          text: this.$t('views.achievement.list.set_status'),
          action: () => { this.setStatusModal() }
        })
      }
      if (this.hasRight('multiple_archive') && this.filterStatus !== 'archive') {
        this.pcgOptions.push({
          text: this.$t('views.achievement.list.archive'),
          action: () => { this.archivePublicationsModal() }
        })
      }
      if (this.hasRight('multiple_archive') && this.filterStatus === 'archive') {
        this.pcgOptions.push({
          text: this.$t('views.achievement.list.restore'),
          action: () => { this.restorePublicationsModal() }
        })
      }
      if (this.hasRight('multiple_eval') && this.filterPublicationType !== '45') {
        this.pcgOptions.push({
          text: this.$t('views.achievement.list.evaluation'),
          action: () => { this.consentToEvaluationModal() }
        })
      }
      if (this.hasRight('multiple_discipline') && this.filterPublicationType !== '45') {
        this.pcgOptions.push({
          text: this.$t('views.achievement.list.assign_discipline'),
          action: () => { this.assignDisciplineModal() }
        })
      }
      if (this.hasRight('multiple_calculate_points')) {
        this.pcgOptions.push({
          text: this.$t('views.achievement.list.count_points'),
          action: () => { this.countPointsModal() }
        })
      }
    },
    cancelMultiCheckbox () {
      this.$emit('cancelMultiCheckbox')
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/stylesheets/vars';

  .my-spinner {
    color: $main-active-color
  }

  .confirm-header {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .confirm-text {
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1rem;
  }

  .cancel-checkbox {
    color: $main-active-color;
    font-weight: lighter;
    font-size: $font-size-s;
    margin-right: .5rem;
    cursor: pointer;

    &:hover {
      color: $hover-color;
    }
  }

  .selected-elements {
    color: $pcg-black;
  }

  .my-label {
    color: $pcg-gray;
    font-weight: 400;
  }

  .available-in {
    font-size: 1em;
    color: $pcg-gray;
    font-weight: 400;
    margin-right: 1.5rem;
    display: inline;
    float: left;
  }

  .my-select-short {
    margin-right: .8rem;
    margin-left: .7rem;
    width: 200px;
    position: relative;
    display: inline-block;
    /deep/ .bootstrap-select .dropdown-toggle {
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
    }
    /deep/ .filter-option-inner {
      position: relative;
      bottom: .45rem;
    }
  }

  .live-cable {
    color: $main-active-color;
    margin-left: .5rem;
  }
</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .my-spinner {
      color: $main-active-color
    }
    .confirm-header {
      color: $pcg-gray;
    }

    .confirm-text {
      color: $pcg-gray;
    }

    .cancel-checkbox {
      color: $main-active-color;

      &:hover {
        color: $hover-color;
      }
    }
    .selected-elements {
      color: $pcg-black;
    }
    .my-label {
      color: $pcg-gray;
    }
    .available-in {
      color: $pcg-gray;
    }
    .my-select-short {
      /deep/ .bootstrap-select .dropdown-toggle {
        color: $pcg-gray;
      }
    }
    .live-cable {
      color: $main-active-color;
    }
  }
</style>
