<template>
    <div class="orcid-authors">
        <div class="d-flex justify-content-between">
            <div class="my-header">
                <span class="edit-authors" v-if="translation">{{ $t('views.orcid_publications.edit_original_authors') }}</span>
                <span class="edit-authors" v-else-if="isAuthors && publicationTypeId !== 7 && publicationTypeId !== 32 && publicationTypeId !== 35 && publicationTypeId !== 45">{{ $t('views.orcid_publications.edit_authors') }}</span>
                <span class="edit-authors" v-else-if="isAuthors && (publicationTypeId === 7 || publicationTypeId === 35)">{{ $t('views.orcid_publications.edit_creators') }}</span>
                <span class="edit-authors" v-else-if="isAuthors && publicationTypeId === 45">{{ $t('views.orcid_publications.edit_contributors') }}</span>
                <span class="edit-authors" v-else-if="isAuthors && publicationTypeId === 32">{{ $t('views.orcid_publications.edit_contractors') }}</span>
                <span class="edit-authors" v-else>{{ $t('views.orcid_publications.edit_redactors') }}</span>
                <span class="question-mark">?</span>
            </div>
            <div>
                <pcg-btn size="small" variant="additional" @click="addAuthor">{{ $t('components.dn_field.add') }}</pcg-btn>
            </div>
        </div>
        <b-row v-for="error in errors" :key="error.id">
            <b-col>
                <img src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                <span class="my-error">{{error}}</span>
            </b-col>
        </b-row>
        <transition-group name="fade" mode="out-in">
            <div v-for="(author, index) in authors" :key="index + 0" style="margin-bottom: 1rem" class="orcid-author">
                <div class="co-author" v-if="translation">{{ $t('views.orcid_publications.original_co_author') }}</div>
                <div class="co-author" v-else-if="isAuthors && publicationTypeId !== 7 && publicationTypeId !== 32 && publicationTypeId !== 35 && publicationTypeId !== 45">{{ $t('views.orcid_publications.co_author') }}</div>
                <div class="co-author" v-else-if="isAuthors && (publicationTypeId === 7 || publicationTypeId === 35)">{{ $t('views.orcid_publications.co_creator') }}</div>
                <div class="co-author" v-else-if="isAuthors && publicationTypeId === 45">{{ $t('views.orcid_publications.contributor') }}</div>
                <div class="co-author" v-else-if="isAuthors && publicationTypeId === 32">{{ $t('views.orcid_publications.co_contractor') }}</div>
                <div class="co-author" v-else>{{ $t('views.orcid_publications.co_redactor') }}</div>
                <orcid-author
                  :author="author"
                  :index="index"
                  @remove-author="removeAuthor"
                  @move-up="moveUp"
                  @move-down="moveDown"
                  :publicationTypeId="publicationTypeId"
                  :managerDisciplines="managerDisciplines"
                />
                <div v-if="publicationTypeId === 7" class="co-author">{{ $t('views.orcid_publications.percentage') }}</div>
                <b-row no-gutters v-if="publicationTypeId === 7">
                    <b-col cols="6">
                        <pcg-input-number class="my-input-number" :showLabel="false" v-model="author.part" :min="0" :max="100" :step="1" />
                    </b-col>
                </b-row>
            </div>
        </transition-group>
        <!-- <div v-if="hasRight('view_add_author')" class="d-flex justify-content-end">-->
        <!--     <div>-->
        <!--         <pcg-btn size="small" variant="additional" @click="addAuthor">{{ $t('components.dn_field.add') }}</pcg-btn>-->
        <!--     </div>-->
        <!-- </div>-->
    </div>
</template>

<script>
import OrcidAuthor from './OrcidAuthor'
import hasRight from '../mixins/has_right'

export default {
  name: 'OrcidAuthors',
  mixins: [hasRight],
  components: { OrcidAuthor },
  props: {
    authors: Array,
    isAuthors: {
      type: Boolean,
      default: true
    },
    publicationTypeId: Number,
    errors: Array,
    managerDisciplines: Array,
    translation: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    closeAuthors () {
      this.$parent.authorsSave()
    },
    addAuthor () {
      this.authors.push({
        id: '',
        name: null,
        authorType: 0,
        publicationTypeIds: this.publicationTypeId,
        part: 0
      })
      this.$nextTick(() => {
        const el = document.querySelector('.orcid-authors .orcid-author:last-of-type .university-authors input')
        el.focus()
      })
    },
    moveUp (authorIdx) {
      this.$emit('move-up', authorIdx)
    },
    moveDown (authorIdx) {
      this.$emit('move-down', authorIdx)
    },
    removeAuthor (authorId) {
      this.authors.splice(authorId, 1)
    },
    mouseEnter (ev) {
      const elem = ev.target
      const allChildren = this.$children
      allChildren.forEach((c) => {
        if (c.$children[0].$options.name === 'LiveSearch') {
          if (c.$children[0].$el === elem) {
            c.$children[0].listActivated = true
          } else {
            c.$children[0].listActivated = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
    @import '../assets/stylesheets/vars';

    .co-author {
        font-weight: 400;
        font-size: $font-size-s;
        color: $pcg-gray;
        position: relative;
        left: .3rem;
    }

    /deep/ .pcg-field {
        border: none !important;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2) !important;
    }

    .my-header {
        margin-bottom: 2rem;
    }

    .edit-authors {
        font-size: $font-size-m;
        font-weight: 400;
        color: $pcg-gray;
        margin-right: .5rem;
    }

    .question-mark {
        font-size: $font-size-m;
        color: $pcg-white;
        background: $pcg-light-gray-background;
        font-weight: 400;
        padding: .05rem .45rem;
        border-radius: 50%;
    }

    /deep/ .check-list {
        width: 247px !important;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

    /deep/ .pcg-button {
        margin: 0;
    }

    .my-error {
        color: $pcg-red;
        font-weight: 400;
        margin-left: .3rem;
    }

    .my-exclamation-mark {
        height: 14px;
        width: 14px;
        padding: .2rem .2rem;
        border-radius: 20px;
        text-align: center;
        background-color: $pcg-red;
        opacity: 0.8;
        position: relative;
        bottom: .2rem;
    }

    .my-input-number {
        /deep/ .pcg-input {
            height: calc(1.5em + 0.75rem + 2px);
        }
    }
</style>

<style lang="scss" scoped>
    @import "../assets/stylesheets/vars-contrast";
    .contrast {
        .co-author {
            color: $pcg-gray;
        }
        .edit-authors {
            color: $pcg-gray;
        }
        .question-mark {
            color: $pcg-white;
            background: $pcg-light-gray;
        }
        .my-error {
            color: $pcg-red;
        }
        .my-exclamation-mark {
            background-color: $pcg-red;
        }
    }
</style>
