import api from './index'

const resource = 'discipline_managers'

export default {
  getDisciplines () {
    const url = '/get_manager_disciplines'
    return api.get(url)
  }
}
