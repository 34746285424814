import api from './index'
import qs from 'qs'

const resource = 'publishers'

export default {
  getPublishers (query = null) {
    let url = ''
    const params = qs.stringify(query)
    url = resource + '?' + params
    return api.get(url)
  },
  getPublisher (id) {
    let url = ''
    url = resource + '/' + id
    return api.get(url)
  },
  getPublications (id) {
    const url = `${resource}/${id}/pre_destroy`
    return api.get(url)
  },
  createPublisher (publisher) {
    const uri = `${resource}`
    const body = {
      publisher: publisher
    }
    return api.post(uri, body)
  },
  updatePublisher (publisher) {
    const uri = `${resource}/${publisher.id}`
    const body = {
      publisher: publisher
    }
    return api.put(uri, body)
  },
  deletePublisher (id) {
    let url = resource
    url += '/' + id
    return api.delete(url)
  }

}
