import api from './index'
import qs from 'qs'

const resource = 'financing_institutions'

export default {
  getInstitutions (query = null) {
    let url = ''
    const params = qs.stringify(query)
    url = resource + '?' + params
    return api.get(url)
  },
  getInstitution (id) {
    let url = ''
    url = resource + '/' + id
    return api.get(url)
  },
  createInstitution (institution) {
    const uri = `${resource}`
    const body = {
      financing_institution: institution
    }
    return api.post(uri, body)
  },
  updateInstitution (institution) {
    const uri = `${resource}/${institution.id}`
    const body = {
      financing_institution: institution
    }
    return api.put(uri, body)
  }
}
