<template>
  <pcg-box :header="header" class="main-class">
    <b-row>
      <b-col cols="1">
        <i v-if="statements.disciplineAccepted" class="pcg-icon-check my-icon"></i>
        <span v-else class="free-space"></span>
      </b-col>
      <b-col>
        <span class="title">
          {{ $t('components.dn_statements.discipline_selection') }}
        </span>
        <div class="change-statement" style="display: inline-block">
          <router-link :to="{ name: 'statements_discipline_path', params: {id: employee_id } }" v-if="hasRight('edit_statements') || hasRight('edit_own_statements')">
            <i class="fas fa-pen my-pen"></i>
            <span style="text-transform: lowercase">{{ $t('components.dn_statements.choose_discipline') }}</span>
          </router-link>
        </div>
        <spinner v-if="deleteDisciplineSpinner" class="delete-spinner"/>
      </b-col>
    </b-row>
    <b-row class="oneline">
      <b-col cols="1">
        <span class="free-space"></span>
      </b-col>
      <b-col>
        <div v-if="previousStatements.length > 0">
          <b-row class="one-subject" v-show="!statement.doctoralSchool" v-for="statement in previousStatements" :key="statement.id">
            <b-col cols="1">
              <div class="my-dot"></div>
            </b-col>
            <b-col>
              <div v-if="$i18n.locale === 'pl'">
                <span class="subject-name">{{statement.name}}</span>
                <span class="statement-dates">({{ statement.share }}%)</span>
              </div>
              <div v-else class="subject-name">
                <span class="subject-name">{{statement.nameEn}}</span>
                <span class="statement-dates">({{ statement.share }}%)</span>
              </div>
              <div v-if="statement.validityTo === 9999" class="statement-dates">
                <span v-if="statement.validityFromDate">({{ $d(new Date(statement.validityFromDate), 'short') }}-{{ $t('general.active') }})</span>
                <span v-else>({{ statement.validityFrom }}-{{ $t('general.active') }})</span>
              </div>
              <div v-else class="statement-dates">
                <span v-if="statement.validityFromDate">({{ $d(new Date(statement.validityFromDate), 'short') }}-{{ $d(new Date(statement.validityToDate), 'short') }})</span>
                <span v-else>({{ statement.validityFrom }}-{{ statement.validityTo }})</span>
              </div>
              <div>
                <a href="javascript:;" v-if="hasRight('edit_statements') || hasRight('edit_own_statements')" class="my-edit" @click="editDisciplineStatementModal(statement.id)">
                  <i class="fa fa-pen my-pen"></i>
                  <span>{{ $t('components.dn_statements.edit') }}</span>
                </a>
                <a href="javascript:;" v-if="hasRight('edit_statements') || hasRight('edit_own_statements')" class="my-remove" @click="removeDisciplineStatementModal(statement.id)">
                  <i class="fa fa-trash my-pen"></i>
                  <span>{{ $t('components.dn_statements.remove') }}</span>
                </a>
              </div>
            </b-col>
          </b-row>
          <b-modal ref="editDisciplineStatement"
                   :hide-header="true"
                   footer-class="my-modal-footer"
                   :centered="true"
                   v-bind:ok-title="$t('general.confirm')"
                   ok-variant="primary"
                   :no-close-on-esc="true"
                   @ok="editDisciplineStatement(statementId)"
                   v-bind:cancel-title="$t('general.cancel')"
                   cancel-variant="default"
                   content-class="my-modal-content"
          >
            <div class="remove-statement-title">{{ $t('components.dn_statements.edit_statement_end_date') }}</div>
            <div class="remove-statement-confirmation" style="margin-bottom: 2rem">{{ $t('components.dn_statements.edit_statement_end_date_confirmation') }}</div>
            <div class="select-date d-flex justify-content-center">{{ $t('components.dn_statements.select_a_date') }}</div>
            <pcg-datetime-picker
              v-show="!newEndDateActive"
              class="my-datetimepicker"
              :locale="$i18n.locale"
              variant="date"
              v-model="newEndDate"
            />
            <pcg-datetime-picker
              v-show="newEndDateActive"
              class="my-datetimepicker"
              :locale="$i18n.locale"
              variant="date"
              :disabled="true"
            />
            <div class="select-active d-flex justify-content-center">{{ $t('components.dn_statements.select_active') }}</div>
            <div class="d-flex justify-content-center" style="margin-bottom: 2rem">
              <pcg-checkbox v-model="newEndDateActive" variant="normal">
                {{ $t('components.dn_statements.active') }}
              </pcg-checkbox>
            </div>
          </b-modal>
          <b-modal ref="removeDisciplineStatement"
                   :hide-header="true"
                   footer-class="my-modal-footer"
                   :centered="true"
                   v-bind:ok-title="$t('general.confirm')"
                   ok-variant="primary"
                   :no-close-on-esc="true"
                   @ok="removeDisciplineStatement(statementId, true)"
                   v-bind:cancel-title="$t('general.cancel')"
                   cancel-variant="default"
                   content-class="my-modal-content"
          >
            <div class="remove-statement-title">{{ $t('views.statements.list.remove_statement') }}</div>
            <div class="remove-statement-confirmation">{{ $t('views.statements.list.remove_statement_confirmation') }}</div>
          </b-modal>
        </div>
        <b-row v-else class="not-filled-line">
          <b-col cols="1">
            <img src="../assets/images/exclamation-mark-red.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')">
          </b-col>
          <b-col>
            <span class="not-filled">{{ $t('components.dn_statements.choose_disciplines') }}</span>
          </b-col>
        </b-row>
        <b-row v-if="previousStatements.length === 0 && numberNStatements > 0" class="not-filled-line">
          <b-col cols="1">
            <img src="../assets/images/exclamation-mark-red.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')">
          </b-col>
          <b-col>
            <span class="not-filled">{{ $t('components.dn_statements.no_discipline') }}</span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="1">
        <i v-if="statements.numberNAccepted && previousStatements.length > 0" class="pcg-icon-check my-icon"></i>
        <span v-else class="free-space"></span>
      </b-col>
      <b-col>
        <span class="title">{{ $t('components.dn_statements.application') }}</span>
        <div v-if="previousStatements.length > 0" class="change-statement" style="display: inline-block" :disabled="recalcSpinner">
          <router-link :to="{ name: 'statements_number_n_path', params: {id: employee_id } }" v-if="hasRight('edit_statements') || hasRight('edit_own_statements')">
            <i class="fas fa-pen my-pen"></i>
             <span>{{ $t('components.dn_statements.edit_n') }}</span>
          </router-link>
        </div>
        <spinner v-if="deleteNumberNSpinner" class="delete-spinner"/>
      </b-col>
    </b-row>
    <b-row class="oneline">
      <b-col cols="1">
        <span class="free-space"></span>
      </b-col>
      <b-col>
        <b-row v-if="wrongNumberN" class="not-filled-line">
          <b-col cols="1">
            <img src="../assets/images/exclamation-mark-red.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')">
          </b-col>
          <b-col>
            <span class="not-filled">{{ $t('components.dn_statements.number_n_error') }}</span>
          </b-col>
        </b-row>
        <div v-if="numberNStatements.length > 0">
          <b-row class="one-subject" v-for="n_statement in numberNStatements" :key="n_statement.id">
            <b-col cols="1">
              <div class="my-dot"></div>
            </b-col>
            <b-col>
              <div v-if="$i18n.locale === 'pl'" class="subject-name">{{n_statement.name}}</div>
              <div v-else class="subject-name">{{n_statement.nameEn}}</div>
              <div v-if="n_statement.validityTo === 9999" class="statement-dates">
                <span v-if="n_statement.validityFromDate">({{ $d(new Date(n_statement.validityFromDate), 'short') }}-{{ $t('general.active') }})</span>
                <span v-else>({{ n_statement.validityFrom }}-{{ $t('general.active') }})</span>
              </div>
              <div v-else class="statement-dates">
                <span v-if="n_statement.validityFromDate">({{ $d(new Date(n_statement.validityFromDate), 'short') }}-{{ $d(new Date(n_statement.validityToDate), 'short') }})</span>
                <span v-else>({{ n_statement.validityFrom }}-{{ n_statement.validityTo }})</span>
              </div>
              <div>
                <a href="javascript:;" v-if="hasRight('edit_statements') || hasRight('edit_own_statements')" class="my-edit" @click="editNumberNStatementModal(n_statement.id)">
                  <i class="fa fa-pen my-pen"></i>
                  <span>{{ $t('components.dn_statements.edit') }}</span>
                </a>
                <a href="javascript:;" v-if="hasRight('edit_statements') || hasRight('edit_own_statements')" class="my-remove" @click="removeNumberNStatementModal(n_statement.id)">
                  <i class="fa fa-trash my-pen"></i>
                  <span>{{ $t('components.dn_statements.remove') }}</span>
                </a>
              </div>
            </b-col>
          </b-row>
          <b-modal ref="editNumberNStatement"
                   :hide-header="true"
                   footer-class="my-modal-footer"
                   :centered="true"
                   v-bind:ok-title="$t('general.confirm')"
                   ok-variant="primary"
                   :no-close-on-esc="true"
                   @ok="editDisciplineStatement(statementId)"
                   v-bind:cancel-title="$t('general.cancel')"
                   cancel-variant="default"
                   content-class="my-modal-content"
          >
            <div class="remove-statement-title">{{ $t('components.dn_statements.edit_statement_end_date') }}</div>
            <div class="select-date d-flex justify-content-center">{{ $t('components.dn_statements.select_a_date') }}</div>
            <pcg-datetime-picker
              v-show="!newEndDateActive"
              class="my-datetimepicker"
              :locale="$i18n.locale"
              variant="date"
              v-model="newEndDate"
            />
            <pcg-datetime-picker
              v-show="newEndDateActive"
              class="my-datetimepicker"
              :locale="$i18n.locale"
              variant="date"
              :disabled="true"
            />
            <div class="select-active d-flex justify-content-center">{{ $t('components.dn_statements.select_active') }}</div>
            <div class="d-flex justify-content-center" style="margin-bottom: 2rem">
              <pcg-checkbox v-model="newEndDateActive" variant="normal">
                {{ $t('components.dn_statements.active') }}
              </pcg-checkbox>
            </div>
          </b-modal>
          <b-modal ref="removeNumberNStatement"
                   :hide-header="true"
                   footer-class="my-modal-footer"
                   :centered="true"
                   v-bind:ok-title="$t('general.confirm')"
                   ok-variant="primary"
                   :no-close-on-esc="true"
                   @ok="removeNumberNStatement(statementId, true)"
                   v-bind:cancel-title="$t('general.cancel')"
                   cancel-variant="default"
                   content-class="my-modal-content"
          >
            <div class="remove-statement-title">{{ $t('views.statements.list.remove_statement') }}</div>
            <div class="remove-statement-confirmation">{{ $t('views.statements.list.remove_n_statement_confirmation') }}</div>
          </b-modal>
        </div>
        <b-row v-else class="not-filled-line">
          <b-col cols="1">
            <img src="../assets/images/exclamation-mark-red.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')">
          </b-col>
          <b-col>
            <span class="not-filled">{{ $t('components.dn_statements.fill_statement') }}</span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!--
    <b-row>
      <b-col cols="1">
        <i v-if="statements.publicationAccepted" class="pcg-icon-check my-icon"></i>
        <span v-else class="free-space"></span>
      </b-col>
      <b-col>
        <span class="title-publication">{{ $t('components.dn_statements.evaluation') }}</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="1">
        <span class="free-space"></span>
      </b-col>
      <b-col>
        <div v-if="statements.publicationAccepted" class="changed-deadline">{{ $t('components.dn_statements.changed') }} {{ $d(new Date(statements.publicationChangedDate), 'longDate') }}</div>
        <b-row v-else class="not-filled-line">
          <b-col cols="1">
            <img src="../assets/images/exclamation-mark-orange.svg" class="my-exclamation-mark-orange">
          </b-col>
          <b-col>
            <span class="deadline">{{ $tc('components.dn_statements.deadline', statements.publicationDeadline) }}</span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    -->
  </pcg-box>
</template>

<script>
import ApiEmployeeStatements from '../api/employee_statements'
import hasRight from '../mixins/has_right'
import waitForJobs from '../mixins/wait_for_jobs'
import unexpectedError from '../mixins/unexpected_error'
import Spinner from './Spinner'
import { mapGetters } from 'vuex'
export default {
  name: 'dn-statements',
  components: { Spinner },
  mixins: [hasRight, unexpectedError, waitForJobs],
  props: {
    statements: Object,
    innerHeader: Boolean,
    employee_id: Number,
    previousStatements: {
      type: Array,
      default: () => []
    },
    numberNStatements: {
      type: Array,
      default: () => []
    },
    wrongNumberN: Boolean
  },
  data: function () {
    return {
      recalcSpinner: false,
      chosenDiscipline: [],
      userRole: '',
      statementId: null,
      deleteDisciplineSpinner: false,
      deleteNumberNSpinner: false,
      newEndDate: null,
      newEndDateActive: false
    }
  },
  mounted () {
    this.userRole = this.$store.getters['auth/currentRole']
    this.previousStatements.forEach(statement => {

    })
  },
  computed: {
    ...mapGetters('auth', ['rights']),
    header () {
      return this.innerHeader ? null : this.$t('components.dn_statements.header')
    }
  },
  methods: {
    cleanDisciplines () {
      for (let i = 0; i < this.chosenDiscipline.length; i++) {
        this.chosenDiscipline[i] = false
      }
      this.chosenDiscipline = []
    },
    checkDisciplines () {
      let exist = false
      if (Array.isArray(this.previousStatements) && this.previousStatements.length) {
        exist = true
      }
      return exist
    },
    editDisciplineStatementModal (id) {
      this.statementId = id
      let statement = null
      for (let i = 0; i < this.previousStatements.length; i++) {
        if (this.previousStatements[i].id === this.statementId) {
          statement = this.previousStatements[i]
        }
      }
      if (statement.validityToDate === '9999-12-31') {
        this.newEndDateActive = true
      } else {
        this.newEndDate = statement.validityToDate
      }
      this.$refs.editDisciplineStatement.show()
    },
    editDisciplineStatement (id) {
      let newDate = null
      if (this.newEndDateActive) {
        newDate = '9999-12-31'
      } else {
        newDate = this.newEndDate
      }
      ApiEmployeeStatements.editStatement(id, newDate)
        .then(result => {
          this.statementsUpdated()
        })
        .catch(error => {
          this.newEndDateActive = false
          console.log(error.response.data)
          this.$toastr.e(this.$t(error.response.data.toString()))
        })
    },
    removeDisciplineStatementModal (id) {
      this.statementId = id
      this.$refs.removeDisciplineStatement.show()
    },
    removeDisciplineStatement (id, userClicked = false) {
      ApiEmployeeStatements.removeStatement(id, userClicked)
        .then(result => {
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.deleteDisciplineSpinner = true
            this.waitForJob(jobId, this.statementsUpdated, this.statementError)
          }
        })
    },
    editNumberNStatementModal (id) {
      this.statementId = id
      let statement = null
      for (let i = 0; i < this.numberNStatements.length; i++) {
        if (this.numberNStatements[i].id === this.statementId) {
          statement = this.numberNStatements[i]
        }
      }
      if (statement.validityToDate === '9999-12-31') {
        this.newEndDateActive = true
      } else {
        this.newEndDate = statement.validityToDate
      }
      this.$refs.editNumberNStatement.show()
    },
    removeNumberNStatementModal (id) {
      this.statementId = id
      this.$refs.removeNumberNStatement.show()
    },
    removeNumberNStatement (id, userClicked = false) {
      ApiEmployeeStatements.removeStatement(id, userClicked)
        .then(result => {
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.deleteNumberNSpinner = true
            this.waitForJob(jobId, this.statementsUpdated, this.statementError)
          }
        })
    },
    statementsUpdated () {
      this.deleteDisciplineSpinner = false
      this.deleteNumberNSpinner = false
      this.newEndDateActive = false
      this.$emit('numberNUpdated')
    },
    statementError () {
      this.deleteDisciplineSpinner = false
      this.deleteNumberNSpinner = false
      this.unexpectedError()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .pcg-icon-check {
    font-size: $font-size-s;
    line-height: 3;
    margin-right: 1rem;
    color: #1d8e00;
  }

  .free-space {
    margin-left: 2.33rem;
  }

  .title {
    color: $main-color;
    font-weight: 400;
    font-size: $font-size-l;
    margin-right: .5rem;
  }

  .title-publication {
    color: $main-color;
    font-weight: 600;
    font-size: $font-size-l;
  }

  .changed {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-s;
  }

  .change-discipline {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-s;
  }

  .change-numbern {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-s;
    position: relative;
    bottom: .4rem;
    @media (max-width: 1561.98px) {
      bottom: .4rem;
    }
    @media (max-width: 991.98px) {
      bottom: .4rem;
    }
    @media (max-width: 771.98px) {
      bottom: .4rem;
    }
    @media (max-width: 767.98px) {
      bottom: .4rem;
    }
  }

  .change-numbern-alternative {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-s;
    position: relative;
    bottom: .4rem;
    @media (max-width: 991.98px) {
      bottom: .4rem;
    }
    @media (max-width: 804.98px) {
      bottom: .4rem;
    }
    @media (max-width: 767.98px) {
      bottom: .4rem;
    }
  }

  .changed-deadline {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-s;
    position: relative;
    bottom: .2rem;
  }

  .deadline {
    color: #ffb502;
    font-size: $font-size-s;
    font-weight: 400;
    margin-top: .2rem;
  }

  .subject {
    color: $main-color;
    font-weight: 400;
    font-size: $font-size-s;
    position: relative;
    bottom: .6rem;
    margin-right: .5rem;
  }

  .main-class {
    /deep/ .header-title {
      font-size: $font-size-xl;
      font-weight: 600;
      letter-spacing: .1rem;
      text-transform: uppercase;
    }

    .change-statement {
      color: $main-active-color;
      font-size: 1em;
      font-weight: 400;
      cursor: pointer;
      a {
        color: unset;
        text-decoration: none;
      }

    }
  }

  .my-dot {
    background: $pcg-lightest-gray-background;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    display: inline-block;
    position: relative;
    top: .1rem;
  }

  .subject-name {
    color: $pcg-gray;
    font-size: $font-size-m;
    font-weight: 400;
  }

  .one-subject {
    margin-bottom: .3rem;
  }

  .oneline {
    margin-bottom: .7rem;
  }

  .not-filled {
    font-size: $font-size-s;
    font-weight: 400;
    color: $pcg-red;
    margin-top: .2rem;
  }

  .not-filled-line {
    margin-top: .2rem;
  }

  .my-icon {
    position: relative;
    bottom: .35rem;
  }

  .my-exclamation-mark {
    width: 18px;
    height: 18px;
    padding: 1px;
    border-radius: 30px;
    border: 2px solid;
    border-color: $pcg-red;
  }

  .my-exclamation-mark-orange {
    width: 18px;
    height: 18px;
    padding: 1px;
    border-radius: 30px;
    border: 2px solid;
    border-color: #ffb502;
  }

  .my-modal {
    padding: 5px 10px;
  }

  .number-n-modal {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .choose_disciplines {
    font-size: $font-size-s;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1rem;
  }

  .checkbox-line {
    display: inline-block;
  }

  .change-numbern-title {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .change-numbern-confirmation {
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1rem;
  }

  .my-pen {
    font-size: 9px;
    position: relative;
    bottom: .05rem;
  }

  .my-spinner {
    color: $main-active-color;
  }

  .delete-spinner {
    color: $pcg-red;
  }

  .remove-statement-title {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .remove-statement-confirmation {
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1rem;
    white-space: pre-wrap;
  }

  .my-remove {
    font-size: 1em;
    color: $pcg-red;
    cursor: pointer;
    font-weight: 400;
  }

  .statement-dates {
    color: $pcg-gray;
    font-size: 1em;
    margin-right: .2rem;
  }

  .my-edit {
    color: $main-active-color;
    font-size: 1em;
    font-weight: 400;
    cursor: pointer;
    margin-right: .5rem;
  }

  .my-datetimepicker {
    /deep/ .pcg-datetimepicker-input {
      font-weight: 400;
      color: $pcg-dark-gray;
      height: 30px;
    }
    /deep/ .pcg-datetimepicker-btn {
      height: auto;
    }
  }

  .select-date {
    color: $pcg-gray;
    font-weight: 400;
    margin-top: 1rem;
  }

  .select-active {
    color: $pcg-gray;
    font-weight: 400;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .pcg-icon-check {
      color: #1d8e00;
    }
    .title {
      color: $main-color;
    }
    .title-publication {
      color: $main-color;
    }
    .changed {
      color: $pcg-gray;
    }
    .change-discipline {
      color: $pcg-gray;
    }
    .change-numbern {
      color: $pcg-gray;
    }
    .change-numbern-alternative {
      color: $pcg-gray;
    }
    .changed-deadline {
      color: $pcg-gray;
    }
    .deadline {
      color: $pcg-orange;
    }
    .subject {
      color: $main-color;
    }
    .main-class {
      .change-statement {
        color: $main-active-color;
      }
    }
    .my-dot {
      background: $pcg-lightest-gray;
    }
    .subject-name {
      color: $pcg-gray;
    }
    .not-filled {
      color: $pcg-red;
    }
    .my-exclamation-mark {
      border-color: $pcg-red;
      background-color: $pcg-white;
    }
    .my-exclamation-mark-orange {
      border-color: $pcg-orange;
    }
    .number-n-modal {
      color: $pcg-gray;
    }
    .choose_disciplines {
      color: $pcg-gray;
    }
    .change-numbern-title {
      color: $pcg-gray;
    }
    .change-numbern-confirmation {
      color: $pcg-gray;
    }
    .my-spinner {
      color: $main-active-color;
    }
    .delete-spinner {
      color: $pcg-red;
    }
    .remove-statement-title {
      color: $pcg-gray;
    }
    .remove-statement-confirmation {
      color: $pcg-gray;
    }
    .my-remove {
      color: $pcg-red;
    }
    .statement-dates {
      color: $pcg-gray;
    }
    .my-edit {
      color: $main-active-color;
    }
    .my-datetimepicker {
      /deep/ .pcg-datetimepicker-input {
        color: $pcg-dark-gray;
      }
    }
  }
</style>
