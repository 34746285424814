<template>
  <div id="base-beam" class="beam" :style="{ paddingLeft: padLeft }">
    <div class="after"/>
    <slot/>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('menu')

export default {
  name: 'BaseBeam',
  data () {
    return {
      padLeft: '90px'
    }
  },
  methods: {
    setPadding () {
      this.padLeft = this.menuOpen ? '280px' : '110px'
      document.getElementById('base-beam').style.paddingLeft = this.padLeft
    }
  },
  computed: {
    ...mapGetters(['menuOpen'])
  },
  watch: {
    menuOpen: {
      handler () {
        this.setPadding()
      }
    },
    $mq: function () {
      if (this.$mq !== 'sm' && this.$mq !== 'xs') {
        this.setPadding()
      }
    }
  },
  mounted () {
    if (this.$mq !== 'sm' && this.$mq !== 'xs') {
      this.setPadding()
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/stylesheets/vars';

  .beam {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 75px;
    background: $pcg-white;
    height: 64px;
    font-weight: bold;
    transition: padding-left .3s;
  }

  .after {
    position: absolute;
    background: $pcg-white;
    width: 1000px;
    right: -1000px;
    height: 100%;
  }
</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .beam {
      background: $pcg-white;
    }

    .after {
      background: $pcg-white;
    }
  }
</style>
