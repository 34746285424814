import Auth from '../../api/auth'
import Cookies from 'js-cookie'
import { decamelize } from '@ridi/object-case-converter'
import Vue from 'vue'
import { store } from '../index'
import ApiAuth from '../../api/auth'

const LOCAL_STORAGE_AUTH_TOKEN_KEY = 'dnAT'
const LOCAL_STORAGE_REDIRECT_TO_KEY = 'dnRT'
const LOCAL_STORAGE_USER_ID_KEY = 'dnUI'
const LOCAL_STORAGE_USER_EMPLOYEE_ID_KEY = 'dnEI'
const LOCAL_STORAGE_USER_FIRST_NAME_KEY = 'dnFN'
const LOCAL_STORAGE_USER_LAST_NAME_KEY = 'dnLN'
const LOCAL_STORAGE_USER_ROLES_KEY = 'dnRS'
const LOCAL_STORAGE_USER_CURRENT_ROLE_KEY = 'dnCR'
const LOCAL_STORAGE_USER_AVATAR_KEY = 'dnAV'
const LOCAL_STORAGE_USER_ACADEMY = 'dnAC'
const LOCAL_STORAGE_USER_AID = 'dnAI'
const LOCAL_STORAGE_USER_FID = 'dnFI'
const LOCAL_STORAGE_USER_FCIDS = 'dnFCIDS'
const LOCAL_STORAGE_USER_VR = 'dnVR'
const LOCAL_STORAGE_EXPIRE = 'dnExp'
const LOCAL_STORAGE_CONTRAST = 'dnCon'

const auth = {
  namespaced: true,
  state: {
    id: localStorage.getItem(LOCAL_STORAGE_USER_ID_KEY) || null,
    token: localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY) || null,
    redirectTo: JSON.parse(localStorage.getItem(LOCAL_STORAGE_REDIRECT_TO_KEY)) || null,
    employeeId: localStorage.getItem(LOCAL_STORAGE_USER_EMPLOYEE_ID_KEY) || null,
    firstName: localStorage.getItem(LOCAL_STORAGE_USER_FIRST_NAME_KEY) || null,
    lastName: localStorage.getItem(LOCAL_STORAGE_USER_LAST_NAME_KEY) || null,
    roles: JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_ROLES_KEY)) || null,
    currentRole: localStorage.getItem(LOCAL_STORAGE_USER_CURRENT_ROLE_KEY) || null,
    avatar: localStorage.getItem(LOCAL_STORAGE_USER_AVATAR_KEY) || null,
    academyName: localStorage.getItem(LOCAL_STORAGE_USER_ACADEMY) || null,
    academyId: localStorage.getItem(LOCAL_STORAGE_USER_AID) || null,
    facultyId: localStorage.getItem(LOCAL_STORAGE_USER_FID) || null,
    facultyChildrenIds: localStorage.getItem(LOCAL_STORAGE_USER_FCIDS) || null,
    expire: localStorage.getItem(LOCAL_STORAGE_EXPIRE) || new Date().getTime() - 1000,
    rights: JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_VR)) || null,
    contrast: localStorage.getItem(LOCAL_STORAGE_CONTRAST) || null,
    signingOut: false
  },
  getters: {
    signingOut: state => state.signingOut,
    // token: state => state.token,
    signedIn: state => {
      if (process.env.RAILS_ENV !== 'development') {
        const zm = Vue.$cookies.get('token')
        if (zm) {
          return true
        } else {
          return false
        }
      } else {
        return !!state.id
      }
    },
    getRedirectTo: state => state.redirectTo,
    roles: state => state.roles,
    currentRole: state => state.currentRole,
    id: state => state.id,
    employeeId: state => state.employeeId,
    firstName: state => state.firstName,
    lastName: state => state.lastName,
    fullName: state => `${state.firstName} ${state.lastName}`,
    avatar: state => state.avatar,
    academyName: state => state.academyName,
    academyId: state => state.academyId,
    facultyId: state => state.facultyId,
    facultyChildrenIds: (state) => {
      return state.facultyChildrenIds ? state.facultyChildrenIds.split(',') : null
    },
    expire: state => { return state.expire < new Date().getTime() },
    rights: state => state.rights,
    contrast: state => state.contrast
  },
  mutations: {
    setSigningOut (state, value) {
      state.signingOut = value
    },
    // setToken (state, token) {
    //   state.token = token
    // },
    setRedirectTo (state, redirectTo) {
      state.redirectTo = redirectTo
    },
    setUser (state, payload) {
      state.roles = payload.roles
      state.id = payload.id
      state.employeeId = payload.employeeId
      state.firstName = payload.firstName
      state.lastName = payload.lastName
      // console.log('setUser - payload.expiresAt = ', payload.expiresAt)
      state.expire = new Date(payload.expiresAt).getTime()
      // console.log('setUser - State.expire = ', state.expire)
      payload.avatar ? state.avatar = payload.avatar.url : state.avatar = null
      payload.academy ? state.academyName = payload.academy.name : state.academyName = null
      payload.academy ? state.academyId = payload.academy.id : state.academyId = null
      payload.faculty ? state.facultyId = payload.faculty.id : state.facultyId = null
      payload.rights ? state.rights = decamelize(payload.rights) : state.rights = {}
    },
    setRights (state, rights) {
      state.rights = decamelize(rights)
    },
    setCurrentRole (state, role) {
      state.currentRole = role
    },
    updateState (state) {
      state.id = localStorage.getItem(LOCAL_STORAGE_USER_ID_KEY) || null
      // state.token = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY) || null
      state.redirectTo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_REDIRECT_TO_KEY)) || null
      state.employeeId = localStorage.getItem(LOCAL_STORAGE_USER_EMPLOYEE_ID_KEY) || null
      state.firstName = localStorage.getItem(LOCAL_STORAGE_USER_FIRST_NAME_KEY) || null
      state.lastName = localStorage.getItem(LOCAL_STORAGE_USER_LAST_NAME_KEY) || null
      state.roles = JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_ROLES_KEY)) || null
      state.currentRole = localStorage.getItem(LOCAL_STORAGE_USER_CURRENT_ROLE_KEY) || null
      state.avatar = localStorage.getItem(LOCAL_STORAGE_USER_AVATAR_KEY) || null
      state.academyName = localStorage.getItem(LOCAL_STORAGE_USER_ACADEMY) || null
      state.academyId = localStorage.getItem(LOCAL_STORAGE_USER_AID) || null
      state.facultyId = localStorage.getItem(LOCAL_STORAGE_USER_FID) || null
      state.facultyChildrenIds = localStorage.getItem(LOCAL_STORAGE_USER_FCIDS) || null
      state.expire = localStorage.getItem(LOCAL_STORAGE_EXPIRE) || new Date().getTime() - 1000
      state.rights = decamelize(JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_VR))) || null
      state.contrast = localStorage.getItem(LOCAL_STORAGE_CONTRAST) || null
    }
  },
  actions: {
    checkUser ({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        Auth.fetchUser()
          .then(response => {
            if (response && response.data && response.data.data) {
              // if (response.data.data.attributes.token) {
              //   const authToken = response.data.data.attributes.token
              //   localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN_KEY, authToken)
              //   commit('setToken', authToken)
              // }
              setItems(response)
              commit('setUser', response.data.data.attributes)
              commit('setRights', response.data.data.attributes.rights)
              resolve(response)
            }
          })
          .catch(error => {
            removeItems()
            commit('setUser', {})
            commit('setRights', {})
            reject(error)
          })
      })
    },
    fetchRights ({ commit, dispatch, state }, currentRole) {
      return new Promise((resolve, reject) => {
        Auth.fetchRights(currentRole)
          .then(response => {
            localStorage.setItem(LOCAL_STORAGE_USER_VR, JSON.stringify(decamelize(response.data)))
            commit('setRights', response.data)
            resolve(response)
          })
          .catch(error => {
            localStorage.removeItem(LOCAL_STORAGE_USER_VR)
            commit('setRights', {})
            reject(error)
          })
      })
    },
    fetchUser ({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        Auth.fetchUser()
          .then(response => {
            if (response && response.data && response.data.data) {
              setItems(response)
              // commit('setToken', authToken)
              commit('setUser', response.data.data.attributes)
              commit('setRights', response.data.data.attributes.rights)
              resolve(response)
            }
          })
          .catch(error => {
            removeItems()
            commit('setUser', {})
            commit('setRights', {})
            reject(error)
          })
      })
    },
    signIn ({ commit, dispatch, state }, params) {
      return new Promise((resolve, reject) => {
        Auth.signIn(params)
          .then(response => {
            if (response) {
              console.log('signIn:')
              console.log(response.data)
              // const authToken = response.headers.authorization
              if (response.data.location) {
                window.location = response.data.location
              } else {
                // const authToken = response.data.data.attributes.token
                // localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN_KEY, authToken)
                setItems(response)
                // commit('setToken', authToken)
                commit('setUser', response.data.data.attributes)
                if (state.currentRole !== response.data.data.attributes.currentRole) {
                  if (state.currentRole == null) {
                    localStorage.setItem(LOCAL_STORAGE_USER_CURRENT_ROLE_KEY, response.data.data.attributes.currentRole)
                    dispatch('setCurrentRole', state.currentRole)
                    commit('setRights', response.data.data.attributes.rights)
                  } else {
                    dispatch('setCurrentRole', state.currentRole)
                    ApiAuth.setCurrentRole(state.currentRole)
                      .then(response => {
                        dispatch('setRights', response.data)
                        this.$nextTick(() => {
                          // this.$router.go()
                          window.location = '/'
                        })
                      })
                      .catch(error => {
                        console.log(error)
                      })
                  }
                } else {
                  commit('setRights', response.data.data.attributes.rights)
                }
                Cookies.set('dnOpenIdSessionState', params.session_state)
              }
              resolve(response)
            } else {
              console.log('Brak uprawnień do logowania')
              const error = {
                response: {
                  data: {
                    error: 'Brak uprawnień do logowania'
                  }
                }
              }
              removeItems()
              commit('setUser', {})
              commit('setRights', {})
              reject(error)
            }
          })
          .catch(error => {
            console.log('signIn error')
            if (error && error.response) {
              console.log(error.response)
            } else {
              console.log(error)
            }
            removeItems()
            commit('setUser', {})
            commit('setRights', {})
            reject(error)
          })
      })
    },
    devSignIn ({ commit, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        Auth.devSignIn(payload)
          .then(response => {
            // const authToken = response.headers.authorization
            // if (response.data.data.attributes.token) {
            //   const authToken = response.data.data.attributes.token
            //   localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN_KEY, authToken)
            //   commit('setToken', authToken)
            // }
            setItems(response)
            commit('setUser', response.data.data.attributes)
            dispatch('setCurrentRole', state.currentRole)
            resolve(response)
          })
          .catch(error => {
            removeItems()
            commit('setUser', {})
            commit('setRights', {})
            reject(error)
          })
      })
    },
    redirSignIn ({ commit, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        Auth.redirSignIn(payload)
          .then(response => {
            // const authToken = response.headers.authorization
            // if (response.data.data.attributes.token) {
            //   const authToken = response.data.data.attributes.token
            //   localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN_KEY, authToken)
            //   commit('setToken', authToken)
            // }
            console.log('redirSignIn')
            console.log(response.data)
            setItems(response)
            commit('setUser', response.data.data.attributes)
            if (state.currentRole !== response.data.data.attributes.currentRole) {
              if (state.currentRole == null) {
                localStorage.setItem(LOCAL_STORAGE_USER_CURRENT_ROLE_KEY, response.data.data.attributes.currentRole)
                dispatch('setCurrentRole', state.currentRole)
                commit('setRights', response.data.data.attributes.rights)
              } else {
                dispatch('setCurrentRole', state.currentRole)
                ApiAuth.setCurrentRole(state.currentRole)
                  .then(response => {
                    dispatch('setRights', response.data)
                    this.$nextTick(() => {
                      // this.$router.go()
                      window.location = '/'
                    })
                  })
                  .catch(error => {
                    console.log(error)
                  })
              }
            } else {
              commit('setRights', response.data.data.attributes.rights)
            }
            resolve(response)
          })
          .catch(error => {
            console.log('redirSignIn error')
            console.log(error)
            removeItems()
            commit('setUser', {})
            commit('setRights', {})
            reject(error)
          })
      })
    },
    setSigninOut ({ commit, dispatch }, value) {
      commit('setSigningOut', value)
    },
    signOut ({ commit, dispatch }) {
      commit('setSigningOut', true)
      return new Promise((resolve, reject) => {
        Auth.signOut()
          .then(response => {
            removeItems()
            commit('setUser', {})
            commit('setRights', {})
            Cookies.remove('dnOpenIdSessionState')
            Cookies.remove('token')
            resolve(response)
          })
          .catch(error => {
            removeItems()
            commit('setUser', {})
            commit('setRights', {})
            reject(error)
          })
      })
    },
    setCurrentRole ({ state, commit, dispatch }, role) {
      if (Array.isArray(state.roles) && state.roles.length > 0) {
        if (state.roles.includes(role)) {
          localStorage.setItem(LOCAL_STORAGE_USER_CURRENT_ROLE_KEY, role)
          commit('setCurrentRole', role)
          // dispatch('fetchRights', state.currentRole)
        } else {
          localStorage.setItem(LOCAL_STORAGE_USER_CURRENT_ROLE_KEY, state.roles[0])
          commit('setCurrentRole', state.roles[0])
          // dispatch('fetchRights', state.currentRole)
        }
      }
    },
    setRights ({ commit, dispatch, state }, rights) {
      localStorage.setItem(LOCAL_STORAGE_USER_VR, JSON.stringify(decamelize(rights)))
      commit('setRights', rights)
    },
    setRedirectTo ({ commit }, payload) {
      commit('setRedirectTo', payload)
      localStorage.setItem(LOCAL_STORAGE_REDIRECT_TO_KEY, JSON.stringify(payload))
    },
    clearRedirectTo ({ commit }) {
      commit('setRedirectTo', null)
      localStorage.removeItem(LOCAL_STORAGE_REDIRECT_TO_KEY)
    },
    updateState ({ commit }) {
      commit('updateState')
    }
  }
}

function setItems (response) {
  localStorage.setItem(LOCAL_STORAGE_USER_ID_KEY, response.data.data.attributes.id)
  localStorage.setItem(LOCAL_STORAGE_USER_EMPLOYEE_ID_KEY, response.data.data.attributes.employeeId)
  localStorage.setItem(LOCAL_STORAGE_USER_FIRST_NAME_KEY, response.data.data.attributes.firstName)
  localStorage.setItem(LOCAL_STORAGE_USER_LAST_NAME_KEY, response.data.data.attributes.lastName)
  localStorage.setItem(LOCAL_STORAGE_USER_ROLES_KEY, JSON.stringify(response.data.data.attributes.roles))
  localStorage.setItem(LOCAL_STORAGE_USER_VR, JSON.stringify(decamelize(response.data.data.attributes.rights)))
  if (response.data.data.attributes.avatar) {
    localStorage.setItem(LOCAL_STORAGE_USER_AVATAR_KEY, response.data.data.attributes.avatar.url)
  }
  localStorage.setItem(LOCAL_STORAGE_USER_ACADEMY, response.data.data.attributes.academy.name)
  localStorage.setItem(LOCAL_STORAGE_USER_AID, response.data.data.attributes.academy.id)
  localStorage.setItem(LOCAL_STORAGE_USER_FID, response.data.data.attributes.faculty.id)
  localStorage.setItem(LOCAL_STORAGE_USER_FCIDS, response.data.data.attributes.faculty.childrenIds)
  localStorage.setItem(LOCAL_STORAGE_EXPIRE, new Date(response.data.data.attributes.expiresAt).getTime())
}

function removeItems () {
  localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)
  localStorage.removeItem(LOCAL_STORAGE_USER_ID_KEY)
  localStorage.removeItem(LOCAL_STORAGE_USER_EMPLOYEE_ID_KEY)
  localStorage.removeItem(LOCAL_STORAGE_USER_FIRST_NAME_KEY)
  localStorage.removeItem(LOCAL_STORAGE_USER_LAST_NAME_KEY)
  localStorage.removeItem(LOCAL_STORAGE_USER_ROLES_KEY)
  localStorage.removeItem(LOCAL_STORAGE_USER_AVATAR_KEY)
  localStorage.removeItem(LOCAL_STORAGE_USER_ACADEMY)
  localStorage.removeItem(LOCAL_STORAGE_USER_AID)
  localStorage.removeItem(LOCAL_STORAGE_USER_FID)
  localStorage.removeItem(LOCAL_STORAGE_USER_FCIDS)
  localStorage.removeItem(LOCAL_STORAGE_USER_VR)
  localStorage.removeItem(LOCAL_STORAGE_EXPIRE)
  localStorage.removeItem(LOCAL_STORAGE_USER_CURRENT_ROLE_KEY)
}

export {
  auth
}
