export default {
  methods: {
    handle422ToastrErrors ({ status, data }, defaultMessage = this.$t('general.unexpected_error')) {
      let errorMsg

      if (status === 422 && data) {
        // errorMsg = Object.values(data).map(attributeErrors => attributeErrors.join('<br/>')).join('<br/>')
        errorMsg = Object.entries(data).map(errors => {
          const field = errors[0]
          const msgs = errors[1].join('<br/>')
          return `${field}: ${msgs}`
        }).join('<br/>')
      } else {
        errorMsg = defaultMessage
      }

      this.$toastr.e(errorMsg)
    }
  }
}
