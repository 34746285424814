<template>
    <span class="spinner">
        <i class="fas fa-spinner fa-spin"></i>
    </span>
</template>

<script>
export default {
  name: 'Spinner'
}
</script>

<style scoped>

</style>
