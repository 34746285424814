<template>
    <b-row v-if="canEditAuthor()" class="mb-2">
        <b-col>
            <live-search class="university-authors"
                         :clear-btn="false"
                         v-model="currentAuthor"
                         :options="authorOptions"
                         :show-search-icon="false"
                         :content-type="'author'"
                         v-on:ls-checked="authorSave" v-on:input="authorSave"
            />
        </b-col>
        <b-col v-if="publicationTypeId !== 7 && publicationTypeId !== 35" cols="3" class="my-column">
            <pcg-checkbox v-model="authorType"
                          :id="author.id.toString()"
                          :value="authorType"
            >
                {{ $t('views.orcid_publications.from_university') }}
            </pcg-checkbox>
        </b-col>
        <b-col cols="1" class="my-column">
          <a href="javascript:;" :aria-label="$t('general.clear')" @click="removeAuthor">
            <i class="pcg-icon-cross"></i>
          </a>
        </b-col>
        <b-col v-if="hasRight('set_author_order')" cols="1" class="my-column" style="top: -5px;">
          <a href="javascript:;" :aria-label="$t('general.go_up')" @click="moveUp">
            <i class="pcg-icon-arrow-up"></i>
          </a>
          <a href="javascript:;" :aria-label="$t('general.go_down')" @click="moveDown">
            <i class="pcg-icon-arrow-down"></i>
          </a>
        </b-col>
    </b-row>
    <b-row v-else class="mb-2">
      <b-col>
        <span class="author-name">{{ author.name }}</span>
      </b-col>
    </b-row>
</template>

<script>
import LiveSearch from './LiveSearch'
import hasRight from '../mixins/has_right'
export default {
  name: 'OrcidAuthor',
  components: { LiveSearch },
  mixins: [hasRight],
  data: function () {
    return {
    }
  },
  props: {
    author: Object,
    index: Number,
    publicationTypeId: Number,
    managerDisciplines: Array
  },
  computed: {
    authorType: {
      get () {
        return (this.author.authorType === 0) || (this.author.authorType === 3)
      },
      set (value) {
        if (value) {
          this.author.authorType = 0
        } else {
          this.author.authorType = 1
        }
      }
    },
    authorOptions () {
      return {
        path: 'authors',
        params: {
          author_id: this.author.id
        }
      }
    },
    currentAuthor: {
      get () {
        return {
          id: this.author.employeeId,
          value: this.author.name
        }
      },
      set (value) {
        this.author.employeeId = value.id
        this.author.name = value.value
      }
    }
  },
  mounted () {
  },
  methods: {
    moveDown () {
      this.$emit('move-down', this.index)
    },
    moveUp () {
      this.$emit('move-up', this.index)
    },
    removeAuthor () {
      this.$emit('remove-author', this.index)
    },
    authorSave () {
      if (this.currentAuthor.id === null) {
        this.$set(this.author, 'name', this.currentAuthor.value.split(',')[0])
        this.$set(this.author, 'employeeId', null)
        if (this.publicationTypeId === 7 || this.publicationTypeId === 35) {
          this.$set(this.author, 'authorType', 1)
        }
      } else {
        this.$set(this.author, 'name', this.currentAuthor.value.split(',')[0])
        this.$set(this.author, 'employeeId', this.currentAuthor.id)
        this.$set(this.author, 'sourceId', this.currentAuthor.id)
        if (this.publicationTypeId === 7 || this.publicationTypeId === 35) {
          this.$set(this.author, 'authorType', 0)
        }
      }
    },
    canEditAuthor () {
      return !this.hasRight('edit_authors_manager') || (this.hasRight('edit_authors_manager') && (this.author.disciplineId === undefined || this.author.disciplineId === null || this.managerDisciplines.includes(this.author.disciplineId)))
    }
  }
}
</script>

<style lang="scss" scoped>
    @import '../assets/stylesheets/vars';

    /deep/ .pcg-checkbox-container {
        margin-right: 0;
    }

    /deep/ .live-search {
        margin-left: -15px;
        border: none;
        input {
            padding: 0 0.75rem;
            height: 2rem;
        }
    }

    .authors {
        font-weight: 400;
        font-size: 1em;
        color: $main-color;
        margin-left: .2rem;
        margin-top: .2rem;
        padding: 4px 10px 4px 10px;
        border-radius: 30px;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
        width: 85%;
        height: 28px;
    }

    .university-authors {
        margin-left: .2rem;
        font-weight: 400;
        font-size: $font-size-m;
        color: $main-color;
        /deep/ .pcg-field {
            height: 2rem !important;
        }
    }

    .my-column {
        position: relative;
        top: .4rem;
    }

    .pcg-icon-cross {
        cursor: pointer;
        color: $pcg-light-gray;
        margin-right: 1rem;
    }

    .author-name {
      color: $pcg-gray;
      margin-left: 1rem;
      font-weight: 400;
    }
</style>

<style lang="scss" scoped>
    @import "../assets/stylesheets/vars-contrast";
    .contrast {
        .authors {
            color: $main-color;
        }
        .university-authors {
            color: $main-color;
        }
        .pcg-icon-cross {
            color: $pcg-light-gray;
        }
    }
</style>
