<template>
  <b-modal ref="newAchiev"
           :id="modalId"
           :hide-header="true"
           :centered="true"
           v-bind:ok-title="$t('general.confirm')"
           ok-variant="primary"
           :no-close-on-esc="true"
           :no-close-on-backdrop="true"
           @ok="newAchievSave"
           @cancel="escNewAchiev"
           @close="escNewAchiev"
           v-bind:cancel-title="$t('general.cancel')"
           cancel-variant="default"
           content-class="my-modal-content"
           footer-class="my-modal-footer"
  >
    <div class="confirm-header" style="display: inline-block">{{ $t('views.achievement.list.new') }}</div>
    <pcg-select :options="tDictionary(availableTypes)"
                v-model="newAchievment.publicationTypeId"
                :default-option="{ text: $t('views.achievement.list.pub_type'), id: null }"
                class="my-select my-pcg-select"
                style="width: 80%"
    />
  </b-modal>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import translateDictionary from '../mixins/translate_dictionary'
import { mapGetters } from 'vuex'
import hasRight from '../mixins/has_right'

export default {
  name: 'NewAchievementModal',
  mixins: [clickaway, translateDictionary, hasRight],
  props: {
    modalId: String
  },
  data: function () {
    return {
      newAchievment: {
        id: null,
        publicationTypeId: null
      },
      availableTypes: []
    }
  },
  beforeMount () {
    if (this.$store.getters['auth/signedIn']) {
      if (this.$store.getters['publicationTypes/publicationTypes'].length === 0) {
        this.$store.dispatch('publicationTypes/setPublicationTypes', null)
      }
    }
  },
  mounted () {
    this.checkAvailableTypes()
  },
  computed: {
    ...mapGetters('publicationTypes', ['publicationTypes'])
  },
  methods: {
    escNewAchiev () {
      this.$refs.newAchiev.hide()
      this.$emit('cancel')
    },
    newAchievSave () {
      if (this.newAchievment.publicationTypeId) {
        this.$refs.newAchiev.hide()
        this.$emit('ok', this.newAchievment.publicationTypeId)
        // ApiPublications.newPublication(this.newAchievment)
        //   .then(response => {
        //     const publicationId = response.data.id
        //     this.$router.push({ name: 'achievement_edit_path', params: { id: publicationId } })
        //   })
        //   .catch(error => {
        //     console.log(error)
        //   })
      } else {
        this.$toastr.e(this.$t('views.achievement.list.no_type'))
        this.$emit('error')
      }
    },
    checkAvailableTypes () {
      if (this.availableTypes.length === 0) {
        this.publicationTypes.forEach(p => {
          switch (p.id) {
            case '3':
              if (this.hasRight('add_monography')) {
                this.availableTypes.push({
                  id: p.id, text: p.text, textEn: p.textEn
                })
              }
              break
            case '7':
              if (this.hasRight('add_patent')) {
                this.availableTypes.push({
                  id: p.id, text: p.text, textEn: p.textEn
                })
              }
              break
            case '19':
              if (this.hasRight('add_chapter')) {
                this.availableTypes.push({
                  id: p.id, text: p.text, textEn: p.textEn
                })
              }
              break
            case '20':
              if (this.hasRight('add_article')) {
                this.availableTypes.push({
                  id: p.id, text: p.text, textEn: p.textEn
                })
              }
              break
            case '21':
              if (this.hasRight('add_redaction')) {
                this.availableTypes.push({
                  id: p.id, text: p.text, textEn: p.textEn
                })
              }
              break
            case '30':
              if (this.hasRight('add_service')) {
                this.availableTypes.push({
                  id: p.id, text: p.text, textEn: p.textEn
                })
              }
              break
            case '32':
              if (this.hasRight('add_project')) {
                this.availableTypes.push({
                  id: p.id, text: p.text, textEn: p.textEn
                })
              }
              break
            case '35':
              if (this.hasRight('add_artistic')) {
                this.availableTypes.push({
                  id: p.id, text: p.text, textEn: p.textEn
                })
              }
              break
            case '45':
              if (this.hasRight('add_environmental_impact')) {
                this.availableTypes.push({
                  id: p.id, text: p.text, textEn: p.textEn
                })
              }
              break
            default:
              break
          }
        })
      }
    }
  },
  watch: {
    publicationTypes: {
      deep: true,
      handler () {
        this.checkAvailableTypes()
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../assets/stylesheets/vars';
  .confirm-header {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .my-select {
    /deep/ .dropdown-toggle {
      height: 28px !important;

    }
    /deep/ .bootstrap-select {
      height: 30px !important;
    }
  }

  .my-pcg-select {
    margin-bottom: 1.5rem;
    /deep/ .pcg-select {
      height: 20px;
      .dropdown-toggle {
        height: 18px;
        padding-bottom: 0;
        padding-top: 0;
        line-height: unset;
      }
    }
    /deep/ .dropdown .filter-option-inner {
      margin-top: -5px;
    }
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .confirm-header {
      color: $pcg-gray;
    }
  }
</style>
