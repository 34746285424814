<template>
  <b-modal ref="addEmployee"
           :id="modalId"
           :hide-header="true"
           footer-class="my-modal-footer"
           content-class="my-modal-content"
           :centered="true"
           v-bind:ok-title="$t('general.confirm')"
           ok-variant="primary"
           :no-close-on-esc="true"
           @ok="addEmployee"
           v-bind:cancel-title="$t('general.cancel')"
           cancel-variant="default"
  >
    <div class="confirm-header">{{ $t('views.scientists.list.adding_employee') }}</div>
    <b-row v-for="error in errors" :key="error.id">
      <b-col>
        <img src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark-employee" :alt="$t('general.exclamation_mark')"/>
        <span class="my-error">{{ error }}</span>
      </b-col>
    </b-row>
    <pcg-text-input
        :label="$t('views.scientists.list.name')"
        :showLabel="true"
        v-model="addingEmployee.firstName"
        class="my-input"
    />
    <pcg-text-input
        :label="$t('views.scientists.list.surname')"
        :showLabel="true"
        v-model="addingEmployee.lastName"
        class="my-input"
    />
    <pcg-text-input
        :label="$t('views.scientists.list.academic_title')"
        :showLabel="true"
        v-model="addingEmployee.title"
        class="my-input"
        style="margin-bottom: 2rem"
    />
    <div style="width: fit-content">
      <pcg-checkbox
        class="my-checkbox"
        variant="normal"
        v-model="addingEmployee.dissertation"
        v-b-tooltip.hover v-bind:title="$t('components.dn_employments.dissertation_tooltip')"
      >
        <span class="my-checkbox-text">{{ $t('components.dn_employments.doctorant_in_school') }}</span>
      </pcg-checkbox>
    </div>

    <!-- <pcg-text-input-->
    <!--     :label="$t('views.scientists.list.academic_title_en')"-->
    <!--     :showLabel="true"-->
    <!--     v-model="addingEmployee.titleEn"-->
    <!--     class="my-input"-->
    <!-- />-->
    <!-- <div class="required-fields">-->
    <!--   * - {{ $t('views.scientists.list.required_fields') }}-->
    <!-- </div>-->
  </b-modal>
</template>

<script>
import ApiEmployees from '../api/employees'

export default {
  name: 'NewEmployeeModal',
  props: {
    modalId: String
  },
  data: function () {
    return {
      addingEmployee: {
        firstName: null,
        lastName: null,
        title: null,
        titleEn: null,
        dissertation: false
      },
      errors: []
    }
  },
  methods: {
    addEmployee () {
      this.errors = []
      ApiEmployees.createEmployee(this.addingEmployee)
        .then(result => {
          this.$router.push({ name: 'employee_path', params: { id: result.data.id } })
        })
        .catch(error => {
          this.$toastr.e(this.$t('views.scientists.list.wrong_employee'))
          this.errors = error.response.data
          this.$refs.addEmployee.show()
        })
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../assets/stylesheets/vars';
  .confirm-header {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .my-input {
    /deep/ .pcg-input {
      height: calc(1.5rem + 0.75rem + 2px)
    }
    position: relative;
    /deep/ .pcg-input-label {
      margin-bottom: 0.25rem;
      color: $pcg-gray;
    }
  }

  .required-fields {
    color: $pcg-gray;
    margin-top: 1rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
  }

  .my-exclamation-mark-employee {
    height: 14px;
    width: 14px;
    padding: .2rem .2rem;
    border-radius: 20px;
    text-align: center;
    background-color: $pcg-red;
    opacity: 0.8;
    position: relative;
    bottom: .2rem;
  }

  .my-error {
    color: $pcg-red;
    font-weight: 400;
    margin-left: .3rem;
  }

  /deep/ .tooltip-inner {
    background: $pcg-white;
    color: $pcg-dark-gray;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
    font-weight: 400;
    padding: .5rem;
    border-color: $pcg-light-gray-background;
    border-style: solid;
    border-width: 1px;
    opacity: 1;
    white-space: pre-wrap;
    min-width: 420px;
    text-align: start;
    @media (max-width: 550px) {
      min-width: 250px;
    }
  }

  /deep/ .arrow::before {
    border-top-color: $pcg-light-gray-background !important;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .confirm-header {
      color: $pcg-gray;
    }
    /deep/ .pcg-input-label {
      color: $pcg-gray;
    }
    .required-fields {
      color: $pcg-gray;
    }
    /deep/ .tooltip-inner {
      background: $pcg-white;
      color: $pcg-dark-gray;
      box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
      border-color: $pcg-light-gray;
    }

    /deep/ .arrow::before {
      border-top-color: $pcg-light-gray !important;
    }
  }
</style>
