<template>
  <div class="pie-chart" :style="pieStyles"></div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'dn-pie-chart',
  props: {
    filling: Number,
    color: String,
    main: Boolean
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    pieStyles () {
      const styles = []
      let fill = ''
      let background = ''
      if (this.contrast) {
        if (this.color === 'dark-blue') {
          if (Number(this.filling) === 100) {
            fill = '#66FFA7 0' + 105 + '%'
          } else {
            fill = '#66FFA7 0' + this.filling + '%'
          }
          background = '#000000 0 100%'
        } else if (this.color === 'gray') {
          if (Number(this.filling) === 100) {
            fill = '#F7F8FA 0' + 105 + '%'
          } else {
            fill = '#F7F8FA 0' + this.filling + '%'
          }
          background = '#808080 0 100%'
        } else {
          if (Number(this.filling) === 100) {
            fill = '#009941 0' + 105 + '%'
          } else {
            fill = '#009941 0' + this.filling + '%'
          }
          background = '#808080 0 100%'
        }
      } else {
        if (this.color === 'dark-blue') {
          if (Number(this.filling) === 100) {
            fill = '#253B80 0' + 105 + '%'
          } else {
            fill = '#253B80 0' + this.filling + '%'
          }
          background = '#FFFFFF 0 100%'
        } else if (this.color === 'gray') {
          if (Number(this.filling) === 100) {
            fill = '#585858 0' + 105 + '%'
          } else {
            fill = '#585858 0' + this.filling + '%'
          }
          background = '#EEF0F5 0 100%'
        } else {
          if (Number(this.filling) === 100) {
            fill = '#00B4EB 0' + 105 + '%'
          } else {
            fill = '#00B4EB 0' + this.filling + '%'
          }
          background = '#EEF0F5 0 100%'
        }
      }
      styles.push(fill)
      styles.push(background)

      return {
        background: `conic-gradient( ${styles.join(',')} )`
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .pie-chart {
    border-radius: 50%;
  }

</style>
