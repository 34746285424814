<template>
  <div class="users-list">
    <dn-loader v-if="showLoader" class="with-margins"/>
    <div v-else>
      <div class="filters" style="margin-bottom: .5rem">
        <b-row>
          <h2 class="sc-title">{{ $t('views.users.list.users_list') }}</h2>
          <span class="sort-by-text">{{ $t('views.scientists.list.sort_by') }}</span>
          <pcg-sort-btn
            class="sort-by my-sort"
            :options="sortOptions"
            v-model="sortBy"
            @input="getFilteredUsers"
          />
        </b-row>
        <b-row align-v="center" style="margin-top: .8rem; margin-bottom: 1rem;" class="normal">
          <label for="fullname" class="filter-users">{{ $t('views.users.list.filter_users') }}</label>
          <pcg-text-input
              id="fullname"
            class="my-text-input"
            style="margin-right: 3rem"
            :show-label="false"
            :placeholder="$t('views.scientists.list.by_names')"
            v-model="filters.fullName"
            @input="getFilteredUsers"
          />
          <label v-if="hasRight('list_users')" class="filter-users">{{ $t('views.users.list.fields.academy') }}</label>
          <pcg-select
            v-if="hasRight('list_users')"
            class="my-select"
            style="margin-right: 2rem"
            :options="tDictionary(academiesList)"
            :default-option="{ text: $t('general.select'), id: '' }"
            v-model="filters.academyId"
            @input="getFilteredUsers"
          />
        </b-row>
        <b-row align-v="center" style="margin-bottom: .7rem">
          <label for="email" class="filter-users">{{ $t('views.users.list.fields.email') }}</label>
          <pcg-text-input
              id="email"
            class="my-text-input"
            style="margin-right: 3rem"
            :show-label="false"
            :placeholder="$t('views.users.list.fields.email')"
            v-model="filters.email"
            @input="getFilteredUsers"
          />
          <label class="filter-users">{{ $t('views.users.list.fields.roles') }}</label>
          <pcg-select
            class="my-select"
            style="margin-right: 2rem"
            :options="tDictionary(rolesOptions)"
            :default-option="{ text: $t('general.select'), id: '' }"
            v-model="filters.roles"
            @input="getFilteredUsers"
          />
        </b-row>
      </div>
      <div>
        <div style="display: inline-block; margin-right: 4rem;">
          <span class="per_page">{{ $t('views.achievement.list.per_page') }}</span>
          <pcg-select
            class="my-page-select"
            :options="perPageOptions"
            :default-option="{ text: perPage.toString(), value: perPage }"
            v-model="perPage"
            @input="getFilteredUsers"
          />
        </div>
        <div style="display: inline-block">
          <pcg-pagination-summary
            class="pagination-summary"
            :currentPage="pagination.page"
            :perPage="perPage"
            :totalRecords="pagination.count">
          </pcg-pagination-summary>
        </div>
        <div v-if="hasRight('add_user')" style="display: inline-block; float: right;">
          <pcg-btn size="small" class="user-btn" @click="userModal">
            <span>+ {{ $t('views.users.list.add_user') }}</span>
            <spinner v-if="addUserSpinner"></spinner>
          </pcg-btn>
          <new-user-modal modal-id="new-user-modal" :userErrors="userErrors" :currentUser="currentUser" :editingUser="editingUser"
                          @updateUsersList="getUsers" @addUserSpinner="updateAddUserSpinner"></new-user-modal>
        </div>
      </div>
      <b-table v-if="users.length > 0"
               class="users-table"
               borderless hover
               thead-class="sthead"
               tbody-tr-class="strow"
               :fields="fields"
               :items="users"
               :primary-key="users.id"
      >
        <template v-slot:cell(fullName)="data">
          <div class="my-text">
            <span>{{ data.value }}</span>
            <a href="javascript:;" :aria-label="$t('views.users.list.edit_user')" v-if="hasRight('edit_user')" @click="editUser(data.item.id)">
              <i class="fas fa-pen roles-pen" v-b-tooltip.hover v-bind:title="$t('views.users.list.edit_user')" style="margin-right: .3rem"></i>
            </a>
            <a href="javascript:;" :aria-label="$t('views.users.list.delete_user')" v-if="hasRight('remove_user')" @click="removeUser(data.item.id)">
              <i class="pcg-icon-cross delete-cross" v-b-tooltip.hover v-bind:title="$t('views.users.list.delete_user')"></i>
            </a>
          </div>
        </template>
        <template v-slot:cell(email)="data">
          <div class="my-text">
            {{ data.value }}
          </div>
        </template>
        <template v-slot:cell(roles)="data">
          <div v-if="data.value.length > 0" class="my-text">
            <div v-for="(role, index) in data.value" :key="role.id">
              <span>{{ roleName(role) }}</span>
              <a href="javascript:;" :aria-label="$t('views.users.list.edit_roles')" v-if="index === 0 && hasRight('edit_user_role')" @click="editRoles(data.item.id)">
                <i class="fas fa-pen roles-pen" v-b-tooltip.hover v-bind:title="$t('views.users.list.edit_roles')"></i>
              </a>
            </div>
          </div>
          <div v-else class="my-text">
            <span class="no-role">{{ $t('views.users.list.no_role') }}</span>
            <a href="javascript:;" :aria-label="$t('views.users.list.edit_roles')" v-if="hasRight('edit_user_role')" @click="editRoles(data.item.id)">
              <i class="fas fa-pen roles-pen" v-b-tooltip.hover v-bind:title="$t('views.users.list.edit_roles')"></i>
            </a>
          </div>
        </template>
        <template v-slot:cell(academy)="data">
          <div class="my-text">
            {{ data.value }}
          </div>
        </template>
        <template v-slot:cell(createdAt)="data">
          <div class="my-text">
            {{ $d(Date.parse(data.value)) }} {{ setTime(data.value) }}
          </div>
        </template>
        <template v-slot:cell(lastLogin)="data">
          <div class="my-text" v-if="data.value">
            {{ $d(Date.parse(data.value)) }} {{ setTime(data.value) }}
          </div>
        </template>
      </b-table>
      <pcg-box v-else style="margin-top: 1rem">
        <div class="d-flex justify-content-center no-records">{{ $t('views.users.list.no_records') }}</div>
      </pcg-box>
      <b-modal ref="roles"
               :hide-header="true"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="rolesSave"
               @hide="escRoles"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               content-class="my-modal-content"
               footer-class="my-modal-footer"
      >
          <div class="d-flex justify-content-between">
            <div class="my-header">
              <span class="edit-roles">{{ $t('views.users.list.edit_roles') }}</span>
            </div>
            <div>
              <pcg-btn size="small" variant="additional" @click="addRole">{{ $t('components.dn_field.add') }}</pcg-btn>
            </div>
          </div>
          <b-row v-for="error in errors" :key="error.id">
            <b-col>
              <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark"/>
              <span class="my-error">{{ error }}</span>
            </b-col>
          </b-row>
          <div style="margin-bottom: 3rem">
            <transition-group name="fade" mode="out-in">
              <div v-for="(role, index) in changingRoles" :key="index + 0" style="margin-bottom: 1rem">
                <div class="one-role">{{ $t('views.users.list.role') }}</div>
                <b-row>
                  <b-col v-if="currentRole === 'manager'">
                    <pcg-select
                      class="modal-select"
                      :options="tDictionary(validRolesOptions)"
                      :default-option="{ text: $t('general.select'), id: '' }"
                      v-model="changingRoles[index].id"
                    />
                  </b-col>
                  <b-col v-if="currentRole === 'manager' && changingRoles[index].id === 'naukowiec'">
                    <live-search
                        v-if="changingRoles[index].id === 'naukowiec'"
                        class="publisher-search modal-select"
                        :clear-btn="false"
                        :options="employeeOptions"
                        v-model="currentEmployee"
                        v-on:ls-checked="employeeChecked()"
                    />
                  </b-col>
                  <b-col v-if="(currentRole === 'manager' && changingRoles[index].id === 'pracownikjednostki') && (hasRight('list_unit') || hasRight('list_unit_employee'))">
                    <pcg-select
                        class="modal-select"
                        :options="facultyOptions"
                        :default-option="{ text: $t('general.select'), option: '' }"
                        v-model="currentUser.facultyId"
                    />
                  </b-col>
                  <b-col v-if="currentRole !== 'manager'">
                    <pcg-select
                        v-if="currentRole === 'useradmin' && role.id !== 'manager' && role.id !== 'analityknaukowy' && role.id !== 'custom3'"
                        class="modal-select"
                        :options="tDictionary(validRolesOptions)"
                        :default-option="{ text: $t('general.select'), id: '' }"
                        v-model="changingRoles[index].id"
                    />
                    <pcg-select
                      v-else-if="role.id !== 'manager' && role.id !== 'analityknaukowy' && role.id !== 'useradmin' && role.id !== 'custom3'"
                      class="modal-select"
                      :options="tDictionary(validRolesOptions)"
                      :default-option="{ text: $t('general.select'), id: '' }"
                      v-model="changingRoles[index].id"
                    />
                    <div v-else class="role-name">{{ roleName(role.id) }}</div>
                  </b-col>
                  <b-col v-if="currentRole !== 'manager' && changingRoles[index].id === 'naukowiec'">
                    <div v-if="role.id !== 'manager' && role.id !== 'analityknaukowy' && role.id !== 'custom1' && role.id !== 'custom3'">
                      <live-search
                          v-if="changingRoles[index].id === 'naukowiec'"
                          class="publisher-search modal-select"
                          :clear-btn="false"
                          :options="employeeOptions"
                          v-model="currentEmployee"
                          v-on:ls-checked="employeeChecked()"
                      />
                    </div>
                    <div v-else-if="changingRoles[index].id === 'naukowiec'">
                      {{ currentEmployee.value }}
                    </div>
                  </b-col>
                  <b-col v-if="(currentRole !== 'manager' && changingRoles[index].id === 'pracownikjednostki') && (hasRight('list_unit') || hasRight('list_unit_employee'))">
                    <pcg-select
                        class="modal-select"
                        :options="facultyOptions"
                        :default-option="{ text: $t('general.select'), option: '' }"
                        v-model="currentUser.facultyId"
                    />
                  </b-col>
                  <b-col cols="1" v-if="currentRole === 'manager'">
                    <a href="javascript:;" :aria-label="$t('general.remove')" @click="removeRole(index)">
                      <i class="pcg-icon-cross my-cross"></i>
                    </a>
                  </b-col>
                  <b-col cols="1" v-else-if="currentRole === 'useradmin'">
                    <a href="javascript:;" :aria-label="$t('general.remove')" v-if="role.id !== 'manager' && role.id !== 'analityknaukowy' && role.id !== 'custom3'" @click="removeRole(index)">
                      <i class="pcg-icon-cross my-cross"></i>
                    </a>
                  </b-col>
                  <b-col cols="1" v-else>
                    <a href="javascript:;" :aria-label="$t('general.remove')" v-if="role.id !== 'manager' && role.id !== 'analityknaukowy' && role.id !== 'useradmin' && role.id !== 'custom3'" @click="removeRole(index)">
                      <i class="pcg-icon-cross my-cross"></i>
                    </a>
                  </b-col>
                </b-row>
              </div>
            </transition-group>
          </div>
      </b-modal>
      <b-modal ref="deleteUserConfirm"
               :hide-header="true"
               footer-class="my-modal-footer"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="deleteUser"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               content-class="my-modal-content"
      >
        <div class="confirm-header">{{ $t('views.users.list.delete_user_confirm') }}</div>
        <div class="confirm-text">{{ $t('views.users.list.delete_user_confirm_text') }}</div>
      </b-modal>
      <nav :aria-label="$t('general.pagination')">
        <pcg-pagination
            class="d-flex justify-content-center my-pagination"
            v-model="pagination.page"
            :page-count="pagination.pages"
            :total-records="pagination.count"
            @input="changePage"
        ></pcg-pagination>
      </nav>
    </div>
  </div>
</template>

<script>
import LiveSearch from '../../components/LiveSearch'
import loaderDelay from '../../mixins/loader_delay'
import DnLoader from '../../components/DnLoader'
import setTitle from '../../mixins/set_title'
import translateDictionary from '../../mixins/translate_dictionary'
import ApiEmployees from '../../api/employees'
import ApiUsers from '../../api/users'
import ApiAcademies from '../../api/academies'
import NewUserModal from '../../components/NewUserModal'
import Spinner from '../../components/Spinner'
import hasRight from '../../mixins/has_right'
import { mapGetters } from 'vuex'
import ApiFaculties from '../../api/faculties'

export default {
  name: 'UsersList',
  mixins: [loaderDelay, setTitle, translateDictionary, hasRight],
  components: {
    DnLoader,
    LiveSearch,
    NewUserModal,
    Spinner
  },
  data: function () {
    return {
      headerTitle: 'views.users.list.title',
      headerSubtitle: 'views.users.list.subtitle',
      employees: [],
      faculties: [],
      filters: {
        orderBy: 'full_name',
        orderDirection: 'asc',
        fullName: '',
        email: '',
        academyId: null,
        roles: null
      },
      pagination: {
        count: 0,
        items: 0,
        last: 0,
        next: null,
        page: 0,
        pages: 0,
        prev: null
      },
      perPage: 10,
      users: [],
      sortOptions: [],
      validRoles: [],
      validRolesOptions: [],
      rolesOptions: [],
      academiesList: [],
      errors: [],
      changingRoles: [],
      currentUser: {
        firstName: null,
        lastName: null,
        uid: null,
        email: null,
        academyId: null,
        facultyId: null
      },
      userErrors: [],
      editingUser: false,
      addUserSpinner: false
    }
  },
  computed: {
    ...mapGetters('auth', ['signedIn', 'currentRole', 'employeeId', 'expire']),
    sortBy: {
      get () {
        return { orderBy: this.filters.orderBy, orderDirection: this.filters.orderDirection === 'asc' ? 'desc' : 'asc' }
      },
      set (newValue) {
        this.filters.orderBy = newValue.orderBy
        this.filters.orderDirection = newValue.orderDirection === 'asc' ? 'desc' : 'asc'
      }
    },
    currentEmployee: {
      get () {
        const idx = this.changingRoles.findIndex(u => {
          return u.id === 'naukowiec'
        })
        if (this.changingRoles[idx].option) {
          const eIdx = this.employees.findIndex(e => {
            return e.id === this.changingRoles[idx].option
          })
          if (eIdx === -1) {
            this.getEmployee(this.changingRoles[idx].option)
            return {
              id: this.changingRoles[idx].option,
              value: ''
            }
          } else {
            return {
              id: this.changingRoles[idx].option,
              value: this.employees[eIdx].fullName
            }
          }
        } else {
          return {
            id: null,
            value: ''
          }
        }
      },
      set (value) {
        const idx = this.changingRoles.findIndex(u => {
          return u.id === 'naukowiec'
        })
        this.changingRoles[idx].option = value.id
        if (value.id) {
          const eIdx = this.employees.findIndex(e => {
            return e.id === this.changingRoles[idx].option
          })
          if (eIdx === -1) {
            this.getEmployee(this.changingRoles[idx].option)
          }
        }
      }
    },
    employeeOptions () {
      const idx = this.changingRoles.findIndex(u => {
        return u.id === 'naukowiec'
      })
      return {
        path: 'authors',
        params: {
          author_id: this.changingRoles[idx].option,
          academy_id: this.currentUser.academyId
        }
      }
    },
    facultyOptions () {
      const pFacultis = []
      this.faculties.forEach(f => {
        let rName = ''
        if (f.depth > 0) {
          rName += f.parents + ' -> '
        }
        rName += f.name
        pFacultis.push({
          text: rName,
          value: f.id
        })
      })
      return pFacultis
    },
    fields: function () {
      return [
        {
          key: 'fullName',
          label: this.$t('views.users.list.fields.full_name')
        },
        {
          key: 'email',
          label: this.$t('views.users.list.fields.email'),
          thStyle: 'width: 18rem;'
        },
        {
          key: 'roles',
          label: this.$t('views.users.list.fields.roles'),
          thStyle: 'width: 12rem;'
        },
        {
          key: 'academy',
          label: this.$t('views.users.list.fields.academy'),
          thStyle: 'width: 14rem;'
        },
        {
          key: 'createdAt',
          label: this.$t('views.users.list.fields.created_at'),
          thStyle: 'width: 11rem;'
        },
        {
          key: 'lastLogin',
          label: this.$t('views.users.list.fields.last_login'),
          thStyle: 'width: 11rem;'
        }
      ]
    },
    perPageOptions () {
      return [
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ]
    }
  },
  created () {
    ApiUsers.getUsers(this.$route.query)
      .then(result => {
        this.users = result.data.datas
        this.users.forEach(user => {
          if (user.employeeId && (this.hasRight('list_employees') || this.hasRight('edit_user_role') || this.currentRole === 'manager' || (this.hasRight('view_own_employee') && user.employeeId.toString() === this.employeeId.toString()))) {
            this.getEmployee(user.employeeId)
          }
        })
        this.validRoles = result.data.validRoles
        this.pagination = result.data.pagination
        const query = this.$route.query
        this.perPage = this.pagination.items || 10
        if (!this.checkPerPage()) {
          this.perPage = 10
        }
        this.validRoles.forEach(role => {
          switch (role) {
            case 'analityknaukowy':
              if (this.currentRole === 'manager') {
                this.rolesOptions.push({ text: 'Analityk naukowy', text_en: 'Scientific analyst', id: 'analityknaukowy' })
                this.validRolesOptions.push({ text: 'Analityk naukowy', text_en: 'Scientific analyst', id: 'analityknaukowy' })
              }
              break
            case 'useradmin':
              if (this.currentRole === 'manager' || this.currentRole === 'useradmin') {
                this.rolesOptions.push({ text: 'Administrator użytkowników', text_en: 'User administrator', id: 'useradmin' })
                this.validRolesOptions.push({ text: 'Administrator użytkowników', text_en: 'User administrator', id: 'useradmin' })
              }
              break
            case 'custom2':
              if (this.currentRole === 'manager' || this.currentRole === 'useradmin') {
                this.rolesOptions.push({ text: 'Custom2', text_en: 'Custom2', id: 'custom2' })
                this.validRolesOptions.push({ text: 'Custom2', text_en: 'Custom2', id: 'custom2' })
              }
              break
            case 'custom3':
              if (this.currentRole === 'manager') {
                this.rolesOptions.push({ text: 'Custom3', text_en: 'Custom3', id: 'custom3' })
                this.validRolesOptions.push({ text: 'Custom3', text_en: 'Custom3', id: 'custom3' })
              }
              break
            case 'dziekan':
              this.rolesOptions.push({ text: 'Dziekan', text_en: 'Dziekan', id: 'dziekan' })
              this.validRolesOptions.push({ text: 'Dziekan', text_en: 'Dziekan', id: 'dziekan' })
              break
            case 'editor':
              this.rolesOptions.push({ text: 'Edytor', text_en: 'Editor', id: 'editor' })
              this.validRolesOptions.push({ text: 'Edytor', text_en: 'Editor', id: 'editor' })
              break
            case 'kierownikdyscypliny':
              this.rolesOptions.push({ text: 'Kierownik dyscypliny', text_en: 'Discipline manager', id: 'kierownikdyscypliny' })
              this.validRolesOptions.push({ text: 'Kierownik dyscypliny', text_en: 'Discipline manager', id: 'kierownikdyscypliny' })
              break
            case 'manager':
              if (this.currentRole === 'manager') {
                this.rolesOptions.push({ text: 'Menadżer', text_en: 'Manager', id: 'manager' })
                this.validRolesOptions.push({ text: 'Menadżer', text_en: 'Manager', id: 'manager' })
              }
              break
            case 'naukowiec':
              this.rolesOptions.push({ text: 'Naukowiec', text_en: 'Scientist', id: 'naukowiec' })
              this.validRolesOptions.push({ text: 'Naukowiec', text_en: 'Scientist', id: 'naukowiec' })
              break
            case 'pracownik':
              this.rolesOptions.push({ text: 'Pracownik administracyjny', text_en: 'Administrative worker', id: 'pracownik' })
              this.validRolesOptions.push({ text: 'Pracownik administracyjny', text_en: 'Administrative worker', id: 'pracownik' })
              break
            case 'pracownikjednostki':
              this.rolesOptions.push({ text: 'Pracownik administracyjny wydziału', text_en: 'Faculty administrative worker', id: 'pracownikjednostki' })
              this.validRolesOptions.push({ text: 'Pracownik administracyjny wydziału', text_en: 'Faculty administrative worker', id: 'pracownikjednostki' })
              break
            case 'superkierownik':
              this.rolesOptions.push({ text: 'Superkierownik', text_en: 'Superkierownik', id: 'superkierownik' })
              this.validRolesOptions.push({ text: 'Superkierownik', text_en: 'Superkierownik', id: 'superkierownik' })
              break
            case 'jednostka':
              this.rolesOptions.push({ text: 'Jednostka', text_en: 'Faculty', id: 'jednostka' })
              this.validRolesOptions.push({ text: 'Jednostka', text_en: 'Faculty', id: 'jednostka' })
              break
            case 'dyscyplina':
              this.rolesOptions.push({ text: 'Dyscyplina', text_en: 'Discipline', id: 'dyscyplina' })
              this.validRolesOptions.push({ text: 'Dyscyplina', text_en: 'Discipline', id: 'dyscyplina' })
              break
            case 'rektor':
              this.rolesOptions.push({ text: 'Rektor', text_en: 'Rector', id: 'rektor' })
              this.validRolesOptions.push({ text: 'Rektor', text_en: 'Rector', id: 'rektor' })
              break
            case 'audyt':
              this.rolesOptions.push({ text: 'Audyt', text_en: 'Audit', id: 'audyt' })
              this.validRolesOptions.push({ text: 'Audyt', text_en: 'Audit', id: 'audyt' })
              break
            default:
              this.rolesOptions.push({ text: role, text_en: role, id: role })
              this.validRolesOptions.push({ text: role, text_en: role, id: role })
          }
        })
        this.validRolesOptions = this.validRolesOptions.sort((a,b) => {
          return a.text.localeCompare(b.text)
        })
        this.rolesOptions.unshift({ text: 'Brak roli', text_en: 'No role', id: 'no_role' })
        this.rolesOptions.unshift({ text: 'Wybierz', text_en: 'Choose', id: '' })
        if (Object.prototype.hasOwnProperty.call(query, 'orderBy')) {
          const sort = this.sortBy
          sort.orderBy = query.orderBy
          this.sortBy = sort
        }
        if (Object.prototype.hasOwnProperty.call(query, 'orderDirection')) {
          const sort = this.sortBy
          sort.orderDirection = query.orderDirection === 'asc' ? 'desc' : 'asc'
          this.filters.orderDirection = query.orderDirection
          this.sortBy = sort
        }
        if (Object.prototype.hasOwnProperty.call(query, 'fullName')) {
          this.filters.fullName = query.fullName
        }
        if (Object.prototype.hasOwnProperty.call(query, 'email')) {
          this.filters.email = query.email
        }
        if (Object.prototype.hasOwnProperty.call(query, 'roles')) {
          this.filters.roles = query.roles
        }
        if (Object.prototype.hasOwnProperty.call(query, 'academyId')) {
          this.filters.academyId = query.academyId
        }
        if (this.hasRight('list_academies') || this.hasRight('list_own_academy')) {
          ApiAcademies.getAcademies()
            .then(result => {
              this.academiesList = result.data.datas
              this.academiesList = this.academiesList.map(academy => {
                return {
                  id: academy.id.toString(),
                  text: academy.name,
                  text_en: academy.name
                }
              })
              this.academiesList.unshift({ text: 'Wybierz', text_en: 'Choose', id: '' })
            })
        }
      })
      .finally(() => {
        this.loadingDone = true
      })
    if (this.hasRight('list_unit') || this.hasRight('list_unit_employee')) {
      this.getFaculties()
    }
  },
  mounted () {
    this.$store.dispatch('header/setHeader', {
      title: this.$t('views.users.list.title'),
      subtitle: this.$t('views.users.list.subtitle'),
      capitalizeSubtitle: false
    })
    this.buildSortOptions()
  },
  watch: {
    '$i18n.locale': function (newLocale, oldLocale) {
      if (oldLocale !== newLocale) {
        this.buildSortOptions()
      }
    }
  },
  methods: {
    buildSortOptions () {
      this.sortOptions = [
        {
          text: this.$t('views.users.list.surname'),
          value: 'full_name'
        }
      ]
    },
    getEmployee (id) {
      const eIdx = this.employees.findIndex(e => {
        return e.id === id
      })
      if (eIdx === -1) {
        ApiEmployees.getEmployeeShort(id)
          .then(result => {
            const eIdx = this.employees.findIndex(e => {
              return e.id === id
            })
            if (eIdx === -1) {
              this.employees.push(result.data)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    },
    getFaculties () {
      ApiFaculties.getFaculties()
        .then(result => {
          if (result) {
            this.faculties = result.data.datas
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getUsers () {
      const filters = this.getFilters()
      let query = {}
      query = filters
      query.page = this.pagination.page
      if (!this.checkPerPage()) {
        this.perPage = 10
      }
      query.per_page = this.perPage
      this.$router.push({ name: 'users_list_path', query: query })
      ApiUsers.getUsers(query)
        .then(result => {
          this.addUserSpinner = false
          this.users = result.data.datas
          this.resultsCount = result.data.results
          this.pagination = result.data.pagination
        })
        .catch((err) => {
          console.log(err)
        })
    },
    checkPerPage () {
      return this.perPage === 10 || this.perPage === 25 || this.perPage === 50 || this.perPage === 100
    },
    changePage () {
      this.getUsers()
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    },
    getFilteredUsers () {
      this.pagination.page = 1
      this.getUsers()
    },
    getFilters () {
      const oFilters = {}
      Object.keys(this.filters).forEach(key => {
        if (this.filters[key]) {
          oFilters[key] = this.filters[key]
        }
      })
      return oFilters
    },
    employeeChecked () {

    },
    escRoles () {
      this.$refs.roles.hide()
      this.errors = []
    },
    addRole () {
      this.changingRoles.push({
        id: '',
        option: ''
      })
    },
    editRoles (id) {
      this.changingRoles = []
      this.errors = []
      this.$refs.roles.show()
      const idx = this.users.findIndex(u => {
        return u.id === id.toString()
      })
      this.currentUser = this.users[idx]
      this.currentUser.roles.forEach(role => {
        if (role === 'naukowiec') {
          this.changingRoles.push({ id: role, option: this.currentUser.employeeId })
        } else if (role === 'pracownikjednostki') {
          this.changingRoles.push({ id: role, option: this.currentUser.facultyId })
        } else {
          this.changingRoles.push({ id: role, option: null })
        }
      })
    },
    removeRole (id) {
      this.changingRoles.splice(id, 1)
    },
    rolesSave () {
      const currentId = this.$store.getters['auth/id']
      ApiUsers.updateRoles(this.currentUser.id, this.changingRoles, this.currentUser.facultyId)
        .then(result => {
          if (result.data.data.id === currentId) {
            this.$store.dispatch('auth/fetchUser')
          }
          this.getUsers()
        })
        .catch((err) => {
          this.$toastr.e(err.response.data)
          console.log(err.response.data)
        })
    },
    userModal () {
      this.userErrors = []
      this.currentUser = { firstName: null, lastName: null, uid: null, email: null, academyId: null }
      this.editingUser = false
      this.$bvModal.show('new-user-modal')
    },
    editUser (id) {
      const idx = this.users.findIndex(u => {
        return u.id === id.toString()
      })
      this.currentUser = this.users[idx]
      this.editingUser = true
      this.$bvModal.show('new-user-modal')
    },
    removeUser (id) {
      const idx = this.users.findIndex(u => {
        return u.id === id.toString()
      })
      this.currentUser = this.users[idx]
      this.$refs.deleteUserConfirm.show()
    },
    deleteUser () {
      ApiUsers.deleteUser(this.currentUser.id)
        .then(result => {
          setTimeout(() => {
            this.getUsers()
            this.$toastr.s(this.$t('views.users.list.delete_user_success'))
          }, 300)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    updateAddUserSpinner () {
      this.addUserSpinner = true
    },
    setTime (date) {
      const momentDate = moment(date)
      return momentDate.format('LT')
    },
    roleName (role) {
      return this.$t(`views.users.list.roles.${role}`)
    }
  }
}

</script>

<style scoped lang="scss">
  @import '../../assets/stylesheets/vars';

  /deep/ .dropdown-menu {
    overflow: auto;
    max-height: 300px;
  }

  .clicked {
    cursor: pointer;
  }
  .sc-title {
    font-size: 1.71em;
    font-weight: bold;
    line-height: 2rem;
    letter-spacing: .04rem;
    color: $main-color;
    margin-left: .7rem;
    margin-right: 1.5rem;
    text-transform: uppercase;
  }
  /deep/ .normal>.checkbox-text {
    white-space: nowrap;
  }
  .normal > .checkbox-text {
    color: #9d9d9d;
    font-size: .9em;
    font-weight: 400;
    position: relative;
    bottom: .1rem;
    margin-right: .5rem;
  }
  .no-margin {
    /deep/ label.pcg-checkbox-container {
      margin: 0 !important;
    }
    &.form-group {
      margin: 0 !important;
    }
  }

  .users-table {
    margin-top: 1rem;
    background-color: $pcg-white;
  }

  /deep/ table.users-table {
    background-color: $pcg-white;
    border-radius: 15px;
    min-width: 655px;

    thead {
      background-color: $main-color;
      color: $pcg-white;

      th {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        vertical-align: middle;

        &.td-center {
          text-align: center;
        }
      }

      th:first-child {
        padding-left: 15px;
        border-top-left-radius: 15px;
      }

      th:last-child {
        padding-right: 15px;
        border-top-right-radius: 15px;
      }
    }

    tbody {
      padding-left: 15px;
      padding-right: 15px;
      background-color: $pcg-white;

      td {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        vertical-align: middle;

        &.td-center {
          text-align: center;
          color: $main-color;
          font-weight: 400;
        }
      }

      td:first-child {
        padding-left: 15px;
      }

      td:last-child {
        padding-right: 15px;
      }

      tr {
        height: 52px;
      }

      tr:last-child {
        td {
          padding-bottom: 15px;
        }

        td:first-child {
          padding-left: 15px;
          border-bottom-left-radius: 15px;
        }

        td:last-child {
          padding-right: 15px;
          border-bottom-right-radius: 15px;
        }
      }
    }
  }

  .sort-by-text {
    color: $pcg-gray;
    font-weight: 400;
    margin-left: .7rem;
    position: relative;
    top: .3rem;
  }

  .sort-by {
    position: relative;
    top: .4rem;
    margin-left: .7rem;
  }

  .filter-users {
    color: $pcg-gray;
    font-weight: 400;
    margin-left: .7rem;
    margin-right: .8rem;
  }

  .my-text-input {
    margin-right: .8rem;
    margin-left: .7rem;
    position: relative;
    width: 250px;
    top: .3rem;
    /deep/ .pcg-field {
      height: calc(1.5em + 0.75em + 2px);
    }
  }

  .modal-select {
    position: relative;
    top: .3rem;
    /deep/ .bootstrap-select .dropdown-toggle {
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
      width: 10rem;
    }
    /deep/ .filter-option-inner {
      position: relative;
      bottom: .45rem;
    }
    /deep/ .ls-element .pcg-input{
      height: calc(1.5em + 0.75em + 2px);
    }
  }
  .my-select {
    margin-right: .8rem;
    margin-left: .7rem;
    position: relative;
    width: 250px;
    top: .3rem;
    /deep/ .bootstrap-select .dropdown-toggle {
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
    }
    /deep/ .filter-option-inner {
      position: relative;
      bottom: .45rem;
    }
  }

  .my-select-short {
    margin-right: .8rem;
    margin-left: .7rem;
    position: relative;
    width: 100px;
    top: .3rem;
    /deep/ .bootstrap-select .dropdown-toggle {
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
    }
    /deep/ .filter-option-inner {
      position: relative;
      bottom: .45rem;
    }
  }

  .per_page {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-s;
  }

  .two-disciplines {
    margin-left: .7rem;
    position: relative;
    top: .3rem;
  }

  .my-checkbox {
    margin-left: .7rem;
    margin-right: 1.2rem;

    &:last-of-type {
      margin-right: 0;
    }

    /deep/ .pcg-checkbox-container {
      margin-right: 0;
      margin-bottom: .5rem;
    }
  }

  .my-text {
    font-weight: 400;
    color: $main-color;
    a {
      color: unset;
    }
  }

  .no-records {
    color: $pcg-dark-gray;
    font-weight: 400;
    font-size: $font-size-m;
  }

  .my-image {
    width: 20px;
    margin-right: .2rem;
    margin-left: .1rem;
    position: relative;
    bottom: .1rem;
  }

  .pagination-summary {
    color: $pcg-gray !important;
    font-weight: 400 !important;
    font-size: $font-size-s !important;
  }

  .my-page-select {
    margin-right: .8rem;
    margin-left: .7rem;
    position: relative;
    width: 100px;
    display: inline-block;
    /deep/ .bootstrap-select .dropdown-toggle {
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
    }
    /deep/ .filter-option-inner {
      position: relative;
      bottom: .45rem;
    }
  }

  .employee-btn {
    margin-bottom: 1rem;
    position: relative;
    bottom: .5rem;
  }

  .my-input {
    /deep/ .pcg-input {
      height: calc(1.5rem + 0.75rem + 2px)
    }
  }

  .my-error {
    color: $pcg-red;
    font-weight: 400;
    margin-left: .3rem;
  }

  .my-exclamation-mark {
    height: 14px;
    width: 14px;
    padding: .2rem .2rem;
    border-radius: 20px;
    text-align: center;
    background-color: $pcg-red;
    opacity: 0.8;
    position: relative;
    bottom: .2rem;
  }

  .confirm-header {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .required-fields {
    color: $pcg-gray;
    margin-top: 1rem;
    font-weight: 400;
  }

  .edit-role {
    color: $main-active-color;
  }

  .my-header {
    margin-bottom: 2rem;
  }

  .edit-roles {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-right: .5rem;
  }

  .one-role {
    font-weight: 400;
    font-size: $font-size-s;
    color: $pcg-gray;
    position: relative;
    left: .3rem;
  }

  .roles-pen {
    margin-left: .4rem;
    cursor: pointer;
    color: $main-active-color;
  }

  .my-cross {
    color: $pcg-gray;
    position: relative;
    top: .8rem;
    cursor: pointer;
  }

  .user-btn {
    margin-bottom: 1rem;
    position: relative;
    bottom: .5rem;
  }

  .confirm-text {
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1rem;
  }

  .delete-cross {
    cursor: pointer;
    color: $pcg-red;
  }

  .role-name {
    color: $pcg-dark-gray;
    font-weight: 400;
    font-size: $font-size-m;
    padding-left: 1rem;
    padding-top: .5rem;
  }

    /deep/ .tooltip-inner {
      background: $pcg-the-lightest-gray-background !important;
      color: $pcg-gray !important;
      font-weight: 400;
      padding: .5rem;
      border-color: $pcg-light-gray-background;
      border-style: solid;
      border-width: 1px;
      opacity: 1;
    }

    /deep/ .arrow::before {
      border-top-color: $pcg-light-gray-background !important;
    }

  .no-role {
    color: $pcg-orange;
  }

  ::v-deep .check-list {
    margin-top: .1rem;
    margin-left: .1rem;
    width: 400px !important;
    @media (max-width: 890px) {
      width: 300px !important;
    }
    @media (max-width: 620px) {
      width: 250px !important;
    }
    @media (max-width: 575.98px) {
      width: 100% !important;
    }
  }
</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .sc-title {
      color: $main-color;
    }
    .normal > .checkbox-text {
      color: #9d9d9d;
    }
    .sort-by-text {
      color: $pcg-gray;
    }
    .filter-users {
      color: $pcg-gray;
    }
    .my-select {
      /deep/ .bootstrap-select .dropdown-toggle {
        color: $pcg-gray;
      }
    }
    .my-select-short {
      /deep/ .bootstrap-select .dropdown-toggle {
        color: $pcg-gray;
      }
    }
    .per_page {
      color: $pcg-gray;
    }
    .my-text {
      color: $main-color;
    }
    .no-records {
      color: $pcg-dark-gray;
    }
    .pagination-summary {
      color: $pcg-gray !important;
    }
    .my-page-select {
      /deep/ .bootstrap-select .dropdown-toggle {
        color: $pcg-gray;
      }
    }
    .my-sort {
      color: $pcg-gray;
      /deep/ .bootstrap-select .dropdown-toggle {
        color: $main-color !important;
        border: none;
      }
    }
    .my-pagination {
      /deep/ .page-item {
        &, &.disabled {
          .page-link {
            color: $pcg-the-darkest-gray !important;
            &:hover {
              color: $hover-color !important;
            }
          }
        }
        &.active {
          .page-link {
            background-color: $main-active-color !important;
            color: $pcg-white !important;
          }
        }
        &:first-child, &:last-child {
          .page-link {
            color: $main-active-color !important;
          }
        }
      }
    }
    .my-error {
      color: $pcg-red;
    }
    .my-exclamation-mark {
      background-color: $pcg-red;
    }
    .confirm-header {
      color: $pcg-gray;
    }
    .required-fields {
      color: $pcg-gray;
    }
    .roles-pen {
      color: $main-active-color;
    }
    .edit-role {
      color: $main-active-color;
    }
    /deep/ .tooltip-inner {
      background: $pcg-the-lightest-gray !important;
      color: $pcg-gray !important;
      border-color: $pcg-light-gray;
    }

    /deep/ .arrow::before {
      border-top-color: $pcg-light-gray !important;
    }
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .users-table {
      background-color: $pcg-white !important;
      thead {
        background-color: $main-color !important;
        color: $pcg-white !important;
      }
      tbody {
        background-color: $pcg-white !important;
        td {
          &.td-center {
            color: $main-color !important;
          }
        }
      }
    }
  }
</style>
