<template>
  <b-row class="select-row">
    <b-col cols=1 class="my-label">
      <label>{{ $t('views.achievement.list.filters') }}</label>
    </b-col>
    <b-col cols="5">
      <div class="pcg-select form-group my-select">
        <b-dropdown variant="light" class="bootstrap-select" ref="filters">
          <template v-slot:button-content>
            <div class="filter-option">
              <div class="filter-option-inner">
                {{ getOption(selectedOption).text }}
              </div>
            </div>
          </template>
          <b-dropdown-item v-for="option in myFiltersSets()"
                           :active="isOptionActive(option.value)"
                           :key="option.value"
                           @click="setFilters(option.value)"
                           class="my-item"
                           >
            <span>{{ option.text }}</span>
            <i v-if="hasRight('remove_filter')" class="fa fa-trash" style="position: relative; top: 1px;" @click.stop="showClearModal(option.value)"></i>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </b-col>
    <b-col style="position: relative; bottom: 2px;">
      <pcg-btn v-if="hasRight('save_filter')" size="small" variant="additional" class="clear-filters" @click="saveFilters">
        {{ $t('views.achievement.list.filters_save') }}
      </pcg-btn>
    </b-col>
    <b-modal ref="saveFiltersModal"
             :hide-header="true"
             footer-class="my-modal-footer"
             :centered="true"
             v-bind:ok-title="$t('general.confirm')"
             ok-variant="primary"
             :no-close-on-esc="true"
             @ok="saveFilterSet(filtersSetName)"
             v-bind:cancel-title="$t('general.cancel')"
             cancel-variant="default"
             content-class="my-modal-content"
    >
      <div class="confirm-header">{{ $t('views.achievement.list.filters_saving') }}</div>
      <input class="form-control pcg-input pcg-field pcg-field" v-model="filtersSetName">
    </b-modal>
    <b-modal ref="clearModal"
             :hide-header="true"
             footer-class="my-modal-footer"
             :centered="true"
             v-bind:ok-title="$t('general.confirm')"
             ok-variant="primary"
             :no-close-on-esc="true"
             @ok="delFilters"
             v-bind:cancel-title="$t('general.cancel')"
             cancel-variant="default"
             content-class="my-modal-content"
    >
      <div class="confirm-header">{{ $t('views.achievement.list.filters_deleting') }}</div>
      <div class="confirm-text">{{ $t('views.achievement.list.filters_delete_confirmation') }}</div>
    </b-modal>
  </b-row>
</template>

<script>
import handle422ToastrErrors from '../mixins/error_handler'
import hasRight from '../mixins/has_right'
import { mixin as clickaway } from 'vue-clickaway'
import ApiPublications from '../api/publications'

export default {
  name: 'SelectFilter',
  mixins: [clickaway, handle422ToastrErrors, hasRight],
  data: function () {
    return {
      filtersSetName: null,
      actualOption: null
    }
  },
  props: {
    filters: Object,
    filtersOptions: Array,
    selectedOption: {
      type: Number,
      default: null
    },
  },
  methods: {
    delFilters () {
      ApiPublications.delFilter(this.actualOption)
      this.$emit('del', this.actualOption)
      this.$refs.filters.hide()
      this.$refs.clearModal.hide()
      this.actualOption = null
    },
    showClearModal (id) {
      this.actualOption = id
      this.$refs.clearModal.show()
    },
    getOption (value) {
      return this.myFiltersSets().find(option => option.value === value) || { text: '', value: null }
    },
    isOptionActive (value) {
      return this.selectedOption === value
    },
    myFiltersSets () {
      return this.filtersOptions?.map(f => {
        return { value: f.id, text: f.name }
      })
    },
    setFilters (id) {
      this.$emit('input', id)
    },
    saveFilters () {
      this.$refs.saveFiltersModal.show()
    },
    saveFilterSet (filterSetName) {
      ApiPublications.saveFilter(filterSetName, this.filters)
          .then(result => {
            const filterSet = result.data
            this.$emit('add', filterSet)
          })
          .catch(error => {
            console.log(error)
            this.handle422ToastrErrors({ status: error.response.status, data: error.response.data })
          })
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";
  .clear-filters {
    margin: 0;
  }
  .my-label {
    font-size: $font-size-s;
    color: $pcg-gray;
    font-weight: 400;
    display: inline;
    float: left;
  }
  .my-select {
    /deep/ .bootstrap-select .dropdown-toggle {
      height: calc(1.5rem + 0.75rem + 2px)
    }
  }
  .my-item {
    /deep/ .dropdown-item {
      display: flex;
      justify-content: space-between;
    }
  }
  .publication-type {
    display: inline-block;
    width: 100%;
  }
  .pcg-select {
    ::v-deep .btn-light {
      width: 100%;
      text-align: left;
      &:after {
        position: absolute !important;
        right: 1.5rem;
        top: 50%;
      }
      &:focus {
        box-shadow: $select-box-shadow;
      }
    }
    ::v-deep .dropdown-menu {
      width: 100% !important;
      .dropdown-item.active {
        border-radius: calc(.25rem - 1px);
      }
    }
    .dropdown.show {
      ::v-deep .btn-light {
        color: $pcg-white;
        background-color: $main-active-color !important;
      }
    }
    .add-value-link {
      display: block;
      font-size: $font-size-s;
      margin-top: $pcg-m-xs;
      background-color: transparent;
      border: none;
      outline: none;
      padding: 0;
    }
    .filter-option-inner {
      overflow: hidden;
      text-overflow: ellipsis;
      width: calc(100% - 15px);
    }
  }
  .select-row {
    display: flex;
    align-items: center;
  }

  .confirm-header {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .confirm-text {
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1rem;
  }
</style>

<style lang="scss" scoped>
@import "../assets/stylesheets/vars-contrast";
.contrast {
  .confirm-header {
    color: $pcg-gray;
  }
  .confirm-text {
    color: $pcg-gray;
  }
  .my-label {
    color: $pcg-gray;
  }
  .pcg-select {
    .dropdown.show {
      ::v-deep .btn-light {
        color: $pcg-white;
        background-color: $main-active-color !important;
      }
    }
  }
}
</style>
