import api from './index'

const resource = 'publications'

export default {
  getComments (publicationId) {
    let url = ''
    url = resource + '/' + publicationId + '/comments'
    return api.get(url)
  },
  createComment (publicationId, comment) {
    let url = ''
    url = resource + '/' + publicationId + '/comments'
    const body = {
      comment: {
        user_id: comment.userId,
        publication_id: publicationId,
        content: comment.text
      }
    }
    return api.post(url, body)
  },
  updateComment (id, comment) {
    const url = 'comments/' + id
    const body = {
      comment: comment
    }
    return api.put(url, body)
  },
  deleteComment (id) {
    const url = 'comments/' + id
    return api.delete(url)
  }
}
