<template>
  <pcg-box class="main-class">
    <div v-if="employee.polonId || employee.polonUuid || employee.pbnId">
      <div class="justify-content-center">
        <div>
          <!--<div v-if="employee.polonId" style="margin-bottom: .2rem" class="d-flex justify-content-center">-->
          <!--  <img src="../assets/images/new_polon.png" class="img-fluid my-image-orcid" alt="POL-on ID" v-b-tooltip.hover title="POL-on ID"/>-->
          <!--</div>-->
          <!--<div v-if="employee.polonId" style="margin-bottom: 1rem" class="polon-id">-->
          <!--  {{ employee.polonId }}-->
          <!--</div>-->
          <div v-if="employee.polonUuid" style="margin-bottom: .2rem" class="d-flex justify-content-center">
            <img src="../assets/images/new_polon.png" class="img-fluid my-image-orcid" alt="POL-on ID" v-b-tooltip.hover title="POL-on UUID"/>
          </div>
          <div v-if="employee.polonUuid" style="margin-bottom: 1rem" class="polon-id">
            {{ employee.polonUuid }}
          </div>
          <div v-if="employee.pbnId" style="margin-bottom: .2rem" class="d-flex justify-content-center">
            <img src="../assets/images/pbn.svg" class="img-fluid my-image-orcid" alt="PBN ID" v-b-tooltip.hover title="PBN ID"/>
          </div>
          <div v-if="employee.pbnId" style="margin-bottom: 1rem" class="polon-id">
            {{ employee.pbnId }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="!employee.polonId && !employee.polonUuid && !employee.pbnId" class="polon-btn">
      <pcg-btn size="normal" variant="additional" @click="updatePolon()" v-if="hasRight('edit_employee') || hasRight('edit_own_employee')">
        <span class="link-account">{{ $t('components.dn_polon.add_id') }}</span>
      </pcg-btn>
    </div>
    <div v-else class="polon-btn">
      <pcg-btn size="normal" variant="additional" @click="updatePolon()" v-if="hasRight('edit_employee') || hasRight('edit_own_employee')">
        <span class="link-account">{{ $t('components.dn_polon.edit_id') }}</span>
      </pcg-btn>
    </div>
    <b-modal ref="connectPolon"
             :hide-header="true"
             :centered="true"
             v-bind:ok-title="$t('general.confirm')"
             ok-variant="primary"
             :no-close-on-esc="true"
             @ok="connectSave(true)"
             v-bind:cancel-title="$t('general.cancel')"
             cancel-variant="default"
             v-on-clickaway = "escConnect"
             footer-class="my-modal-footer"
             content-class="my-modal-content"
    >
      <!--<pcg-text-input-->
      <!--        class="my-polon"-->
      <!--        :label="$t('components.dn_polon.polon_id')"-->
      <!--        v-model="polonId"-->
      <!--/>-->
      <pcg-text-input
          class="my-polon"
          :label="$t('components.dn_polon.polon_uuid')"
          v-model="polonUuid"
      />
      <pcg-text-input
          class="my-polon"
          :label="$t('components.dn_polon.pbn_id')"
          v-model="pbnId"
      />
    </b-modal>
  </pcg-box>
</template>

<script>
import ApiPolon from '../api/polon'
import { mixin as clickaway } from 'vue-clickaway'
import unexpectedError from '../mixins/unexpected_error'
import hasRight from '../mixins/has_right'
import handle422ToastrErrors from '../mixins/error_handler'

export default {
  name: 'dn-polon',
  mixins: [clickaway, hasRight, handle422ToastrErrors, unexpectedError],
  props: {
    innerHeader: Boolean,
    employee: Object
  },
  data: function () {
    return {
      polonId: null,
      polonUuid: null,
      pbnId: null
    }
  },
  methods: {
    updatePolon () {
      this.polonId = this.employee.polonId
      this.polonUuid = this.employee.polonUuid
      this.pbnId = this.employee.pbnId
      this.$refs.connectPolon.show()
    },
    escConnect () {
      this.polon = this.polonId
      this.$refs.connectPolon.hide()
    },
    connectSave (userClicked = false) {
      ApiPolon.updatePolonId(this.employee.id, this.polonId, this.polonUuid, this.pbnId, userClicked)
        .then(result => {
          if (result) {
            this.$refs.connectPolon.hide()
            this.$parent.employer.polonId = this.polonId
            this.$parent.employer.polonUuid = this.polonUuid
            this.$parent.employer.pbnId = this.pbnId
          } else {
          }
        })
        .catch(error => {
          console.log(error)
          const errors = error.response.data
          this.handle422ToastrErrors({ status: error.response.status, data: error.response.data })
          // this.unexpectedError()
        })
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .polon-text {
    text-align: center;
    color: $pcg-gray;
    font-weight: 400;
  }

  .polon-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .change-btn {
    color: $main-active-color;
    font-size: 1em;
    font-weight: 400;
    cursor: pointer;
  }

  .my-pen {
    font-size: 9px;
    position: relative;
    bottom: .1rem;
  }

  .polon-btn-text {
    font-weight: 600;
    margin-right: .7rem;
  }

  .my-image {
    width: 50px;
    height: 30px;
    display: inline-block;
    position: relative;
    bottom: .2rem;
  }

  .my-image-orcid {
    height: 22px;
    display: inline-block;
    position: relative;
    bottom: .1rem;
    margin-right: .5rem;
  }

  .polon-label {
    color: $pcg-gray;
    font-weight: 400;
  }

  .polon-id {
    color: $main-color;
    font-size: $font-size-m;
    font-weight: 400;
    letter-spacing: .05rem;
    overflow-wrap: break-word;
    width: 100%;
    text-align: center;
  }

  .polon-achievements {
    color: $main-active-color;
    font-weight: 600;
    font-size: $font-size-m;
    cursor: pointer;
  }

  .polon-space {
    margin-top: .5rem;
  }

  .link-account {
    margin-right: .5rem;
  }

  .my-polon {
    margin: .5rem 1rem 2rem;
    /deep/ .pcg-input-label {
      margin-bottom: .4rem;
      margin-left: .25rem;
      color: $pcg-gray;
    }
    /deep/ .form-control {
      height: calc(1.5em + 0.75rem + 2px);
    }
  }

  .polon-id-text {
    color: $pcg-gray;
    font-weight: 400;
  }

  /deep/ .tooltip-inner {
    background: $pcg-white;
    color: $pcg-dark-gray;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
    font-weight: 400;
    padding: .5rem;
    border-color: $pcg-light-gray-background;
    border-style: solid;
    border-width: 1px;
    opacity: 1;
    a {
      color: unset;
    }
  }

  /deep/ .arrow::before {
    border-top-color: $pcg-light-gray-background !important;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .polon-text {
      color: $pcg-gray;
    }
    .change-btn {
      color: $main-active-color;
    }
    .polon-id {
      color: $main-color;
    }
    .polon-achievements {
      color: $main-active-color;
    }
    .my-polon {
      /deep/ .pcg-input-label {
        color: $pcg-gray;
      }
    }
    .polon-id-text {
      color: $pcg-gray;
    }
    /deep/ .tooltip-inner {
      background: $pcg-white;
      color: $pcg-dark-gray;
      box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
      border-color: $pcg-light-gray;
    }
    /deep/ .arrow::before {
      border-top-color: $pcg-light-gray !important;
    }
  }
</style>
