<template>
  <div class="d-flex align-items-center">
    <div v-if="contrast" class="pcg-color-status-flex" :class="`${className}_contrast`"></div>
    <div v-else class="pcg-color-status-flex" :class="className"></div>
    <span class="ml-2 font-weight-bold" :class="`${className}-color`" v-if="!pbn && !contrast">
      {{ $t(`components.publication_element.statuses.${status}`) }}
    </span>
    <span class="ml-2 font-weight-bold" :class="`${className}_contrast-color`" v-if="!pbn && contrast">
      {{ $t(`components.publication_element.statuses.${status}`) }}
    </span>
    <span class="ml-2 font-weight-bold" :class="`${className}-color`" v-if="pbn && !contrast">
      {{ $t(`components.publication_element.statusesPolon.${status}`) }}
    </span>
    <span class="ml-2 font-weight-bold" :class="`${className}_contrast-color`" v-if="pbn && contrast">
      {{ $t(`components.publication_element.statusesPolon.${status}`) }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'DnStatus',
  props: {
    className: {
      type: String,
      default: ''
    },
    status: {
      required: true,
      type: String
    },
    pbn: {
      type: Boolean,
      default: false
    },
    publicationTypeId: {
      type: Number
    },
    contrast: {
      type: Boolean,
      default: false
    }
  }
}
</script>
