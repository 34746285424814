<template>
  <div class="live-search" :class="{ 'live-search-form': (!clearBtn), 'live-search-form-alternative': (clearBtn) }">
    <div class="ls-element" style="width: 100%">
      <label class="pcg-input-label" :for="id" :class="{ 'pcg-input-label-required': required }" v-if="label">{{ label }}</label>
      <pcg-text-input v-if="$i18n.locale === 'pl'"
                      v-model="value.value"
                      :show-label="false"
                      autocomplete="off"
                      @input = "searchElements"
                      @enterEvent = "newElement"
                      @keyup.down.native="nextSelect"
                      @keyup.up.native="prevSelect"
                      v-on-clickaway = "hideList"
                      :id="id"
                      class="flex-grow-1 my-text-input my-input"
      />
      <pcg-text-input v-else
                      v-model="value.valueEn"
                      :show-label="false"
                      autocomplete="off"
                      @input = "searchElements"
                      @enterEvent = "newElement"
                      @keyup.down.native="nextSelect"
                      @keyup.up.native="prevSelect"
                      v-on-clickaway = "hideList"
                      :id="id"
                      style="display: inline-block"
      />
      <i class="fas fa-search my-search-icon fa-lg" v-if="showSearchIcon && !value.value && !value.valueEn"></i>
      <a href="javascript:;" :aria-label="$t('general.clear')" @click="clearValue" style="display: inline-block" v-if="clearBtn && (value.value || value.valueEn)" class="ls-clear">
        <i class="pcg-icon-cross" :class="{'alternative-cross': !alternativeClass}"></i>
      </a>
    </div>
    <div class="check-list" v-if="listActivated" style="width: 100%" v-bind:style="{top: label ? '4.1rem' : '2.1rem'}">
      <ul>
        <li v-for="(element,index) in currentList"
            :class="{'preferred-part': (element.additionalInformation === 'preferred')}"
            :data-idx="index"
            :key="`${index}-${element.id}`"
            @click="checkElement"
            @mouseover="addSelectedClass"
            @mouseleave="removeSelectedClass"
        >
          {{ $i18n.locale === 'pl' ? (element.value ? element.value : element.name) : element.valueEn ? element.valueEn : element.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// Parametry komponentu:
// - options: {
//      path: String - ściezka do zapytania do backendu
//      params: Hash - dodatkowe parametry przekazywane do backendu
//   }
// - model: {
//    id: Integer
//    value: String - wartość wyświetlana i wyszukiwana
//  }
//
// Zapytanie do backendu:
// method: POST
// url: /api/v1/<options.path>
// body: {
//      parmas: <options.params>
//      query: <this.value.value>
//      }
//
// Odpowiedz z backendu:
// [
//  {
//      id: Integer,
//      value: String
//  }
// ]

import ApiSearch from '../api/live_search'
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'LiveSearch',
  mixins: [clickaway],
  props: {
    value: Object,
    options: {
      // { path: String, params: String }
      type: Object,
      required: true
    },
    clearBtn: {
      // { id: Integer, value: String }
      type: Boolean,
      default: true
    },
    showSearchIcon: {
      type: Boolean,
      default: true
    },
    alternativeClass: {
      type: Boolean,
      default: false
    },
    label: String,
    required: Boolean,
    id: String,
    contentType: String
  },
  data () {
    return {
      listActivated: false,
      currentList: []
    }
  },
  watch: {
  },
  mounted () {
    document.addEventListener('keydown', (e) => {
      let cl
      if (e.target.parentElement.parentElement && this.hasFocus()) {
        cl = e.target.parentElement.parentElement.classList
        // LiveSearch with 'esc' key
        if (this.$i18n.locale === 'pl') {
          if ((e.keyCode === 27 && cl.contains('live-search')) || (this.value.value && this.value.value.length === 0)) {
            this.listActivated = false
            this.$emit('close')
          }
        } else {
          if ((e.keyCode === 27 && cl.contains('live-search')) || (this.value.valueEn && this.value.valueEn.length === 0)) {
            this.listActivated = false
            this.$emit('close')
          }
        }
        // LiveSearch with 'enter' key
        if (e.keyCode === 13 && cl.contains('live-search')) {
          this.listActivated = false
          this.$emit('ls-checked')
        }
        // LiveSearch with 'arrowUp' key
        // if (e.keyCode === 38 && cl.contains('check-list')) {
        //   this.listActivated = true
        //   this.clearSelectedClass(e)
        //   this.addSelectedClass(e)
        // }
        // // LiveSearch with 'arrowDown' key
        // if (e.keyCode === 40) {
        //   if (cl.contains('ls-element')) {
        //     this.listActivated = true
        //     // this.$nextTick(() => {
        //     const list = document.querySelector('.check-list')
        //     const elem = list.children[0].children[0]
        //     elem.classList.add('selected')
        //     elem.focus()
        //     // })
        //   } else {
        //     this.listActivated = true
        //     this.clearSelectedClass(e)
        //     this.addSelectedClass(e)
        //   }
        // }
      }
    })
  },
  methods: {
    clearValue () {
      this.value.value = null
      this.value.valueEn = null
      this.value.id = null
      this.listActivated = false
      this.$emit('ls-clear')
    },
    hasFocus () {
      const elem = this.$el.querySelector('input')
      if (document.activeElement !== elem) {
        this.listActivated = false
      }
      return document.activeElement === elem
    },
    searchElements () {
      this.value.id = null
      this.$emit('input', this.value)
      if (this.$i18n.locale === 'pl') {
        if (this.value.value.length > 2) {
          this.refreshList()
        } else {
          this.listActivated = false
        }
      } else {
        if (this.value.valueEn.length > 2) {
          this.refreshList()
        } else {
          this.listActivated = false
        }
      }
    },
    refreshList (clear = false) {
      if (clear) {
        this.currentList = []
        this.$emit('ls-checked')
      } else {
        if (this.$i18n.locale === 'pl') {
          ApiSearch.searchList(this.options.path, this.options.params, this.value.value)
            .then(result => {
              this.currentList = result.data
              this.listActivated = this.currentList.length > 0 && this.hasFocus()
              this.$emit('ls-active', this.listActivated)
            })
            .catch((error) => {
              console.log(error)
            })
        } else {
          ApiSearch.searchList(this.options.path, this.options.params, this.value.valueEn)
            .then(result => {
              this.currentList = result.data
              this.listActivated = this.currentList.length > 0 && this.hasFocus()
              this.$emit('ls-active', this.listActivated)
            })
            .catch((error) => {
              console.log(error)
            })
        }
      }
    },
    checkElement (ev) {
      const elem = ev.target
      const idx = elem.dataset.idx
      this.listActivated = false
      this.value.value = this.currentList[idx].value ? this.currentList[idx].value : this.currentList[idx].name
      this.value.valueEn = this.currentList[idx].valueEn ? this.currentList[idx].valueEn : this.currentList[idx].nameEn
      this.value.id = this.currentList[idx].id
      this.$emit('input', this.value)
      this.$emit('ls-checked')
      this.$emit('ls-active', this.listActivated)
    },
    newElement (value) {
      if (this.contentType !== 'author') {
        let element
        if (this.$i18n.locale === 'pl') {
          const idx = this.currentList.findIndex((el) => {
            if (this.contentType === 'author') {
              return el.value.split(',')[0] === value
            } else {
              return el.value.replace(/\s\| ISBN.*$|\s\| ISSN.*$|\s\(.*\)$/g, '') === value
            }
          })
          if (idx >= 0) {
            element = {
              id: this.currentList[idx].id,
              value: this.currentList[idx].value.replace(/\s\| ISBN.*$|\s\| ISSN.*$|\s\(.*\)$/g, '')
            }
          } else {
            element = {
              id: null,
              value: value
            }
          }
        } else {
          const idx = this.currentList.findIndex((el) => {
            if (this.contentType === 'author') {
              return el.valueEn.split('') === value
            } else {
              return el.valueEn.replace(/\s\| ISBN.*$|\s\| ISSN.*$|\s\(.*\)$/g, '') === value
            }
          })
          if (idx >= 0) {
            element = {
              id: this.currentList[idx].id,
              valueEn: this.currentList[idx].valueEn.replace(/\s\| ISBN.*$|\s\| ISSN.*$|\s\(.*\)$/g, '')
            }
          } else {
            element = {
              id: null,
              valueEn: value
            }
          }
        }
        element = Object.assign(this.value, element)
        this.$emit('input', element)
        this.$emit('ls-checked')
      }
      this.listActivated = false
    },
    addSelectedClass (ev) {
      const elem = ev.target
      elem.classList.add('selected')
    },
    removeSelectedClass (ev) {
      const elem = ev.target
      elem.classList.remove('selected')
    },
    nextSelect (ev) {
      const elem = ev.target
      const lsElem = elem.parentElement.parentElement.parentElement
      const checkList = lsElem.querySelector('.check-list')
      const currentElem = checkList.querySelector('.selected')
      let idx
      if (currentElem) {
        idx = parseInt(currentElem.dataset.idx) + 1
        if (checkList.children[0].children[idx]) {
          currentElem.classList.remove('selected')
        }
      } else {
        idx = 0
      }
      if (checkList.children[0].children[idx]) {
        const nextElem = checkList.children[0].children[idx]
        nextElem.classList.add('selected')
      }
      this.value.value = this.currentList[idx].value ? this.currentList[idx].value : this.currentList[idx].name
      this.value.valueEn = this.currentList[idx].valueEn ? this.currentList[idx].valueEn : this.currentList[idx].nameEn
      this.value.id = this.currentList[idx].id
    },
    prevSelect (ev) {
      const elem = ev.target
      const lsElem = elem.parentElement.parentElement.parentElement
      const checkList = lsElem.querySelector('.check-list')
      const currentElem = checkList.querySelector('.selected')
      let idx
      if (currentElem) {
        idx = parseInt(currentElem.dataset.idx) - 1
        if (checkList.children[0].children[idx]) {
          currentElem.classList.remove('selected')
        }
      } else {
        idx = 0
      }
      if (checkList.children[0].children[idx]) {
        const nextElem = checkList.children[0].children[idx]
        nextElem.classList.add('selected')
      }
      this.value.value = this.currentList[idx].value ? this.currentList[idx].value : this.currentList[idx].name
      this.value.valueEn = this.currentList[idx].valueEn ? this.currentList[idx].valueEn : this.currentList[idx].nameEn
      this.value.id = this.currentList[idx].id
    },
    hideList () {
      if (this.hasFocus()) {
        this.listActivated = false
        if (this.$i18n.locale === 'pl') {
          this.newElement(this.value.value)
        } else {
          this.newElement(this.value.valueEn)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../assets/stylesheets/vars';

  .live-search-form {
    //::v-deep .form-group {
    //  display: inline-block;
    //  min-width: 100%;
    //}
  }

  .live-search-form-alternative {
    //::v-deep .form-group {
    //  display: inline-block;
    //  min-width: 98%;
    //  @media (max-width: 1000px) {
    //    min-width: 95%;
    //  }
    //  @media (max-width: 350px) {
    //    min-width: 90%;
    //  }
    //}
  }

  .live-search {
    position: relative;
    color: $main-color;
    display: flex;
    justify-content: space-between;

    .ls-clear {
      width: 1px;
      display: inline-block;
      margin-left: -30px;

      .pcg-icon-cross {
        cursor: pointer;
        color: $pcg-light-gray;
      }

      .alternative-cross {
        position: relative;
        bottom: .6rem;
      }
    }

    .check-list {
      background-color: white;
      border: none;
      border-radius: 5px;
      position: absolute;
      left: 0;
      //top: 2.1rem;
      box-shadow: 0 3px 15px rgba(0, 0, 0, 0.2);
      z-index: 1000;

      ul {
        padding: 10px;
        margin-bottom: 0;
        list-style: none;
        font-size: small;
        cursor: pointer;
      }

      .selected {
        background-color: $pcg-lightest-gray-background;
        color: $main-active-color;
      }
    }

    .my-search-icon {
      width: 0;
      color: $pcg-gray;
      position: relative;
      right: 2.5rem;
      cursor: text;
    }

    .my-input {
      /deep/ .pcg-input {
        height: calc(1.5rem + 0.75rem + 2px)
      }
    }

    .my-text-input {
      display: inline-block;
      width: 98%;
      /deep/ .pcg-input {
        padding-right: 2.7rem;
      }
    }
  }

  .preferred-part {
    border-bottom: $main-color 3px solid;
    margin-bottom: .5rem;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .live-search {
      color: $main-color;
      .ls-clear {
        .pcg-icon-cross {
          color: $pcg-light-gray;
        }
      }
      .check-list {
        background-color: $pcg-white;
        .selected {
          background-color: $pcg-lightest-gray;
          color: $main-active-color;
        }
      }
    }
    .preferred-part {
      border-bottom: $main-color 3px solid;
    }
  }
</style>
