export default {
  data () {
    return {
      $_done: false,
      minTimePass: false,
      minLoadingTimeMs: 1000
    }
  },
  computed: {
    showLoader () {
      return !(this.loadingDone && this.minTimePass)
    },
    loadingDone: {
      get: function () {
        return this.$data.$_done
      },
      set: function (newValue) {
        this.$data.$_done = newValue
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.minTimePass = true
    }, this.minLoadingTimeMs)
  }
}
