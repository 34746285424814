import ApiPublicationTypes from '../../api/publication_types'

const publicationTypes = {
  namespaced: true,
  state: {
    publication_types: []
  },
  getters: {
    publicationTypes: state => state.publication_types
  },
  mutations: {
    setPublicationTypes (state, publicationTypes) {
      state.publication_types = publicationTypes
    }
  },
  actions: {
    setPublicationTypes ({ commit }, payload) {
      ApiPublicationTypes.chosenPublicationTypes()
        .then(result => {
          let publications = result.data
          publications = publications.map(publication => {
            return {
              id: publication.id.toString(),
              text: publication.name,
              text_en: publication.nameEn
            }
          })
          commit('setPublicationTypes', publications)
        })
        .catch(() => {
          console.log('Error')
          return []
        })
    }
  }
}

export { publicationTypes }
