<template>
    <div class="xml-element row">
        <b-col cols="5" style="margin: auto">
            <span class="filename">{{ element.fileName }}</span>
        </b-col>
        <b-col cols="3" style="margin: auto">
            <span class="date">{{ $d(Date.parse(element.createdAt), 'longDate') }}</span>
        </b-col>
        <b-col cols="4" class="d-flex justify-content-end">
            <pcg-btn @click="deleteXml" class="btn-danger" :disabled="xmlSpinner">{{ $t('components.polon_element.delete') }}</pcg-btn>
            <pcg-btn @click="downloadXml" class="pcg-button pcg-main-btn pcg-normal-btn" :disabled="xmlSpinner">{{ $t('general.download')}}</pcg-btn>
        </b-col>
    </div>
</template>

<script>
import waitForJobs from '../mixins/wait_for_jobs'
import unexpectedError from '../mixins/unexpected_error'
import ApiXml from '../api/xmls'

export default {
  name: 'XmlElement',
  mixins: [unexpectedError, waitForJobs],
  data: function () {
    return {
      jid: ''
    }
  },
  props: {
    element: Object,
    xmlSpinner: Boolean
  },
  watch: {
    // jid (newValue) {
    //   if (newValue !== null && newValue !== '') {
    //     this.xmlSpinner = true
    //     this.waitForJob(this.jid, this.xmlParsed, this.unexpectedError)
    //   }
    // }
  },
  created () {
    this.jid = this.element.jid
  },
  methods: {
    deleteXml () {
      ApiXml.deleteXml(this.element.id)
        .then(result => {
          this.$emit('deleted')
        })
        .catch(error => {
          console.log(error)
        })
    },
    downloadXml () {
      this.$emit('downloadXml', this.element.id)
    }
  }
}
</script>

<style scoped lang="scss">
    @import '../assets/stylesheets/vars';

    .btn-danger {
        color: #fff;
        background-color: #dc3545;
        border-color: #dc3545;

        &:hover {
            color: #fff;
            background-color: #c82333;
            border-color: #bd2130;
        }
    }

    .filename {
        color: $pcg-gray;
        font-weight: 400;
    }

    .date {
        color: $pcg-gray;
        font-weight: 400;
    }
</style>

<style lang="scss" scoped>
    @import "../assets/stylesheets/vars-contrast";
    .contrast {
        .btn-danger {
            color: #fff;
            background-color: #dc3545;
            border-color: #dc3545;
            &:hover {
                color: #fff;
                background-color: #c82333;
                border-color: #bd2130;
            }
        }
        .filename {
            color: $pcg-gray;
        }
        .date {
            color: $pcg-gray;
        }
    }
</style>
