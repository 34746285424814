import api from './index'

const resource = 'duplicate_reports'

export default {
  getReportsFiles (page = null) {
    let url = ''
    if (page) {
      url = resource + '?page=' + page
    } else {
      url = resource
    }
    return api.get(url)
  },
  getReportFile (id) {
    let url = resource
    url += '/' + id
    return api.get(url)
  },
  deleteFile (id) {
    let url = resource
    url += '/' + id
    return api.delete(url)
  }
}
