import api from './index'

const resource = 'job'

export default {
  checkJob (jobId) {
    let url = resource
    url += '?id=' + jobId
    return api.get(url)
  },
  checkBw (id) {
    let url = 'bw'
    url += '?id=' + id
    return api.get(url)
  }
}
