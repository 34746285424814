export default {
  methods: {
    points (p) {
      if (p) {
        return p.toFixed(4)
      } else {
        return 0
      }
    }
  }
}
