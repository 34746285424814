import api from './index'
import qs from 'qs'

const resource = 'conferences'

export default {
  getConferences (query = null) {
    let url = ''
    const params = qs.stringify(query)
    url = resource + '?' + params
    return api.get(url)
  },
  getPublications (id) {
    const url = `${resource}/${id}/pre_destroy`
    return api.get(url)
  },
  createConference (conference) {
    const uri = `${resource}`
    const body = {
      conference: conference
    }
    return api.post(uri, body)
  },
  updateConference (conference) {
    const uri = `${resource}/${conference.id}`
    const body = {
      conference: conference
    }
    return api.put(uri, body)
  },
  deleteConference (id) {
    let url = resource
    url += '/' + id
    return api.delete(url)
  },
  getConference (id) {
    let url = resource
    url += '/' + id
    return api.get(url)
  }

}
