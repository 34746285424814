<template>
    <div class="slots-bar" :class="{ 'slots-bar-small': small }" :style="{ width: `${jobs * 100}%` }">
        <div class="progress" v-for='(publication, index) in publicationsSlots' :key="publication.id"
             :class="pointsClass(points[index] / publicationsSlots[index].percent)"
             :style="{ width: `${progressPercent(publication)}%` }"
             :id="'pub-' + scientist + '-' +  publication.id + '-' + publication.publicationType + '-' + index"
        ></div>
        <b-tooltip v-for="(publication, index) in publications"
                   :key="scientist + '-' + publication.id + '-' + publication.publicationType"
                   :target="'pub-' + scientist + '-' + publication.id + '-' + publication.publicationType + '-' + index"
                   :custom-class="tooltipClass(publication.points)"
                   @show="readPublication(publication)"
        >
            <div class="m-2">
                <span class="slots" v-if="!small">{{ Math.round(calcPercent(publication)*100)/100 }}%</span>
                <span class="publication-type">{{ publicationType(publication.publicationType) }}</span>
                <span class="autors">{{ $tc('views.discipline.show.authors', publication.authors.length) }}</span>
            </div>
            <div class="title m-2">
                <div style="margin-right: .2rem; display: inline-block;">{{ publication.title }}</div>
                <div v-if="publication.alternativePoints && (hasRight('view_points') || hasRight('view_own_points'))" class="my-points" :class="pointsClass(publication.alternativePoints / publication.percent)">
                    <span>{{ publication.alternativePoints }}</span>
                    <span>({{ publicationPoints(publication.points) }})</span>
                </div>
                <div v-if="!publication.alternativePoints && (hasRight('view_points') || hasRight('view_own_points'))" class="my-points" :class="pointsClass(publication.points / publication.percent)">{{ publicationPoints(publication.points) }}</div>
            </div>
            <div class="periodical m-2">{{ publication.periodical }}</div>
            <div style="margin-top: 1rem" v-if="publication.rejectionReason && hasRight('show_rejection_reason')">
                <img src="../assets/images/exclamation-mark-orange.svg" class="my-exclamation-mark" style="position: relative; bottom: .1rem;" :alt="$t('general.exclamation_mark')">
                <span class="rejection-reasons">{{ rejectionReasons(publication.rejectionReason, publication.disciplineId, isDoctorant) }}</span>
            </div>
        </b-tooltip>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import pointsClasses from '../mixins/points_classes'
import ApiPublication from '../api/publications'
import hasRight from '../mixins/has_right'
import rejectionReasons from '../mixins/rejection_reasons'

export default {
  name: 'SlotsBar',
  mixins: [pointsClasses, hasRight, rejectionReasons],
  props: {
    maxSlots: Number,
    publications: Array,
    scientist: Number,
    small: {
      type: Boolean,
      default: false
    },
    isDoctorant: {
      type: Boolean,
      defeault: false
    }
  },
  data: function () {
    return {
      publication_type: [],
      percentages: 0,
      publicationsSlots: [],
      points: []
    }
  },
  beforeMount () {
    if (this.$store.getters['publicationTypes/publicationTypes'].length === 0) {
      this.$store.dispatch('publicationTypes/setPublicationTypes', null)
    }
  },
  created () {
    this.publications.forEach(p => {
      let percent = 0
      const pub = Object.assign({}, p)
      if (this.maxSlots === 0) {
        percent = p.slots
      } else {
        percent = p.slots
        this.percentages += percent
        if (this.percentages > this.maxSlots) {
          const diff = this.percentages - this.maxSlots
          this.percentages = this.maxSlots
          percent -= diff
        }
      }
      pub.slots = percent
      this.publicationsSlots.push(pub)
      if (p.alternativePoints) {
        this.points.push(p.alternativePoints)
      } else {
        this.points.push(p.points)
      }
    })
  },
  mounted () {
  },
  computed: {
    ...mapGetters('publicationTypes', ['publicationTypes']),
    jobs () {
      if (this.maxSlots === 0) {
        return 1
      } else {
        return this.maxSlots / 4.0
      }
    }
  },
  methods: {
    publicationType (id) {
      const idx = this.publicationTypes.findIndex(r => {
        return r.id === id.toString()
      })
      if (idx === -1) {
        return ''
      } else if (this.$i18n.locale === 'pl') {
        return this.publicationTypes[idx].text
      } else {
        return this.publicationTypes[idx].text_en
      }
    },
    progressPercent (publication) {
      if (this.maxSlots === 0) {
        return publication.slots * 100 / 4
      } else {
        return (publication.slots / this.maxSlots) * 100
      }
    },
    calcPercent (publication) {
      return publication.slots * 100
    },
    tooltipClass (points) {
      const result = this.pointsClass(points).replace('points ', '')
      return 'dn-tooltip ' + result
    },
    publicationPoints (points) {
      if (points > 0) {
        return points.toFixed(4) * (10 / 10)
      } else {
        return 0
      }
    },
    readPublication (publication) {
      if (publication.title.length === 0) {
        ApiPublication.getPublicationShort(publication.id)
          .then(response => {
            const datas = response.data
            publication.title = datas.title
            publication.publicationType = datas.publicationType
            publication.authors = datas.authors
            publication.periodical = datas.periodical
          })
      }
    }
  }

}
</script>

<style scoped lang="scss">
    @import '../assets/stylesheets/vars';
    .slots-bar {
        background-color: $pcg-lightest-gray-background;
        height: 15px;
        width: 100%;
        border-radius: 15px;
        box-shadow: 0 5px 10px rgba(200, 200, 200, 0.1);
        overflow: hidden;
        cursor: default;
    }
    .slots-bar-small {
        height: 7px;
        background-color: transparent;
        .progress {
            height: 7px;
            margin-bottom: 8px;
        }
    }
    .tooltip {
        opacity: 1 !important;
    }
    /deep/ .tooltip-inner {
        min-width: 420px;
        text-align: start;
        @media (max-width: 550px) {
            min-width: 250px;
        }
    }
    .progress {
        display: inline-block;
        border-radius: unset;
    }
    .points {
        margin: 0;
        padding: 0;
    }
    .points-p0 {
        &:hover {
            border: 2px solid darken($disabled-color, 30%);
        }
         /deep/ .tooltip-inner{
             border: 1px solid $disabled-color;
         }
        /deep/ .arrow::before {
            border-top-color: $disabled-color;
        }
        .slots {
            background-color: $disabled-color;
        }
    }
    .points-p25 {
        &:hover {
            border: 2px solid darken($main-active-color, 30%);
        }
        /deep/ .tooltip-inner{
            border: 1px solid $main-active-color;
        }
        /deep/ .arrow::before {
            border-top-color: $main-active-color;
        }
        .slots {
            background-color: $main-active-color;
        }
    }
    .points-p50 {
      &:hover {
        border: 2px solid darken($pcg-another-blue, 30%);
      }
      /deep/ .tooltip-inner{
        border: 1px solid $pcg-another-blue;
      }
      /deep/ .arrow::before {
        border-top-color: $pcg-another-blue;
      }
      .slots {
        background-color: $pcg-another-blue;
      }
    }
    .points-p75 {
        &:hover {
            border: 2px solid lighten($main-color,30%);
        }
        /deep/ .tooltip-inner{
            border: 1px solid $main-color;
        }
        /deep/ .arrow::before {
            border-top-color: $main-color;
        }
        .slots {
            background-color: $main-color;
        }
    }

    .dn-tooltip {
        &.points-p0, &.points-p25, &.points-p50, &.points-p75 {
            background-color: transparent;
            &:hover {
                border: none;
            }
        }
        /deep/ .tooltip-inner{
          background: $pcg-white;
          color: $pcg-dark-gray;
          box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
            padding: 0.5rem;
        }
        .slots {
            padding: 3px;
            color: $pcg-white;
            border-radius: 5px;
            margin-right: .2rem;
        }
        .points {
            padding: 1px 3px;
            color: $pcg-white;
            border-radius: 5px;
        }
        .publication-type {
            font-weight: bold;
            font-size: 1rem;
            color: $main-color;
            margin-right: .2rem;
        }
        .title{
            font-weight: bold;
        }
        .periodical {
            text-align: left;
        }
        .my-points {
            display: inline-block;
            min-width: fit-content;
            &:hover {
                border: none !important;
            }
        }
    }

    .my-exclamation-mark {
        width: 17px;
        height: 17px;
        padding: 1px;
        border-radius: 30px;
        border: 2px solid;
        border-color: $pcg-orange;
        margin-right: .4rem;
    }

    .rejection-reasons {
        color: #ffb502;
        font-size: 1em;
        font-weight: 400;
        white-space: pre-wrap;
    }
</style>

<style lang="scss" scoped>
    @import "../assets/stylesheets/vars-contrast";
    .contrast {
        .slots-bar {
            background-color: $pcg-lightest-gray;
        }
        .points-p0 {
            &:hover {
                border: 2px solid darken($main-color, 30%);
            }
            /deep/ .tooltip-inner{
                border: 1px solid $main-color;
            }
            /deep/ .arrow::before {
                border-top-color: $main-color;
            }
            .slots {
                background-color:$disabled-color !important;
            }
        }
        .points-p25 {
            &:hover {
                border: 2px solid darken($main-active-color, 30%);
            }
            /deep/ .tooltip-inner{
                border: 1px solid $main-active-color;
            }
            /deep/ .arrow::before {
                border-top-color: $main-active-color;
            }
            .slots {
                background-color: $main-active-color !important;
            }
        }
      .points-p50 {
        &:hover {
          border: 2px solid darken($pcg-another-blue, 30%);
        }
        /deep/ .tooltip-inner{
          border: 1px solid $pcg-another-blue;
        }
        /deep/ .arrow::before {
          border-top-color: $pcg-another-blue;
        }
        .slots {
          background-color: $pcg-another-blue !important;
        }
      }
        .points-p75 {
            &:hover {
                border: 2px solid lighten($main-select-color, 30%);
            }
            /deep/ .tooltip-inner{
                border: 1px solid $main-select-color;
            }
            /deep/ .arrow::before {
                border-top-color: $main-select-color;
            }
            .slots {
                background-color: $main-select-color !important;
            }
        }
        .dn-tooltip {
            &.points-p0, &.points-p25, &.points-p75 {
                background-color: transparent;
                &:hover {
                    border: none;
                }
            }
            /deep/ .tooltip-inner{
              background: $pcg-white;
              color: $pcg-dark-gray;
              box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
            }
            .slots {
                color: $pcg-white;
            }
            .points {
                color: $pcg-white;
            }
            .publication-type {
                color: $main-color;
            }
        }
    }
</style>
