<template>
  <div>
    <b-row>
      <b-col>
        <div v-if="hasRight('edit_evaluation')" style="display: inline-block; float: right;">
          <pcg-btn size="small" class="user-btn" @click="edit(null)">
            <span>+ {{ $t('views.evaluations.list.add_evaluation') }}</span>
            <spinner v-if="addEvaluationSpinner"></spinner>
          </pcg-btn>
        </div>
      </b-col>
    </b-row>

    <div class="evaluations">
      <div class="evaluation-elements">
        <b-row class="text-label list-header">
          <b-col class="th">
            {{ $t('views.evaluations.list.evaluation_period') }}
          </b-col>
          <b-col class="th">
            {{ $t('views.evaluations.list.evaluation_years') }}
          </b-col>
          <b-col cols="2" class="th">

          </b-col>
        </b-row>
        <div class="evaluation" v-for="evaluation in evaluations" :key="evaluation.id">
          <div class="evaluation-row row">
            <b-col class="name th">
              {{ evaluation.name }}
            </b-col>
            <b-col class="name th">
              {{ evaluation.years.join(', ') }}
            </b-col>
            <b-col cols='2' class="th d-flex justify-content-end">
              <div class="buttons">
                <spinner v-if="addEvaluationSpinner" class="my-spinner"></spinner>
              <span v-else class="button-element" @click="edit(evaluation)">
                <i class="fas fa-pen evaluation-icon" :id="`evaluation` + evaluation.id"></i>
                <b-tooltip :target="`evaluation` + evaluation.id">
                  {{ $t('general.edit') }}
                </b-tooltip>
              </span>
              </div>
            </b-col>
          </div>
        </div>
      </div>
      <b-modal ref="evaluationModal"
               :hide-header="true"
               :centered="true"
               footer-class="my-modal-footer"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="evaluationSave"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               v-on-clickaway = "escModal"
               content-class="my-modal-content"
      >
        <div>
          <label>{{ $t('views.evaluations.list.name') }}</label>
          {{ evaluation.name }}
        </div>
        <pcg-input-number class="my-input" v-model="evaluation.from" type="number" :label="$t('general.from')" :showLabel="true"/>
        <pcg-input-number class="my-input" v-model="evaluation.to" type="number" :label="$t('general.to')" :showLabel="true"/>
      </b-modal>
    </div>
  </div>
</template>

<script>
import setTitle from '../mixins/set_title'
import range from '../mixins/range'
import { mixin as clickaway } from 'vue-clickaway'
import ApiEvaluations from '../api/evaluations'
import hasRight from '../mixins/has_right'
import Spinner from '../components/Spinner'
import unexpectedError from '../mixins/unexpected_error'
import waitForJobs from '../mixins/wait_for_jobs'

export default {
  name: 'Evaluations',
  mixins: [hasRight, setTitle, clickaway, range, unexpectedError, waitForJobs],
  components: { Spinner },
  data: function () {
    return {
      headerTitle: 'views.evaluations.list.title',
      headerSubtitle: 'views.evaluations.list.subtitle',

      addEvaluationSpinner: false,
      evaluations: [],
      evaluation: {
        id: null,
        name: null,
        from: null,
        to: null
      },
      currentUser: null
    }
  },
  created () {
    this.getEvaluations()
  },
  beforeMount () {
    this.currentUser = this.$store.getters['auth/id']
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.$store.dispatch('header/setHeader', {
      title: this.$t(this.headerTitle),
      subtitle: this.$t(this.headerSubtitle),
      capitalizeSubtitle: false
    })
  },
  watch: {
    evaluation: {
      deep: true,
      handler: function (value) {
        if (this.evaluation.from === null || this.evaluation.to === null) {
          this.evaluation.name = ''
        } else {
          this.evaluation.name = `${this.evaluation.from}-${this.evaluation.to}`
        }
      }
    }
  },
  methods: {
    getEvaluations () {
      ApiEvaluations.getEvaluations()
        .then(result => {
          this.evaluations = result.data.datas
          this.addEvaluationSpinner = false
        })
        .catch(error => {
          console.log(error)
          this.addEvaluationSpinner = false
        })
    },
    edit (evaluation) {
      if (evaluation === null) {
        this.evaluation = {
          id: null,
          name: null,
          from: null,
          to: null
        }
      } else {
        this.evaluation.id = evaluation.id
        this.evaluation.from = Math.min(...evaluation.years)
        this.evaluation.to = Math.max(...evaluation.years)
        this.evaluation.name = `${this.evaluation.from}-${this.evaluation.to}`
      }
      this.$refs.evaluationModal.show()
    },
    evaluationSave () {
      if (this.evaluation.id === null) {
        ApiEvaluations.createEvaluation(this.evaluation)
          .then(result => {
            const jobId = result.data.jobId
            if (jobId !== undefined) {
              this.addEvaluationSpinner = true
              this.waitForJob(jobId, this.evaluationUpdate, this.evaluationError)
            }
          })
          .catch(error => {
            console.log(error)
          })
      } else {
        ApiEvaluations.updateEvaluation(this.evaluation)
          .then(result => {
            const idx = this.evaluations.findIndex(e => {
              return e.id === this.evaluation.id
            })
            this.evaluations[idx].name = this.evaluation.name
            this.evaluations[idx].years = this.range(this.evaluation.from, this.evaluation.to)
            const jobId = result.data.jobId
            if (jobId !== undefined) {
              this.addEvaluationSpinner = true
              this.waitForJob(jobId, this.evaluationUpdate, this.evaluationError)
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    escModal () {
      this.evaluation = {
        id: null,
        name: null,
        from: null,
        to: null
      }
    },
    evaluationUpdate () {
      this.getEvaluations()
      this.addEvaluationSpinner = false
    },
    evaluationError () {
      this.unexpectedError()
      this.addEvaluationSpinner = false
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../assets/stylesheets/vars';
  .name {
    color: $pcg-gray;
  }
  .text-label {
    font-weight: bold;
    color: $pcg-the-darkest-gray;
  }
  .my-pen {
    color: $main-active-color;
  }
  .evaluation-elements {
    margin-bottom: 2rem;
    margin-top: 2rem;
    background-color: $pcg-white;
    border-radius: 15px;
    .list-header {
      background-color: $main-color;
      color: $pcg-white;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      .th {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        vertical-align: middle;
      }

      .th:first-child {
        padding-left: 15px;
        border-top-left-radius: 15px;
      }

      .th:last-child {
        padding-right: 15px;
        border-top-right-radius: 15px;
      }
    }
  }
  .evaluation {
    margin-right: -10px;
    margin-left: -10px;
    padding-top: 10px;
    background-color: $pcg-white;
    .evaluation-row {
      background-color: $pcg-white;
      padding-top: 10px;
      &.row {
        margin-right: 0;
        margin-left: 0;
      }
    }

    &:last-child {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
      .evaluation-row {
        background-color: $pcg-white;
        &.row {
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;
          .col:first-child {
            border-bottom-left-radius: 15px;
          }

          .col:last-child {
            border-bottom-right-radius: 15px;
          }
        }
      }
    }
  }
  .evaluation-icon {
    padding: 12px;
    border-radius: 50%;
    background-color: #ecf9fd;
    margin-right: .3rem;
  }

  .button-element {
    color: $main-active-color;
    position: relative;
    bottom: .7rem;
    cursor: pointer;
  }

  /deep/ .tooltip-inner {
    background: $pcg-white;
    color: $pcg-dark-gray;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
    font-weight: 400;
    padding: .5rem;
    border-color: $pcg-light-gray-background;
    border-style: solid;
    border-width: 1px;
    opacity: 1;
  }

  /deep/ .arrow::before {
    border-top-color: $pcg-light-gray-background !important;
  }

  .my-spinner {
    color: $main-active-color;
    padding-right: .5rem;
    padding-bottom: 1rem;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .name {
      color: $pcg-gray;
    }
    .text-label {
      color: $pcg-the-darkest-gray;
    }
    .my-pen {
      color: $main-active-color;
    }
    .evaluation-elements {
      background-color: $pcg-white;
      .list-header {
        background-color: $main-color;
        color: $pcg-white;
      }
    }
    .evaluation {
      background-color: $pcg-white;
      .evaluation-row {
        background-color: $pcg-white;
      }
      &:last-child {
        .evaluation-row {
          background-color: $pcg-white;
        }
      }
    }
    .evaluation-icon {
      background-color: #003204;
    }
    .button-element {
      color: $main-active-color;
    }
    /deep/ .tooltip-inner {
      background: $pcg-white;
      color: $pcg-dark-gray;
      border-color: $pcg-light-gray;
    }

    /deep/ .arrow::before {
      border-top-color: $pcg-light-gray !important;
    }
    .my-spinner {
      color: $main-active-color;
    }
  }
</style>
