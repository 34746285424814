<template>
    <div class="statements-list">
        <dn-loader v-if="showLoader" class="with-margins"/>
        <div v-else>
            <b-row align-v="center">
                <label :for="$t('views.scientists.list.filter_scientists')" class="filter-scientists">{{ $t('views.scientists.list.filter_scientists') }}</label>
                <pcg-text-input
                  :id="$t('views.scientists.list.filter_scientists')"
                  class="my-text-input"
                  :show-label="false"
                  :placeholder="$t('views.scientists.list.by_names')"
                  v-model="filters.fullName"
                  @input="getFilteredEmployees"
                />
                <pcg-select
                  class="my-select"
                  always-active
                  :options="tDictionary(myDisciplineOptions)"
                  :default-option="{ text: $t('views.scientists.list.by_branches'), id: null }"
                  v-model="filters.byDiscipline"
                  @input="getFilteredEmployees"
                />
                <!--            <b-col cols="3" class="no-margin">-->
                <!--                <pcg-checkbox variant="normal" v-model="filters.twoDisciplines">-->
                <!--                    {{ $t('views.scientists.list.two_branches') }}-->
                <!--                </pcg-checkbox>-->
                <!--            </b-col>-->
            </b-row>
          <b-row align-v="center">
            <label class="filter-scientists">{{ $t('views.achievement.list.pub_date') }}</label>
            <pcg-select
                style="margin-bottom: .7rem"
                :additionalLabel="$t('views.achievement.list.pub_date')"
                class="discipline my-select"
                :default-option="{ text: $t('general.from'), id: null }"
                :options="tDictionary(optionsDatesFrom)"
                v-model="filters.datesFrom"
                @input="getFilteredEmployees"
            />
            <pcg-select
                style="margin-bottom: 1rem"
                :additionalLabel="$t('views.achievement.list.pub_date')"
                class="discipline my-select"
                :default-option="{ text: $t('general.to'), id: null }"
                :options="tDictionary(optionsDatesTo)"
                v-model="filters.datesTo"
                @input="getFilteredEmployees"
            />
          </b-row>
          <div style="margin-top: 1rem">
                <div style="display: inline-block; margin-right: 4rem;">
                    <span class="per_page">{{ $t('views.achievement.list.per_page') }}</span>
                    <pcg-select
                      class="my-page-select"
                      :options="perPageOptions"
                      :default-option="{ text: perPage.toString(), value: perPage }"
                      v-model="perPage"
                      @input="getFilteredEmployees"
                    />
                </div>
                <div v-if="step === 0" style="display: inline-block">
                    <pcg-pagination-summary
                      class="pagination-summary"
                      :currentPage="pagination.disciplines.page"
                      :perPage="perPage"
                      :totalRecords="pagination.disciplines.count">
                    </pcg-pagination-summary>
                </div>
                <div v-else style="display: inline-block">
                    <pcg-pagination-summary
                      class="pagination-summary"
                      :currentPage="pagination.numberN.page"
                      :perPage="perPage"
                      :totalRecords="pagination.numberN.count">
                    </pcg-pagination-summary>
                </div>
            </div>
            <b-tabs v-model="step" class="mt-3">
                <b-tab :title="$t('views.statements.list.discipline')">
                    <b-table v-if="disciplineStatements.length > 0"
                             class="scientists-table"
                             borderless hover
                             thead-class="sthead"
                             tbody-tr-class="strow"
                             :fields="fields"
                             :items="disciplinesData">
                        <template v-slot:cell(full_name)="data">
                        <span class="my-text">
                            <router-link v-if="hasRight('view_employee')" class="clicked" :to="{ name: 'employee_path', params: {id: data.item.id } }" >
                                {{ data.value }}
                            </router-link>
                            <span v-else>{{ data.value }}</span>
                        </span>
                        </template>
                        <template v-slot:cell(disciplines)="data">
                            <span v-if="data.value.length === 0" class=" small">
                                {{ fnDisciplines(data.value) }}
                            </span>
                            <template v-else v-for="(discipline, index) in fnDisciplines(data.value)">
                                <small class="my-text" :key="discipline.id">
                                    <router-link :to="{ name: 'discipline_show_path', params: { id: data.value[index] } }">
                                        <span>{{ discipline }}</span>
                                        <span v-if="data.item['share'][index] !== 100">({{ data.item['share'][index] }}%)</span>
                                        <span v-if="data.item['validity_to'][index] === 9999">({{ data.item['validity_from'][index] }}-{{ $t('general.active') }})</span>
                                        <span v-else>({{ data.item['validity_from'][index] }}-{{ data.item['validity_to'][index] }})</span>
                                    </router-link>
                                </small>
                                <a href="javascript:;" :aria-label="$t('general.remove')" v-if="hasRight('edit_statements')" @click="editStatement(data.item['statement_id'][index])" :key="discipline.id">
                                  <i class="pcg-icon-cross my-cross"></i>
                                </a>
                                <br :key="discipline.id"/>
                            </template>
                            <b-modal ref="removeStatement"
                                     :hide-header="true"
                                     footer-class="my-modal-footer"
                                     :centered="true"
                                     v-bind:ok-title="$t('general.confirm')"
                                     ok-variant="primary"
                                     :no-close-on-esc="true"
                                     @ok="removeStatement(statementId)"
                                     v-bind:cancel-title="$t('general.cancel')"
                                     cancel-variant="default"
                                     content-class="my-modal-content"
                            >
                                <div class="remove-statement-title">{{ $t('views.statements.list.remove_statement') }}</div>
                                <div class="remove-statement-confirmation">{{ $t('views.statements.list.remove_statement_confirmation') }}</div>
                            </b-modal>
                        </template>
                    </b-table>
                    <pcg-box v-else>
                        <div class="d-flex justify-content-center no-records">{{ $t('views.statements.list.no_records') }}</div>
                    </pcg-box>
                    <nav :aria-label="$t('general.pagination')">
                      <pcg-pagination v-if="disciplineStatements.length > 0"
                                      class="d-flex justify-content-center my-pagination"
                                      v-model="pagination.disciplines.page"
                                      :page-count="pagination.disciplines.pages"
                                      :total-records="pagination.disciplines.count"
                                      :only-event="true"
                                      @input="getEmployees"
                      ></pcg-pagination>
                    </nav>
                </b-tab>
                <b-tab :title="$t('views.statements.list.number_n')">
                    <b-table v-if="numberN.length > 0"
                             class="scientists-table"
                             borderless hover
                             thead-class="sthead"
                             tbody-tr-class="strow"
                             :fields="fields"
                             :items="numberNData">
                        <template v-slot:cell(full_name)="data">
                        <span class="my-text">
                            <router-link v-if="hasRight('employee_view')" clicked :to="{ name: 'employee_path', params: {id: data.item.id } }" >
                                {{ data.value }}
                            </router-link>
                            <span v-else>{{ data.value }}</span>
                        </span>
                        </template>
                        <template v-slot:cell(disciplines)="data">
                            <span v-if="data.value.length === 0" class="dn-gray-color small">
                                {{ fnDisciplines(data.value) }}
                            </span>
                            <template v-else v-for="(discipline, index) in fnDisciplines(data.value)">
                                <small class="my-text" :key="discipline.id">
                                    <router-link :to="{ name: 'discipline_show_path', params: { id: data.value[index] } }">
                                        <span>{{ discipline }}</span>
                                        <span v-if="data.item['validity_to'][index] === 9999">({{ data.item['validity_from'][index] }}-{{ $t('general.active') }})</span>
                                        <span v-else>({{ data.item['validity_from'][index] }}-{{ data.item['validity_to'][index] }})</span>
                                    </router-link>
                                </small>
                                <i v-if="hasRight('edit_statements')" class="pcg-icon-cross my-cross" @click="editStatement(data.item['statement_id'][index])" :key="discipline.id"></i>
                                <br :key="discipline.id"/>
                            </template>
                            <b-modal ref="removeStatement"
                                     :hide-header="true"
                                     footer-class="my-modal-footer"
                                     :centered="true"
                                     v-bind:ok-title="$t('general.confirm')"
                                     ok-variant="primary"
                                     :no-close-on-esc="true"
                                     @ok="removeStatement(statementId)"
                                     v-bind:cancel-title="$t('general.cancel')"
                                     cancel-variant="default"
                                     content-class="my-modal-content"
                            >
                                <div class="remove-statement-title">{{ $t('views.statements.list.remove_statement') }}</div>
                                <div class="remove-statement-confirmation">{{ $t('views.statements.list.remove_statement_confirmation') }}</div>
                            </b-modal>
                        </template>
                    </b-table>
                    <pcg-box v-else>
                        <div class="d-flex justify-content-center no-records">{{ $t('views.statements.list.no_records') }}</div>
                    </pcg-box>
                    <nav :aria-label="$t('general.pagination')">
                      <pcg-pagination v-if="numberN.length > 0"
                                      v-model="pagination.numberN.page"
                                      class="d-flex justify-content-center my-pagination"
                                      :page-count="pagination.numberN.pages"
                                      :total-records="pagination.numberN.count"
                                      :only-event="true"
                                      @input="getEmployees"
                      ></pcg-pagination>
                    </nav>
                </b-tab>
                <!--<b-tab :title="$t('views.statements.list.publications')">-->
                <!--    <b-table-->
                <!--            class="scientists-table"-->
                <!--            borderless hover-->
                <!--            thead-class="sthead"-->
                <!--            tbody-tr-class="strow"-->
                <!--            :fields="publicationFields"-->
                <!--            :items="publicationsData">-->
                <!--        <template v-slot:cell(full_name)="data">-->
                <!--            {{ data.value }}-->
                <!--        </template>-->
                <!--        <template v-slot:cell(dates)="data">-->
                <!--            <template v-for="date in fnDates(data.value)">-->
                <!--                <small :key="date.id">{{ date }}</small><br :key="date.id"/>-->
                <!--            </template>-->
                <!--        </template>-->
                <!--        <template v-slot:cell(disciplines)="data">-->
                <!--            <span v-if="data.value.length === 0" class="dn-gray-color small">-->
                <!--                {{ fnDisciplines(data.value) }}-->
                <!--            </span>-->
                <!--            <template v-else v-for="discipline in fnDisciplines(data.value)">-->
                <!--                <small :key="discipline.id">{{ discipline }}</small><br :key="discipline.id"/>-->
                <!--            </template>-->
                <!--        </template>-->
                <!--    </b-table>-->
                <!--    <pcg-pagination-->
                <!--            v-model="pagination.publications.page"-->
                <!--            :page-count="pagination.publications.pages"-->
                <!--            :total-records="pagination.publications.count"-->
                <!--            @input="getEmployees"-->
                <!--    ></pcg-pagination>-->
                <!--</b-tab>-->
            </b-tabs>
        </div>
    </div>
</template>

<script>
import hasRight from '../../mixins/has_right'
import setTitle from '../../mixins/set_title'
import translateDictionary from '../../mixins/translate_dictionary'
import ApiStatements from '../../api/employee_statements'
import loaderDelay from '../../mixins/loader_delay'
import DnLoader from '../../components/DnLoader'
import waitForJobs from '../../mixins/wait_for_jobs'
import unexpectedError from '../../mixins/unexpected_error'
import { mapGetters } from 'vuex'

export default {
  name: 'StatementsList',
  mixins: [hasRight, setTitle, translateDictionary, loaderDelay, unexpectedError, waitForJobs],
  components: {
    DnLoader
  },
  data: function () {
    return {
      headerTitle: 'views.statements.list.title',
      headerSubtitle: 'views.statements.list.subtitle',
      step: 0,
      statementId: null,
      updateStatements: false,
      disciplineStatements: [],
      myDisciplineOptions: [],
      numberN: [],
      publications: [],
      pagination: {
        disciplines: {
          count: 0,
          items: 0,
          last: 0,
          next: null,
          page: 0,
          pages: 0,
          prev: null
        },
        numberN: {
          count: 0,
          items: 0,
          last: 0,
          next: null,
          page: 0,
          pages: 0,
          prev: null
        },
        publications: {
          count: 0,
          items: 0,
          last: 0,
          next: null,
          page: 0,
          pages: 0,
          prev: null
        }
      },
      filters: {
        datesFrom: null,
        datesTo: null,
        fullName: '',
        byDiscipline: null,
        twoDisciplines: false
      },
      perPage: 10
    }
  },
  created () {
    ApiStatements.getStatementsList(this.$route.query)
      .then(result => {
        this.disciplineStatements = result.data.disciplines
        this.numberN = result.data.numberN
        // this.publications = result.data.publications
        this.pagination = result.data.pagination
        const query = this.$route.query
        if (Object.prototype.hasOwnProperty.call(query, 'fullName')) {
          this.filters.fullName = query.fullName
        }
        if (Object.prototype.hasOwnProperty.call(query, 'byDiscipline')) {
          this.filters.byDiscipline = query.byDiscipline
        }
        if (Object.prototype.hasOwnProperty.call(query, 'datesFrom')) {
          this.filters.datesFrom = JSON.parse(query.datesFrom)
        }
        if (Object.prototype.hasOwnProperty.call(query, 'datesTo')) {
          this.filters.datesTo = JSON.parse(query.datesTo)
        }
        // if (query.hasOwnProperty('twoDisciplines')) {
        //   this.filters.twoDisciplines = JSON.parse(query.twoDisciplines)
        // }
      })
      .finally(() => {
        this.loadingDone = true
      })
  },
  beforeMount () {
    if (this.$store.getters['disciplines/disciplines'].length === 0) {
      this.$store.dispatch('disciplines/setDisciplines', null)
    }
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.setTitles()
    this.initializeDisciplinesOptions()
  },
  computed: {
    ...mapGetters('disciplines', ['disciplines']),
    ...mapGetters('auth', ['rights']),
    ...mapGetters('period', ['years', 'allYears']),
    disciplinesData: function () {
      return this.disciplineStatements.map(s => {
        return {
          full_name: s.fullName,
          dates: s.disciplines.map(d => d.submitDate),
          disciplines: s.disciplines.map(d => d.disciplineId),
          id: s.id,
          share: s.disciplines.map(d => d.share),
          statement_id: s.disciplines.map(d => d.id),
          validity_from: s.disciplines.map(d => d.validityFrom),
          validity_to: s.disciplines.map(d => d.validityTo)
        }
      })
    },
    numberNData: function () {
      return this.numberN.map(s => {
        return {
          full_name: s.fullName,
          dates: s.disciplines.map(d => d.submitDate),
          disciplines: s.disciplines.map(d => d.disciplineId),
          id: s.id,
          statement_id: s.disciplines.map(d => d.id),
          validity_from: s.disciplines.map(d => d.validityFrom),
          validity_to: s.disciplines.map(d => d.validityTo)
        }
      })
    },
    publicationsData: function () {
      return this.publications.map(s => {
        return {
          full_name: s.fullName,
          dates: s.statements.map(d => d.submitDate),
          disciplines: s.statements.map(d => d.disciplineId)
        }
      })
    },
    fields: function () {
      return [
        {
          key: 'full_name',
          label: this.$t('views.statements.list.fields.full_name')
        },
        {
          key: 'disciplines',
          label: this.$t('views.statements.list.fields.disciplines'),
          thStyle: 'width: 50%;'
        }
      ]
    },
    publicationFields: function () {
      return [
        {
          key: 'full_name',
          label: this.$t('views.statements.list.fields.full_name')
        },
        {
          key: 'disciplines',
          label: this.$t('views.statements.list.fields.disciplines'),
          thStyle: 'width: 50%;'
        }
      ]
    },
    perPageOptions () {
      return [
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ]
    },
    optionsDatesFrom () {
      const result = [{ text: 'wszystkie', text_en: 'all', id: '' }]
      this.allYears.forEach((year) => {
        result.push({ text: year.toString(), text_en: year.toString(), id: year })
      })
      return result
    },
    optionsDatesTo () {
      const result = [{ text: 'wszystkie', text_en: 'all', id: '' }]
      this.allYears.forEach((year) => {
        result.push({ text: year.toString(), text_en: year.toString(), id: year })
      })
      return result
    }
  },
  methods: {
    fnDisciplines (brs) {
      if (brs.length === 0) {
        return this.$t('views.scientists.list.no_branch')
      }
      const that = this
      return brs.map(x => {
        const idx = that.disciplines.findIndex(b => b.id === x.toString())
        if (idx >= 0) {
          if (that.$i18n.locale === 'pl') {
            return that.disciplines[idx].text
          } else {
            return that.disciplines[idx].text_en
          }
        }
      })
    },
    fnDates (dates) {
      if (dates.length === 0) {
        return ''
      }
      const that = this
      return dates.map(d => {
        return that.$d(new Date(d), 'longDate')
      })
    },
    getEmployees () {
      const filters = this.getFilters()
      let query = {}
      query = Object.assign(query, filters)
      if (this.pagination.disciplines.page !== 0) {
        query.discipline_page = this.pagination.disciplines.page
      }
      if (this.pagination.numberN.page !== 0) {
        query.numberN_page = this.pagination.numberN.page
      }
      query.per_page = this.perPage
      this.$router.push({ name: 'statements_list_path', query: query })
      ApiStatements.getStatementsList(query)
        .then(result => {
          this.disciplineStatements = result.data.disciplines
          this.numberN = result.data.numberN
          // this.publications = result.data.publications
          this.pagination = result.data.pagination
        })
    },
    getFilteredEmployees () {
      this.pagination.disciplines.page = 1
      this.pagination.numberN.page = 1
      // this.pagination.publications.page = 1
      this.getEmployees()
    },
    getFilters () {
      const oFilters = {}
      Object.keys(this.filters).forEach(key => {
        if (this.filters[key]) {
          oFilters[key] = this.filters[key]
        }
      })
      return oFilters
    },
    initializeDisciplinesOptions () {
      this.myDisciplineOptions = []
      this.myDisciplineOptions.push({ text: 'wszystkie', text_en: 'all', id: '' })
      for (let i = 0; i < this.disciplines.length; i++) {
        this.myDisciplineOptions.push(this.disciplines[i])
      }
    },
    editStatement (id) {
      this.statementId = id
      this.$refs.removeStatement.show()
    },
    removeStatement (id) {
      ApiStatements.removeStatement(id)
        .then(result => {
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.waitForJob(jobId, this.statementsUpdated, this.statementError)
          }
        })
    },
    statementsUpdated () {
      ApiStatements.getStatementsList(this.$route.query)
        .then(result => {
          let incorrect = false
          for (let i = 0; i < result.data.disciplines.length; i++) {
            if (result.data.disciplines[i].disciplines.length === 0) {
              this.statementsUpdated()
              incorrect = true
            }
          }
          for (let i = 0; i < result.data.numberN.length; i++) {
            if (result.data.numberN[i].disciplines.length === 0) {
              this.statementsUpdated()
              incorrect = true
            }
          }
          if (!incorrect) {
            this.disciplineStatements = result.data.disciplines
            this.numberN = result.data.numberN
            this.pagination = result.data.pagination
            const query = this.$route.query
            if (Object.prototype.hasOwnProperty.call(query, 'fullName')) {
              this.filters.fullName = query.fullName
            }
            if (Object.prototype.hasOwnProperty.call(query, 'byDiscipline')) {
              this.filters.byDiscipline = query.byDiscipline
            }
          }
        })
    },
    statementError () {
      this.unexpectedError()
    }
  },
  watch: {
    disciplines: {
      deep: true,
      handler () {
        this.initializeDisciplinesOptions()
      }
    }
  }
}
</script>

<style scoped lang="scss">
    @import '../../assets/stylesheets/vars';

    /deep/ .dropdown-menu {
        overflow: auto;
        max-height: 300px;
    }
    .scientists-table {
        margin-top: 1rem;
    }
    /deep/ table.scientists-table {
        background-color: $pcg-white;
        border-radius: 15px;

        thead {
            background-color: $main-color;
            color: $pcg-white;

            th {
                padding-top: 15px;
                padding-bottom: 15px;
                padding-right: 0.5rem;
                padding-left: 0.5rem;
                vertical-align: middle;
                font-size: small;

                &.td-center {
                    text-align: center;
                }
            }

            th:first-child {
                padding-left: 15px;
                border-top-left-radius: 15px;
            }

            th:last-child {
                padding-right: 15px;
                border-top-right-radius: 15px;
            }
        }

        tbody {
            padding-left: 15px;
            padding-right: 15px;
            background-color: $pcg-white;

            td {
                padding-top: 10px;
                padding-bottom: 10px;
                padding-right: 0.5rem;
                padding-left: 0.5rem;
                vertical-align: middle;

                &.td-center {
                    text-align: center;
                }
            }

            td:first-child {
                padding-left: 15px;
            }

            td:last-child {
                padding-right: 15px;
            }

            tr {
                height: 52px;
            }

            tr:last-child {
                td {
                    padding-bottom: 15px;
                }

                td:first-child {
                    padding-left: 15px;
                    border-bottom-left-radius: 15px;
                }

                td:last-child {
                    padding-right: 15px;
                    border-bottom-right-radius: 15px;
                }
            }
        }
    }

    .my-text-input {
        margin-right: .8rem;
        margin-left: .7rem;
        position: relative;
        width: 250px;
        top: .4rem;
        /deep/ .pcg-field {
            height: calc(1.5em + 0.75em + 2px);
        }
    }

    .my-select {
        margin-left: .7rem;
        position: relative;
        width: 250px;
        top: .4rem;
        /deep/ .bootstrap-select .dropdown-toggle {
            height: calc(1.5em + 0.75em + 2px);
            color: $pcg-gray;
        }
        /deep/ .filter-option-inner {
            position: relative;
            bottom: .45rem;
        }
    }

    .filter-scientists {
        font-weight: 400;
        color: $pcg-gray;
        margin-left: .7rem;
        margin-right: .8rem;
    }

    .my-text {
        font-weight: 400;
        color: $main-color;
        a {
            color: unset;
        }
    }

    .no-records {
        color: $pcg-dark-gray;
        font-weight: 400;
        font-size: $font-size-m;
    }

    .clicked {
        cursor: pointer;
    }

    .my-cross {
        color: $pcg-gray;
        height: 10px;
        position: relative;
        top: .05rem;
        cursor: pointer;
        margin-left: .5rem;
        font-size: 11px;
    }

    .remove-statement-title {
        font-size: $font-size-m;
        font-weight: 400;
        color: $pcg-gray;
        margin-bottom: 1.5rem;
    }

    .remove-statement-confirmation {
        font-weight: 400;
        color: $pcg-gray;
        margin-bottom: 1rem;
    }

    .per_page {
        color: $pcg-gray;
        font-weight: 400;
        font-size: $font-size-s;
    }

    .pagination-summary {
        color: $pcg-gray !important;
        font-weight: 400 !important;
        font-size: $font-size-s !important;
    }

    .my-page-select {
        margin-right: .8rem;
        margin-left: .7rem;
        position: relative;
        width: 100px;
        display: inline-block;
        /deep/ .bootstrap-select .dropdown-toggle {
            height: calc(1.5em + 0.75em + 2px);
            color: $pcg-gray;
        }
        /deep/ .filter-option-inner {
            position: relative;
            bottom: .45rem;
        }
    }

    .discipline {
      display: inline-block;
      min-width: 300px;
      margin-right: 2rem;
    }

    .my-select {
      /deep/ .bootstrap-select .dropdown-toggle {
        height: calc(1.5rem + 0.75rem + 2px)
      }
    }
</style>

<style lang="scss" scoped>
    @import "../../assets/stylesheets/vars-contrast";
    .contrast {
        .my-select {
            /deep/ .bootstrap-select .dropdown-toggle {
                color: $pcg-gray;
            }
        }
        .filter-scientists {
            color: $pcg-gray;
        }
        .my-text {
            color: $main-color;
        }
        .no-records {
            color: $pcg-dark-gray;
        }
        .my-cross {
            color: $pcg-gray;
        }
        .remove-statement-title {
            color: $pcg-gray;
        }
        .remove-statement-confirmation {
            color: $pcg-gray;
        }
        .my-pagination {
            /deep/ .page-item {
                &, &.disabled {
                    .page-link {
                        color: $pcg-the-darkest-gray !important;
                        &:hover {
                            color: $hover-color !important;
                        }
                    }
                }
                &.active {
                    .page-link {
                        background-color: $main-active-color !important;
                        color: $pcg-white !important;
                    }
                }
                &:first-child, &:last-child {
                    .page-link {
                        color: $main-active-color !important;
                    }
                }
            }
        }
    }
</style>

<style lang="scss">
    @import "../../assets/stylesheets/vars-contrast";

    .contrast {
        .scientists-table {
            background-color: $pcg-white !important;

            .points {
                color: $pcg-white;
            }

            .points-0 {
                background-color: $pcg-gray;
            }

            .points-p0 {
                background-color: $main-color;
            }

            .points-p25 {
                background-color: $main-active-color;
            }

            .points-p75 {
                background-color: $main-select-color;
            }

            thead {
                background-color: $main-color !important;
                color: $pcg-white !important;
            }

            tbody {
                background-color: $pcg-white !important;

                td {
                    &.td-center {
                        color: $main-color !important;
                    }
                }
            }
        }
    }
</style>
