<template>
  <div class="managers">
    <div class="d-flex justify-content-between managers">
      <div>
        <span class="edit-managers">{{ $t('components.dn_field.edit_manager') }}</span>
        <span class="question-mark">?</span>
      </div>
      <pcg-btn size="small" variant="additional" @click="addManager">{{ $t('components.dn_field.add') }}</pcg-btn>
    </div>
    <b-row v-for="error in errors" :key="error.id">
      <b-col>
        <img src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
        <span class="my-error">{{error}}</span>
      </b-col>
    </b-row>
    <transition-group name="fade" mode="out-in">
      <div v-for="(manager, index) in managers" :key="index + 0">
        <div class="manager">{{ $t('components.dn_field.discipline_manager') }}</div>
        <dn-discipline-manager
          :manager="manager"
          @remove-manager="removeManager"
        />
      </div>
    </transition-group>
  </div>
</template>

<script>
import DnDisciplineManager from './DnDisciplineManager'
export default {
  name: 'DnDisciplineManagers',
  components: { DnDisciplineManager },
  data () {
    return {
      managers: this.value
    }
  },
  props: {
    value: Array,
    errors: Array
  },
  methods: {
    addManager () {
      this.managers.push({
        id: null,
        managerName: null
      })
    },
    removeManager (managerId) {
      const idx = this.managers.findIndex((a) => { return a.id === managerId })
      this.managers.splice(idx, 1)
    },
    mouseEnter (ev) {
      const elem = ev.target
      const allChildren = this.$children
      allChildren.forEach((c) => {
        if (c.$children[0].$options.name === 'LiveSearch') {
          if (c.$children[0].$el === elem) {
            c.$children[0].listActivated = true
          } else {
            c.$children[0].listActivated = false
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .manager {
    font-weight: 400;
    font-size: $font-size-s;
    color: $pcg-gray;
    margin-left: .6rem;
    margin-bottom: .2rem;
  }

  .managers {
    margin-bottom: 2rem;
  }

  .edit-managers {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-right: .5rem;
  }

  /deep/ .pcg-button {
    margin: 0;
  }

  .question-mark {
    font-size: $font-size-m;
    color: $pcg-white;
    background: $pcg-light-gray-background;
    font-weight: 400;
    padding: .05rem .45rem;
    border-radius: 50%;
  }

  /deep/ .pcg-field {
    border: none !important;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2) !important;
  }

  /deep/ .check-list {
    width: 348px !important;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .my-error {
    color: $pcg-red;
    font-weight: 400;
    margin-left: .3rem;
  }

  .my-exclamation-mark {
    height: 14px;
    width: 14px;
    padding: .2rem .2rem;
    border-radius: 20px;
    text-align: center;
    background-color: $pcg-red;
    opacity: 0.8;
    position: relative;
    bottom: .2rem;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .manager {
      color: $pcg-gray;
    }
    .edit-managers {
      color: $pcg-gray;
    }
    .question-mark {
      color: $pcg-white;
      background: $pcg-light-gray;
    }
    .my-error {
      color: $pcg-red;
    }
    .my-exclamation-mark {
      background-color: $pcg-red;
    }
  }
</style>
