<template>
  <div>
    <div class="no-rights">Nie masz odpowiednich uprawnień aby przejść dalej. Twoje konto jest w systemie, ale musisz zostać zaakceptowny przez administratora, aby móc korzystać z aplikacji.</div>
    <div style="text-align: center" class="return-login" @click="userLogout">
      Wróć na ekran główny
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyView',
  data () {
    return {
      headerTitle: 'Brak uprawnień',
      headerSubtitle: ''
    }
  },

  mounted () {
    this.$store.dispatch('header/setHeader', {
      title: this.$t(this.headerTitle),
      subtitle: this.$t(this.headerSubtitle),
      capitalizeSubtitle: false
    })
  },
  methods: {
    userLogout () {
      this.$store.dispatch('auth/signOut')
        .then(response => {
          // eslint-disable-next-line eqeqeq
          if (process.env.LOCAL_LOGIN == '1') {
            window.location = '/login'
          } else {
            if (this.signedIn) {
              // window.location = `${process.env.DN_OP_END_SESSION_ENDPOINT}?id_token_hint=${idToken}&redirect_uri=${process.env.SERWER_ADDRESS_WITH_PROTOCOL}`
              window.location = `${process.env.DN_OP_END_SESSION_ENDPOINT}?post_logout_redirect_uri=${process.env.SERWER_ADDRESS_WITH_PROTOCOL}`
            } else {
              window.location = '/'
            }
          }
        })
        .catch(error => {
          console.log(error.message)
          this.$toastr.e(error.message)
        })
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../assets/stylesheets/vars';

  .no-rights {
    color: $pcg-gray;
    font-weight: bold;
    font-size: $font-size-m;
    padding: 0 8rem;
    margin: 5rem 0;
  }

  .return-login {
    color: $main-active-color;
    font-weight: 400;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
</style>
