import api from './index'

const resourceParent = 'dictionaries'
const resource = 'dictionary_words'

export default {
  getDictionaryWords (dictionaryId, status, page, academy) {
    const params = {}
    params.page = page
    if (!_.isNil(status)) {
      params.status = status
    }
    if (academy) {
      params.academy_id = academy
    }
    return api.get(`${resourceParent}/${dictionaryId}/${resource}`, {
      params: params
    })
  },
  getDictionaryWordsRecent (dictionaryId, params = {}) {
    return api.get(`${resourceParent}/${dictionaryId}/${resource}/recent`, { params: params })
  },
  getDictionaryWordsByKey (dictionaryKey) {
    return api.get(`${resourceParent}/${dictionaryKey}/words`)
  },
  getDictionaryWord (dictionaryId, wordId) {
    return api.get(`${resourceParent}/${dictionaryId}/${resource}/${wordId}`)
  },
  updateDictionaryWord (dictionaryId, wordId, payload) {
    return api.patch(`${resourceParent}/${dictionaryId}/${resource}/${wordId}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  createDictionaryWord (dictionaryId, payload, userClicked = false, pubId = null) {
    let url = `${resourceParent}/${dictionaryId}/${resource}`
    if (userClicked) url += '?user_clicked=true'
    if (pubId) url += `&pub_id=${pubId}`
    return api.post(url, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  },
  suggestDictionaryWord (dictionaryId, payload) {
    return api.post(`${resourceParent}/${dictionaryId}/${resource}/suggest`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}
