<template>
    <div class="xml-list">
        <b-row>
            <b-col>
                <pcg-btn @click="newXmlFile" :disabled="xmlSpinner">
                    {{ $t('views.xml_files.new_file') }}
                    <spinner v-if="xmlSpinner"/>
                </pcg-btn>
            </b-col>
        </b-row>
        <b-modal ref="xmlFile"
                 :hide-header="true"
                 :centered="true"
                 v-bind:ok-title="$t('general.generate')"
                 ok-variant="primary"
                 :no-close-on-esc="true"
                 @ok="xmlFileGenerate"
                 v-bind:cancel-title="$t('general.cancel')"
                 cancel-variant="default"
                 v-on-clickaway = "escXmlFile"
                 footer-class="my-modal-footer"
                 content-class="my-modal-content"
        >
            <pcg-text-input
                    v-model="fileName"
                    label="Nazwa pliku"
            />
        </b-modal>
        <div class="polon-elements" v-if="xmlFiles.length > 0">
            <b-row class="text-label list-header">
                <b-col cols="5" class="th">
                    {{ $t('views.xml_files.file_name') }}
                </b-col>
                <b-col cols="3" class="th">
                    {{ $t('views.xml_files.file_date') }}
                </b-col>
                <b-col cols='4' class="buttons th">
                    &nbsp;
                </b-col>
            </b-row>
            <div v-for="file in xmlFiles" :key="file.id" class="polon-element">
                <xml-element :element="file" @deleted="getXmlFiles" @downloadXml="downloadXml" :xmlSpinner="xmlSpinner"/>
            </div>
        </div>
        <nav :aria-label="$t('general.pagination')">
          <pcg-pagination
              v-model="pagination.page"
              :page-count="pagination.pages"
              :total-records="pagination.count"
              :only-event="true"
              @input="getXmlFiles"
              class="my-pagination"
          ></pcg-pagination>
        </nav>
    </div>
</template>

<script>
import XmlElement from '../../components/XmlElement'
import ApiXml from '../../api/xmls'
import { mixin as clickaway } from 'vue-clickaway'
import setTitle from '../../mixins/set_title'
import waitForJobs from '../../mixins/wait_for_jobs'
import unexpectedError from '../../mixins/unexpected_error'
import Spinner from '../../components/Spinner'
import axios from 'axios'

export default {
  name: 'ExportXmls',
  mixins: [clickaway, setTitle, waitForJobs, unexpectedError],
  components: { Spinner, XmlElement },
  data () {
    return {
      headerTitle: 'views.xml_files.list.title',
      headerSubtitle: 'views.xml_files.list.subtitle',
      xmlFiles: [],
      fileName: '',
      xmlSpinner: false,
      jid: '',
      pagination: {
        count: 0,
        items: 0,
        last: 0,
        next: null,
        page: 0,
        pages: 0,
        prev: null
      }
    }
  },
  created () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    const query = this.$route.query
    if (Object.prototype.hasOwnProperty.call(query, 'page')) {
      this.pagination.page = query.page
    } else {
      this.pagination.page = 1
    }
    ApiXml.getXmlFiles(this.pagination.page)
      .then(result => {
        this.xmlFiles = result.data.datas
        this.pagination = result.data.pagination
      })
  },
  mounted () {
    this.$store.dispatch('header/setHeader', {
      title: this.$t(this.headerTitle),
      subtitle: this.$t(this.headerSubtitle),
      capitalizeSubtitle: false
    })
  },
  methods: {
    getXmlFiles () {
      const query = {}
      if (this.pagination.page !== 0) {
        query.page = this.pagination.page
      }
      this.$router.push({ name: 'export_xml_path', query: query })
      ApiXml.getXmlFiles(this.pagination.page)
        .then(result => {
          this.xmlFiles = result.data.datas
          this.pagination = result.data.pagination
        })
    },
    newXmlFile () {
      const date = new Date()
      this.fileName = 'export_xml_' + this.$d(new Date(date), 'short')
      this.$refs.xmlFile.show()
    },
    xmlFileGenerate () {
      ApiXml.createXmlFile(this.fileName)
        .then((result) => {
          const jobId = result.data.jid
          if (jobId !== undefined) {
            this.xmlSpinner = true
            this.waitForJob(jobId, this.xmlGenerated, this.unexpectedError)
          }
        })
    },
    escXmlFile () {
      this.fileName = ''
    },
    xmlGenerated () {
      this.xmlSpinner = false
      this.fileName = ''
      this.getXmlFiles()
    },
    downloadXml (id) {
      axios({
        url: '/api/v1/export_xmls/' + id + '/download',
        method: 'GET',
        responseType: 'blob'
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', 'ExportXml.xml')
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    }
  }
}
</script>

<style scoped lang="scss">
    @import '../../assets/stylesheets/vars';

    .my-pagination {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
    }
    .text-label {
        font-weight: bold;
        color: $pcg-the-darkest-gray;
    }
    .polon-elements {
        margin-bottom: 2rem;
        margin-top: 1rem;
        background-color: $pcg-white;
        border-radius: 15px;
        min-width: 700px;
        .list-header {
            background-color: $main-color;
            color: $pcg-white;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            .th {
                padding-top: 15px;
                padding-bottom: 15px;
                padding-right: 0.5rem;
                padding-left: 0.5rem;
                vertical-align: middle;
            }

            .th:first-child {
                padding-left: 15px;
                border-top-left-radius: 15px;
            }

            .th:last-child {
                padding-right: 15px;
                border-top-right-radius: 15px;
            }
        }
    }

    .polon-element {
        margin-right: -10px;
        margin-left: -10px;
        background-color: $pcg-white;
        /deep/ .xml-element {
            background-color: $pcg-white;
            &.row {
                margin-right: 0;
                margin-left: 0;
            }
        }

        &:last-child {
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            /deep/ .xml-element {
                background-color: $pcg-white;
                &.row {
                    border-bottom-left-radius: 15px;
                    border-bottom-right-radius: 15px;
                    .col {
                        padding-bottom: 15px;
                    }

                    .col:first-child {
                        border-bottom-left-radius: 15px;
                    }

                    .col:last-child {
                        border-bottom-right-radius: 15px;
                    }
                }
            }
        }
    }
</style>

<style lang="scss" scoped>
    @import "../../assets/stylesheets/vars-contrast";
    .contrast {
        .my-pagination {
            /deep/ .page-item {
                &, &.disabled {
                    .page-link {
                        color: $pcg-the-darkest-gray !important;
                        &:hover {
                            color: $hover-color !important;
                        }
                    }
                }
                &.active {
                    .page-link {
                        background-color: $main-active-color !important;
                        color: $pcg-white !important;
                    }
                }
                &:first-child, &:last-child {
                    .page-link {
                        color: $main-active-color !important;
                    }
                }
            }
        }
        .text-label {
            color: $pcg-the-darkest-gray;
        }
        .polon-elements {
            background-color: $pcg-white;
            .list-header {
                background-color: $main-color;
                color: $pcg-white;
            }
        }
        .polon-element {
            background-color: $pcg-white;
            /deep/ .xml-element {
                background-color: $pcg-white;
            }

            &:last-child {
                /deep/ .xml-element {
                    background-color: $pcg-white;
                }
            }
        }
    }
</style>
