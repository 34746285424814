<template>
  <b-row class="right-element" :id="`right-${rightElement.id}`">
    <b-col cols="3">
      <span v-if="$i18n.locale === 'pl'" :id="`right-${rightElement.id}-name`">{{ rightElement.name }}</span>
      <span v-else :id="`right-${rightElement.id}-name`">{{ rightElement.nameEn }}</span>
      <br/>
      <span class="small">{{ rightElement.key }}</span>
    </b-col>
<!--    <b-col cols="1" class="text-center" v-if="!currentAcademy">-->
<!--      <pcg-checkbox v-model="rightElement.rights[0]"/>-->
<!--    </b-col>-->
    <b-col cols="9" v-if="rightElement.key !== 'list_users'">
      <b-row>
        <b-col class="" v-for="right in rightElement.rights" :key="right.id">
          <pcg-checkbox v-model="right.value"/>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="9" v-if="rightElement.key === 'list_users' && role === 'manager'">
      <b-row>
        <b-col class="" v-for="right in rightElement.rights" :key="right.id">
          <pcg-checkbox v-model="right.value"/>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="9" v-if="rightElement.key === 'list_users' && role !== 'manager'">
    </b-col>
    <b-tooltip v-if="$i18n.locale === 'pl'" :target="`right-${rightElement.id}-name`">
      {{ rightElement.description }}
    </b-tooltip>
    <b-tooltip v-else :target="`right-${rightElement.id}-name`">
      {{ rightElement.descriptionEn }}
    </b-tooltip>
  </b-row>
</template>

<script>
import ApiRights from '../api/rights'
import { mapGetters } from 'vuex'

export default {
  name: 'RightElement',
  data: function () {
    return {
      updateable: false,
      role: null
    }
  },
  props: {
    rightElement: Object,
    currentAcademy: {
      default: null,
      type: Number
    }
  },
  computed: {
    ...mapGetters('auth', ['currentRole', 'signedIn'])
  },
  watch: {
    rightElement: {
      deep: true,
      handler: function (newValue) {
        if (this.updateable) {
          ApiRights.updateRight(this.currentAcademy, newValue)
            .then(result => {
              if (this.signedIn) {
                this.$store.dispatch('auth/fetchUser')
              }
            })
            .catch((err) => {
              console.log(err)
            })
        }
      }
    }
  },
  mounted () {
    setTimeout(() => {
      this.updateable = true
      this.role = this.currentRole
    }, 1000)
  }
}
</script>

<style scoped lang="scss">
  @import '../assets/stylesheets/vars';

  .right-element {
    background-color: $pcg-white;
    color: $main-color;
    padding-top: 10px;

    &:hover {
      background-color: $pcg-lightest-gray-background;
    }
  }

  /deep/ .tooltip-inner {
    background: $pcg-white;
    color: $pcg-dark-gray;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
    font-weight: 400;
    padding: .5rem;
    border-color: $pcg-light-gray-background;
    border-style: solid;
    border-width: 1px;
    opacity: 1;
    a {
      color: unset;
    }
  }

  /deep/ .arrow::before {
    border-top-color: $pcg-light-gray-background !important;
  }

  ::v-deep label {
    margin: 0;
    padding: 0;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .right-element {
      background-color: $pcg-white;
      color: $main-color;
    }
    /deep/ .tooltip-inner {
      background: $pcg-white;
      color: $pcg-dark-gray;
      box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
      border-color: $pcg-light-gray;
    }

    /deep/ .arrow::before {
      border-top-color: $pcg-light-gray !important;
    }
  }
</style>
