<template>
  <div class="component-container">
    <dn-loader v-if="showLoader" class="with-margins"/>
    <div v-else>
      <div v-if="!scientist" class="profile-description d-flex align-items-center">
        <div class="profile-part">
          <!--        <div class="academic-degree">{{ scTitle }}</div>-->
          <div class="name">
            <router-link :to="{ name: 'employee_path', params: {id: employee.id } }" >
              {{ employee.fullName }}
              <i v-if="employee.evaluation.dissertation" class="fas fa-graduation-cap" :id="'doctoral_student-' + employee.id"></i>
            </router-link>
          </div>
          <div class="orcid" v-if="employee.orcidId">
            <img src="../../assets/images/orcid.png" class="img-fluid my-image" alt="ORCiD"/>
            <span>
            <router-link :to="{ name: 'orcid_publications_path', params: {id: employee.id } }" >
              {{ employee.orcidId }}
            </router-link>
          </span>
          </div>
        </div>
      </div>
      <b-row>
        <b-col class="col-12 col-lg-8">
          <div v-for="(mainDiscipline, index) in mainDisciplineStatements" :key="mainDiscipline.id" class="oneline">
            <template v-if="employee.isDoctorant">
              <div class="dn-header my-header">{{ $t('views.discipline_statements.dissertation') }}</div>
              <pcg-btn v-if="index === 1" size="small" variant="additional" class="add-btn" v-on:click="removeDiscipline()">
                {{ $t('views.discipline_statements.remove_discipline') }}
              </pcg-btn>
<!--              <pcg-btn v-if="mainDisciplineStatements.length === 1" size="small" variant="additional" class="add-btn" v-on:click="addDiscipline()">-->
<!--                {{ $t('views.discipline_statements.add_another') }}-->
<!--              </pcg-btn>-->
              <dn-discipline-statements v-if="mainDisciplineStatements.length > 1" :disciplineStatements="mainDiscipline.disciplineStatements" :sliderVisible="false" :taken="taken" :index="index"></dn-discipline-statements>
              <dn-discipline-statements v-else :disciplineStatements="mainDiscipline.disciplineStatements" :disabled="true" :sliderVisible="false" :taken="taken" :index="index"></dn-discipline-statements>
            </template>
            <template v-else>
              <div class="dn-header my-header">{{ $t('general.discipline') }} {{ index + 1 }}</div>
              <pcg-btn v-if="index === 1" size="small" variant="additional" class="add-btn" v-on:click="removeDiscipline()">
                {{ $t('views.discipline_statements.remove_discipline') }}
              </pcg-btn>
              <pcg-btn v-if="mainDisciplineStatements.length === 1" size="small" variant="additional" class="add-btn" v-on:click="addDiscipline()">
                {{ $t('views.discipline_statements.add_another') }}
              </pcg-btn>
              <dn-discipline-statements v-if="mainDisciplineStatements.length > 1" :disciplineStatements="mainDiscipline.disciplineStatements" :taken="taken" :index="index"></dn-discipline-statements>
              <dn-discipline-statements v-else :disciplineStatements="mainDiscipline.disciplineStatements" :disabled="true" :taken="taken" :index="index"></dn-discipline-statements>
            </template>
          </div>
          <b-row style="margin-bottom: 2rem">
            <b-col class="col-12 col-sm-6">
              <pcg-select class="my-validity"
                          v-if="employee.isDoctorant"
                          :label="$t('components.dn_discipline_statements.dissertation_from')"
                          :options="tDictionary(validityFrom)"
                          v-model="disciplineValidityFrom"
              />
              <pcg-select class="my-validity"
                          v-else
                          :label="$t('components.dn_discipline_statements.validity_from')"
                          :options="tDictionary(validityFrom)"
                          v-model="disciplineValidityFrom"
              />
              <pcg-datetime-picker
                  class="my-datetimepicker"
                  :label="$t('components.dn_discipline_statements.exact_date')"
                  :locale="$i18n.locale"
                  variant="date"
                  v-model="validityFromDate"
              />
            </b-col>
            <b-col class="col-12 col-sm-6">
              <pcg-select class="my-validity"
                          v-if="employee.isDoctorant"
                          :label="$t('components.dn_discipline_statements.dissertation_to')"
                          :options="tDictionary(validityTo)"
                          v-model="disciplineValidityTo"
              />
              <pcg-select class="my-validity"
                          v-else
                          :label="$t('components.dn_discipline_statements.validity_to')"
                          :options="tDictionary(validityTo)"
                          v-model="disciplineValidityTo"
              />
              <pcg-datetime-picker
                  v-if="disciplineValidityTo !== 9999"
                  class="my-datetimepicker"
                  :label="$t('components.dn_discipline_statements.exact_date')"
                  :locale="$i18n.locale"
                  variant="date"
                  v-model="validityToDate"
              />
            </b-col>
          </b-row>
          <pcg-btn size="small" v-on:click="employeeStatementConfirmation(employee_id, mainDisciplineStatements, true)" :disabled="recalcSpinner">
            {{ $t('views.common.save') }}
            <spinner v-if="recalcSpinner" />
          </pcg-btn>
          <b-modal ref="assignDisciplineConfirm"
                   :hide-header="true"
                   footer-class="my-modal-footer"
                   :centered="true"
                   v-bind:ok-title="$t('general.confirm')"
                   ok-variant="primary"
                   :no-close-on-esc="true"
                   @ok="createEmployeeStatement(employee_id, mainDisciplineStatements, true)"
                   @hide="assignDisciplineEsc"
                   v-bind:cancel-title="$t('general.cancel')"
                   cancel-variant="default"
                   content-class="my-modal-content"
                   size="lg"
          >
            <div class="confirm-header">{{ $t('components.dn_discipline_statements.overwriting_statements') }}</div>
            <div class="confirm-text">{{ $t('components.dn_discipline_statements.overwriting_statements_confirmation') }}</div>
            <div class="confirm-text" v-for="statement in overwritingStatements" :key="statement.id">
              <span v-if="$i18n.locale === 'pl'">{{ statement.name }}:</span>
              <span v-else>{{ statement.nameEn }}:</span>
              <span style="margin-right: .3rem">({{statement.validityFrom}}-{{ $t('general.active') }})</span>
              <i class="fas fa-arrow-right" style="margin-right: .3rem"></i>
              <span>({{statement.validityFrom}}-{{ disciplineValidityFrom - 1 }})</span>
            </div>
          </b-modal>
        </b-col>
        <b-col class="col-12 col-lg-4 previous-statement">
          <dn-previous-statement v-if="previousStatements.length > 0" :previousStatements="previousStatements" :dateAdded="dateAdded" @disciplineUpdated="updateStatements"></dn-previous-statement>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import DnDisciplineStatements from '../../components/DnDisciplineStatements'
import DnPreviousStatement from '../../components/DnPreviousStatement'
import Spinner from '../../components/Spinner'
import setTitle from '../../mixins/set_title'
import waitForJobs from '../../mixins/wait_for_jobs'
import unexpectedError from '../../mixins/unexpected_error'
import ApiEmployeeStatements from '../../api/employee_statements'
import ApiEmployees from '../../api/employees'
import translateDictionary from '../../mixins/translate_dictionary'
import DnLoader from '../../components/DnLoader'
import loaderDelay from '../../mixins/loader_delay'
import { mapGetters } from 'vuex'
export default {
  name: 'discipline-statements',
  mixins: [setTitle, unexpectedError, waitForJobs, translateDictionary, loaderDelay],
  components: {
    DnDisciplineStatements,
    DnPreviousStatement,
    Spinner,
    DnLoader
  },
  data: function () {
    return {
      headerTitle: 'views.discipline_statements.title',
      headerSubtitle: '',
      employee_id: 0,
      employee: {},
      taken: ['', ''],
      index: 0,
      errors: [],
      scientist: false,
      validityFrom: [],
      validityTo: [],
      disciplineValidityFrom: null,
      disciplineValidityTo: 9999,
      validityFromDate: null,
      validityToDate: null,

      mainDisciplineStatements: [
        {
          disciplineStatements: {
            discipline_participation: '100',
            discipline_type: null,
            field_type: null,
            validity_from: null,
            validity_from_date: null,
            validity_to: null,
            validity_to_date: null,
            is_leading: true
          }
        }
      ],
      previousStatements: [],
      dateAdded: null,
      recalcSpinner: false,
      overwritingStatements: []
    }
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.$store.dispatch('header/setHeader', {
      title: this.$t('views.discipline_statements.title')
    })
    this.employee_id = this.$route.params.id
    const that = this
    ApiEmployees.getEmployee(this.employee_id)
      .then(result => {
        this.employee = result.data.employee
        if (this.employee.isDoctorant) {
          this.headerTitle = 'views.discipline_statements.doctoral_school'
        }
        this.headerSubtitle = this.employee.fullName
        this.setTitles()
        this.checkAccess(this.employee_id)
        this.disciplineValidityFrom = this.years[0]
        this.years.forEach(year => {
          let oneYear = {}
          if (this.employee.isDoctorant) {
            if (year >= 2019) {
              oneYear = { text: year, text_en: year, id: year }
              this.validityFrom.push(oneYear)
              this.validityTo.push(oneYear)
            }
          } else {
            oneYear = { text: year, text_en: year, id: year }
            this.validityFrom.push(oneYear)
            this.validityTo.push(oneYear)
          }
        })
        const active = { text: 'aktywne', text_en: 'active', id: 9999 }
        this.validityFrom.unshift(active)
        ApiEmployeeStatements.getDisciplines(this.employee_id)
          .then(result => {
            this.previousStatements = result.data.statements
            this.dateAdded = result.data.dateAdded
            this.loadingDone = true
          })
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.$toastr.i(this.$t('views.employee.no_employee'))
        } else {
          this.$toastr.e(error)
        }
      })
    this.checkScientist()
  },
  computed: {
    ...mapGetters('period', ['allYears']),
    first_discipline_participation () {
      if (this.mainDisciplineStatements.length && this.mainDisciplineStatements.length > 1) {
        return this.mainDisciplineStatements[0].disciplineStatements.discipline_participation
      }
      return null
    },
    second_discipline_participation () {
      if (this.mainDisciplineStatements.length && this.mainDisciplineStatements.length > 1) {
        return this.mainDisciplineStatements[1].disciplineStatements.discipline_participation
      }
      return null
    },
    first_discipline_leading () {
      if (this.mainDisciplineStatements.length && this.mainDisciplineStatements.length > 1) {
        return this.mainDisciplineStatements[0].disciplineStatements.is_leading
      }
      return null
    },
    second_discipline_leading () {
      if (this.mainDisciplineStatements.length && this.mainDisciplineStatements.length > 1) {
        return this.mainDisciplineStatements[1].disciplineStatements.is_leading
      }
      return null
    },
    scTitle () {
      if (this.$i18n.locale === 'pl') {
        return this.employee.title
      } else {
        return this.employee.titleEn
      }
    },
    years () {
      return this.allYears
    }
  },
  watch: {
    first_discipline_participation (newValue, oldValue) {
      if (newValue !== null) {
        this.mainDisciplineStatements[1].disciplineStatements.discipline_participation = (100 - parseInt(newValue)).toString()
      }
    },
    second_discipline_participation (newValue, oldValue) {
      if (newValue !== null) {
        this.mainDisciplineStatements[0].disciplineStatements.discipline_participation = (100 - parseInt(newValue)).toString()
      }
    },
    first_discipline_leading (newValue, oldValue) {
      if (newValue !== null) {
        this.mainDisciplineStatements[1].disciplineStatements.is_leading = !newValue
      }
    },
    second_discipline_leading (newValue, oldValue) {
      if (newValue !== null) {
        this.mainDisciplineStatements[0].disciplineStatements.is_leading = !newValue
      }
    },
    mainDisciplineStatements: {
      deep: true,
      handler () {
        this.checkDiscipline()
        this.checkScientist()
      }
    },
    disciplineValidityFrom: {
      deep: true,
      handler () {
        this.setValidityFromValues()
        this.setValidityToValues()
        if (!this.validityFromDate) {
          const dateFrom = new Date()
          dateFrom.setFullYear(Number(this.disciplineValidityFrom), 0, 1)
          this.validityFromDate = dateFrom
        } else {
          if (Number(this.validityFromDate.getFullYear()) !== Number(this.disciplineValidityFrom)) {
            const dateFrom = new Date()
            dateFrom.setFullYear(Number(this.disciplineValidityFrom), 0, 1)
            this.validityFromDate = dateFrom
          } else {
            this.validityFromDate.setFullYear(Number(this.disciplineValidityFrom))
          }
        }
      }
    },
    disciplineValidityTo: {
      deep: true,
      handler () {
        this.setValidityToValues()
        this.setValidityFromValues()
        if (this.disciplineValidityTo !== 9999) {
          if (!this.validityToDate) {
            const dateTo = new Date()
            dateTo.setFullYear(Number(this.disciplineValidityTo), 11, 31)
            this.validityToDate = dateTo
          } else {
            if (Number(this.validityToDate.getFullYear()) !== Number(this.disciplineValidityTo)) {
              const dateTo = new Date()
              dateTo.setFullYear(Number(this.disciplineValidityTo), 11, 31)
              this.validityToDate = dateTo
            } else {
              this.validityToDate.setFullYear(Number(this.disciplineValidityTo))
            }
          }
        }
      }
    },
    years: {
      deep: true,
      handler () {
        if (this.employee.isDoctorant) {
          this.disciplineValidityFrom = 2019
        }
        this.years.forEach(year => {
          let oneYear = {}
          if (this.employee.isDoctorant) {
            if (year >= 2019) {
              oneYear = { text: year, text_en: year, id: year }
              this.validityFrom.push(oneYear)
              this.validityTo.push(oneYear)
            }
          } else {
            oneYear = { text: year, text_en: year, id: year }
            this.validityFrom.push(oneYear)
            this.validityTo.push(oneYear)
          }
        })
      }
    },
    validityFromDate: {
      deep: true,
      handler () {
        if (this.validityFrom && this.validityFrom.length > 0) {
          const validityFromYear = this.validityFromDate.getFullYear()
          if (validityFromYear >= this.validityFrom[0].id && validityFromYear <= this.validityFrom[this.validityFrom.length - 1].id) {
            this.disciplineValidityFrom = validityFromYear
          } else {
            const dateFrom = new Date()
            dateFrom.setFullYear(Number(this.disciplineValidityFrom), 0, 1)
            this.validityFromDate = dateFrom
            this.$toastr.i(this.$t('views.discipline_statements.wrong_year'))
          }
        }
      }
    },
    validityToDate: {
      deep: true,
      handler () {
        if (this.validityTo && this.validityTo.length > 0 && this.disciplineValidityTo !== 9999) {
          const validityToYear = this.validityToDate.getFullYear()
          if (validityToYear >= this.validityTo[0].id && validityToYear <= this.validityTo[this.validityTo.length - 2].id) {
            this.disciplineValidityTo = validityToYear
          } else {
            const dateTo = new Date()
            dateTo.setFullYear(Number(this.disciplineValidityTo), 11, 31)
            this.validityToDate = dateTo
            this.$toastr.i(this.$t('views.discipline_statements.wrong_year'))
          }
        }
      }
    }
  },
  methods: {
    addDiscipline: function () {
      this.mainDisciplineStatements[0].disciplineStatements.discipline_participation = '50'
      this.mainDisciplineStatements.push({
        disciplineStatements: {
          discipline_participation: '',
          discipline_type: null,
          field_type: null,
          validity_from: null,
          validity_from_date: null,
          validity_to: null,
          validity_to_date: null,
          is_leading: false
        }
      })
    },
    checkScientist () {
      const userRole = this.$store.getters['auth/currentRole']
      const employeeId = this.$store.getters['auth/employeeId']
      this.scientist = userRole === 'naukowiec'
      if (this.scientist && Number(employeeId) !== Number(this.employee_id)) {
        this.$router.push({ name: 'scientist_dashboard_path' }).catch((err) => {
          throw new Error(this.$t('general.error_handler') + ': ' + err + '.')
        })
      }
    },
    checkDiscipline: function () {
      this.taken[0] = ''
      this.taken[1] = ''
      if (this.mainDisciplineStatements.length > 1) {
        if (this.taken[1] !== this.mainDisciplineStatements[0].disciplineStatements.discipline_type) {
          this.taken[0] = this.mainDisciplineStatements[0].disciplineStatements.discipline_type
          this.mainDisciplineStatements[0].disciplineStatements.discipline_type = this.taken[0]
        } else {
          this.taken[0] = ''
          this.mainDisciplineStatements[0].disciplineStatements.discipline_type = this.taken[0]
        }
        if (this.taken[0] !== this.mainDisciplineStatements[1].disciplineStatements.discipline_type) {
          this.taken[1] = this.mainDisciplineStatements[1].disciplineStatements.discipline_type
          this.mainDisciplineStatements[1].disciplineStatements.discipline_type = this.taken[1]
        } else {
          this.taken[1] = ''
          this.mainDisciplineStatements[1].disciplineStatements.discipline_type = this.taken[1]
        }
        return this.taken
      }
    },
    removeDiscipline: function () {
      if (this.mainDisciplineStatements.length > 1) {
        this.mainDisciplineStatements.pop()
      }
    },
    employeeStatementConfirmation (employeeId, form, userClicked = false) {
      this.recalcSpinner = true
      this.previousStatements.forEach(previous => {
        if (previous.validityFrom < this.disciplineValidityFrom && previous.validityTo === 9999) {
          this.overwritingStatements.push(previous)
        }
      })
      if (this.overwritingStatements.length > 0) {
        this.$refs.assignDisciplineConfirm.show()
      } else {
        this.createEmployeeStatement(employeeId, form, userClicked)
      }
    },
    createEmployeeStatement (employeeId, form, userClicked = false) {
      for (let i = 0; i < form.length; i++) {
        form[i].disciplineStatements.validity_from = this.disciplineValidityFrom
        form[i].disciplineStatements.validity_to = this.disciplineValidityTo
        form[i].disciplineStatements.validity_from_date = this.validityFromDate
        form[i].disciplineStatements.validity_to_date = this.validityToDate
        form[i].disciplineStatements.doctoral_school = !!this.employee.isDoctorant
      }
      ApiEmployeeStatements.createEmployeeStatement(employeeId, form, userClicked)
        .then(result => {
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.recalcSpinner = true
            this.waitForJob(jobId, this.disciplineUpdated, this.disciplineError)
          }
        })
        .catch(error => {
          console.log(error.response.data)
          this.errors = error.response.data
          this.$toastr.e(this.$t('views.discipline_statements.wrong_discipline') + '\n' + this.errors.toString())
          this.recalcSpinner = false
        })
    },
    disciplineError () {
      this.recalcSpinner = false
      this.unexpectedError()
    },
    disciplineUpdated () {
      this.recalcSpinner = false
      if (this.employee.evaluation.dissertation) {
        this.$toastr.s(this.$t('views.discipline_statements.correct_doctoral_school'))
      } else {
        this.$toastr.s(this.$t('views.discipline_statements.correct_discipline'))
      }
      const userRole = this.$store.getters['auth/currentRole']
      this.scientist = userRole === 'naukowiec'
      if (this.scientist) {
        this.$router.push({ name: 'scientist_dashboard_path' }).catch((err) => {
          throw new Error(this.$t('general.error_handler') + ': ' + err + '.')
        })
      } else {
        const id = this.employee_id.toString()
        this.$router.push({ name: 'employee_path', params: { id } }).catch((err) => {
          throw new Error(this.$t('general.error_handler') + ': ' + err + '.')
        })
      }
    },
    checkAccess (id) {
      if (this.employee.authorType === 3) {
        const userRole = this.$store.getters['auth/currentRole']
        this.scientist = userRole === 'naukowiec'
        if (this.scientist) {
          this.$router.push({ name: 'scientist_dashboard_path' }).catch((err) => {
            throw new Error(this.$t('general.error_handler') + ': ' + err + '.')
          })
        } else {
          this.$router.push({ name: 'employee_path', params: { id } }).catch((err) => {
            throw new Error(this.$t('general.error_handler') + ': ' + err + '.')
          })
        }
      }
    },
    updateStatements () {
      ApiEmployeeStatements.getDisciplines(this.employee_id)
        .then(result => {
          this.previousStatements = result.data.statements
          this.dateAdded = result.data.dateAdded
        })
    },
    setValidityFromValues () {
      if (this.disciplineValidityTo > 0) {
        this.validityFrom = []
        for (let i = this.years[0]; i <= this.disciplineValidityTo; i++) {
          if (i > this.years[this.years.length - 1]) {
            break
          }
          const year = { text: i, text_en: i, id: i }
          this.validityFrom.push(year)
        }
      }
    },
    setValidityToValues () {
      if (this.disciplineValidityFrom > 0) {
        this.validityTo = []
        const active = { text: 'aktywne', text_en: 'active', id: 9999 }
        this.validityTo.unshift(active)
        for (let i = this.years[this.years.length - 1]; i >= this.disciplineValidityFrom; i--) {
          const year = { text: i, text_en: i, id: i }
          this.validityTo.unshift(year)
        }
      }
    },
    assignDisciplineEsc () {
      this.recalcSpinner = false
      this.overwritingStatements = []
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/stylesheets/vars';
  .component-container {
    padding-top: 20px;
  }

  .oneline {
    margin-bottom: 2rem;
  }

  .previous-statement {
    @media (max-width: 991.98px) {
      margin-top: 1.5rem;
    }
  }

  .my-remove {
    position: relative;
    left: 1rem;
    bottom: .3rem;
    font-weight: 400;
    font-size: $font-size-m;
    color: $pcg-gray;
    cursor: pointer;
  }

  .extender {
    display: inline-block;
    width: 13px;
  }
  /deep/ .spinner {
    margin-left: 1px;
  }

  .profile-description {
    margin-bottom: 1.5rem;
  }

  .profile-part {
    display: inline-block;
    margin-left: .5rem;
  }

  .academic-degree {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-m;
  }

  .name {
    color: $main-color;
    font-weight: 600;
    font-size: $font-size-l;
    margin-bottom: .4rem;
    a {
      color: unset;
    }
  }

  .orcid {
    color: $main-color;
    font-weight: 400;
    font-size: $font-size-m;
    span {
      cursor: pointer;
    }
    a {
      color: unset;
    }
  }

  .my-image {
    height: 24px;
    display: inline-block;
    margin-right: .5rem;
    position: relative;
    bottom: .1rem;
  }

  .add-btn {
    margin-left: 1rem;
    position: relative;
    bottom: .3rem;
  }

  /deep/ .pcg-button {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  .my-header {
    margin-bottom: .3rem;
    display: inline-block;
  }

  .my-validity {
    position: relative;
    /deep/ .pcg-input-label {
      margin-bottom: 0.25rem;
      color: $pcg-gray;
    }
  }

  .confirm-header {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .confirm-text {
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1rem;
  }

  .my-datetimepicker {
    /deep/ .pcg-datetimepicker-input {
      font-weight: 400;
      color: $pcg-dark-gray;
      height: 30px;
    }
    /deep/ .pcg-datetimepicker-btn {
      height: auto;
    }
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .my-remove {
      color: $pcg-gray;
    }
    .academic-degree {
      color: $pcg-gray;
    }
    .name {
      color: $main-color;
    }
    .orcid {
      color: $main-color;
    }
    .my-validity {
      /deep/ .pcg-input-label {
        color: $pcg-gray;
      }
    }
    .confirm-header {
      color: $pcg-gray;
    }
    .confirm-text {
      color: $pcg-gray;
    }
  }
</style>
