<template>
  <b-row class="pcg-header-container">
    <div class="header-background" :class="{ show: showBackground }"></div>
    <b-col md="auto" class="pcg-logo-container" :class="[{ 'pcg-small': !menuOpen }, ...logoContainerClasses]">
      <img :src="logo" class="logo" alt="Sciencecloud" />
      <img :src="negativeLogo" class="logo-negatyw" alt="Sciencecloud" />
    </b-col>
    <b-col>
      <b-row class="languages-container">
        <div class="pcg-top-container" :class="{ 'pcg-background-transparent': backgroundImage }">
          <div class="pcg-txt-right">
            <slot name="upper"></slot>
          </div>
        </div>
      </b-row>
      <b-row>
        <slot name="bottom"></slot>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'DnLayoutHeader',
  props: {
    logo: String,
    negativeLogo: String,
    logoContainerClasses: Array
  },
  data () {
    return {
      showBackground: false
    }
  },
  computed: {
    ...mapGetters('menu', ['menuOpen']),
    ...mapGetters('pageCover', ['backgroundImage'])
  },
  methods: {
    onScroll () {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      this.showBackground = currentScrollPosition > 100
    }
  },
  mounted () {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.onScroll)
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .logo-negatyw{
    display: none;
  }
  .languages-container {
    background: transparent;
    height: 40px;
    @media screen and (max-width: $screen-sm-max) {
      background: $pcg-the-lightest-gray;
      display: none;
    }
  }
  .pcg-logo-container {
    transition: all 0.3s;
    &.no-box-shadow {
      border-bottom: none;
      box-shadow: none;
    }
    &.pcg-small {
      padding: 0 5px;
      width: 90px;
      min-width: 90px;
      max-width: 90px;
    }
  }
  .header-background {
    background: transparent;
    position: absolute;
    left: 0;
    width: 3000px;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s;
    @media screen and (min-width: $screen-md-min) {
      &.show {
        opacity: 1;
        ::v-deep .pcg-top-container {
        }
      }
    }
  }
</style>

<style lang="scss">
  .contrast{
    .logo-negatyw{
      display: inline-block;
    }
    .logo{
      display: none;
    }
  }
</style>
