import api from './index'

const resource = 'import_pcg'

export default {
  import () {
    const url = resource
    return api.get(url)
  }
}
