import api from './index'

const resource = 'employee_faculties'

export default {
  removeFaculty (id) {
    let url = resource
    url += '/' + id
    return api.delete(url)
  },

  createEmployeeFaculty (employeeId, facultyId) {
    let employeeFaculty = {}
    employeeFaculty = {
      employee_id: employeeId,
      faculty_id: facultyId
    }
    const body = {
      employee_faculty: employeeFaculty
    }
    return api.post(`${resource}`, body)
  },

  changeArchive (faculty) {
    let url = resource
    url += '/' + faculty.id + '/change_archive'
    const body = {
      employee_faculty: {
        id: faculty.id,
        archive: faculty.archive
      }
    }
    api.put(url, body)
  },
}
