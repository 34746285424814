import api from './index'

const resource = 'histories'

export default {
  getHistories (dateFrom, dateTo) {
    const url = resource
    const params = {
      date_from: dateFrom,
      date_to: dateTo
    }
    return api.get(url, { params })
  },
  getReport (jobId) {
    const url = `${resource}/report`
    const params = {
      job_id: jobId
    }
    return api.get(url, { params })
  }
}
