import api from './index'

export default {
  updatePolonId (employeeId, polonId, polonUuid, pbnId, userClicked = false) {
    const resource = 'employees'
    let url = ''
    url = resource + '/' + employeeId + '/update_polon'
    if (userClicked) url += '?user_clicked=true'
    const body = {
      employee: {
        polonId: polonId,
        polonUuid: polonUuid,
        pbnId: pbnId
      }
    }
    return api.post(url, body)
  },
  getPbnToken () {
    const resource = 'polon'
    const url = resource + '/get_pbn_token'
    return api.get(url)
  },
  userToken (ott) {
    const resource = 'polon'
    const url = resource + '/mycallback?ott=' + ott
    return api.get(url)
  },
  importPbnApi (params) {
    const resource = 'polon'
    const url = resource + `/import?${params}`
    return api.get(url)
  },
  importFeesPbnApi (params) {
    const resource = 'polon'
    const url = resource + `/import_fees?${params}`
    return api.get(url)
  },
  editToken (token) {
    const resource = 'polon'
    const url = resource + '/edit_pbn_token'
    const body = {
      token: token
    }
    return api.post(url, body)
  }
}
