import ApiCheckJob from '../api/check_job'

export default {
  methods: {
    waitForJob (jobId, callbackOk, callbackError) {
      if (jobId === undefined) {
        return
      }
      ApiCheckJob.checkJob(jobId)
        .then(result => {
          if (result.status === 200) {
            this.progress = 100
            callbackOk()
          } else if (result.status === 202) {
            this.progress = result.data.progress
            setTimeout(this.waitForJob, 2000, jobId, callbackOk, callbackError)
          } else if (result.status > 400) {
            callbackError()
          } else {
            setTimeout(this.waitForJob, 2000, jobId, callbackOk, callbackError)
          }
        })
        .catch((error) => {
          console.log(error)
          callbackError()
        })
    },
    waitForBigWorker (id, callbackOk, callbackError) {
      if (id === undefined) {
        return
      }
      ApiCheckJob.checkBw(id)
        .then(result => {
          if (result.status === 200) {
            this.progress = 100
            callbackOk()
          } else if (result.status === 202) {
            this.progress = result.data.progress
            setTimeout(this.waitForBigWorker, 2000, id, callbackOk, callbackError)
          } else if (result.status > 400) {
            callbackError()
          } else {
            setTimeout(this.waitForBigWorker, 2000, id, callbackOk, callbackError)
          }
        })
        .catch((error) => {
          console.log(error)
          callbackError()
        })
    }

  }
}
