<template>
  <b-col cols="12">
    <pcg-table :items="dictionaryWords" :fields="fields">
      <template v-slot:header>{{$t('views.dictionary_words.index.table_header')}}</template>
    </pcg-table>
    <nav :aria-label="$t('general.pagination')">
      <pcg-pagination v-model="pagination.currentPage"
                      @input="getDictionaryWords"
                      :page-count="pagination.pages"
                      :total-records="pagination.totalRecords"
                      class="col-12 justify-content-center my-pagination"/>
    </nav>
    <b-modal ref="switchWord"
             :hide-header="true"
             :centered="true"
             v-bind:ok-title="$t('general.save')"
             ok-variant="primary"
             :no-close-on-esc="true"
             :no-close-on-backdrop="true"
             @ok="switchWord"
             v-bind:cancel-title="$t('general.cancel')"
             cancel-variant="default"
             v-on-clickaway = "escSwitchWord"
             footer-class="my-modal-footer"
             content-class="my-modal-content"
    >
      <b-row style="align-items: center;">
        <b-col cols="5">
          <b>{{ word.oldWordValue }}</b>
        </b-col>
        <b-col cols="2">
          {{$t('general.change_to')}}
        </b-col>
        <b-col :cols="5">
          <pcg-search-select :options="wordsOptions"
                             autocomplete="off"
                             :value="selectedWord"
                             @input="wordSelected"
                             always-active
                             allow-empty
                             :show-value-addition="false"
                             :show-error="false"
                             class="flex-grow-1 my-text-input my-input"
                             style="display: inline-block" />
          <spinner v-if="switchWordSpinner" style="position: absolute;top: 10px;right: -10px;"></spinner>
        </b-col>
      </b-row>
      <div class="attention">
        {{$t('views.dictionary_words.changed_word')}}
      </div>
    </b-modal>
  </b-col>
</template>

<script>
import ApiDictionaryWords from '../../api/dictionary_words'
import ApiPublications from '../../api/publications'
import PaginableResourceMixin from '../../mixins/paginable_resource_mixin'
import { decamelize } from 'humps'
import { mixin as clickaway } from 'vue-clickaway'
import hasRight from '../../mixins/has_right'
import Spinner from '../../components/Spinner'

export default {
  name: 'DictionaryWordsIndex',
  components: { Spinner },
  mixins: [PaginableResourceMixin, clickaway, hasRight],
  data () {
    return {
      // academyId: this.$route.query.academyId,
      dictionaryId: this.$route.params.dictionaryId,
      dictionaryWords: [],
      fields: [],
      word: {
        oldWordId: null,
        oldWordValue: null,
        newWordId: null
      },
      selectedWord: null,
      wordsOptions: [],
      switchWordSpinner: false
    }
  },
  props: ['status', 'academyId', 'dictionary'],
  watch: {
    '$i18n.locale': function (oldLocale, newLocale) {
      if (oldLocale !== newLocale) {
        this.rebuildTableFields()
      }
    },
    academyId () {
      this.getDictionaryWords()
    },
    status () {
      this.getDictionaryWords()
    },
    dictionary () {
      this.getDictionaryWords()
    }
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    // if (!this.academyId) {
    //   this.$parent.academyId = this.$route.query.academyId
    // }
    ApiDictionaryWords.getDictionaryWordsByKey('keywords')
      .then(result => {
        const fs = result.data.data
        this.wordsOptions = []
        this.wordsId = result.data.meta.dictionaryId
        fs.forEach(f => {
          this.wordsOptions.push({
            text: f.attributes.value + ' (' + f.attributes.lang + ')',
            text_en: f.attributes.valueEn + '(' + f.attributes.lang + ')',
            key: f.attributes.key,
            value: f.id
          })
        })
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    getDictionaryWords () {
      ApiDictionaryWords.getDictionaryWords(this.dictionaryId, this.status, this.pagination.currentPage, this.academyId)
        .then(response => {
          this.setPagination(response)
          this.fields = this.fieldsTable(response.data.data)
          this.dictionaryWords = this.dataTable(response.data.data)
        }).catch(error => {
          this.$toastr.e(error)
          console.log(error)
        })
    },
    updateDictionaryWord (dictionaryWord) {
      this.loading = true
      ApiDictionaryWords.updateDictionaryWord(this.dictionaryId, dictionaryWord.id, { dictionaryWord: { status: this.booleanToStatus(!this.statusToBoolean(dictionaryWord.attributes.status)) } })
        .then(response => {
          this.errors = {}
          dictionaryWord.attributes.status = this.booleanToStatus(!this.statusToBoolean(dictionaryWord.attributes.status))
          dictionaryWord.attributes.switches[0].value = this.statusToBoolean(dictionaryWord.attributes.status)
          setTimeout(() => { this.$toastr.s(this.$t('general.dictionary_word_updated')) }, 1)
        }).catch(error => {
          this.errors = error.response.data
          setTimeout(() => { this.$toastr.e(this.$t('general.check_errors')) }, 1)
        }).finally(() => {
          this.loading = false
          this.$emit('updated')
        })
    },
    // setHeader () {
    //   this.$store.dispatch('header/setHeader', {
    //     title: this.$t('views.dictionary_words.index.title'),
    //     subtitle: this.$t('views.dictionary_words.index.subtitle')
    //   })
    // },
    statusToBoolean (status) {
      if (status === 'accepted') {
        return true
      } else {
        return false
      }
    },
    booleanToStatus (status) {
      if (status) {
        return 'accepted'
      } else {
        return 'rejected'
      }
    },
    rebuildTableFields () {
      this.dictionaryWords.forEach(d => {
        if (d.actions.length > 0) d.actions[0].text = this.$t('general.edit')
      })
      this.fields.forEach(f => {
        f.label = this.$t(`views.dictionary_words.index.${decamelize(f.key)}`)
      })
    },
    switch (dictionary) {
      this.word.oldWordId = dictionary.id
      this.word.oldWordValue = dictionary.attributes.value
      this.$refs.switchWord.show()
    },
    switchWord (event) {
      event.preventDefault()
      this.switchWordSpinner = true
      ApiPublications.switchKeyword(this.word.oldWordId, this.word.newWordId)
        .then(result => {
          this.switchWordSpinner = false
          this.word = {
            oldWordId: null,
            oldWordValue: null,
            newWordId: null
          }
          this.selectedWord = null
          this.$refs.switchWord.hide()
          this.getDictionaryWords()
        })
        .catch(err => {
          const datas = err.response.data
          const message = []
          for (const key in datas) {
            message.push(`${key.toString()} ${datas[key]}`)
          }
          this.$toastr.e(message.join('\n'))
          console.log(err)
          this.switchWordSpinner = false
        })
    },
    escSwitchWord () {
      this.word = {
        oldWordId: null,
        oldWordValue: null,
        newWordId: null
      }
      this.selectedWord = null
      this.$refs.switchWord.hide()
    },
    wordSelected (wordId) {
      this.word.newWordId = wordId
      const newWord = this.wordsOptions.find(wo => wo.value === wordId)
      this.selectedWord = newWord ? newWord.value : null
    },
    dataTable (data) {
      const result = []
      const dict = this.dictionary
      data.forEach((dictionary, index) => {
        const attributes = dictionary.attributes
        const actions = {
          actions: [
            {
              id: `${dictionary.id}-a`,
              link: () => this.$router.push({ name: 'dictionary_words_edit_path', params: { dictionaryId: this.dictionaryId, id: dictionary.id } }),
              type: 'button',
              text: this.$t('general.edit')
            }]
        }
        if (dict.key === 'keywords' && this.status === 'accepted' && this.hasRight('switch_keyword')) {
          actions.actions.push({
            id: `${dictionary.id}-a2`,
            link: () => this.switch(dictionary),
            type: 'button',
            text: this.$t('views.dictionary_words.index.switch')
          })
        }
        const switches = {
          switches: [
            {
              id: `${dictionary.id}-b`,
              link: () => this.updateDictionaryWord(dictionary),
              type: 'switch',
              value: this.statusToBoolean(attributes.status)
            }]
        }
        Object.assign(attributes, switches)
        Object.assign(attributes, actions)
        result.push(attributes)
      })
      return result
    },
    fieldsTable (data) {
      const fields = []
      data.forEach((dictionary, index) => {
        if (index === 0) {
          _.forEach(dictionary.attributes, (val, key) => {
            if (key === 'status') {
              fields.push({
                key: key,
                label: this.$t(`views.dictionary_words.labels.${decamelize(key)}`),
                formatter: value => {
                  return this.$t(`views.dictionary_words.statuses.${value}`)
                }
              })
            } else {
              fields.push({
                key: key,
                label: this.$t(`views.dictionary_words.labels.${decamelize(key)}`)
              })
            }
          })
        }
      })
      fields.push({
        key: 'switches',
        label: this.$t('views.dictionary_words.labels.switches')
      })
      fields.push({
        key: 'actions',
        label: this.$t('views.dictionary_words.labels.actions')
      })
      return fields
    }
  }
  // beforeRouteEnter (to, from, next) {
  //   next(vm => {
  //     // vm.setHeader()
  //   })
  // }
}
</script>

<style lang="scss" scoped>
@import '../../assets/stylesheets/vars';

.attention {
  font-size: $font-size-s;
  font-weight: 400;
  color: $dn-orange;
  margin-right: 1rem;
}
</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .my-pagination {
      /deep/ .page-item {
        &, &.disabled {
          .page-link {
            color: $pcg-the-darkest-gray !important;
            &:hover {
              color: $hover-color !important;
            }
          }
        }
        &.active {
          .page-link {
            background-color: $main-active-color !important;
            color: $pcg-white !important;
          }
        }
        &:first-child, &:last-child {
          .page-link {
            color: $main-active-color !important;
          }
        }
      }
    }
  }
</style>
