<template>
  <div>
    <div class="d-flex justify-content-between" style="margin-bottom: 2.5rem;">
      <div>
        <i class="fa fa-user fa-lg my-user"></i>
        <span class="additional-ids-title">{{ $t('views.employee.additional_identifiers') }}</span>
      </div>
      <div>
        <i class="pcg-icon-cross my-cross" @click="escAdditionalIds"></i>
      </div>
    </div>
    <b-row v-if="additionalIds.length > 0" style="margin-bottom: 1.5rem" class="additional-header">
      <b-col cols="5">
        {{ $t('views.employee.key') }}
      </b-col>
      <b-col cols="5">
        {{ $t('views.employee.value') }}
      </b-col>
    </b-row>
    <div class="one-additional-id" v-for="additional in additionalIds" :key="additional.id">
      <b-row>
        <b-col cols="5">
          <div class="additional-name">{{ additional.key }}</div>
        </b-col>
        <b-col cols="5">
          <div class="additional-name">{{ additional.value }}</div>
        </b-col>
        <b-col cols="1">
        </b-col>
        <b-col v-if="additionalIds.length > 0" cols="1">
          <pcg-btn class="remove-btn" @click="removeAdditionalId(additional.id, true)" round :icon="removeIcon" :text="$t('views.employee.remove_id')" variant="danger"></pcg-btn>
        </b-col>
      </b-row>
      <div class="my-border"></div>
    </div>
    <b-row v-for="error in errors" :key="error.id">
      <b-col>
        <img src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
        <span class="my-error">{{ error }}</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <pcg-text-input
          :label="$t('views.employee.key')"
          :showLabel="true"
          v-model="newAdditionalId.key"
          class="my-input"
        />
      </b-col>
      <b-col cols="4">
        <pcg-text-input
          :label="$t('views.employee.value')"
          :showLabel="true"
          v-model="newAdditionalId.value"
          class="my-input"
        />
      </b-col>
      <b-col cols="4">
        <pcg-btn size="small" style="position: relative; top: 1.4rem;" @click="addAdditionalId(employee.id, newAdditionalId, true)">+ {{ $t('views.employee.add_id') }}</pcg-btn>
      </b-col>
    </b-row>
    <div class="my-border" style="margin-bottom: 1rem;"></div>
    <b-row class="d-flex justify-content-center">
      <pcg-btn @click="createAdditionalId(employee.id, newAdditionalId, true)">+ {{ $t('views.employee.save_and_close') }}</pcg-btn>
    </b-row>
  </div>
</template>

<script>
import ApiEmployees from '../api/employees'
import ApiAdditionalIds from '../api/additional_ids'

export default {
  name: 'DnAdditionalIds',
  props: {
    employee: Object
  },
  data: function () {
    return {
      additionalIds: [],
      newAdditionalId: {
        key: '',
        value: ''
      },
      errors: [],
      removeIcon: {
        type: 'component',
        value: 'icon-remove',
        width: '11',
        height: '13',
        color: '#00B4EB',
        hoverColor: '#a90000'
      }
    }
  },
  created () {
    ApiEmployees.getAdditionalIdentifiers(this.employee.id)
      .then((result) => {
        this.additionalIds = result.data.additionalIds
      })
  },
  methods: {
    escAdditionalIds () {
      this.$emit('escAdditionalIds')
    },
    removeAdditionalId (id, userClicked = false) {
      ApiAdditionalIds.removeAdditionalId(id, userClicked)
        .then((result) => {
          ApiEmployees.getAdditionalIdentifiers(this.employee.id)
            .then((result) => {
              this.additionalIds = result.data.additionalIds
            })
        })
    },
    addAdditionalId (employeeId, form, userClicked = false) {
      this.errors = []
      ApiAdditionalIds.createadditionalId(employeeId, form, userClicked)
        .then(result => {
          ApiEmployees.getAdditionalIdentifiers(this.employee.id)
            .then((result) => {
              this.additionalIds = result.data.additionalIds
              this.newAdditionalId.key = ''
              this.newAdditionalId.value = ''
            })
        })
        .catch(error => {
          this.errors = error.response.data
        })
    },
    createAdditionalId (employeeId, form, userClicked = false) {
      this.errors = []
      ApiAdditionalIds.createadditionalId(employeeId, form, userClicked)
        .then(result => {
          ApiEmployees.getAdditionalIdentifiers(this.employee.id)
            .then((result) => {
              this.additionalIds = result.data.additionalIds
              this.$emit('escAdditionalIds')
            })
        })
        .catch(error => {
          this.errors = error.response.data
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .identities {
    color: $main-active-color;
    font-weight: 600;
    font-size: $font-size-l;
    cursor: pointer;
  }

  .my-user {
    color: $main-color;
    margin-right: .3rem;
  }

  .additional-ids-title {
    color: $main-color;
    font-weight: 600;
    font-size: $font-size-m;
    text-transform: uppercase;
  }

  .my-cross {
    color: $pcg-gray;
    cursor: pointer;
  }

  .remove-btn {
    position: relative;
    bottom: .6rem;
  }

  .one-additional-id {
    margin-bottom: 2rem;
  }

  .my-border {
    border-bottom: 1px solid $pcg-lightest-gray-background;
    margin-left: -30px;
    margin-right: -30px;
  }

  .additional-name {
    margin-bottom: 2rem;
    color: $pcg-gray;
    font-weight: 400;
  }

  .my-input {
    /deep/ .pcg-input {
      height: calc(1.5rem + 0.75rem + 2px)
    }
  }

  .my-error {
    color: $pcg-red;
    font-weight: 400;
    margin-left: .3rem;
    position: relative;
    bottom: .1rem;
  }

  .my-exclamation-mark {
    height: 14px;
    width: 14px;
    padding: .2rem .2rem;
    border-radius: 20px;
    text-align: center;
    background-color: $pcg-red;
    opacity: 0.8;
    position: relative;
    bottom: .2rem;
  }

  .my-checkbox {
    /deep/ .checkbox-text {
      color: $pcg-gray;
      font-weight: 600;
    }
  }

  .my-checkbox-alternative {
    /deep/ .checkbox-text {
      color: $main-active-color;
      font-weight: 600;
    }
  }

  .my-radio {
    /deep/ .pcg-radio-container {
      color: $pcg-gray;
      font-weight: 600;
    }
  }

  .my-radio-alternative {
    /deep/ .pcg-radio-container {
      color: $main-active-color;
      font-weight: 600;
    }
  }

  .additional-header {
    color: $pcg-dark-gray;
    font-size: $font-size-m;
    font-weight: 400;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .identities {
      color: $main-active-color;
    }
    .my-user {
      color: $main-color;
    }
    .additional-ids-title {
      color: $main-color;
    }
    .my-cross {
      color: $pcg-gray;
    }
    .my-border {
      border-bottom: 1px solid $pcg-lightest-gray;
    }
    .additional-name {
      color: $pcg-gray;
    }
    .my-error {
      color: $pcg-red;
    }
    .my-exclamation-mark {
      background-color: $pcg-red;
    }
    .my-checkbox {
      /deep/ .checkbox-text {
        color: $pcg-gray;
      }
    }
    .my-checkbox-alternative {
      /deep/ .checkbox-text {
        color: $main-active-color;
      }
    }
    .my-radio {
      /deep/ .pcg-radio-container {
        color: $pcg-gray;
      }
    }
    .my-radio-alternative {
      /deep/ .pcg-radio-container {
        color: $main-active-color;
      }
    }
    .additional-header {
      color: $pcg-dark-gray;
    }
  }
</style>
