<template>
    <div class="scientists-slots">
        <b-row align-v="center" class="mb-3 js-closed" @click="showInfo">
            <b-col class="scientist-points col-2">
              <span v-if="maxPoints" class="points" :class="pointsWithSlotsClass(maxPoints.points, currentPoints)">{{ currentPoints }}</span>
              <span v-if="minusesSum">
                <img src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark" style="margin-right: .1rem" :alt="$t('views.discipline.show.minuses')"/>
                <span class="my-minus-person">{{ minusesSum * -1 }}</span>
              </span>
            </b-col>
            <b-col class="scientist col-4 col-lg-3">
              <router-link v-if="canViewEmployer(scientist)" class="scientist-name" :to="{ name: 'employee_path', params: {id: scientist.id} }" :style="[ minusesSum ? { 'margin-right': '.2rem' } : { 'margin-right': '0' } ]">
                  {{ scientist.fullName }}
                  <i v-if="scientist.isDoctorant" class="fas fa-graduation-cap"></i>
              </router-link>
              <span v-else class="scientist-name" style="cursor: not-allowed" :style="[ minusesSum ? { 'margin-right': '.2rem' } : { 'margin-right': '0' } ]">
                    {{ scientist.fullName }}
                    <i v-if="scientist.isDoctorant" class="fas fa-graduation-cap"></i>
              </span>
            </b-col>
            <b-col class="scientist-percents col-1 col-lg-2">
                <b-tooltip v-if="short === 'DS010801N' || short === 'DS010802N' || short === 'DS010803N'" :target="`scientist-${scientist.id}`">
                  {{ $t('views.discipline.show.limit') }}
                </b-tooltip>
                <b-tooltip v-else :target="`scientist-${scientist.id}`">
                  {{ $t('views.discipline.show.filling') }}
                </b-tooltip>
                <img v-if="myContrast" src="../assets/images/i-black.svg" class="icon-too-many" :id="`scientist-${scientist.id}`" :alt="$t('views.discipline.show.filling')"/>
                <img v-else src="../assets/images/i.svg" class="icon-too-many" :id="`scientist-${scientist.id}`" :alt="$t('views.discipline.show.filling')"/>
                <span class="slots">{{ $n(Math.round(currentPercent*100)/100) }}%</span>
            </b-col>
            <b-col class="col-5">
                <slots-bar class="my-slots-bar"
                        :publications="scientist.publications"
                        :maxSlots="maxSlots"
                        :scientist="scientist.id"
                />
            </b-col>
        </b-row>
        <div class="pub-info" style="display:none;">
            <b-row class="mb-3" style="margin-top: -1rem;">
                <b-col cols="7">
                  <div v-for="(patent, index) in patents" :key="patent.id" style="margin-bottom: .2rem">
                    <i class="fas fa-cog fa-lg my-cog-icon"></i>
                    <span :id="'patent-' +scientist.id + '-' + patent.id + '-' + index">
                      <span v-if="patent.alternativePoints" class="patent-points" :class="pointsClass(patent.alternativePoints)">
                        <span>{{ patent.alternativePoints }}</span>
                        <span>({{ patentPoints(patent) }})</span>
                      </span>
                      <span v-else class="patent-points" :class="pointsClass(patent.points)">
                        {{ patentPoints(patent) }}
                      </span>
                    </span>
                    <b-tooltip :target="'patent-' + scientist.id + '-' + patent.id + '-' + index"
                               :custom-class="tooltipClass(patent.points)"
                    >
                      <div class="m-2">
                        <div style="margin-right: .2rem; display: inline-block;">{{ patent.title }}</div>
                        <span class="autors">{{ $tc('views.discipline.show.creators', patent.authors.length) }}</span>
                      </div>
                    </b-tooltip>
                  </div>
                </b-col>
                <b-col>
                    <slots-bar
                            v-for="(pubs,index) in rejected"
                            :key="pubs.id"
                            :publications="pubs"
                            :max-slots="0"
                            :scientist="scientist.id"
                            :isDoctorant="scientist.isDoctorant"
                            :small="true"
                            style="margin-bottom: 5px;"
                    />
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="6">
<!--                    <img v-if="myContrast" src="../assets/images/i-black.svg" class="icon-too-many"/>-->
<!--                    <img v-else src="../assets/images/i.svg" class="icon-too-many"/>-->
<!--                    <span class="dn-gray-color">{{ $t('views.discipline.show.working_time') }}:</span>-->
<!--                    <span class="working-time">{{ jobParts(scientist.partTime) }}</span>-->
<!--                    <span v-if="scientist.disciplines === 2" class="working-time"> / {{ $t('views.discipline.show.two_disciplines') }}</span>-->
                  <div v-if="scientist.partTime === 0">
                    <img src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark-red" :alt="$t('general.exclamation_mark')"/>
                    <span class="not-enough-working-time">{{ $t('views.discipline.show.no_required_working_time') }}</span>
                  </div>
                  <div v-if="scientist.minuses">
                      <div v-for="(minus, index) in scientist.minuses" :key="`minus-${scientist.id}-${index}`">
                        <img src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark-red" :alt="$t('general.exclamation_mark')"/>
                        <span class="my-minus-number">{{ minus.value * -1}}</span>
                        <span class="my-minuses">- {{ $t(`general.minuses.m${minus.kind}`) }}</span>
                      </div>
                  </div>
                </b-col>
                <b-col cols="3" v-if="short !== 'DS010801N' && short !== 'DS010802N' && short !== 'DS010803N'">
                    <span class="slots">{{ maxSlots }}</span>
                    <span class="small dn-gray-color">{{ $tc('views.discipline.show.slots', maxSlots ) }}</span>
                </b-col>
                <b-col cols="3">
                    <span class="slots">{{ currentSlots }}</span>
                    <span class="small dn-gray-color">{{ $t('views.discipline.show.filled') }}</span>
                </b-col>
            </b-row>
<!--            <b-row v-if="scientist.partTime === 0" style="margin-top: .3rem">-->
<!--                <b-col cols="12">-->
<!--                    <img src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark-red"/>-->
<!--                    <span class="not-enough-working-time">{{ $t('views.discipline.show.no_required_working_time') }}</span>-->
<!--                </b-col>-->
<!--            </b-row>-->
        </div>
    </div>
</template>

<script>
import SlotsBar from './SlotsBar'
import pointsClasses from '../mixins/points_classes'
import jobParts from '../mixins/job_parts'
import hasRight from '../mixins/has_right'
import { mapGetters } from 'vuex'

export default {
  name: 'ScientistSlots',
  components: { SlotsBar },
  mixins: [jobParts, pointsClasses, hasRight],
  props: {
    scientist: {
      type: Object,
      required: true
    },
    maxPoints: Object,
    short: String
  },
  data: function () {
    return {
      currentUser: {},
      myContrast: false,
      rejected: [],
      patents: []
    }
  },
  beforeMount () {
    this.currentUser = {
      id: this.$store.getters['auth/id'],
      author: this.$store.getters['auth/fullName'],
      entity: '',
      avatar: this.$store.getters['auth/avatar'],
      role: this.$store.getters['auth/currentRole'],
      facultyId: this.$store.getters['auth/facultyId'],
      facultyChildrenIds: this.$store.getters['auth/facultyChildrenIds']
    }
  },
  mounted () {
    this.myContrast = this.contrast
    this.rejected = this.rejectedSlots()
    this.isPatent()
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    ...mapGetters('publicationTypes', ['publicationTypes']),
    allPoints () {
      return (this.maxSlots * this.scientist.pointsPerSlot)
    },
    currentPoints () {
      // let sum = 0
      // this.scientist.publications.forEach(p => {
      //   if (p.alternativePoints) {
      //     sum += p.alternativePoints
      //   } else {
      //     sum += p.points
      //   }
      // })
      // return sum.toFixed(4) * (10 / 10)
      if (this.scientist.points) {
        return this.scientist.points.toFixed(4) * (10 / 10)
      } else {
        return 0
      }
    },
    currentSlots () {
      // let sum = 0
      // this.scientist.publications.forEach(p => {
      //   sum += p.slots
      // })
      if (this.scientist.slots) {
        return this.scientist.slots.toFixed(4) * (10 / 10)
      } else {
        return null
      }
    },
    currentPercent () {
      // if (this.allPoints > 0) {
      //   return Math.round((this.currentPoints * 100) / (this.allPoints))
      // } else {
      //   return 0
      // }
      if (this.maxSlots > 0 && this.currentSlots) {
        return ((this.currentSlots / this.maxSlots) * 100).toFixed(2)
      } else {
        return 0
      }
    },
    maxSlots () {
      // let result = 0
      // if (this.scientist.partTime === null) {
      //   result = 4 * (this.scientist.share / 100.0)
      // } else {
      //   result = this.scientist.partTime * 4 * (this.scientist.share / 100.0)
      // }
      // return result
      return this.scientist.maxSlots
    },
    minusesSum () {
      let sum = 0
      if (this.scientist.minuses) {
        this.scientist.minuses.forEach(m => {
          sum += m.value
        })
      }
      return sum
    }
  },
  methods: {
    canViewEmployer (employer) {
      if (this.currentUser.role === 'pracownikjednostki') {
        return this.forUnitWorker(employer)
      }
      return this.hasRight('view_employee')
    },
    forUnitWorker (employer) {
      if (!employer.faculties) { return false }
      const facultiesIds = employer.faculties.map((f) => f.id.toString())
      if (facultiesIds.length === 0) { return false }
      const fIds = [...this.currentUser.facultyChildrenIds]
      fIds.push(this.currentUser.facultyId)
      const userFaculties = [...new Set(fIds)]
      var difference = facultiesIds.filter(x => !userFaculties.includes(x))
      return difference.length <= 0
    },
    showInfo (ev) {
      const el = ev.target
      const elSs = el.closest('.scientists-slots')
      const elInfo = elSs.querySelector('.pub-info')
      if (window.getComputedStyle(elInfo).display === 'none') {
        const elData = elSs.querySelector('.js-closed')
        elData.classList.remove('js-closed')
        elData.classList.add('js-opened')
        elInfo.style.display = 'block'
      } else {
        const elData = elSs.querySelector('.js-opened')
        elData.classList.remove('js-opened')
        elData.classList.add('js-closed')
        elInfo.style.display = 'none'
      }
    },
    rejectedSlots () {
      let sum = 0
      const pubs = []
      let pub = {}
      let idx = 0
      let diff = 0
      let pslot = 0
      pubs[idx] = []
      this.scientist.rejected.forEach(p => {
        sum += p.slots
        pub = Object.assign({}, p)
        if (sum < 4) {
          pubs[idx].push(pub)
        } else {
          diff = sum - 4
          pslot = p.slots - diff
          pub.slots = pslot
          pubs[idx].push(pub)
          idx += 1
          pubs[idx] = []
          sum = diff
          pub = Object.assign({}, p)
          pub.slots = diff
          pubs[idx].push(pub)
        }
      })
      return pubs
    },
    isPatent () {
      this.scientist.publications.forEach(publication => {
        if (publication.publicationType === 7) {
          this.patents.push(publication)
        }
      })

      this.rejected.forEach(publication => {
        if (publication.publicationType === 7) {
          this.patents.push(publication)
        }
      })
    },
    patentPoints (patent) {
      let points = 0
      points = Number(patent.points).toFixed(4) * (10 / 10)
      return points
    },
    tooltipClass (points) {
      const result = this.pointsClass(points).replace('points ', '')
      return 'dn-tooltip ' + result
    },
    calcPercent (patent) {
      return patent.slots * 100
    },
    publicationType (id) {
      const idx = this.publicationTypes.findIndex(r => {
        return r.id === id.toString()
      })
      if (idx === -1) {
        return ''
      } else if (this.$i18n.locale === 'pl') {
        return this.publicationTypes[idx].text
      } else {
        return this.publicationTypes[idx].text_en
      }
    }
  },
  watch: {
    contrast (newValue) {
      this.myContrast = newValue
    }
  }
}
</script>

<style scoped lang="scss">
    @import '../assets/stylesheets/vars';

    .scientists-slots {
        margin-bottom: 1rem;

        //.minuses {
        //  color: $danger;
        //}
        .points {
            font-weight: 500;
            min-width: 30px;
            width: fit-content;
            margin-right: 0 !important;
            font-size: $font-size-s;
        }
        .scientist {
            color: $main-color;
            font-weight: 500;
            a {
                color: unset;
            }
        }
        .js-closed {
            cursor: zoom-in;
        }
        .js-opened {
            cursor: zoom-out;
        }
    }
    .slots {
      color: $main-color;
      font-weight: 400;
    }
    .working-time {
      color: $pcg-dark-gray;
      font-weight: 400;
    }
    .icon-too-many {
      height: 17px;
      width: 17px;
      padding: .2rem .2rem;
      border-radius: 20px;
      text-align: center;
      background-color: $main-color;
      margin-right: .3rem;
        position: relative;
        bottom: .1rem;
    }
    .my-exclamation-mark {
        height: 14px;
        width: 14px;
        padding: .2rem .2rem;
        border-radius: 20px;
        text-align: center;
        background-color: #ff3b5a;
        opacity: 0.8;
        position: relative;
        bottom: .1rem;
    }

    .my-exclamation-mark-red {
        height: 17px;
        width: 17px;
        padding: .2rem .2rem;
        border-radius: 20px;
        text-align: center;
        background-color: $pcg-red;
        margin-right: .3rem;
        position: relative;
        bottom: .1rem;
    }

    .not-enough-working-time {
        color: $pcg-red;
        font-weight: 400;
    }

    /deep/ .tooltip-inner {
      background: $pcg-white;
      color: $pcg-dark-gray;
      box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
      font-weight: 400;
      padding: .5rem;
      border-color: $pcg-light-gray-background;
      border-style: solid;
      border-width: 1px;
      opacity: 1;
    }

    /deep/ .arrow::before {
      border-top-color: $pcg-light-gray-background !important;
    }

    .scientist-name {
      @media (max-width: 830.98px) {
          position: relative;
          right: .5rem;
      }
    }

    .scientist-percents {
      @media (max-width: 1050.98px) {
        position: relative;
        right: 1rem;
      }
      @media (max-width: 830.98px) {
        position: relative;
        right: 1.4rem;
      }
      @media (max-width: 410.98px) {
        position: relative;
        right: 1.7rem;
      }
    }

    .scientist-points {
      @media (max-width: 830.98px) {
        position: relative;
        right: 1.5rem;
      }
      @media (max-width: 410.98px) {
        position: relative;
        right: 2rem;
      }
  }

  .my-minuses {
    color: $pcg-dark-gray
  }

  .my-minus-number {
    color: $pcg-red;
    font-weight: 400;
  }

    .my-minus-person {
      color: $pcg-red;
      font-weight: 400;
    }

    .my-cog-icon {
      color: $pcg-light-gray;
      position: relative;
      top: .45rem;
      margin-right: .5rem;
    }

    .patent-points {
      display: inline-block;
      padding: 0 3px;
      border-radius: 5px;
      color: $pcg-white;
      min-width: 4rem;
      width: fit-content;
      font-size: $font-size-s;
      position: relative;
      text-align: center;
      font-weight: 400;
      top: .35rem;
    }

    .tooltip {
      opacity: 1 !important;
    }

    .points-p0 {
      /deep/ .tooltip-inner{
        border: 1px solid $disabled-color;
      }
      /deep/ .arrow::before {
        border-top-color: $disabled-color;
      }
      .slots {
        background-color: $disabled-color;
      }
    }
    .points-p25 {
      /deep/ .tooltip-inner{
        border: 1px solid $main-active-color;
      }
      /deep/ .arrow::before {
        border-top-color: $main-active-color;
      }
      .slots {
        background-color: $main-active-color;
      }
    }
    .points-p50 {
      /deep/ .tooltip-inner{
        border: 1px solid $pcg-another-blue;
      }
      /deep/ .arrow::before {
        border-top-color: $pcg-another-blue;
      }
      .slots {
        background-color: $pcg-another-blue;
      }
    }
    .points-p75 {
      /deep/ .tooltip-inner{
        border: 1px solid $main-color;
      }
      /deep/ .arrow::before {
        border-top-color: $main-color;
      }
      .slots {
        background-color: $main-color;
      }
    }

    .dn-tooltip {
      &.points-p0, &.points-p25, &.points-p75 {
        background-color: transparent;
        &:hover {
          border: none;
        }
      }
      /deep/ .tooltip-inner{
        background: $pcg-white;
        color: $pcg-dark-gray;
        box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
        padding: 0.5rem;
        min-width: 420px;
        text-align: start;
        @media (max-width: 550px) {
          min-width: 250px;
        }
      }
      .title{
        font-weight: bold;
      }
    }
</style>

<style lang="scss">
    @import "../assets/stylesheets/vars-contrast";
    .contrast {
        /deep/ .tooltip-inner {
          background: $pcg-white;
          color: $pcg-dark-gray;
          box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
          border-color: $pcg-light-gray;
        }
        /deep/ .arrow::before {
          border-top-color: $pcg-light-gray !important;
        }
          .scientists-slots {
              .scientist {
                  color: $main-color;
              }
          }
        .slots {
            color: $main-color;
        }
        .working-time {
            color: $pcg-dark-gray;
        }
        .icon-too-many {
            background-color: $main-color;
        }
        .my-exclamation-mark {
            background-color: #ff3b5a;
        }
        .my-exclamation-mark-red {
            background-color: $pcg-red;
        }
        .not-enough-working-time {
            color: $pcg-red;
        }
      .my-minuses {
        color: $pcg-dark-gray
      }
      .my-minus-number {
        color: $pcg-red;
      }
      .my-minus-person {
        color: $pcg-red;
      }
      .my-cog-icon {
        color: $pcg-light-gray;
      }
      .patent-points {
        color: $pcg-white;
      }
      .points-p0 {
        /deep/ .tooltip-inner{
          border: 1px solid $disabled-color !important;
        }
        /deep/ .arrow::before {
          border-top-color: $disabled-color !important;
        }
        .slots {
          background-color: $disabled-color !important;
        }
      }
      .points-p25 {
        /deep/ .tooltip-inner{
          border: 1px solid $main-active-color !important;
        }
        /deep/ .arrow::before {
          border-top-color: $main-active-color !important;
        }
        .slots {
          background-color: $main-active-color !important;
        }
      }
      .points-p50 {
        /deep/ .tooltip-inner{
          border: 1px solid $pcg-another-blue !important;
        }
        /deep/ .arrow::before {
          border-top-color: $pcg-another-blue !important;
        }
        .slots {
          background-color: $pcg-another-blue !important;
        }
      }
      .points-p75 {
        /deep/ .tooltip-inner{
          border: 1px solid $main-color !important;
        }
        /deep/ .arrow::before {
          border-top-color: $main-color !important;
        }
        .slots {
          background-color: $main-color !important;
        }
      }
    }
</style>

<style lang="scss" scoped>
    @import "../assets/stylesheets/vars-contrast";
    .contrast {
        .contrast {
            .scientists-slots {
                .scientist {
                    color: $main-color;
                }
            }
            .slots {
                color: $main-color;
            }
            .working-time {
                color: $pcg-dark-gray;
            }
            .icon-too-many {
                background-color: $main-color;
            }
            .my-exclamation-mark {
                background-color: #ff3b5a;
            }
            .my-exclamation-mark-red {
                background-color: $pcg-red;
            }
            .not-enough-working-time {
                color: $pcg-red;
            }
        }
    }
</style>
