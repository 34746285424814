<template>
  <div class="d-flex align-items-center h-100" v-if="$mq !== 'sm' & $mq !== 'xs'" :class="desktopClass">
    <slot></slot>
  </div>
  <div v-else class="d-flex align-items-center h-100 pr-4" :class="mobileClass">
    <slot name="mobile_beam"></slot>
    <img @click="active = !active" src="../../assets/images/icons/opcje.svg" class="pointer mr-4 ml-2" :alt="$t('components.publication_element.actions')">
    <div class="d-flex align-items-center options pt-2" :class="mobileActiveClass" v-if="active">
      <slot name="options"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DnBeam',
  data () {
    return {
      active: false
    }
  },
  props: {
    mobileClass: {
      type: String,
      default: 'justify-content-end'
    },
    mobileActiveClass: {
      type: String,
      default: 'justify-content-center'
    },
    desktopClass: {
      type: String,
      default: 'justify-content-end'
    }
  },
  watch: {
    $mq: function () {
      if (this.$mq === 'sm' || this.$mq === 'xs') {
        const baseBeam = document.getElementById('base-beam')
        baseBeam.style.paddingLeft = '0'
        baseBeam.style.paddingRight = '0'
      }
    }
  },
  mounted () {
    if (this.$mq === 'sm' || this.$mq === 'xs') {
      const baseBeam = document.getElementById('base-beam')
      baseBeam.style.paddingLeft = '0'
      baseBeam.style.paddingRight = '0'
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/stylesheets/vars';

  .options {
    position: absolute;
    bottom: 64px;
    left: 0;
    right: 0;
    background-color: $pcg-white;
  }

</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .options {
      background-color: $pcg-white;
    }
  }
</style>
