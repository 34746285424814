<template>
  <div class="faculty-item" :class="{ 'with-children': hasChildren(), root: isRoot()}">
  <b-row style="height: 38px">
    <b-col class="faculty-item-line__name d-flex align-items-center">
      <span v-if="isChildren()" class="faculty-depth" :style="{ 'width': (faculty.depth * 40) + 'px' }"></span>
      <span style="margin-right: .2rem">{{ '&#x2B91;'.repeat(faculty.depth) }}</span>
      <span>{{ faculty.name }}</span>
    </b-col>
    <b-col cols="2" class="faculty-item-line__actions d-flex align-items-center">
      <span v-if="isChildren()" style="width: 100px">
        <a href="javascript:;" :aria-label="$t('general.go_up')" @click="moveLeft" v-if="rights() && !isRoot() && !faculty.first">
          <i class="fas fa-arrow-up text-active clicked faculty-icon" :style="[rights() && !isRoot() && faculty.last ? { 'margin-right': '1.2rem' } : { 'margin-right': '.5rem' } ]"></i>
        </a>
        <a href="javascript:;" :aria-label="$t('general.down')" @click="moveRight" v-if="rights() && !isRoot() && !faculty.last">
          <i class="fas fa-arrow-down text-active clicked faculty-icon" :style="[rights() && !isRoot() && !faculty.first ? {} : { 'margin-left': '3.4rem' } ]"></i>
        </a>
      </span>
    </b-col>
    <b-col cols="2" class="faculty-item-line__actions d-flex justify-content-end align-items-center">
      <a href="javascript:;" :aria-label="$t('views.faculties.list.new')" @click="addFaculty" v-if="rights()">
        <i class="fas fa-plus text-active clicked faculty-icon" :id="`add-faculty` + faculty.id" :style="[ rights() && !isRoot() ? { 'margin-right': '1rem' } : { 'margin-right': '7.4rem' } ]"></i>
      </a>
      <b-tooltip :target="`add-faculty` + faculty.id">
        {{ $t('views.faculties.list.new') }}
      </b-tooltip>
      <a href="javascript:;" :aria-label="$t('views.faculties.list.edit_faculty')" @click="editFaculty" v-if="rights() && !isRoot()">
        <i class="fas fa-pen text-active clicked faculty-icon" :id="`edit-faculty` + faculty.id" style="margin-right: 1rem" @click="editFaculty" v-if="rights() && !isRoot()"></i>
      </a>
      <b-tooltip :target="`edit-faculty` + faculty.id">
        {{ $t('views.faculties.list.edit_faculty') }}
      </b-tooltip>
      <a href="javascript:;" :aria-label="$t('views.faculties.list.remove_faculty')" @click="facultyDropping" v-if="rights() && !isRoot()">
        <i class="fas fa-eraser text-danger clicked faculty-icon" :id="`drop-faculty` + faculty.id"></i>
      </a>
      <b-tooltip :target="`drop-faculty` + faculty.id">
        {{ $t('views.faculties.list.remove_faculty') }}
      </b-tooltip>
    </b-col>
    <b-modal ref="dropConfirm"
             :hide-header="true"
             footer-class="my-modal-footer"
             :centered="true"
             v-bind:ok-title="$t('general.confirm')"
             ok-variant="primary"
             :no-close-on-esc="true"
             @ok="removeFaculty"
             v-bind:cancel-title="$t('general.cancel')"
             cancel-variant="default"
             content-class="my-modal-content"
    >
      <div class="confirm-header">{{ $t('views.faculties.list.base_deleting') }}</div>
      <div class="confirm-text">{{ $t('views.faculties.list.base_deleting_confirmation') }}</div>
    </b-modal>

  </b-row>
  </div>
</template>

<script>
import hasRight from '../../mixins/has_right'
import { mapGetters } from 'vuex'
import ApiFaculties from '../../api/faculties'

export default {
  name: 'FacultyElement',
  mixins: [hasRight],
  components: { },
  data: function () {
    return {
      headerTitle: 'views.faculties.list.title',
      headerSubtitle: 'views.faculties.list.subtitle',

      drag: false,
      faculties: [],
      rFaculty: {}
    }
  },
  props: {
    faculty: {
      type: Object
    }
  },
  computed: {
    ...mapGetters('auth', ['signedIn', 'currentRole'])
  },
  created () {
  },
  mounted () {
    if (this.faculty.children && this.faculty.children.length > 0) {
      this.faculties = this.faculty.children
    }
  },
  methods: {
    addFaculty () {
      this.$emit('add', this.faculty)
    },
    moveLeft () {
      ApiFaculties.moveLeft(this.faculty)
        .then(result => {
          this.$emit('moved')
        })
        .catch(err => {
          // console.log(err)
          const datas = err.response.data
          const message = []
          for (const key in datas) {
            message.push(`${key.toString()} ${datas[key]}`)
          }
          this.$toastr.e(message.join('\n'))
        })
    },
    moveRight () {
      ApiFaculties.moveRight(this.faculty)
        .then(result => {
          this.$emit('moved')
        })
        .catch(err => {
          // console.log(err)
          const datas = err.response.data
          const message = []
          for (const key in datas) {
            message.push(`${key.toString()} ${datas[key]}`)
          }
          this.$toastr.e(message.join('\n'))
        })
    },
    editFaculty () {
      this.$emit('edit', this.faculty)
    },
    facultyDropping () {
      this.$refs.dropConfirm.show()
    },
    removeFaculty () {
      this.$emit('drop', this.faculty)
    },
    rights () {
      return this.hasRight('edit_unit')
    },
    isRoot () {
      return this.faculty.parentId === null
    },
    hasChildren () {
      return this.faculty.hasChildren
    },
    isChildren () {
      return this.faculty.parentId !== null
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/stylesheets/vars';

.faculty-item {
  color: $pcg-dark-gray;
  font-weight: 400;
  position: relative;
  bottom: .3rem;

  .faculty-item-line {
    background: $pcg-white;
    display: grid;
    grid-template-columns: auto 1fr 150px;
    grid-gap: 15px;
    align-items: center;
    padding: 15px;
    border: 1px solid $border-line;
    border-bottom: none;
    box-shadow: 0 1px 0 0 $border-line;

    &__handler {
      cursor: grab;
      padding: 0 5px;
    }

    &__children-toggler {
      cursor: pointer;
      padding: 0 5px;
    }

    &__name {

    }

    &__actions {
      justify-self: right;

      .errors {
        color: $alert-error;
        font-size: 2.2rem;
        margin-right: 15px;
      }

      .action {
        color: $pcg-the-darkest-gray;
        font-size: 2.2rem;
        cursor: pointer;
        margin-right: 15px;

        &:last-child {
          margin-right: 0px;
        }

        &:hover {
          color: $add-cart-color;
        }
      }
    }
  }

  &.with-children {
    & > .faculty-item-line {
      grid-template-columns: auto auto 1fr 150px;
    }
  }

  &.root {
    & > .children {
      border-left: 1px solid $border-line;
    }
  }
}
  .clicked {
    cursor: pointer;
  }

.confirm-header {
  font-size: $font-size-m;
  font-weight: 400;
  color: $pcg-gray;
  margin-bottom: 1.5rem;
}

.confirm-text {
  font-weight: 400;
  color: $pcg-gray;
  margin-bottom: 1rem;
}

  .text-active {
    color: $main-active-color;
  }

  /deep/ .tooltip-inner {
    background: $pcg-the-lightest-gray-background;
    color: $pcg-gray;
    font-weight: 400;
    padding: .5rem;
    border-color: $pcg-light-gray-background;
    border-style: solid;
    border-width: 1px;
    opacity: 1;
  }

  /deep/ .arrow::before {
    border-top-color: $pcg-light-gray-background !important;
  }

  .faculty-icon {
    padding: 12px;
    border-radius: 50%;
    background-color: #ecf9fd;
  }
</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .faculty-item {
      color: $pcg-dark-gray;
      .faculty-item-line {
        background: $pcg-white;
        &__actions {
          .errors {
            color: $alert-error;
          }
          .action {
            color: $pcg-the-darkest-gray;
          }
        }
      }
    }
    .confirm-header {
      color: $pcg-gray;
    }
    .confirm-text {
      color: $pcg-gray;
    }
    .text-active {
      color: $main-active-color;
    }
    /deep/ .tooltip-inner {
      background: $pcg-the-lightest-gray;
      color: $pcg-gray;
      border-color: $pcg-light-gray;
    }

    /deep/ .arrow::before {
      border-top-color: $pcg-light-gray !important;
    }
    .faculty-icon {
      background-color: #003204;
    }
  }
</style>
