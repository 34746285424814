import api from './index'

// const resource = 'orcid_publications'

export default {
  getPublications (employee, part = null, page = null, filters = null) {
    let url = 'employees/' + employee + '/orcid_publications'
    const params = []
    if (part) {
      params.push('part=' + part)
    }
    if (page) {
      params.push('page_' + part + '=' + page)
    }
    if (filters) {
      params.push(filters)
    }
    if (params.length > 0) {
      url += '?' + params.join('&')
    }
    return api.get(url)
  },

  getPublication (employee, publicationId) {
    let url = 'employees/' + employee + '/orcid_publications'
    url += '/' + publicationId
    return api.get(url)
  },

  update_orcid_id (employee, orcidId, userClicked = false) {
    let url = 'employees/' + employee + '/orcid_publications/update_orcid_id'
    if (userClicked) url += '?user_clicked=true'
    const body = { orcid_id: orcidId }
    return api.post(url, body)
  },

  update_discipline (employee, id, disciplineId) {
    const url = 'employees/' + employee + '/orcid_publications/' + id + '/update_discipline'
    const body = { discipline_id: disciplineId }
    return api.put(url, body)
  },

  update_publisher (employee, id, publisher, conferenceMaterial) {
    const url = 'employees/' + employee + '/orcid_publications/' + id + '/update_publisher'
    const body = { publisher: publisher }
    return api.put(url, body, { params: { conference_material: conferenceMaterial } })
  },

  update_authors (employee, id, authors, originalAuthors, redaction) {
    const url = 'employees/' + employee + '/orcid_publications/' + id + '/update_authors'
    const body = { authors: authors, original_authors: originalAuthors, redaction: redaction }
    return api.put(url, body)
  },

  getOrcid (employee) {
    const url = 'employees/' + employee + '/orcid_publications/get_orcid'
    return api.get(url)
  },

  clearJid (employee) {
    const url = 'employees/' + employee + '/orcid_publications/clear_jid'
    return api.post(url)
  }
}
