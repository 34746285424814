export default {
  methods: {
    hasRight (rights) {
      if (!Array.isArray(rights)) {
        rights = [rights]
      }
      const authRights = this.$store.getters['auth/rights']
      if (!authRights) {
        return false
      }
      let result = false
      for (const right of rights) {
        if (authRights[right]) {
          result = true
          break
        }
      }
      return result
    }
  }
}
