<template>
  <pcg-box>
    <div class="header">
      <i class="fas fa-info-circle"></i>
      <span style="margin-right: .3rem">{{ $t('components.dn_previous_statement.header') }}</span>
      <spinner v-if="deleteDisciplineSpinner" class="delete-spinner"/>
    </div>
    <ul class="competences">
      <li v-for="discipline in previousStatements" :key="discipline.id" class="competence">
        <div>
          <span v-if="$i18n.locale === 'pl'" class="discipline-name">
            <router-link :to="{ name: 'discipline_show_path', params: {id: discipline.disciplineId } }" >
              {{ discipline.name }}
            </router-link>
          </span>
          <span v-else class="discipline-name">
            <router-link :to="{ name: 'discipline_show_path', params: {id: discipline.disciplineId } }" >
              {{ discipline.nameEn }}
            </router-link>
          </span>
          <span v-if="!nStatement" class="statement-percent">({{ discipline.share }}%)</span>
          <span v-if="!nStatement" class="discipline-publications">{{ $tc('components.dn_previous_statement.publications', discipline.publicationsCount) }}</span>
          <a href="javascript:;" style="display: inline-block" class="my-remove" @click="editDisciplineStatement(discipline.id)">
            <i class="fa fa-trash"></i>
            <span>{{ $t('components.dn_statements.remove') }}</span>
          </a>
        </div>
        <div>
          <span v-if="discipline.validityTo === 9999" class="discipline-count">
            <span v-if="discipline.validityFromDate">{{ $d(new Date(discipline.validityFromDate), 'short') }} - {{ $t('general.active') }}</span>
            <span v-else>{{ discipline.validityFrom }} - {{ $t('general.active') }}</span>
          </span>
          <span v-else class="discipline-count">
            <span v-if="discipline.validityFromDate">{{ $d(new Date(discipline.validityFromDate), 'short') }} - {{ $d(new Date(discipline.validityToDate), 'short') }}</span>
            <span v-else>{{ discipline.validityFrom }} - {{ discipline.validityTo }}</span>
          </span>
        </div>
      </li>
      <b-modal ref="removeDisciplineStatement"
               :hide-header="true"
               footer-class="my-modal-footer"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="removeDisciplineStatement(statementId)"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               content-class="my-modal-content"
      >
        <div class="remove-statement-title">{{ $t('views.statements.list.remove_statement') }}</div>
        <div class="remove-statement-confirmation">{{ $t('views.statements.list.remove_statement_confirmation') }}</div>
      </b-modal>
    </ul>
  </pcg-box>
</template>

<script>
import ApiEmployeeStatements from '../api/employee_statements'
import Spinner from './Spinner'
import unexpectedError from '../mixins/unexpected_error'
import waitForJobs from '../mixins/wait_for_jobs'

export default {
  name: 'dn-previous-statement',
  components: { Spinner },
  mixins: [unexpectedError, waitForJobs],
  props: {
    previousStatements: {
      type: Array,
      default: () => []
    },
    dateAdded: {
      type: Date
    },
    nStatement: {
      type: Boolean
    }
  },
  data: function () {
    return {
      deleteDisciplineSpinner: false,
      statementId: null
    }
  },
  methods: {
    editDisciplineStatement (id) {
      this.statementId = id
      this.$refs.removeDisciplineStatement.show()
    },
    removeDisciplineStatement (id) {
      ApiEmployeeStatements.removeStatement(id)
        .then(result => {
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.deleteDisciplineSpinner = true
            this.waitForJob(jobId, this.statementsUpdated, this.statementError)
          }
        })
    },
    statementsUpdated () {
      this.deleteDisciplineSpinner = false
      this.$emit('disciplineUpdated')
    },
    statementError () {
      this.deleteDisciplineSpinner = false
      this.unexpectedError()
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .header {
    font-size: $font-size-m;
    font-weight: bold;
    text-transform: uppercase;
    color: $main-color;
    margin-bottom: .5rem;
    i {
      font-size: $font-size-m;
      margin-right: .714rem;
    }
  }

  .date {
    font-size: $font-size-m;
    color: $pcg-gray;
    font-weight: 400;
    margin-left: 2rem;
  }

  .competences {
    padding-left: 24px;
    position: relative;
    margin: 0;
  }

  .competence {
    position: relative;
    z-index: 0;
    padding-left: 8px;
    margin-bottom: $font-size-m;
    list-style: none;

    .discipline-count {
      color: $pcg-light-gray;
      font-size: $font-size-m;
      font-weight: 400;
    }

    .discipline-name {
      color: $main-color;
      font-size: $font-size-m;
      font-weight: 400;
      a {
        color: unset;
      }
    }

    .discipline-publications {
      color: $pcg-gray;
      font-size: $font-size-s;
      font-weight: 400;
      position: relative;
      left: .2rem;
      margin-right: .3rem;
    }

    &:before {
      content: $dn-icon-bullet;
      color: $pcg-light-gray;
      left: -20px;
      position: absolute;
      font-size: 50px;
      font-weight: 600;
      top: -12px;
    }
  }

  .container {
    padding: .2em!important;
    display: inline-block;
    width: 100%;
    clear: both;
  }

  .print-container {
    display: inline;
    float: right;
    color: $main-active-color;
    font-size: $font-size-l;
    font-weight: 400;
  }

  .my-printer-icon {
    margin-right: 1rem;
    width: 20px;
    height: 20px;
  }

  .delete-spinner {
    color: $pcg-red;
  }

  .remove-statement-title {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .remove-statement-confirmation {
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1rem;
    white-space: pre-wrap;
  }

  .my-remove {
    color: $pcg-red;
    cursor: pointer;
    font-weight: 400;
  }

  .statement-percent {
    color: $pcg-gray;
    font-size: 1em;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .header {
      color: $main-color;
    }
    .date {
      color: $pcg-gray;
    }
    .competence {
      .discipline-count {
        color: $pcg-light-gray;
      }
      .discipline-name {
        color: $main-color;
      }
      .discipline-publications {
        color: $pcg-gray;
      }
      &:before {
        color: $pcg-light-gray;
      }
    }
    .print-container {
      color: $main-active-color;
    }
    .delete-spinner {
      color: $pcg-red;
    }
    .remove-statement-title {
      color: $pcg-gray;
    }
    .remove-statement-confirmation {
      color: $pcg-gray;
    }
    .my-remove {
      color: $pcg-red;
    }
  }
</style>
