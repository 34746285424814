import api from './index'
import qs from 'qs'

const resource = 'financing_sources'

export default {
  getSources (query = null) {
    let url = ''
    const params = qs.stringify(query)
    url = resource + '?' + params
    return api.get(url)
  },
  createSource (source) {
    const uri = `${resource}`
    const body = {
      financing_source: source
    }
    return api.post(uri, body)
  },
  updateSource (source) {
    const uri = `${resource}/${source.id}`
    const body = {
      financing_source: source
    }
    return api.put(uri, body)
  },
  getSource (id) {
    let url = resource
    url += '/' + id
    return api.get(url)
  }
}
