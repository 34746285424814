import api from './index'

const resource = 'academies'

export default {
  getAcademies () {
    const url = resource
    return api.get(url)
  },
  createAcademy (academy) {
    const url = resource
    const body = {
      academy: academy
    }
    return api.post(url, body)
  },
  fillAcademy (academy, latest) {
    let url = resource
    url += '/' + academy.id + '/fill'
    const body = {
      academy: academy,
      latest: latest
    }
    return api.put(url, body)
  },
  updateAcademy (academy) {
    let url = resource
    url += '/' + academy.id
    const body = {
      academy: academy
    }
    return api.put(url, body)
  },
  clearAcademy (academy) {
    let url = resource
    url += '/' + academy.id + '/clear'
    return api.delete(url)
  },
  lockAcademy (academy) {
    let url = resource
    url += '/' + academy.id + '/lock'
    return api.post(url)
  },
  restartApp () {
    const url = resource + '/restart_app'
    return api.get(url)
  },
  getFooter () {
    const url = resource + '/get_footer'
    return api.get(url)
  },
  getLanguageMode () {
    const url = resource + '/get_language'
    return api.get(url)
  },
  getDefaultFilters () {
    const url = resource + '/get_default_filters'
    return api.get(url)
  },
  simulateEmployment (simulate) {
    const url = resource + '/simulate_employment'
    const body = {
      simulate: simulate
    }
    return api.put(url, body)
  }
}
