<template>
  <div class="rights-list">
    <dn-loader v-if="showLoader" class="with-margins"/>
    <div v-else>
      <b-row v-if="academies.length > 1">
        <b-col cols="6">
          <pcg-select v-model="currentAcademy" :options="academyOptions" :include-blank="true"/>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col class="academy-name">
          Uczelnia: {{ academies[0].name }}
        </b-col>
      </b-row>
      <b-row class="rights-header" ref="rightsHead">
        <b-col cols="3"></b-col>
        <b-col cols="1" class="text-center" v-if="!currentAcademy">{{ roles[0] }}</b-col>
        <b-col cols="9" v-else>
          <b-row>
            <b-col class="rotated" v-for="role in roles" :key="role">
              <div>{{ role }}</div>
            </b-col>
          </b-row>
<!--          <b-row>-->
<!--            <b-col cols="1" class="text-center rotate-90" v-for="role in oddRoles" :key="role.key">{{ role.name }}</b-col>-->
<!--          </b-row>-->
<!--          <b-row>-->
<!--            <b-col cols="1" class="text-center rotate-90" v-for="role in evenRoles" :key="role.key">{{ role.name }}</b-col>-->
<!--          </b-row>-->
        </b-col>
      </b-row>
      <!--    <div v-for="right in rights" class="element">-->
      <right-element ref="rightElem" v-for="right in rights" class="element" :right-element="right" :current-academy="currentAcademy" :key="right.id"/>
      <!--    </div>-->
    </div>
  </div>
</template>

<script>
import ApiAcademies from '../../api/academies'
import ApiRights from '../../api/rights'
import RightElement from '../../components/RightElement'
import DnLoader from '../../components/DnLoader'
import loaderDelay from '../../mixins/loader_delay'

export default {
  name: 'Rights',
  components: {
    RightElement,
    DnLoader
  },
  mixins: [loaderDelay],
  data: function () {
    return {
      headerTitle: 'views.academies.rights.title',
      headerSubtitle: 'views.academies.rights.subtitle',
      currentUser: {
        id: '',
        role: ''
      },
      scrollPosition: null,
      academies: [
        {
          id: null,
          name: null
        }
      ],
      currentAcademy: null,
      rights: [],
      roles: [],
      rightsSpinner: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.updateScroll)
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.$store.dispatch('header/setHeader', {
      title: this.$t(this.headerTitle),
      subtitle: this.$t(this.headerSubtitle),
      capitalizeSubtitle: false
    })
    this.currentUser = {
      id: this.$store.getters['auth/id'],
      role: this.$store.getters['auth/currentRole'],
      academyId: parseInt(this.$store.getters['auth/academyId'])
    }
    this.currentAcademy = (this.currentUser.academyId === 'undefined' || Number.isNaN(this.currentUser.academyId)) ? null : this.currentUser.academyId
    this.getRights(this.currentAcademy)
    this.getAcademies()
  },
  computed: {
    academyOptions () {
      const result = []
      this.academies.forEach(academy => {
        result.push({
          text: academy.name,
          value: academy.id
        })
      })
      return result
    },
    oddRoles () {
      const result = []
      const srs = this.roles.slice(1)
      for (let i = 0; i < srs.length; i++) {
        if ((i % 2) === 0) {
          result.push({ name: srs[i], key: srs[i] })
        } else {
          result.push({ name: '', key: i })
        }
      }
      return result
    },
    evenRoles () {
      const result = []
      const srs = this.roles.slice(1)
      for (let i = 0; i < srs.length; i++) {
        if ((i % 2) !== 0) {
          result.push({ name: srs[i], key: srs[i] })
        } else {
          result.push({ name: '', key: i })
        }
      }
      return result
    }
  },
  watch: {
    currentAcademy (newValue) {
      // this.getRights(newValue)
    }
  },
  methods: {
    updateScroll () {
      this.scrollPosition = window.scrollY
      const heading = this.$refs.rightsHead
      if (this.scrollPosition > 150) {
        if (heading) {
          heading.classList.add('fixed')
          heading.style.width = `${this.$refs.rightElem[0].$el.clientWidth}px`
        }
      } else {
        if (heading) {
          heading.classList.remove('fixed')
        }
      }
    },
    getAcademies () {
      ApiAcademies.getAcademies()
        .then(result => {
          this.academies = result.data.datas
        })
        .catch(err => {
          console.log(err)
        })
    },
    getRights (academyId) {
      this.$children.forEach(ch => {
        if (ch.updateable !== undefined) {
          ch.updateable = false
        }
      })
      ApiRights.getRights(academyId)
        .then(result => {
          this.rights = result.data.rights
          this.roles = result.data.roles
          this.$nextTick(() => {
            this.$children.forEach(ch => {
              if (ch.updateable !== undefined) {
                ch.updateable = true
              }
            })
          })
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loadingDone = true
        })
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/stylesheets/vars';

  .rights-header {
    margin-top: 1rem;
    background-color: $main-color;
    color: $pcg-white;
    padding-top: 15px;
    padding-bottom: 15px;
    vertical-align: middle;
    border-radius: 15px 15px 0px 0px;

    &.fixed{
      position: fixed;
      top: 90px;
      z-index: 99;
    }

    .rotated {
      writing-mode: vertical-lr;
      text-align: right;

      div {
        vertical-align: middle;
      }
    }

  }

  .element {
    background-color: $pcg-white;

    &:last-child {
      ::v-deep div {
        padding-bottom: 10px;
        border-radius: 0 0 15px 15px;
      }
    }
  }

  ::v-deep table.rights-table {
    background-color: $pcg-white;
    border-radius: 15px;

    thead {
      background-color: $main-color;
      color: $pcg-white;

      th {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        vertical-align: middle;

        &.td-center {
          text-align: center;
        }
      }

      th:first-child {
        padding-left: 15px;
        border-top-left-radius: 15px;
      }

      th:last-child {
        padding-right: 15px;
        border-top-right-radius: 15px;
      }
    }

    tbody {
      padding-left: 15px;
      padding-right: 15px;
      background-color: $pcg-white;

      td {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        vertical-align: top;
        color: $pcg-dark-gray;

        &.td-left {
          text-align: left;
          color: $main-color;
          font-weight: 400;
          width: 16rem;
        }

        &.td-center {
          text-align: center;
        }

        &.td-role {
          width: 11rem;
        }
      }

      td:first-child {
        padding-left: 15px;
      }

      td:last-child {
        padding-right: 15px;
      }

      tr {
        height: 42px;
      }

      tr:last-child {
        td {
          padding-bottom: 15px;
        }

        td:first-child {
          padding-left: 15px;
          border-bottom-left-radius: 15px;
        }

        td:last-child {
          padding-right: 15px;
          border-bottom-right-radius: 15px;
        }
      }
    }
  }
  .academy-name {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
  }
</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .rights-header {
      background-color: $main-color;
      color: $pcg-white;
    }
    .element {
      background-color: $pcg-white;
    }
    ::v-deep table.rights-table {
      background-color: $pcg-white;
      thead {
        background-color: $main-color;
        color: $pcg-white;
      }
      tbody {
        background-color: $pcg-white;
        td {
          color: $pcg-dark-gray;
          &.td-left {
            color: $main-color;
          }
        }
      }
    }
  }
</style>
