<template>
  <div class="pbn-token">
    <pcg-btn @click="setToken" :disabled="ptSpinner">
      {{ this.$t('views.pbn_token.generate') }}
      <spinner v-if="ptSpinner"/>
    </pcg-btn>
    <b-row>
      <b-col>
        <pcg-text-input class="my-text-input"
        label="Token"
        style="width: 350px; margin: 1rem 0.71rem; display: inline-block"
        v-model="token"
        :placeholder="'Token'"/>
        <pcg-btn @click="editToken">
          {{ this.$t('views.pbn_token.save') }}
          <spinner v-if="ptSpinner"/>
        </pcg-btn>
      </b-col>
    </b-row>
    <b-row v-if="pbnExpires">
      <b-col v-if="pbnExpires > new Date()">
        Token wygenerowany. Można wykonać export API-PBN.<br/>
        Ważność tokenu wygasa: {{ $d(pbnExpires, 'long') }}
      </b-col>
      <b-col v-else>
        Ważność tokenu wygasła: {{ $d(pbnExpires, 'long') }}
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import setTitle from '../../mixins/set_title'
import waitForJobs from '../../mixins/wait_for_jobs'
import unexpectedError from '../../mixins/unexpected_error'
import Spinner from '../../components/Spinner'
import ApiPolon from '../../api/polon'

export default {
  name: 'PbnToken',
  mixins: [clickaway, setTitle, waitForJobs, unexpectedError],
  components: { Spinner },
  data () {
    return {
      headerTitle: 'views.pbn_token.title',
      headerSubtitle: 'views.pbn_token.subtitle',
      ptSpinner: false,
      jid: '',
      academyId: null,
      pbnApiAddress: null,
      pbnExpires: null,
      token: null
    }
  },
  created () {
  },
  mounted () {
    this.$store.dispatch('header/setHeader', {
      title: this.$t(this.headerTitle),
      subtitle: this.$t(this.headerSubtitle),
      capitalizeSubtitle: false
    })
    if (this.$route.name === 'polon_callback_path' || this.$route.name === 'polon_pbn_callback_path') {
      const ott = this.$route.query.ott
      ApiPolon.userToken(ott)
        .then(result => {
          if (result) {
            this.pbnExpires = Date.parse(result.data.expiresAt)
            this.academyId = result.data.academyId
            this.token = result.data.token
          } else {
            this.$router.push({ name: 'login_path' }).catch(error => {
              console.log(error.message)
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.$toastr.e(this.$t('general.unexpected_error'))
        })
    } else {
      ApiPolon.getPbnToken()
        .then(result => {
          if (result) {
            this.pbnExpires = Date.parse(result.data.expiresAt)
            this.academyId = result.data.academyId
            this.pbnApiAddress = result.data.pbnApiAddress
            this.token = result.data.token
          } else {
            this.$router.push({ name: 'login_path' }).catch(error => {
              console.log(error.message)
            })
          }
        })
        .catch(error => {
          console.log(error)
          this.$toastr.e(this.$t('general.unexpected_error'))
        })
    }
  },
  methods: {
    setToken () {
      window.location = `${this.pbnApiAddress}/auth/pbn/api/registration/user/token/${this.academyId}`
    },
    editToken () {
      ApiPolon.editToken(this.token)
        .then(result => {
          if (result.data.message === 'ok') {
            this.$toastr.s(this.$t('general.success'))
          }
        })
        .catch(error => {
          console.log(error)
          this.$toastr.e(this.$t('general.unexpected_error'))
        })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
