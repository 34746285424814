import ApiScienceFields from '../../api/science_fields'

const scienceFields = {
  namespaced: true,
  state: {
    science_fields: []
  },
  getters: {
    scienceFields: state => state.science_fields
  },
  mutations: {
    setScienceFields (state, scienceFields) {
      state.science_fields = scienceFields
    }
  },
  actions: {
    setScienceFields ({ commit }, payload) {
      ApiScienceFields.getScienceFields()
        .then(res => {
          let fields = res.data
          fields = fields.map(field => {
            return {
              id: field.id.toString(),
              text: field.name,
              text_en: field.nameEn
            }
          })
          commit('setScienceFields', fields)
        })
        .catch(() => {
          console.log('Error')
          return []
        })
    }
  }
}

export { scienceFields }
