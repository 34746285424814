import api from './index'

const resource = 'evaluations'

export default {
  getEvaluations () {
    const url = resource
    return api.get(url)
  },
  getEvaluation (id) {
    const url = `${resource}/${id}`
    return api.get(url)
  },
  createEvaluation (evaluation) {
    const url = resource
    const body = {
      evaluation: evaluation
    }
    return api.post(url, body)
  },
  updateEvaluation (evaluation) {
    let url = resource
    url += '/' + evaluation.id
    const body = {
      evaluation: evaluation
    }
    return api.put(url, body)
  }
}
