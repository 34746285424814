import ApiDisciplins from '../../api/disciplins'

const disciplines = {
  namespaced: true,
  state: {
    disciplines: []
  },
  getters: {
    disciplines: state => state.disciplines
  },
  mutations: {
    setDisciplines (state, disciplines) {
      state.disciplines = disciplines
    }
  },
  actions: {
    setDisciplines ({ commit }, payload) {
      ApiDisciplins.getDisciplins()
        .then(res => {
          let disciplines = res.data.disciplines
          disciplines = disciplines.map(discipline => {
            return {
              id: discipline.id.toString(),
              text: discipline.name,
              text_en: discipline.nameEn,
              science_field_id: discipline.scienceFieldId
            }
          })
          commit('setDisciplines', disciplines)
        })
        .catch(() => {
          console.log('Error')
          return []
        })
    }
  }
}

export { disciplines }
