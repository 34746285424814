<template>
  <div class="container">
    <pcg-table :items="dictionaries" :fields="fields">
      <template v-slot:header>{{$t('views.dictionaries.index.table_header')}}</template>
    </pcg-table>
    <nav :aria-label="$t('general.pagination')">
      <pcg-pagination v-model="pagination.currentPage"
                      @input="getDictionaries"
                      :page-count="pagination.pages"
                      :total-records="pagination.totalRecords"
                      class="col-12 justify-content-center my-pagination"/>
    </nav>
  </div>
</template>

<script>
import ApiDictionaryAcceptances from '../../api/dictionary_acceptances'
import PaginableResourceMixin from '../../mixins/paginable_resource_mixin'

export default {
  name: 'DictionaryAcceptancesIndex',
  components: { },
  mixins: [PaginableResourceMixin],
  data () {
    return {
      dictionaries: [],
      fields: []
    }
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.getDictionaries()
  },
  methods: {
    getDictionaries () {
      ApiDictionaryAcceptances.getDictionaryAcceptances({ page: this.pagination.currentPage })
        .then(response => {
          this.setPagination(response)
          this.fields = this.fieldsTable(response.data.data)
          this.dictionaries = this.dataTable(response.data.data)
        }).catch(error => {
          this.$toastr.e(error)
          console.log(error)
        })
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.dictionary_acceptances.index.title'),
        subtitle: this.$t('views.dictionary_acceptances.index.subtitle'),
        capitalizeSubtitle: false
      })
    },
    dataTable (data) {
      const result = []
      data.forEach((dictionary, index) => {
        const attributes = dictionary.attributes
        const actions = {
          actions: [{
            id: `${dictionary.id}-a`,
            link: () => this.$router.push({ name: 'dictionary_acceptance_words_path', params: { dictionaryId: dictionary.id } }),
            type: 'button',
            text: this.$t('general.words')
          },
          {
            id: `${dictionary.id}-b`,
            link: () => this.$router.push({ name: 'dictionary_acceptances_edit_path', params: { id: dictionary.id } }),
            type: 'button',
            text: this.$t('general.edit')
          }]
        }
        Object.assign(attributes, actions)
        result.push(attributes)
      })
      return result
    },
    fieldsTable (data) {
      const fields = []
      data.forEach((dictionary, index) => {
        if (index === 0) {
          _.forEach(dictionary.attributes, (val, key) => {
            fields.push({
              key: key,
              label: this.$t(`views.dictionary_acceptances.index.labels.${key}`)
            })
          })
        }
      })
      fields.push({
        key: 'actions',
        label: this.$t('views.dictionary_acceptances.index.labels.actions')
      })
      return fields
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .my-pagination {
      /deep/ .page-item {
        &, &.disabled {
          .page-link {
            color: $pcg-the-darkest-gray !important;
            &:hover {
              color: $hover-color !important;
            }
          }
        }
        &.active {
          .page-link {
            background-color: $main-active-color !important;
            color: $pcg-white !important;
          }
        }
        &:first-child, &:last-child {
          .page-link {
            color: $main-active-color !important;
          }
        }
      }
    }
  }
</style>
