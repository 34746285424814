<template>
  <b-col class="box-container">
    <div class="header d-flex justify-content-between align-items-center" v-if="header">
      <span class="header-title">
        <i :class="iconClass" v-if="iconClass"></i>
        {{ header }}
        <span class="subtitle" v-if="subtitle">
          {{ subtitle }}
        </span>
      </span>
      <span class="header-link" v-if="$slots.headerLink">
        <slot name="headerLink"></slot>
      </span>
    </div>
    <div class="box">
      <slot></slot>
    </div>
  </b-col>
</template>
<script>
export default {
  name: 'DnBox',
  props: {
    header: {
      type: String,
      required: false
    },
    subtitle: {
      type: String
    },
    iconClass: {
      type: String
    }
  },
  data: function () {
    return {}
  }
}
</script>
<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';
  .box-container {
    margin-bottom: $grid-gutter-width;
    background: white;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
    .header {
      padding-bottom: 10px;
      padding-left: 5px;
      .header-title {
        color: $main-color;
        font-size: $font-size-xl;
        display: inline-block;
        i {
          font-size: $font-size-s;
        }
        .subtitle {
          font-size: 0.86rem;
          color: $pcg-gray;
        }
      }
      .header-link a {
        color: $main-active-color;
        font-size: $font-size-s;
        font-weight: 500;
        margin-left: 30px;
      }
    }
    .box {
      padding: $pcg-box-padding-x $pcg-box-padding-y;
    }
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .box-container {
      background: $pcg-white;
      .header {
        .header-title {
          color: $main-color;
          .subtitle {
            color: $pcg-gray;
          }
        }
        .header-link a {
          color: $main-active-color;
        }
      }
    }
  }
</style>
