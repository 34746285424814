<template>
    <div class="academy row">
        <b-col>
            <span class="name" style="margin-right: .3rem">{{academy.name}}</span>
            <span class="clicked" @click="goInto" :id="`academy-go-into` + academy.id"><i class="fas fa-sign-in-alt academy-icon"></i></span>
            <b-tooltip :target="`academy-go-into` + academy.id">
              {{ $t('views.academies.list.go_into_academy') }}
            </b-tooltip>
        </b-col>
        <b-col>
          <span class="name">{{academy.replicationDate ? $d(Date.parse(academy.replicationDate), 'long') : ''}}</span>
        </b-col>
        <b-col cols="1" class="pub-type">
          <span class="locked">{{ tf(academy.locked) }}</span>
        </b-col>
        <b-col cols="4" class="buttons d-flex justify-content-end">
          <spinner v-if="updateSpinner" class="buttons-icon" style="width: 38px; padding: 0 12px;"/>
          <span class="buttons-icon clicked button-element" @click="fill" v-if="!updateSpinner && hasRight('edit_academy')" :disabled="updateSpinner">
            <i class="fas fa-sync-alt institution-icon" :id="`actualize-academy` + academy.id"></i>
            <b-tooltip :target="`actualize-academy` + academy.id">
              {{ $t('general.update') }}
            </b-tooltip>
          </span>
          <spinner v-if="blockSpinner" class="buttons-icon" style="width: 38px; padding: 0 12px;"/>
          <span class="buttons-icon clicked button-element" @click="lockAcademy" v-if="!blockSpinner && hasRight('edit_academy')" :disabled="blockSpinner">
            <i class="fas fa-ban institution-icon" :id="`block-academy` + academy.id"></i>
            <b-tooltip :target="`block-academy` + academy.id">
              {{ academy.locked ? $t('general.unlock') : $t('general.lock') }}
            </b-tooltip>
          </span>
          <spinner v-if="editSpinner" class="buttons-icon" style="width: 38px; padding: 0 12px;"/>
          <span class="buttons-icon clicked button-element" @click="edit" v-if="!editSpinner && hasRight('edit_academy')" :disabled="editSpinner">
            <i class="fas fa-pen institution-icon" :id="`edit-academy` + academy.id"></i>
            <b-tooltip :target="`edit-academy` + academy.id">
              {{ $t('general.edit') }}
            </b-tooltip>
          </span>
          <spinner v-if="clearSpinner" class="text-danger" style="width: 38px; padding: 0 12px;"/>
          <span class="text-danger clicked button-element" @click="clearModal" v-if="hasRight('edit_academy') && !clearSpinner" :disabled="clearSpinner">
            <i class="fas fa-eraser institution-icon" :id="`delete-academy` + academy.id"></i>
            <b-tooltip :target="`delete-academy` + academy.id">
              {{ $t('general.delete') }}
            </b-tooltip>
          </span>
        </b-col>
        <b-modal ref="academyModal"
                 :hide-header="true"
                 :centered="true"
                 footer-class="my-modal-footer"
                 v-bind:ok-title="$t('general.confirm')"
                 ok-variant="primary"
                 :no-close-on-esc="true"
                 @ok="academySave"
                 v-bind:cancel-title="$t('general.cancel')"
                 cancel-variant="default"
                 v-on-clickaway = "escModal"
                 content-class="my-modal-content"
        >
          <pcg-text-input class="my-input" v-model="rAcademy.name" :label="$t('views.academies.list.name')" :showLabel="true"/>
          <pcg-text-input class="my-input" v-model="rAcademy.tenantId" label="Tenant" :showLabel="true"/>
          <pcg-text-input class="my-input" v-model="rAcademy.managerEmail" label="Manager" :showLabel="true"/>
        </b-modal>
        <b-modal ref="academyFillModal"
                 :hide-header="true"
                 :centered="true"
                 footer-class="my-modal-footer"
                 v-bind:ok-title="$t('general.confirm')"
                 ok-variant="primary"
                 :no-close-on-esc="true"
                 @ok="academyFill"
                 v-bind:cancel-title="$t('general.cancel')"
                 cancel-variant="default"
                 v-on-clickaway = "escModal"
                 content-class="my-modal-content"
        >
          <div class="confirm-header">{{ $t('views.academies.list.base_updating') }}</div>
          <div class="confirm-text">{{ $t('views.academies.list.base_updating_confirmation') }}</div>
          <pcg-checkbox v-model="latest">{{ $t('views.academies.list.only_last') }}</pcg-checkbox>
        </b-modal>
        <b-modal ref="clearModal"
                 :hide-header="true"
                 footer-class="my-modal-footer"
                 :centered="true"
                 v-bind:ok-title="$t('general.confirm')"
                 ok-variant="primary"
                 :no-close-on-esc="true"
                 @ok="clear"
                 v-bind:cancel-title="$t('general.cancel')"
                 cancel-variant="default"
                 content-class="my-modal-content"
        >
            <div class="confirm-header">{{ $t('views.academies.list.base_deleting') }}</div>
            <div class="confirm-text">{{ $t('views.academies.list.base_deleting_confirmation') }}</div>
        </b-modal>
    </div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import ApiAcademies from '../api/institutions'
import Spinner from './Spinner'
import hasRight from '../mixins/has_right'
import tf from '../mixins/tf'
import waitForJobs from '../mixins/wait_for_jobs'
import api from '../api'

export default {
  name: 'InstitutionElement',
  components: { Spinner },
  mixins: [clickaway, hasRight, tf, waitForJobs],
  data: function () {
    return {
      blockSpinner: false,
      clearSpinner: false,
      editSpinner: false,
      latest: true,
      updateSpinner: false,
      rAcademy: {
        aid: null,
        name: null,
        subDomain: null,
        dbUser: null,
        dbPassword: null
      }
    }
  },
  props: {
    academy: Object
  },
  mounted: function () {
    for (const key in this.academy) {
      this.rAcademy[key] = this.academy[key]
    }
  },
  computed: {
  },
  methods: {
    clear () {
      this.clearSpinner = true
      ApiAcademies.clearAcademy(this.academy)
        .then(result => {
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.waitForJob(jobId, this.clearedAcademy, this.clearedAcademy)
          } else {
            this.clearSpinner = false
          }
        })
        .catch(error => {
          console.log(error)
          this.$toastr.e(this.$t('general.unexpected_error'))
          this.clearSpinner = false
        })
    },
    clearedAcademy () {
      ApiAcademies.restartApp()
        .then(result => {
          this.clearSpinner = false
          this.$toastr.s(this.$t('views.academies.droped'))
          setTimeout(() => {
            this.$router.go(0)
          }, 1000)
        })
        .catch(error => {
          const datas = error.response.data
          const message = []
          for (const key in datas) {
            message.push(`${key.toString()} ${datas[key]}`)
          }
          this.$toastr.e(message.join('\n'))
          this.createSpinner = false
        })
    },
    clearModal () {
      this.$refs.clearModal.show()
    },
    edit () {
      for (const key in this.academy) {
        this.rAcademy[key] = this.academy[key]
      }
      this.$refs.academyModal.show()
    },
    escModal () {
      this.$refs.academyModal.hide()
    },
    fill () {
      for (const key in this.academy) {
        this.rAcademy[key] = this.academy[key]
      }
      this.$refs.academyFillModal.show()
    },
    goInto () {
      ApiAcademies.goInto(this.academy)
        .then(response => {
          if (response) {
            if (response.data.location) {
              window.location = response.data.location
            }
          }
        })
        .catch(error => {
          console.log(error)
          this.$toastr.e(this.$t('general.unexpected_error'))
        })
    },
    lockAcademy () {
      this.blockSpinner = true
      ApiAcademies.lockAcademy(this.academy)
        .then(result => {
          setTimeout(() => {
            this.$router.go(0)
          }, 1000)
        })
        .catch(error => {
          console.log(error)
          this.$toastr.e(this.$t('general.unexpected_error'))
          this.blockSpinner = false
        })
    },
    academyFill () {
      this.updateSpinner = true
      ApiAcademies.fillAcademy(this.rAcademy, this.latest)
        .then(result => {
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.waitForJob(jobId, this.updatedAcademy, this.updateAcademy)
          } else {
            this.updateSpinner = false
          }
        })
        .catch(err => {
          console.log(err)
          this.$toastr.e(this.$t('general.unexpected_error'))
          this.updateSpinner = false
        })
    },
    academySave () {
      this.editSpinner = true
      ApiAcademies.updateAcademy(this.rAcademy)
        .then(result => {
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.waitForJob(jobId, this.updateAcademy, this.updateAcademy)
          } else {
            this.editSpinner = false
          }
        })
        .catch(err => {
          console.log(err)
          this.$toastr.e(this.$t('general.unexpected_error'))
          this.editSpinner = false
        })
    },
    updateAcademy () {
      this.updateSpinner = false
      this.editSpinner = false
      this.$toastr.s(this.$t('views.academies.updated'))
    },
    updatedAcademy () {
      this.$emit('updated')
      this.updateSpinner = false
      this.editSpinner = false
    }
  }
}
</script>

<style scoped lang="scss">
    @import '../assets/stylesheets/vars';
    .name {
        color: $pcg-gray;
        font-weight: 400;
    }

    .locked {
      color: $pcg-gray;
      font-weight: 400;
    }

    .my-input {
        /deep/ .pcg-input {
            height: calc(1.5rem + 0.75rem + 2px)
        }
        /deep/ .pcg-input-label {
            margin-bottom: 0.25rem;
            color: $pcg-gray;
        }
    }

    .clicked {
        cursor: pointer;
        font-weight: 400;
    }

    .confirm-header {
        font-size: $font-size-m;
        font-weight: 400;
        color: $pcg-gray;
        margin-bottom: 1.5rem;
    }

    .confirm-text {
        font-weight: 400;
        color: $pcg-gray;
        margin-bottom: 1rem;
    }

    .academy-icon {
      color: $main-active-color;
    }

    /deep/ .tooltip-inner {
      background: $pcg-the-lightest-gray-background;
      color: $pcg-gray;
      font-weight: 400;
      padding: .5rem;
      border-color: $pcg-light-gray-background;
      border-style: solid;
      border-width: 1px;
      opacity: 1;
    }

    /deep/ .arrow::before {
      border-top-color: $pcg-light-gray-background !important;
    }

    .buttons-icon {
      color: $main-active-color;
      margin-right: 2rem;
      @media (max-width: 1100px) {
        margin-right: 1rem;
      }
    }

    .text-active {
      color: $main-active-color;
    }

  .institution-icon {
    padding: 12px;
    border-radius: 50%;
    background-color: #ecf9fd;
  }

  .button-element {
    position: relative;
    bottom: .7rem;
  }
</style>

<style lang="scss" scoped>
    @import "../assets/stylesheets/vars-contrast";
    .contrast {
        .name {
            color: $pcg-gray;
        }
      .locked {
        color: $pcg-gray;
      }
        .my-input {
            /deep/ .pcg-input-label {
                color: $pcg-gray;
            }
        }
        .confirm-header {
            color: $pcg-gray;
        }
        .confirm-text {
            color: $pcg-gray;
        }
      .academy-icon {
        color: $main-active-color;
      }
      /deep/ .tooltip-inner {
        background: $pcg-the-lightest-gray;
        color: $pcg-gray;
        border-color: $pcg-light-gray;
      }
      /deep/ .arrow::before {
        border-top-color: $pcg-light-gray !important;
      }
      .buttons-icon {
        color: $main-active-color;
      }
      .text-active {
        color: $main-active-color;
      }
      .institution-icon {
        background-color: #003204;
      }
    }
</style>
