<template>
  <div>
    <div class="d-flex justify-content-between" style="margin-bottom: 2.5rem;">
      <div>
        <i class="fa fa-user fa-lg my-user"></i>
        <span class="identities-title">{{ $t('views.employee.identities') }}</span>
      </div>
      <div>
        <i class="pcg-icon-cross my-cross" @click="escIdentities"></i>
      </div>
    </div>
    <b-row style="margin-bottom: 1rem">
      <b-col cols="4">
        <pcg-text-input
          :label="$t('views.employee.academic_title')"
          :showLabel="true"
          v-model="academicTitle"
          class="my-input"
        />
      </b-col>
    </b-row>
    <div class="one-identity" v-for="identity in identities" :key="identity.id">
      <b-row>
        <b-col cols="5">
          <div class="identity-name">{{ identity.firstName }} {{ identity.lastName }}</div>
        </b-col>
        <b-col cols="2">
          <pcg-checkbox
            v-model="identity.active"
            @input="changeActive(identity)"
            :class="{'my-checkbox': !identity.active, 'my-checkbox-alternative': identity.active}"
            :disabled="identity.preferred"
          >{{ $t('views.employee.active') }}</pcg-checkbox>
        </b-col>
        <b-col cols="2">
          <pcg-radio
            name="preferredIdentity"
            v-model="preferredIdentity"
            :options="preferredOptions(identity.id)"
            :show-label="false"
            @input="setPreferred(identity)"
            :class="{'my-radio': preferredIdentity !== identity.id.toString(), 'my-radio-alternative': preferredIdentity === identity.id.toString()}"
          />
        </b-col>
        <b-col cols="2">
        </b-col>
        <b-col v-if="identities.length > 1" cols="1">
          <pcg-btn class="remove-btn" @click="removeIdentity(identity.id, true)" round :icon="removeIcon" :text="$t('views.employee.remove_identity')" variant="danger"></pcg-btn>
        </b-col>
      </b-row>
      <div class="my-border"></div>
    </div>
    <b-row v-for="error in errors" :key="error.id">
      <b-col>
        <img src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
        <span class="my-error">{{ error }}</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="4">
        <pcg-text-input
          :label="$t('views.employee.name')"
          :showLabel="true"
          v-model="newIdentity.name"
          class="my-input"
        />
      </b-col>
      <b-col cols="4">
        <pcg-text-input
          :label="$t('views.employee.surname')"
          :showLabel="true"
          v-model="newIdentity.surname"
          class="my-input"
        />
      </b-col>
      <b-col cols="4">
        <pcg-btn size="small" style="position: relative; top: 1.4rem;" @click="addEmployeeIdentity(employee.id, newIdentity, academicTitle, true)">+ {{ $t('views.employee.add_name') }}</pcg-btn>
      </b-col>
    </b-row>
    <div class="my-border" style="margin-bottom: 1rem;"></div>
    <b-row class="d-flex justify-content-center">
      <pcg-btn @click="createEmployeeIdentity(employee.id, newIdentity, academicTitle, true)">+ {{ $t('views.employee.save_and_close') }}</pcg-btn>
    </b-row>
  </div>
</template>

<script>
import ApiEmployees from '../api/employees'
import ApiEmployeeIdentities from '../api/employee_identities'

export default {
  name: 'DnIdentity',
  props: {
    employee: Object
  },
  data: function () {
    return {
      identities: [],
      academicTitle: null,
      newIdentity: {
        name: '',
        surname: ''
      },
      preferredIdentity: '0',
      errors: [],
      removeIcon: {
        type: 'component',
        value: 'icon-remove',
        width: '11',
        height: '13',
        color: '#00B4EB',
        hoverColor: '#a90000'
      }
    }
  },
  created () {
    ApiEmployees.getIdentities(this.employee.id)
      .then((result) => {
        this.identities = result.data.identities
        if (this.identities.length > 0) {
          for (let i = 0; i < this.identities.length; i++) {
            if (this.identities[i].preferred) {
              this.identities[i].active = true
              this.preferredIdentity = this.identities[i].id.toString()
            }
          }
        }
      })
    this.academicTitle = this.employee.title
  },
  methods: {
    escIdentities () {
      this.$emit('escIdentities')
    },
    changeActive (identity) {
      if (!identity.preferred) {
        return ApiEmployeeIdentities.changeActive(identity)
      }
    },
    changePreferred (identity) {
      return ApiEmployeeIdentities.changePreferred(identity)
    },
    removeIdentity (id, userClicked = false) {
      ApiEmployeeIdentities.removeIdentity(id, userClicked)
        .then((result) => {
          ApiEmployees.getIdentities(this.employee.id)
            .then((result) => {
              this.identities = result.data.identities
              this.setPreferred()
            })
        })
    },
    addEmployeeIdentity (employeeId, form, userClicked = false) {
      this.errors = []
      ApiEmployeeIdentities.createEmployeeIdentity(employeeId, form, this.academicTitle, userClicked)
        .then(result => {
          ApiEmployees.getIdentities(this.employee.id)
            .then((result) => {
              this.identities = result.data.identities
              this.newIdentity.name = ''
              this.newIdentity.surname = ''
            })
        })
        .catch(error => {
          this.errors = error.response.data
        })
    },
    createEmployeeIdentity (employeeId, form, userClicked = false) {
      this.errors = []
      ApiEmployeeIdentities.createEmployeeIdentity(employeeId, form, this.academicTitle, userClicked)
        .then(result => {
          ApiEmployees.getIdentities(this.employee.id)
            .then((result) => {
              this.identities = result.data.identities
              this.$emit('escIdentities')
            })
        })
        .catch(error => {
          this.errors = error.response.data
        })
    },
    preferredOptions (id) {
      let radioOptions = []
      radioOptions = [{ text: this.$t('views.employee.preferred'), value: id.toString() }]
      return radioOptions
    },
    setPreferred (identity = null) {
      if (this.identities.length > 0) {
        if (identity !== null) {
          this.changePreferred(identity)
          for (let i = 0; i < this.identities.length; i++) {
            if (this.identities[i].preferred) {
              this.identities[i].preferred = false
            }
          }
          identity.preferred = true
          if (!identity.active) {
            identity.active = true
          }
          for (let i = 0; i < this.identities.length; i++) {
            if (this.identities[i].preferred) {
              this.preferredIdentity = this.identities[i].id.toString()
            }
          }
        } else {
          for (let i = 0; i < this.identities.length; i++) {
            if (this.identities[i].preferred) {
              this.preferredIdentity = this.identities[i].id.toString()
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .identities {
    color: $main-active-color;
    font-weight: 600;
    font-size: $font-size-l;
    cursor: pointer;
  }

  .my-user {
    color: $main-color;
    margin-right: .3rem;
  }

  .identities-title {
    color: $main-color;
    font-weight: 600;
    font-size: $font-size-m;
    text-transform: uppercase;
  }

  .my-cross {
    color: $pcg-gray;
    cursor: pointer;
  }

  .remove-btn {
    position: relative;
    bottom: .6rem;
  }

  .one-identity {
    margin-bottom: 2rem;
  }

  .my-border {
    border-bottom: 1px solid $pcg-lightest-gray-background;
    margin-left: -30px;
    margin-right: -30px;
  }

  .identity-name {
    margin-bottom: 2rem;
    color: $pcg-gray;
    font-weight: 400;
  }

  .my-input {
    /deep/ .pcg-input {
      height: calc(1.5rem + 0.75rem + 2px)
    }
  }

  .my-error {
    color: $pcg-red;
    font-weight: 400;
    margin-left: .3rem;
    position: relative;
    bottom: .1rem;
  }

  .my-exclamation-mark {
    height: 14px;
    width: 14px;
    padding: .2rem .2rem;
    border-radius: 20px;
    text-align: center;
    background-color: $pcg-red;
    opacity: 0.8;
    position: relative;
    bottom: .2rem;
  }

  .my-checkbox {
    /deep/ .checkbox-text {
      color: $pcg-gray;
      font-weight: 600;
    }
  }

  .my-checkbox-alternative {
    /deep/ .checkbox-text {
      color: $main-active-color;
      font-weight: 600;
    }
  }

  .my-radio {
    /deep/ .pcg-radio-container {
      color: $pcg-gray;
      font-weight: 600;
    }
  }

  .my-radio-alternative {
    /deep/ .pcg-radio-container {
      color: $main-active-color;
      font-weight: 600;
    }
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .identities {
      color: $main-active-color;
    }
    .my-user {
      color: $main-color;
    }
    .identities-title {
      color: $main-color;
    }
    .my-cross {
      color: $pcg-gray;
    }
    .my-border {
      border-bottom: 1px solid $pcg-lightest-gray;
    }
    .identity-name {
      color: $pcg-gray;
    }
    .my-error {
      color: $pcg-red;
    }
    .my-exclamation-mark {
      background-color: $pcg-red;
    }
    .my-checkbox {
      /deep/ .checkbox-text {
        color: $pcg-gray;
      }
    }
    .my-checkbox-alternative {
      /deep/ .checkbox-text {
        color: $main-active-color;
      }
    }
    .my-radio {
      /deep/ .pcg-radio-container {
        color: $pcg-gray;
      }
    }
    .my-radio-alternative {
      /deep/ .pcg-radio-container {
        color: $main-active-color;
      }
    }
  }
</style>
