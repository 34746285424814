<template>
  <div>
    <h2 class="title">{{ $t('views.import_pbn_api.import_publications') }}</h2>
    <b-row class="d-flex align-items-center">
      <pcg-checkbox class="col-12"
                    v-model="withDisciplines">
        {{$t('views.import_pbn_api.with_discipline')}}
      </pcg-checkbox>
      <pcg-checkbox class="col-12"
                    v-model="addEmployee">
        {{$t('views.xml_files.add_employee')}}
      </pcg-checkbox>
      <pcg-checkbox class="col-12"
                    v-model="calcPoints">
        {{$t('views.xml_files.calc_points')}}
      </pcg-checkbox>
      <pcg-checkbox class="col-12"
                    v-model="withFees">
        {{$t('views.xml_files.with_fees')}}
      </pcg-checkbox>
      <b-col cols="12">
        <div class="available-in" style="position: relative; top: .4rem;">
          <label style="position: relative; bottom: .1rem;">{{ this.$t('views.import_polon.import_mode') }}</label>
        </div>
        <pcg-select
            class="my-select-short"
            style="display: inline-block"
            :options="tDictionary(updateModes)"
            :default-option="{ text: $t('general.select'), id: 'only_new' }"
            v-model="updateMode"
        />
      </b-col>
    </b-row>
    <b-row class="d-flex align-items-center" style="margin-bottom: 3rem">
      <b-col cols="4" style="margin-right: .5rem">
        <pcg-input-number class="my-input" v-model="yearFrom" :label="$t('views.import_pbn_api.year_from')" :showLabel="true"/>
      </b-col>
      <b-col cols="4" style="margin-right: .5rem">
        <pcg-input-number class="my-input" v-model="yearTo" :label="$t('views.import_pbn_api.year_to')" :showLabel="true"/>
      </b-col>
      <pcg-btn size="small" class="import-btn" @click="importPublication" :disabled="publicationSpinner">
        <span>{{ this.$t('views.import_pbn_api.import') }}</span>
        <spinner v-if="publicationSpinner"/>
        <span v-if="cableStatusLive">({{ cableStatusLive }})</span>
      </pcg-btn>
    </b-row>
    <b-row>
      <b-col>
        <pcg-text-input v-model="pbnId" :label="$t('views.import_pbn_api.pbn_id')" :showLabel="true"/>
      </b-col>
    </b-row>
    <h2 style="margin-top: 5rem;" class="title">{{ $t('views.import_pbn_api.import_fees') }}</h2>
    <b-row class="d-flex align-items-center" style="margin-bottom: 3rem">
      <b-col cols="4" style="margin-right: .5rem">
        <pcg-input-number class="my-input" v-model="feesYearFrom" :label="$t('views.import_pbn_api.year_from')" :showLabel="true"/>
      </b-col>
      <b-col cols="4" style="margin-right: .5rem">
        <pcg-input-number class="my-input" v-model="feesYearTo" :label="$t('views.import_pbn_api.year_to')" :showLabel="true"/>
      </b-col>
      <pcg-btn size="small" class="import-btn" @click="importFees" :disabled="publicationSpinner">
        <span>{{ this.$t('views.import_pbn_api.import') }}</span>
        <spinner v-if="publicationSpinner"/>
      </pcg-btn>
    </b-row>
  </div>
</template>

<script>
import ApiPbn from '../../api/polon'
import setTitle from '../../mixins/set_title'
import waitForJobs from '../../mixins/wait_for_jobs'
import Spinner from '../../components/Spinner'
import { mapGetters } from 'vuex'
import translateDictionary from '../../mixins/translate_dictionary'

export default {
  name: 'ImportPbnApi',
  mixins: [translateDictionary, setTitle, waitForJobs],
  components: { Spinner },
  channels: {
    ChatChannel: {
      connected () {
        console.log('connected')
      },
      rejected () {
        console.log('rejected')
      },
      received (data) {
        console.log(data)
        this.cableStatus = data.body
      },
      disconnected () {
        console.log('dc')
      }
    }
  },
  data () {
    return {
      headerTitle: 'views.import_pbn_api.title',
      headerSubtitle: '',
      yearFrom: null,
      yearTo: null,
      cableStatus: null,
      publicationSpinner: false,
      withDisciplines: false,
      addEmployee: true,
      calcPoints: false,
      withFees: false,
      updateMode: 'only_new',
      updateModes: [
        { text: 'Tylko nowe', text_en: 'Only new', id: 'only_new' },
        { text: 'Aktualizacja', text_en: 'Update', id: 'only_update' },
        { text: 'Nowe i aktualizacja', text_en: 'New and update', id: 'new_and_update' }
      ],
      feesYearFrom: null,
      feesYearTo: null,
      currentUser: null,
      pbnId: null
    }
  },
  beforeMount () {
    if (this.$store.getters['period/years'].length === 0) {
      this.$store.dispatch('period/setInitialYears')
    }
    this.currentUser = this.$store.getters['auth/id']
  },
  computed: {
    ...mapGetters('period', ['allYears']),
    cableStatusLive () {
      return this.cableStatus
    },
    years () {
      return this.allYears
    }
  },
  watch: {
    years: {
      deep: true,
      handler: function (value) {
        this.yearFrom = Math.min(...this.years)
        this.yearTo = Math.max(...this.years)
        this.feesYearFrom = Math.min(...this.years)
        this.feesYearTo = Math.max(...this.years)
      }
    }
  },
  mounted () {
    this.$cable.subscribe({
      channel: 'ChatChannel',
      userId: this.currentUser
    })
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.$store.dispatch('header/setHeader', {
      title: this.$t(this.headerTitle),
      subtitle: this.$t(this.headerSubtitle),
      capitalizeSubtitle: false
    })
    this.yearFrom = Math.min(...this.years)
    this.yearTo = Math.max(...this.years)
    this.feesYearFrom = Math.min(...this.years)
    this.feesYearTo = Math.max(...this.years)
  },
  beforeDestroy () {
    this.$cable.unsubscribe({
      channel: 'ChatChannel',
      userId: this.currentUser
    })
  },
  methods: {
    importPublication () {
      let publicationParameters = `year_from=${this.yearFrom}&year_to=${this.yearTo}&add_employee=${this.addEmployee}`
      if (this.withDisciplines) {
        publicationParameters += '&with_disciplines=true'
      }
      if (this.calcPoints) {
        publicationParameters += '&calc_points=true'
      }
      if (this.withFees) {
        publicationParameters += '&with_fees=true'
      }
      if (this.updateMode) {
        publicationParameters += `&update_mode=${this.updateMode}`
      }
      if (this.pbnId) {
        publicationParameters += `&pbn_id=${this.pbnId}`
      }
      ApiPbn.importPbnApi(publicationParameters)
        .then((result) => {
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.publicationSpinner = true
            this.waitForJob(jobId, this.publicationSuccess, this.publicationFailure)
          }
        })
    },
    importFees () {
      const publicationParameters = `year_from=${this.feesYearFrom}&year_to=${this.feesYearTo}`
      ApiPbn.importFeesPbnApi(publicationParameters)
          .then((result) => {
            const jobId = result.data.jobId
            if (jobId !== undefined) {
              this.publicationSpinner = true
              this.waitForJob(jobId, this.publicationSuccess, this.publicationFailure)
            }
          })
    },
    publicationSuccess () {
      this.publicationParameters = ''
      this.publicationSpinner = false
      this.$toastr.s(this.$t('general.success'))
    },
    publicationFailure () {
      this.publicationSpinner = false
      this.$toastr.e(this.$t('general.error'))
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/stylesheets/vars';

  .import-btn {
    position: relative;
    top: .3rem;
  }

  .import-text {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-m;
  }

  .my-select-short {
    margin-right: .8rem;
    margin-left: .7rem;
    width: 200px;
    position: relative;
    display: inline-block;
    /deep/ .bootstrap-select .dropdown-toggle {
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
    }
    /deep/ .filter-option-inner {
      position: relative;
      bottom: .45rem;
    }
  }

  .title {
    color: $main-color;
    font-weight: 600;
    font-size: $font-size-l;
    margin-bottom: 2rem;
  }

</style>

<style lang="scss">
@import "../../assets/stylesheets/vars-contrast";
.contrast {
  .available-in {
    color: $pcg-gray;
  }
  .title {
    color: $main-color;
  }
}
</style>
