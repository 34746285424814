import api from './index'

const resource = 'employee_publications'

export default {
  changeAlternativePoints (changes) {
    let url = resource
    url += '/change_alternative_points'
    const body = {
      employee_publication: {
        employee_id: changes.employeeId,
        publication_id: changes.publicationId,
        alternative_points: changes.alternativePoints
      }
    }
    return api.put(url, body)
  }
}
