<template>
  <pcg-box :header="header" iconClass="pcg-icon-competences header-icon">
    <b-row>
      <b-col class="col-12 col-xl-6">
        <pcg-search-select :placeholder="placeholderText"
                           :options="clearedSuggestions"
                           autocomplete="off"
                           :value="selectedTag"
                           @input="add"
                           always-active
                           allow-empty
                           :show-value-addition="showValueAddition"
                           :show-error="showError"
                           :error-text="errorText"
                           @showModalSuggestion="showModalSuggestion"
                           class="flex-grow-1 my-text-input my-input"/>
        <i class="fas fa-search my-search-icon fa-lg"></i>
      </b-col>
    </b-row>
    <div class="tags-container" v-if="value.length > 0">
      <span v-for="tag in value"
            :key="`tag-${tag.value}`"
            @click="remove(tag)"
            :class="tag.class"
            class="tag">
        {{ tag.text }}
        <span>
          ✕
        </span>
      </span>
    </div>
    <div class="tags-container pcg-no-records" v-else>
      {{ emptyText }}
    </div>
  </pcg-box>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'DnTagsSearch',
  components: {
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    options: {
      type: Array,
      default: () => []
    },
    header: {
      type: String,
      required: false
    },
    emptyText: String,
    placeholderText: String,
    buttonText: String,
    showError: Boolean,
    errorText: String,
    showValueAddition: Boolean
  },
  mixins: [clickaway],
  data: function () {
    return {
      newTag: '',
      selectedTag: ''
    }
  },
  computed: {
    clearedSuggestions () {
      const ids = this.value.map(tag => tag.value)
      return this.options.filter(tag => !ids.includes(tag.value))
    }
  },
  methods: {
    showModalSuggestion () {
      this.$emit('showModalSuggestion')
    },
    remove (removeTag) {
      this.$emit('input', this.value.filter(tag => tag.value !== removeTag.value))
      this.$emit('removed', removeTag.value)
      this.selectedTag = ''
    },
    add (addTagId) {
      const newTag = this.fetchTag(addTagId)
      if (newTag && !this.value.map(tag => tag.value).includes(newTag.value)) {
        this.$emit('added', addTagId)
        this.$emit('input', this.value.concat(newTag))
      }
      this.selectedTag = ''
    },
    fetchTag (tagId) {
      return this.options.find(option => option.value === tagId)
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .tags-container {
    padding-top: .5rem;
  }

  .tag {
    color: $main-color;
    background-color: $color-active-filter;
    margin-right: 15px;
    margin-bottom: 10px;
    display: inline-block;
    font-size: $font-size-s;
    font-weight: 500;
    line-height: 1rem;
    padding: 8px 13px;
    border-radius: 12px;
    transition: background-color 0.3s;
    &:hover {
      cursor: pointer;
      background-color: darken($color-active-filter, 10%);
      span {
        color: darken($pcg-dark-gray, 10%);
      }
    }
    span {
      color: $pcg-dark-gray;
      padding-left: 10px;
      font-size: 0.9rem;
    }
  }

  .bootstrap-select {
    position: relative;
    .dropdown-menu {
      top: -14px;
    }
  }

  .my-search-icon {
    width: 0;
    color: $pcg-gray;
    position: relative;
    right: 2.5rem;
    cursor: text;
  }

  .my-input {
    /deep/ .pcg-input {
      height: calc(1.5rem + 0.75rem + 2px)
    }
  }

  .my-text-input {
    display: inline-block;
    width: 98%;
    /deep/ .pcg-input {
      padding-right: 2.7rem;
    }
  }

  .alternative {
    color: $pcg-gray;
    font-weight: 400;
    position: relative;
    margin-bottom: 1rem;
    margin-top: 1rem;
    bottom: .5rem;
    @media (max-width: 991.98px) {
      bottom: 0;
    }
  }

  .keyword-button {
    width: 100%;
    position: relative;
    bottom: .8rem;
    @media (max-width: 991.98px) {
      width: 98%;
      right: .71rem;
    }
  }

</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .tag {
      color: $main-color;
      background-color: $color-active-filter;
      &:hover {
        background-color: darken($color-active-filter, 10%);
        span {
          color: darken($pcg-dark-gray, 10%);
        }
      }
      span {
        color: $pcg-dark-gray;
      }
    }
    .my-search-icon {
      color: $pcg-gray;
    }
    .alternative {
      color: $pcg-gray;
    }
  }
</style>
