<template>
  <div class="component-container">
    <b-row>
      <b-col class="col-12 col-lg-8">
        <dn-evaluation :evaluation="evaluation"
                       :owner="false"
                       :not_submitted="notSubmitted"
                       :no_discipline="noDiscipline"
                       :statements="statements"
        />
      </b-col>
      <b-col class="col-12 col-lg-4">
        <dn-statements :statements="statements" :publication="true" class="oneline"></dn-statements>
        <dn-orcid :orcid="scientist.orcidId" :added="true" class="oneline"></dn-orcid>
        <dn-publication-statistics :publicationStatistics="publicationStatistics"></dn-publication-statistics>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import setTitle from '../../mixins/set_title'
import ApiEmployees from '../../api/employees'
import DnEvaluation from '../../components/DnEvaluation'
import DnStatements from '../../components/DnStatements'
import DnPublicationStatistics from '../../components/DnPublicationStatistics'
import DnOrcid from '../../components/DnOrcid'

export default {
  name: 'scientist',
  components: {
    DnOrcid,
    DnPublicationStatistics,
    DnStatements,
    DnEvaluation
  },
  mixins: [setTitle],
  data: function () {
    return {
      headerTitle: 'views.scientist_dashboard.title',
      headerSubtitle: '',
      scientist: {},
      publications: [],
      evaluation: {
        estimated_result: 0,
        scoring: 0,
        slots: 0,
        disciplines: []
      },
      publicationStatistics: {},
      statements: {},
      notSubmitted: 0,
      noDiscipline: 0
    }
  },
  created () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    ApiEmployees.getEmployee(this.$route.params.id)
      .then(result => {
        this.scientist = result.data.employee
        this.publications = result.data.publications
        this.evaluation = result.data.evaluation
        this.statements = result.data.statements
        this.publicationStatistics = result.data.publicationStatistics
        this.notSubmitted = result.data.notSubmitted
        this.noDiscipline = result.data.noDiscipline
        this.$store.dispatch('header/setHeader', {
          title: this.scientist.fullName
        })
      })
  },
  mounted () {
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/stylesheets/vars';

  .component-container {
    padding-top: 20px;
  }

  .oneline {
    margin-bottom: 2.5rem;
  }
</style>
