<template>
  <div v-if="$i18n.locale === 'pl'">
    <h1 id="a11y-deklaracja-pl">Deklaracja dostępności</h1>
    <p id="a11y-wstep-pl" class="declaration-text">
      <span id="a11y-podmiot-pl">Uniwersytet Warszawski</span> zobowiązuje się zapewnić dostępność swojej strony internetowej zgodnie z przepisami ustawy z dnia 4 kwietnia 2019 r.
      o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych. Oświadczenie w sprawie dostępności ma zastosowanie do strony internetowej
      <a id="a11y-url-pl" href="http://sc.uw.edu.pl"><strong>sc.uw.edu.pl</strong></a>
    </p>
    <ul class="declaration-text">
      <li>Data publikacji strony internetowej: <span id="a11y-data-publikacja-pl"><strong>2021-12-12</strong></span>.</li>
      <li>Data ostatniej istotnej aktualizacji: <span id="a11y-data-aktualizacja-pl"><strong>2021-12-12 </strong></span>.</li>
    </ul>

    <h2>Status zgodności z ustawą o dostępności cyfrowej</h2>

    <div id="a11y-status-pl" class="declaration-text">
      <p>Strona internetowa jest częściowo zgodna z ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych
        podmiotów publicznych z powodu niezgodności lub wyłączeń wymienionych poniżej.</p>

    </div>
    <ul class="declaration-text">
      <li>Kotwice (linki typu skoczki w obrębie strony)</li>
      <ul>
        <li>Serwis nie posiada odnośników umożliwiających bezpośrednie przejście do treści stron.</li>
      </ul>
      <li>Linki</li>
      <ul>
        <li>Serwis posiada odnośniki, których treścią jest adres URL.</li>
      </ul>
      <li>Definicja języka/języków strony</li>
      <ul>
        <li>Na stronach serwisu nie określono znacznika języka.</li>
      </ul>
      <li>Nagłówki</li>
      <ul>
        <li>Strony serwisu posiadają tytuły nie oznaczone znacznikami nagłówków.</li>
      </ul>
      <li>Alternatywne opisy dla grafiki</li>
      <ul>
        <li>Serwis posiada ilustracje bez tekstów alternatywnych.</li>
      </ul>
      <li>Formularze</li>
      <ul>
        <li>Serwis posiada pola formularza bez etykiet lub tekstu alternatywnego.</li>
      </ul>
      <li>Obsługa błędów formularza</li>
      <ul>
        <li>W serwisie występują formularze z problemami dot. właściwej sygnalizacji błędnego wypełnienia danych.</li>
      </ul>
      <li>Pozostałe elementy sterujące strony</li>
      <ul>
        <li>Elementy rozwijalne menu głównego nie są dostępne za pomocą klawiatury.</li>
        <li>Serwis posiada elementy sterujące nie dostępne za pomocą klawiatury.</li>
        <li>W serwisie występują przyciski bez etykiety tekstowej.</li>
      </ul>
      <li>Elementy klikalne</li>
      <ul>
        <li>Serwis posiada listy rozwijane, które nie są obsługiwane za pomocą klawiatury.</li>
        <li>W serwisie występują elementy klikalne bez dostępnej nazwy lub nazwa jest niewystarczająca.</li>
        <li>Serwis zawiera elementy rozwijalne, które nie informują o stanie.</li>
      </ul>
      <li>LandMarki</li>
      <ul>
        <li>Serwis nie posiada zdefiniowanych obszarów tzw. landmarków.</li>
      </ul>
      <li>Automatyczne odświeżanie się strony lub jej części</li>
      <ul>
        <li>Serwis może automatycznie przeładować stronę bez informowania użytkownika.</li>
      </ul>
      <li>Inne zauważone problemy związane z dostępnością dla Screen Readera (opis i wskazanie miejsca)</li>
      <ul>
        <li>Czytnik ekranu nie jest informowany o przeładowaniu się treści na stronie.</li>
      </ul>
      <li>Kontrasty i kolorystyka strony</li>
      <ul>
        <li>Serwis posiada elementy niespełniające wymogów kontrastu.</li>
        <li>Zaimplementowany tryb kontrastowy, zawiera elementy niespełniające wymogów kontrastu.</li>
        <li>Serwis posiada odnośniki wyróżnione tylko kolorem, które nie spełniają wymogów kontrastu.</li>
      </ul>
      <li>Widoczność fokusa klawiatury</li>
      <ul>
        <li>Serwis może ukrywać widoczność fokusa klawiatury.</li>
        <li>Strony serwisu zawiera elementy aktywne ukrywające fokus klawiatury.</li>
      </ul>
      <li>Wyróżnienie linków i innych istotnych elementów strony</li>
      <ul>
        <li>Serwis posiada odnośniki wyróżnione tylko kolorem, które nie spełniają wymogów kontrastu.</li>
      </ul>
      <li>Logiczna kolejność fokusa klawiatury</li>
      <ul>
        <li>Serwis pomija położenie fokusa dla niektórych elementów menu.</li>
        <li>Serwis pomija położenie fokusa klawiatury dla niektórych elementów klikalnych.</li>
      </ul>
      <li>Walidacja html/css</li>
      <ul>
        <li>W serwisie występują problemy z walidacją HTML.</li>
      </ul>
      <li>Konsekwentna identyfikacja elementów strony</li>
      <ul>
        <li>Serwis posiada elementy wyróżnione cechami wyglądu i nie oznaczone właściwymi znacznikami.</li>
      </ul>
    </ul>
    <p class="declaration-text">
      Oświadczenie sporządzono dnia: <span id="a11y-data-sporzadzenie-pl"><strong>2022-02-10</strong></span>.
    </p>
    <p class="declaration-text">
      Deklarację sporządzono na podstawie samooceny przeprowadzonej przez podmiot publiczny: <span id=" a11y-audytor-pl">
      <strong><a href="https://bon.uw.edu.pl">Biuro ds. Osób z Niepełnosprawnościami UW</a></strong></span>.
    </p>
    <p class="declaration-text">Wyniki przeprowadzonej oceny zapewnienia dostępności cyfrowej strony internetowej: <strong>
      <a href="https://audyt.bon.uw.edu.pl/szczegoly-audytu/361?token=lgBBHFXtHlQuBiPA4PFeQVduZZ8CMtA8" target="_blank">Raport badania audytowego</a></strong></p>
    <h2>Skróty klawiaturowe</h2>

    <p class="declaration-text">Na stronie <strong><a href=http://sc.uw.edu.pl>sc.uw.edu.pl</a></strong> nie zastosowano żadnych specjalnych skrótów klawiaturowych.</p>

    <h2 id="a11y-kontakt-pl">Informacje zwrotne i dane kontaktowe</h2>

    <p class="declaration-text">Osobą kontaktową w sprawach związanych z dostępnością tej strony internetowej jest <span id="a11y-osoba-pl"><strong>Agata Zarzycka</strong></span>.
      W przypadku problemów z dostępnością strony internetowej prosimy o kontakt: e-mail: <span id="a11y-email-pl"><strong>agata.zarzycka@adm.uw.edu.pl</strong></span>, telefon <span id="a11y-telefon-pl"><strong>22 55 22 044</strong></span>.
      Tą samą drogą można składać wnioski o udostępnienie informacji niedostępnej oraz składać żądania zapewnienia dostępności.</p>

    <h2 id="a11y-procedura-pl">Procedura wnioskowo-skargowa</h2>

    <p class="declaration-text">Każdy ma prawo do wystąpienia z żądaniem zapewnienia dostępności cyfrowej strony internetowej, aplikacji mobilnej lub jakiegoś ich elementu.
      Można także zażądać udostępnienia informacji za pomocą alternatywnego sposobu dostępu, na przykład przez odczytanie niedostępnego cyfrowo dokumentu,
      opisanie zawartości filmu bez audiodeskrypcji itp.</p>

    <p class="declaration-text">Żądanie powinno zawierać dane osoby zgłaszającej żądanie, wskazanie, o którą stronę internetową lub aplikację mobilną chodzi oraz sposób kontaktu.
      Jeżeli osoba żądająca zgłasza potrzebę otrzymania informacji za pomocą alternatywnego sposobu dostępu, powinna także określić dogodny dla niej sposób
      przedstawienia tej informacji.</p>

    <p class="declaration-text">Podmiot publiczny powinien zrealizować żądanie niezwłocznie, nie później niż w ciągu 7 dni od dnia wystąpienia z żądaniem.
      Jeżeli dotrzymanie tego terminu nie jest możliwe, podmiot publiczny niezwłocznie informuje o tym wnoszącego żądanie, kiedy realizacja żądania będzie możliwa,
      przy czym termin ten nie może być dłuższy niż 2 miesiące od dnia wystąpienia z żądaniem.</p>

    <p class="declaration-text">Jeżeli zapewnienie dostępności cyfrowej nie jest możliwe, podmiot publiczny może zaproponować alternatywny sposób dostępu do informacji.
      W przypadku, gdy podmiot publiczny odmówi realizacji żądania zapewnienia dostępności lub alternatywnego sposobu dostępu do informacji,
      wnoszący żądanie może złożyć skargę w sprawie zapewniana dostępności cyfrowej strony internetowej, aplikacji mobilnej lub elementu strony internetowej,
      lub aplikacji mobilnej. Po wyczerpaniu wskazanej wyżej procedury można także złożyć wniosek do
      <a href="https://www.rpo.gov.pl"><strong>Rzecznika Praw Obywatelskich.</strong></a></p>

    <h2 id="a11y-architektura-pl">Dostępność architektoniczna</h2>
    <p class="declaration-text">Pełen wykaz budynków należących do UW znajduje się na <strong><a href="http://uw.edu.pl/deklaracja-dostepnosci">stronie deklaracji dostępności UW</a></strong>.</p>
    <p class="declaration-text">Jednostka Prowadząca deklaruje dostępność architektoniczną budynku: <strong>Budynek D. CIUW, Krakowskie Przedmieście 26/28, 00-927 Warszawa</strong></p>

    <p class="declaration-text">Kierownik / Dyrektor administracyjny: <strong>Anna Kwaśniewska-Twardziak</strong>
    <p class="declaration-text">Telefon: <strong>55 22 24 024 lub 602 254 901</strong></p>
    <p class="declaration-text">Email: <strong>anna.kwasniewska-twardziak@adm.uw.edu.pl</strong></p>
    <h3>Wejście do budynku</h3>
    <p class="declaration-text">Przed wejściem do budynku są dwa stopnie. Jest podjazd - metalowa pochylnia dla osób niepełnosprawnych. Dzwonek przy pochylni na wysokości 110 cm.
    Drzwi o szerokości 94 cm z możliwością otwarcia drugiego skrzydła (w sumie szer. 188 cm).</p>
    <h3>Korytarze i przejścia</h3>
    <p class="declaration-text">Wewnątrz w przedsionku 5 stopni oraz winda - platforma dla niepełnosprawnych. Kolejny dzwonek przy platformie na wys. 120 cm.
    Obsługa platformy przez przeszkoloną osobę z portierni. Platforma wymiary podstawy 72x72 cm, co przy dużych wózkach stanowi problem (z trudem się mieszczą).
    Dalej na parterze korytarze płaskie bez progów. Dostęp do dziekanatu WPiA. Toaleta dla niepełnosprawnych. Winda na pozostałe piętra. Szerokość drzwi w windzie 87 cm.
    Wewnątrz jest komunikacja z portiernią. Na III piętrze są sale WDIiB. Szerokość drzwi do sal 90 cm, klamki na wys 110 cm, toaleta dla niepełnosprawnych.</p>
    <h3>Procedury</h3>
    <p class="declaration-text">Pomoc przy obsłudze platformy dla osób niepełnosprawnych. W portierni znajduje się klucz do uruchomienia  platformy. Portiernia czynna całodobowo.</p>
    <h3>Dodatkowa pomoc</h3>
    <p class="declaration-text">Kontakt z portiernią.</p>
    <h3>Łazienki, toalety, szatnie</h3>
    <p class="declaration-text">Szatnia znajduje się w portierni na poziomie parteru. Dwie toalety dla niepełnosprawnych: na parterze i na III piętrze.</p>
    <h3>Dodatkowe dostosowania</h3>
    <p class="declaration-text">Brak podpisów brajlowskich na budynku i przy salach. Brak informacji głosowej w windzie. Brak pętli indukcyjnych w salach.</p>
    <h3>Dojazd i miejsca parkingowe</h3>
    <p class="declaration-text">Komunikacja miejska: metro do stacji Nowy Świat Uniwersytet, autobusy przystanek Uniwersytet na Krakowskim Przedmieściu, korzystanie z miejsc parkingowych
    na terenie Uniwersytetu po wcześniejszym uzyskaniu przepustki wjazdowej.</p>
    <h3>Prawo wstępu z psem asystującym</h3>
    <p class="declaration-text">Nie ma ograniczeń wstępu z psem asystującym.</p>
    <h3>Tłumacz PJM</h3>
    <p class="declaration-text">Potrzebę skorzystania z pomocy tłumacza PJM należy składać do Biura ds. Osób Niepełnosprawnych UW z co najmniej 3 dniowym wyprzedzeniem.
    Kontakt do BON: /22/ 55 24 22, bon@uw.edu.pl, www.bon.uw.edu.pl.</p>
  </div>
  <div v-else>
    <h1 id="a11y-deklaracja">Accessibility statement</h1>
    <p id="a11y-wstep" class="declaration-text">
      <span id="a11y-podmiot">UW</span> is committed to making its website accessible, in accordance with Act of 4 April 2019 on digital accessibility of websites and
      mobile applications operated by public sector bodies. This accessibility statement applies to <a id="a11y-url" href="http://sc.uw.edu.pl"><strong>sc.uw.edu.pl</strong></a>
    </p>
    <ul class="declaration-text">
      <li>Date of website publication: <span id="a11y-data-publikacja"><strong>2021-12-12</strong></span>.</li>
      <li>Last major update: <span id="a11y-data-aktualizacja"><strong>2021-12-12 </strong></span>.</li>
    </ul>

    <h2>Compliance status with Act on digital accessibility</h2>

    <div id="a11y-status" class="declaration-text">
      <p>This website is partially compliant with Act of 4 April 2019 on digital accessibility of websites and mobile applications operated by
        public sector bodies due to the non-compliances or the exemptions listed below:</p>
    </div>
    <ul class="declaration-text">
      <li>Anchors (in page links)</li>
      <ul>
        <li>The site does not contain skip links.</li>
      </ul>
      <li>Links</li>
      <ul>
        <li>The site contains links with URL address as their text.</li>
      </ul>
      <li>Language attribute for page or passage.</li>
      <ul>
        <li>Pages of the site do not contain language attribute.</li>
      </ul>
      <li>Headings</li>
      <ul>
        <li>Titles of the pages are not in H1 headings.</li>
      </ul>
      <li>Alternative text for images</li>
      <ul>
        <li>The site contains images without alternative text.</li>
      </ul>
      <li>Forms</li>
      <ul>
        <li>The site contains form fields with missing labels or with no alternative text.</li>
      </ul>
      <li>Form fields</li>
      <ul>
        <li>Form fields have no labels or labels have been incorrectly related to fields.</li>
      </ul>
      <li>Handling of form errors</li>
      <ul>
        <li>The site contains forms that experience problems with appropriate presentation of error messages.</li>
      </ul>
      <li>Other page controls</li>
      <ul>
        <li>The drop-down main menu items are not accessible through a keyboard.</li>
        <li>The site contains controls that are not accessible through a keyboard.</li>
        <li>The site contains buttons with no text labels.</li>
      </ul>
      <li>Clickable items</li>
      <ul>
        <li>The site contains drop-down lists which cannot be operated through a keyboard.</li>
        <li>The site contains clickable items that are not accessible through a keyboard.</li>
      </ul>
      <li>Landmarks</li>
      <ul>
        <li>The site does not contain landmarks.</li>
      </ul>
      <li>Contrasts and colours on the site</li>
      <ul>
        <li>The site contains links that are distinguishable only by colour, which do not meet contrast requirements.</li>
      </ul>
      <li>Keyboard Focus visibility</li>
      <ul>
        <li>The site may hide keyboard focus visibility.</li>
        <li>Pages of the site contain active elements that hide keyboard focus.</li>
      </ul>
      <li>Highlighting links and other important elements on the site</li>
      <ul>
        <li>The site contains links that are distinguishable only by colour, which do not meet contrast requirements.</li>
      </ul>
      <li>Logical sequence of keyboard focus</li>
      <ul>
        <li>The site hides the location of keyboard focus.</li>
        <li>The site skips the location of focus for some menu items.</li>
      </ul>
      <li>HTML/CSS validation</li>
      <ul>
        <li>There are problems with HTML validation on the site.</li>
      </ul>
      <li>Consistent Identification of page elements</li>
      <ul>
        <li>The site contains elements that are highlighted visually but are not marked with proper attributes.</li>
      </ul>
      <li>User interface accessibility</li>
      <ul>
        <li>Interface of the site contains elements that are inaccessible or difficult to operate.</li>
      </ul>
    </ul>
    <p class="declaration-text">
      This statement was prepared on: <span id="a11y-data-sporzadzenie"><strong>2022-02-21</strong></span>.
    </p>
    <p class="declaration-text">
      The statement was based on the self-assessment done by the public sector body: <span id=" a11y-audytor"><strong><a href="https://bon.uw.edu.pl">Office for Persons with Disabilities (BON), UW.</a></strong></span>.
    </p>

    <h2>Keyboard shortcuts</h2>

    <p class="declaration-text">No keyboard shortcuts have been specified on the <strong><a href=http://sc.uw.edu.pl>sc.uw.edu.pl</a></strong> website.</p>

    <h2 id="a11y-kontakt">Feedback and contact information</h2>

    <p class="declaration-text">The contact person for the accessibility of this website is: <span id="a11y-osoba"><strong>Agata Zarzycka</strong></span>.
      If you experience problems with accessibility of this website, contact with us by sending an e-mail to:
      <span id="a11y-email"><strong>agata.zarzycka@adm.uw.edu.pl</strong></span>, or by calling <span id="a11y-telefon"><strong>22 55 22 044</strong></span>.
      Requests for providing accessible alternative for non-accessible content as well as other requests related to accessibility can be submitted by the same channels.</p>

    <h2 id="a11y-procedura">Complaints procedure</h2>

    <p class="declaration-text">Everyone has the right to request digital accessibility to the website, mobile application or any elements thereof.
      You can also request information to be made available using an alternative means of access, for example by reading out a digitally inaccessible document,
      describing the content of a video without audio description, etc.</p>

    <p class="declaration-text">The request should contain the particulars of the person filing the request, indication of the website or mobile application it
      concerns and contact information. If the requesting person reports the need to receive information by means of an alternative method of access,
      they should also specify a convenient mode of presenting such information to them.</p>

    <p class="declaration-text">The public entity should promptly satisfy the request, not later than within 7 days from the date the request was filed.
      If meeting this deadline is not possible, the public entity will promptly notify the person filing the request about the time when the request can be satisfied,
      however, this deadline may not exceed 2 months from the date of filing the request.</p>

    <p class="declaration-text">If the provision of digital accessibility is not possible, the public entity may offer an alternative access to the information.
      If the public entity refuses to comply with the request to provide accessibility or an alternative method to access the information, the person filing the
      request may lodge a complaint regarding the provision of digital accessibility to the website, mobile application or an element of the website or mobile application.
      After exhausting the above-mentioned procedure, an application may be filed with the <a href="https://www.rpo.gov.pl/en"><strong>Commissioner for Human Rights.</strong></a></p>

    <h2 id="a11y-architektura">Architectural accessibility</h2>
    <p class="declaration-text">A list of UW buildings together with a description of their accessibility can be accessed through the UW’s accessibility statement.</p>
    <p class="declaration-text">The Leading Unit declares the architectural availability of the building:
      <strong>The old building of University IT centre, Krakowskie Przedmieście 26/28, 00-927 Warszawa</strong></p>

    <p class="declaration-text">Administrative Director: <strong>Anna Kwaśniewska-Twardziak</strong>
    <p class="declaration-text">Phone: <strong>55 22 24 024 lub 602 254 901</strong></p>
    <p class="declaration-text">Email: <strong>anna.kwasniewska-twardziak@adm.uw.edu.pl</strong></p>
    <h3>Entrance to the building</h3>
    <p class="declaration-text">Two steps in front of the entrance to the building. A metal ramp for persons with disabilities. A bell at the ramp at a height of 110 centimetres. Doors 94 centimetres
      wide with the possibility of opening a second leaf (total width 188 centimetres).</p>
    <h3>Corridors and passages</h3>
    <p class="declaration-text">Inside in the vestibule 5 steps and a lift - platform for the wheelchair users. Another bell at the platform at a height of 120 centimetres.
    Operation of the platform by a trained person from the reception desk. The platform has a base size of 72x72 centimetres - problematic for large wheelchairs (they hardly fit).
    Further on the ground floor, flat corridors without thresholds. Access to the Dean's Office of the Faculty of Arts and Sciences. Toilet for persons with disabilities.
    Lift to other floors. Door width in lift 87 centimetres. Possibility of communication with the concierge hall inside. On the third floor the halls of the Faculty of
      Journalism Information and Book Studies. Door width to the halls 90 centimetres, handles at a height of 110 centimetres, toilet for persons with disabilities</p>
    <h3>Procedures</h3>
    <p class="declaration-text">
      Help with operating the platform for persons with disabilities. A key for operating the platform at the reception desk. The concierge hall open 24 hours a day.</p>
    <h3>Extra help</h3>
    <p class="declaration-text">Contact the concierge hall.</p>
    <h3>Bathrooms, toilets, changing rooms</h3>
    <p class="declaration-text">A cloakroom with a concierge hall, on the ground floor. Two accessible restrooms: on the ground floor and on the third floor.</p>
    <h3>Additional customizations</h3>
    <p class="declaration-text">No Braille captions, no voice informations, no inductive loops in the building.</p>
    <h3>Access and parking spaces</h3>
    <p class="declaration-text">Public transport: M2 metro line to "Nowy Świat - Uniwersytet" station, buses to the stop "Uniwersytet" at Krakowskie Przedmieście Street, parking spaces on University
      premises after obtaining an entry pass.</p>
    <h3>The right to enter with an assistance dog</h3>
    <p class="declaration-text">Easy access with a guiding dog.</p>
    <h3>PJM translator</h3>
    <p class="declaration-text">If there is a need of the assistance of a Polish Sign Language interpreter, it is highly recommended to subbmit the application at University's
    Office for Persons with Disabilities at least 3 days before the actual date of the service. Contact to the OPD: telephone no.: +48 22 55 24 222,
      e-mail address: bon@uw.edu.pl, website: www.bon.uw.edu.pl.</p>
  </div>
</template>

<script>
import setTitle from '../mixins/set_title'

export default {
  name: 'accessibility-declaration',
  mixins: [setTitle],
  data () {
    return {
      headerTitle: 'views.accessibility_declaration.title',
      headerSubtitle: ''
    }
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.$store.dispatch('header/setHeader', {
      title: this.$t(this.headerTitle),
      subtitle: this.$t(this.headerSubtitle),
      capitalizeSubtitle: false
    })
  }
}
</script>

<style scoped lang="scss">
  @import '../assets/stylesheets/vars';
  h1 {
    margin-top: 3rem;
    color: $main-color;
    margin-bottom: 1.5rem;
    font-size: 2.25rem;
  }
  h2 {
    color: $main-active-color;
    margin-bottom: 1.5rem;
    margin-top: 3rem;
    font-size: 1.8rem;
  }
  h3 {
    color: $main-active-color;
    margin-bottom: 1.5rem;
    margin-top: 3rem;
    font-size: 1.575rem;
  }
  .declaration-text {
    color: $pcg-the-darkest-gray;
    font-weight: 400;
    a {
      color: unset;
      text-decoration: underline;
    }
  }

</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    h1 {
      color: $main-color;
    }
    h2 {
      color: $main-active-color;
    }
    h3 {
      color: $main-active-color;
    }
    .declaration-text {
      color: $pcg-the-darkest-gray;
    }
  }
</style>
