export default {
  methods: {
    pointsClass (points) {
      let klass = 'points'
      if (points === 0) {
        klass += ' points-0'
      } else if (points >= 0 && points < 21) {
        klass += ' points-p0'
      } else if (points >= 21 && points < 81) {
        klass += ' points-p25'
      } else if (points >= 81 && points < 141) {
        klass += ' points-p50'
      } else {
        klass += ' points-p75'
      }
      return klass
    },
    pointsWithSlotsClass (maxPoints, points) {
      const calculatedMax = maxPoints
      const firstPercentageBreakpoint = parseInt((calculatedMax * 0.25).toFixed(0))
      const secondPercentageBreakpoint = parseInt((calculatedMax * 0.50).toFixed(0))
      const thirdPercentageBreakpoint = parseInt((calculatedMax * 0.75).toFixed(0))
      const score = parseInt(points.toFixed(0))
      let klass = 'points'
      if (score === 0 || points === 0) {
        klass += ' points-0'
      } else if (score >= 0 && score <= firstPercentageBreakpoint) {
        klass += ' points-p0'
      } else if (score > firstPercentageBreakpoint && score <= secondPercentageBreakpoint) {
        klass += ' points-p25'
      } else if (score > secondPercentageBreakpoint && score <= thirdPercentageBreakpoint) {
        klass += ' points-p50'
      } else {
        klass += ' points-p75'
      }
      return klass
    }
  }
}
