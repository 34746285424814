<template>
  <div>
    <div class="user">{{ $t('components.dn_discipline_user.employee') }}</div>
    <div v-if="checkUsers(discipline.users)" class="user-info">
      <span v-for="user in discipline.users" :key="user.id" class="user-name">
        {{ user.userName }}
      </span>
      <a href="javascript:;" v-if="hasRight('edit_discipline_manager')" class="change" @click="usersEdit(discipline)">{{ $t('components.dn_field.change') }}</a>
    </div>
    <div v-else class="user-info">
      <span class="no-user">{{ $t('components.dn_field.no_manager') }}</span>
      <a href="javascript:;" v-if="hasRight('edit_discipline_manager')" class="change" @click="usersEdit(discipline)">{{ $t('components.dn_field.choose') }}</a>
    </div>
    <b-modal ref="users"
             :hide-header="true"
             v-bind:ok-title="$t('general.confirm')"
             ok-variant="primary"
             :centered="true"
             :no-close-on-esc="true"
             @ok="usersSave"
             v-bind:cancel-title="$t('general.cancel')"
             cancel-variant="default"
             @hide="cleanUsers"
             content-class="my-modal-content"
             footer-class="my-modal-footer"
    >
      <div style="margin-bottom: 2rem">
        <div class="d-flex justify-content-between" style="margin-bottom: 2rem">
          <div>
            <span class="edit-users">{{ $t('components.dn_discipline_user.edit_user') }}</span>
            <span class="question-mark">?</span>
          </div>
          <pcg-btn size="small" variant="additional" @click="addUser">{{ $t('components.dn_field.add') }}</pcg-btn>
        </div>
        <b-row v-for="error in errors" :key="error.id">
          <b-col>
            <img src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
            <span class="my-error">{{error}}</span>
          </b-col>
        </b-row>
        <transition-group name="fade" mode="out-in">
          <div v-for="(user, index) in users" :key="index + 0">
            <div class="user-line">{{ $t('components.dn_discipline_user.discipline_user') }}</div>
            <dn-discipline-user-element
                :user="user"
                @remove-user="removeUser"
            />
          </div>
        </transition-group>
      </div>
    </b-modal>
  </div>
</template>

<script>
import DnDisciplineUserElement from './DnDisciplineUserElement'
import ApiDiscipline from '../api/disciplins'
import hasRight from '../mixins/has_right'

export default {
  name: 'DnDisciplineUser',
  components: { DnDisciplineUserElement },
  mixins: [hasRight],
  props: {
    discipline: Object,
    admin: Boolean
  },
  data: function () {
    return {
      users: [],
      userSpinner: false,
      lastDiscipline: '',
      errors: []
    }
  },
  methods: {
    checkUsers (users) {
      if (users && users.length && users.length > 0) {
        return true
      } else {
        return false
      }
    },
    usersEdit (discipline) {
      this.lastDiscipline = discipline
      this.users = this.copyUsers(discipline.users)
      if (this.users.length === 0) {
        this.users.push({
          id: null,
          userName: null
        })
      }
      this.$refs.users.show()
    },
    copyUsers (users) {
      const newUsers = []
      users.forEach(a => {
        const na = {}
        for (const key in a) {
          na[key] = a[key]
        }
        newUsers.push(na)
      })
      return newUsers
    },
    usersSave () {
      for (let i = 0; i < this.users.length; i++) {
        if (!this.users[i].userId) {
          this.users[i].newId = this.users[i].id
          this.users[i].id = null
        }
      }
      if (this.validateUsers(this.users)) {
        ApiDiscipline.updateUsers(this.discipline.id, this.users)
            .then(response => {
              const users = response.data.users
              this.discipline.users = this.copyUsers(users)
              this.$refs.users.hide()
            }).catch(error => {
          this.errors = error.response.data
          this.users = this.discipline.users
          this.usersEdit(this.lastDiscipline)
        })
      }
    },
    cleanUsers () {
      this.errors = []
    },
    validateUsers (users) {
      return true
    },
    addUser () {
      this.users.push({
        id: null,
        userName: null
      })
    },
    removeUser (userId) {
      const idx = this.users.findIndex((a) => { return a.id === userId })
      this.users.splice(idx, 1)
    }
  },
  created () {
    this.roles = this.$store.getters['auth/roles']
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/stylesheets/vars';

.user {
  font-size: $font-size-s;
  font-weight: 400;
  color: $pcg-gray;
  margin-right: .2rem;
  display: inline-block;
}
.user-name {
  color: $main-color;
  font-size: $font-size-s;
  font-weight: 600;
  margin-right: 1rem;
  a {
    color: unset;
  }
}
.change {
  color: $main-active-color;
  font-size: $font-size-s;
  font-weight: 400;
  cursor: pointer;
}

.no-user {
  font-size: $font-size-s;
  font-weight: 400;
  color: $dn-orange;
  margin-right: 1rem;
}

.user-info {
  display: inline-block;
}

/deep/ .pcg-field {
  height: inherit;
  padding-bottom: 0;
  padding-top: 0;
  line-height: unset;
  color: $main-color;
  font-weight: 400;
}

/deep/ .form-group {
  display: inline-block;
  width: 85%;
  margin: 0 5px;
}

/deep/ .btn-default {
  color: $main-active-color
}

/deep/ .btn-primary {
  border-radius: 30px;
  background-color: $main-active-color;
  padding: .2rem 2rem;
  border: none;
}

/deep/ .modal-title {
  color: $pcg-gray;
  font-weight: 400;
  font-size: $font-size-l;
}

/deep/ .pcg-input:focus {
  color: $pcg-gray;
}

/deep/ .check-list {
  color: $pcg-gray;
  font-weight: 400 !important;
  margin-left: .3rem;

  ul {
    font-size: $font-size-s !important;
  }

  li {
    margin-bottom: .3rem !important;
  }

  li:last-of-type {
    margin-bottom: 0 !important;
  }
}

.edit-users {
  font-size: $font-size-m;
  font-weight: 400;
  color: $pcg-gray;
  margin-right: .5rem;
}

.user-line {
  font-weight: 400;
  font-size: $font-size-s;
  color: $pcg-gray;
  margin-left: .6rem;
  margin-bottom: .2rem;
}

.question-mark {
  font-size: $font-size-m;
  color: $pcg-white;
  background: $pcg-light-gray-background;
  font-weight: 400;
  padding: .05rem .45rem;
  border-radius: 50%;
}

</style>

<style lang="scss" scoped>
@import "../assets/stylesheets/vars-contrast";
.contrast {
  .user {
    color: $pcg-gray;
  }
  .user-name {
    color: $main-color;
  }
  .change {
    color: $main-active-color;
  }
  .no-user {
    color: $dn-orange;
  }
  /deep/ .pcg-field {
    color: $main-color;
  }
  /deep/ .pcg-field {
    color: $main-color;
  }
  /deep/ .btn-default {
    color: $main-active-color
  }
  /deep/ .btn-primary {
    background-color: $main-active-color;
  }
  /deep/ .modal-title {
    color: $pcg-gray;
  }
  /deep/ .pcg-input:focus {
    color: $pcg-gray;
  }
  /deep/ .check-list {
    color: $pcg-gray;
  }
  .edit-users {
    color: $pcg-gray;
  }
  .user-line {
    color: $pcg-gray;
  }
  .question-mark {
    color: $pcg-white;
    background: $pcg-light-gray;
  }
}
</style>
