<template>
  <div class="container">
    <b-row class="top-bar">
      <div class="ml-auto">
        <pcg-btn class="ml-aut" size="small"
                 @click="$router.push({
               name: 'dictionary_acceptance_words_new_path',
               params: { dictionaryId: dictionaryId } })">
          + {{$t('views.dictionary_words.index.add')}}
        </pcg-btn>
      </div>
    </b-row>
    <pcg-table :items="dictionaryWords" :fields="fields">
      <template v-slot:header>{{$t('views.dictionary_acceptance_words.index.table_header')}}</template>
    </pcg-table>
    <nav :aria-label="$t('general.pagination')">
      <pcg-pagination v-model="pagination.currentPage"
                      @input="getDictionaryAcceptanceWords"
                      :page-count="pagination.pages"
                      :total-records="pagination.totalRecords"
                      class="col-12 justify-content-center my-pagination"/>
    </nav>
  </div>
</template>

<script>
import ApiDictionaryAcceptanceWords from '../../api/dictionary_acceptance_words'
import PaginableResourceMixin from '../../mixins/paginable_resource_mixin'
import { decamelize } from 'humps'

export default {
  name: 'DictionaryAcceptanceWordsIndex',
  components: { },
  mixins: [PaginableResourceMixin],
  data () {
    return {
      dictionaryId: this.$route.params.dictionaryId,
      dictionaryWords: [],
      fields: []
    }
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.getDictionaryAcceptanceWords()
  },
  methods: {
    getDictionaryAcceptanceWords () {
      ApiDictionaryAcceptanceWords.getDictionaryAcceptanceWords(this.dictionaryId, { page: this.pagination.currentPage })
        .then(response => {
          this.setPagination(response)
          this.fields = this.fieldsTable(response.data.data)
          this.dictionaryWords = this.dataTable(response.data.data)
        }).catch(error => {
          this.$toastr.e(error)
          console.log(error)
        })
    },
    deleteDictionaryAcceptanceWords (dictionaryWordId) {
      ApiDictionaryAcceptanceWords.deleteDictionaryAcceptanceWord(this.dictionaryId, dictionaryWordId)
        .then(response => {
          this.dictionaryWords = this.dictionaryWords.filter((word) => word.hidden_id !== dictionaryWordId)
        }).catch(error => {
          this.$toastr.e(error)
          console.log(error)
        })
    },

    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.dictionary_acceptance_words.index.title'),
        subtitle: this.$t('views.dictionary_acceptance_words.index.subtitle'),
        capitalizeSubtitle: false
      })
    },
    dataTable (data) {
      const result = []
      data.forEach((dictionary, index) => {
        const attributes = dictionary.attributes
        const actions = {
          actions: [{
            id: `${dictionary.id}-a`,
            link: () => this.$router.push({
              name: 'dictionary_acceptance_words_edit_path',
              params: { dictionaryId: this.dictionaryId, id: dictionary.id }
            }),
            type: 'button',
            text: this.$t('general.edit')
          },
          {
            id: `${dictionary.id}-b`,
            link: () => this.deleteDictionaryAcceptanceWords(dictionary.id),
            type: 'button',
            text: this.$t('general.delete')
          }
          ]
        }
        const switches = {
          switches: [
            {
              id: `${dictionary.id}-b`,
              link: () => this.updateDictionaryAcceptanceWord(dictionary),
              type: 'switch',
              value: this.acceptanceTypeToBoolean(attributes.acceptanceType)
            }]
        }
        Object.assign(attributes, switches)
        Object.assign(attributes, actions)
        Object.assign(attributes, { hidden_id: dictionary.id })
        result.push(attributes)
      })
      return result
    },
    fieldsTable (data) {
      const fields = []
      data.forEach((dictionary, index) => {
        if (index === 0) {
          _.forEach(dictionary.attributes, (val, key) => {
            if (key === 'status') {
              fields.push({
                key: key,
                label: this.$t(`views.dictionary_acceptance_words.labels.${key}`),
                formatter: value => {
                  return this.$t(`views.dictionary_acceptance_words.statuses.${value}`)
                }
              })
            } else if (key === 'type') {
              fields.push({
                key: key,
                label: this.$t(`views.dictionary_acceptance_words.labels.${key}`),
                formatter: value => {
                  return this.$t(`views.dictionary_acceptance_words.types.${value}`)
                }
              })
            } else if (key === 'acceptanceType') {
              fields.push({
                key: key,
                label: this.$t(`views.dictionary_acceptance_words.labels.${decamelize(key)}`),
                formatter: value => {
                  return this.$t(`views.dictionary_acceptance_words.acceptance_types.${value}`)
                }
              })
            } else {
              fields.push({
                key: key,
                label: this.$t(`views.dictionary_acceptance_words.labels.${decamelize(key)}`)
              })
            }
          })
        }
      })
      fields.push({
        key: 'switches',
        label: this.$t('views.dictionary_acceptance_words.labels.switches')
      })
      fields.push({
        key: 'actions',
        label: this.$t('views.dictionary_acceptance_words.labels.actions')
      })
      return fields
    },
    updateDictionaryAcceptanceWord (dictionaryWord) {
      this.loading = true
      const booleanAcceptanceType = this.acceptanceTypeToBoolean(dictionaryWord.attributes.acceptanceType)
      const payload = {
        dictionaryAcceptanceWord: {
          acceptanceType: this.booleanToAcceptanceType(!booleanAcceptanceType)
        }
      }
      ApiDictionaryAcceptanceWords.updateDictionaryAcceptanceWord(this.dictionaryId, dictionaryWord.id, payload)
        .then(response => {
          this.errors = {}
          const acceptanceType = this.booleanToAcceptanceType(!booleanAcceptanceType)
          dictionaryWord.attributes.acceptanceType = acceptanceType
          dictionaryWord.attributes.switches[0].value = this.acceptanceTypeToBoolean(acceptanceType)
          setTimeout(() => { this.$toastr.s(this.$t('general.dictionary_word_updated')) }, 1)
        }).catch(error => {
          this.handleErrors(error)
        }).finally(() => {
          this.loading = false
        })
    },
    acceptanceTypeToBoolean (type) {
      return type === 'allow_emails'
    },
    booleanToAcceptanceType (type) {
      if (type) {
        return 'allow_emails'
      } else {
        return null
      }
    },
    handleErrors (errors) {
      this.errors = errors.response.data
      setTimeout(() => {
        if (errors.response.status === 422 && this.errors) {
          this.$toastr.e(Object.values(this.errors).map(errors => errors.join('<br/>')).join(('<br/>')))
        } else {
          this.$toastr.e(this.$t('general.check_errors'))
        }
      }, 1)
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .my-pagination {
      /deep/ .page-item {
        &, &.disabled {
          .page-link {
            color: $pcg-the-darkest-gray !important;
            &:hover {
              color: $hover-color !important;
            }
          }
        }
        &.active {
          .page-link {
            background-color: $main-active-color !important;
            color: $pcg-white !important;
          }
        }
        &:first-child, &:last-child {
          .page-link {
            color: $main-active-color !important;
          }
        }
      }
    }
  }
</style>
