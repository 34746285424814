<template>
<div class="dictionary">
  <div v-for="scienceField in scienceFields" :key="scienceField.id" class="science-fields">
    <b-row class="science-field">
      <b-col cols="6">
        {{ scienceField.name }}
        <pcg-btn v-if="hasRight('edit_dictionaries')" size="small" @click="disciplineCreate(scienceField.id)">{{ $t('views.dictionary_disciplines.new') }}</pcg-btn>
      </b-col>
      <b-col cols="4" class="align-self-center">
        {{ scienceField.nameEn }}
      </b-col>
      <b-col cols="1" class="align-self-center">
        {{ scienceField.short }}
      </b-col>
      <b-col cols="1" class="align-self-center">
      <span v-if="hasRight('edit_dictionaries') || hasRight('edit_own_dictionaries')" class="clicked small ml-1 field-edit" @click="scEdit(scienceField.id)">
          <i class="fas fa-pen small"></i>
          {{ $t('general.edit') }}
      </span>
      </b-col>
    </b-row>
    <b-row v-for="discipline in scDisciplines(scienceField.id)" :key="discipline.id" class="disciplin">
      <b-col cols="1">
        {{ discipline.id }}
      </b-col>
      <b-col cols="5">
        {{ discipline.name }}
      </b-col>
      <b-col cols="4">
        {{ discipline.nameEn }}
      </b-col>
      <b-col cols="1">
        {{ discipline.short }}
      </b-col>
      <b-col cols="1">
        <span v-if="hasRight('edit_dictionaries') || hasRight('edit_own_dictionaries')" class="clicked small ml-1 publisher-edit" @click="disciplineEdit(discipline.id)">
            <i class="fas fa-pen small"></i>
            {{ $t('general.edit') }}
        </span>
      </b-col>
    </b-row>
  </div>
  <b-modal ref="disciplineModal"
           :hide-header="true"
           :centered="true"
           v-bind:ok-title="$t('general.confirm')"
           ok-variant="primary"
           :no-close-on-esc="true"
           @ok="disciplineSave"
           @hide="escDiscipline"
           v-bind:cancel-title="$t('general.cancel')"
           cancel-variant="default"
           v-on-clickaway = "escDiscipline"
           footer-class="my-modal-footer"
           content-class="my-modal-content"
  >
    <div class="edit-discipline" style="display: inline-block">{{ $t('views.dictionary_disciplines.modal_discipline') }}</div>
    <pcg-text-input
        class="my-field"
        :label="$t('views.dictionary_disciplines.field_name')"
        :value="scienceName(mDiscipline.scienceFieldId)"
        :disabled="true"
    />
    <pcg-text-input
        class="my-field"
        :label="$t('views.dictionary_disciplines.id')"
        v-model="mDiscipline.id"
        :disabled="!creating"
    />
    <pcg-text-input
        class="my-field"
        :label="$t('views.dictionary_disciplines.short')"
        v-model="mDiscipline.short"
    />
    <pcg-text-input
        class="my-field"
        :label="$t('views.dictionary_disciplines.name')"
        v-model="mDiscipline.name"
    />
    <pcg-text-input
        class="my-field"
        style="margin-bottom: 1.5rem"
        :label="$t('views.dictionary_disciplines.name_en')"
        v-model="mDiscipline.nameEn"
    />
    <pcg-text-input
        class="my-field"
        style="margin-bottom: 1.5rem"
        :label="$t('views.dictionary_disciplines.api_id')"
        v-model="mDiscipline.apiId"
    />
  </b-modal>
  <b-modal ref="fieldModal"
           :hide-header="true"
           :centered="true"
           v-bind:ok-title="$t('general.confirm')"
           ok-variant="primary"
           :no-close-on-esc="true"
           @ok="fieldSave"
           @hide="escField"
           v-bind:cancel-title="$t('general.cancel')"
           cancel-variant="default"
           v-on-clickaway = "escField"
           footer-class="my-modal-footer"
           content-class="my-modal-content"
  >
    <div class="edit-discipline" style="display: inline-block">{{ $t('views.dictionary_disciplines.modal_field') }}</div>
    <pcg-text-input
        class="my-field"
        :label="$t('views.dictionary_disciplines.short')"
        v-model="mScienceField.short"
      />
    <pcg-text-input
        class="my-field"
        :label="$t('views.dictionary_disciplines.name')"
        v-model="mScienceField.name"
    />
    <pcg-text-input
        class="my-field"
        style="margin-bottom: 1.5rem"
        :label="$t('views.dictionary_disciplines.name_en')"
        v-model="mScienceField.nameEn"
    />
  </b-modal>
</div>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import setTitle from '../../mixins/set_title'
import ApiDisciplines from '../../api/disciplins'
import hasRight from '../../mixins/has_right'

export default {
  name: 'DictionaryDisciplines',
  mixins: [setTitle, clickaway, hasRight],
  data: function () {
    return {
      headerTitle: 'views.dictionary_disciplines.title',
      headerSubtitle: '',
      creating: false,
      scienceFields: [],
      disciplines: [],
      mDiscipline: {
        id: null,
        short: null,
        name: null,
        nameEn: null,
        scienceFieldId: null,
        apiId: null
      },
      mScienceField: {
        id: null,
        short: null,
        name: null,
        nameEn: null
      }
    }
  },
  computed: {
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.setTitles()
    ApiDisciplines.getDictionary()
      .then(result => {
        this.scienceFields = result.data.scienceFields
        this.disciplines = result.data.datas
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    scDisciplines (dId) {
      return this.disciplines.filter(discipline => {
        return discipline.scienceFieldId === dId
      })
    },
    scEdit (id) {
      const sf = this.scienceFields.find(field => {
        return field.id === id
      })
      if (sf) {
        Object.assign(this.mScienceField, sf)
      }
      this.$refs.fieldModal.show()
    },
    fieldSave (modalEvt) {
      modalEvt.preventDefault()
      const sc = {}
      Object.assign(sc, this.mScienceField)
      const idx = this.scienceFields.findIndex(el => {
        return this.mScienceField.id === el.id
      })
      ApiDisciplines.updateField(this.mScienceField)
        .then(result => {
          Object.assign(this.scienceFields[idx], sc)
          this.$toastr.s('Success')
          this.$refs.fieldModal.hide()
          this.mScienceField = {
            id: null,
            short: null,
            name: null,
            nameEn: null
          }
        })
        .catch(err => {
          const datas = err.response.data
          const message = []
          for (const key in datas) {
            message.push(`${datas[key]}`)
          }
          this.$toastr.e(message.join('\n'))
          Object.assign(this.mScienceField, sc)
        })
    },
    escField () {
      this.mScienceField = {
        id: null,
        short: null,
        name: null,
        nameEn: null
      }
    },
    disciplineCreate (scId) {
      this.creating = true
      this.mDiscipline = {
        id: null,
        short: null,
        name: null,
        nameEn: null,
        scienceFieldId: scId,
        apiId: null
      }
      this.$refs.disciplineModal.show()
    },
    disciplineEdit (id) {
      this.creating = false
      const discipline = this.disciplines.find(d => {
        return d.id === id
      })
      if (discipline) {
        Object.assign(this.mDiscipline, discipline)
      }
      this.$refs.disciplineModal.show()
    },
    disciplineSave (modalEvt) {
      modalEvt.preventDefault()
      const d = {}
      Object.assign(d, this.mDiscipline)
      const idx = this.disciplines.findIndex(el => {
        return this.mDiscipline.id === el.id
      })
      if (idx > -1) {
        ApiDisciplines.updateDiscipline(this.mDiscipline)
          .then(result => {
            Object.assign(this.disciplines[idx], d)
            this.$toastr.s('Success')
            this.$refs.disciplineModal.hide()
            this.mDiscipline = {
              id: null,
              short: null,
              name: null,
              nameEn: null,
              scienceFieldId: null
            }
          })
          .catch(err => {
            const datas = err.response.data
            const message = []
            for (const key in datas) {
              message.push(`${datas[key]}`)
            }
            this.$toastr.e(message.join('\n'))
            Object.assign(this.mDiscipline, d)
          })
      } else {
        Object.assign(d, this.mDiscipline)
        ApiDisciplines.createDiscipline(this.mDiscipline)
          .then(result => {
            if (result) {
              ApiDisciplines.getDictionary()
                .then(result => {
                  this.scienceFields = result.data.scienceFields
                  this.disciplines = result.data.datas
                  this.$toastr.s('Success')
                  this.$refs.disciplineModal.hide()
                })
                .catch(error => {
                  console.log(error)
                })
              this.mDiscipline = {
                id: null,
                short: null,
                name: null,
                nameEn: null,
                scienceFieldId: null
              }
            } else {
              this.$refs.disciplineModal.hide()
            }
          })
          .catch(err => {
            const datas = err.response.data
            const message = []
            for (const key in datas) {
              message.push(`${datas[key]}`)
            }
            this.$toastr.e(message.join('\n'))
            Object.assign(this.mDiscipline, d)
          })
      }
    },
    escDiscipline () {
      this.mDiscipline = {
        id: null,
        short: null,
        name: null,
        nameEn: null,
        scienceFieldId: null
      }
    },
    scienceName (id) {
      const sf = this.scienceFields.find(field => {
        return field.id === id
      })
      if (sf) {
        return sf.name
      } else {
        return null
      }
    }
  }

}
</script>

<style scoped lang="scss">
  @import '../../assets/stylesheets/vars';

  .science-fields {
    &:first-of-type {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      margin-left: -10px;
      padding-left: 10px;
      margin-right: -10px;
      padding-right: 10px;
      padding-top: 5px;
      background-color: $main-color;
    }
    &:last-of-type {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
  .science-field {
    background-color: $main-color;
    color: $pcg-white;
    padding: 0.5rem 0 0.5rem 0;

  }

  .disciplin {
    color: $main-color;
    background-color: $pcg-white;
  }

  .edit-discipline {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-l;
    margin-bottom: 1rem;
  }

  .my-field {
    margin-bottom: 1rem;
    position: relative;
    /deep/ .pcg-input-label {
      margin-bottom: 0.25rem;
      color: $pcg-gray;
    }
    /deep/ .pcg-input {
      height: calc(1.5em + 0.75rem + 2px);
    }
  }

  .publisher-edit {
    color: $main-active-color;
  }

  .field-edit {
    color: $pcg-white;
  }
</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .science-fields {
      &:first-of-type {
        background-color: $main-color;
      }
    }
    .science-field {
      background-color: $main-color;
      color: $pcg-white;
    }
    .disciplin {
      color: $main-color;
      background-color: $pcg-white;
    }
    .edit-discipline {
      color: $pcg-gray;
    }
    .my-field {
      /deep/ .pcg-input-label {
        color: $pcg-gray;
      }
    }
    .publisher-edit {
      color: $main-active-color;
    }
    .field-edit {
      color: $pcg-white;
    }
  }
</style>
