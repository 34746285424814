import api from './index'

const resource = 'employments'

export default {
  updateEmployments (employee, employments, userClicked = false) {
    let url = ''
    url = resource + '/' + employee.id
    if (userClicked) url += '?user_clicked=true'
    const body = {
      evaluation: employee.evaluation,
      employments
    }
    return api.put(url, body)
  }
}
