<template>
  <b-row class="mb-2">
    <b-col>
      <template>
        <live-search class="managers"
                     :clear-btn="false"
                     v-model="currentManager"
                     :options="managerOptions"
                     :show-search-icon="false"
                     v-on:ls-checked="managerSave"
        />
      </template>
    </b-col>
    <b-col cols="1" class="my-column">
      <a href="javascript:;" :aria-label="$t('general.remove')" @click="removeManager">
        <i class="pcg-icon-cross"></i>
      </a>
    </b-col>
  </b-row>
</template>

<script>
import LiveSearch from './LiveSearch'
export default {
  name: 'DnDisciplineManager',
  components: { LiveSearch },
  props: {
    manager: Object
  },
  data: function () {
    return {
    }
  },
  computed: {
    currentManager: {
      get () {
        return {
          id: this.manager.employeeId || this.manager.id,
          value: this.manager.managerName
        }
      },
      set (value) {
        if (this.manager.employeeId) {
          this.manager.employeeId = value.id
        } else {
          this.manager.id = value.id
        }
        this.manager.managerName = value.value
      }
    },
    managerOptions () {
      return {
        path: 'discipline_managers',
        params: {
          id: this.currentManager.id
        }
      }
    }
  },
  mounted () {
  },
  methods: {
    removeManager () {
      this.$emit('remove-manager', this.manager.id)
    },
    managerSave () {
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .clicked {
    cursor: pointer;
    font-size: $font-size-m;
    color: $pcg-gray;
    position: relative;
    bottom: .15rem;
  }
  ::v-deep .pcg-checkbox-container {
    margin-right: 0;
  }

  ::v-deep .live-search {
    margin-left: -15px;
    input {
      padding: 0 0.75rem;
      height: 2rem;
    }
  }

  .managers {
    margin-left: .1rem;
    font-weight: 400;
    font-size: $font-size-m;
    color: $pcg-gray;
  }

  .my-column {
    position: relative;
    top: .4rem;
  }

  .pcg-icon-cross {
    cursor: pointer;
    color: $pcg-light-gray;
    margin-right: 1rem;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .clicked {
      color: $pcg-gray;
    }
    .managers {
      color: $pcg-gray;
    }
    .pcg-icon-cross {
      color: $pcg-light-gray;
    }
  }
</style>
