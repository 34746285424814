<template>
  <div class="progress-bar">
    <div class="progress" :style="[ progressPercent < 100 ? { width: `${progressPercent}%`, background: getProgressColor() } : { width: `100%`, background: '#1d8e00' } ] "></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'dn-progress-bar',
  props: {
    progressPercent: {
      type: Number,
      default: 0
      // validator (progress) {
      //   return progress >= 0 && progress <= 100
      // }
    }
  },
  computed: {
    ...mapGetters('page', ['contrast'])
  },
  methods: {
    getProgressColor () {
      if (this.contrast) {
        return '#009941'
      } else {
        return '#00B4EB'
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .progress-bar {
    background-color: $pcg-lightest-gray-background;
    height: 15px;
    width: 100%;
    border-radius: 15px;
    box-shadow: 0 5px 10px rgba(200, 200, 200, 0.1);
    overflow: hidden;
  }

  .progress {
    border-radius: inherit;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .progress-bar {
      background-color: $pcg-lightest-gray;
      box-shadow: 0 5px 10px rgba(200, 200, 200, 0.1);
    }
  }
</style>
