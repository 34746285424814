<template>
  <pcg-box :header="header" class="main-class">
    <div v-if="orcid">
      <div class="d-flex justify-content-center">
        <div>
          <img src="../assets/images/orcid.png" class="img-fluid my-image-orcid" alt="ORCiD"/>
          <span class="orcid-id" @click="editOrcid" v-if="hasRight('edit_employee') || hasRight('edit_own_employee')">
            {{ orcid }}
          </span>
          <span v-else class="orcid-id">
            {{ orcid }}
          </span>
          <a href="javascript:;" :aria-label="$t('general.edit')" @click="editOrcid" class="change-btn" style="position: relative; bottom: .1rem;" v-if="hasRight('edit_employee') || hasRight('edit_own_employee')">
            <i class="fas fa-pen my-pen"></i>
          </a>
        </div>
      </div>
      <div class="synchronize d-flex justify-content-center" style="margin-top: .5rem" v-if="hasRight('import_orcid') || hasRight('import_own_orcid')" >
        <router-link :to="{ name: 'orcid_publications_path', params: {id: employee_id } }" >
          <i class="fas fa-sync my-sync"></i>
          {{ $t('components.dn_orcid.synchronize') }}
        </router-link>
      </div>
    </div>
    <div v-if="!orcid" class="orcid-btn">
      <pcg-btn size="normal" variant="additional" @click="editOrcid"  v-if="hasRight('edit_employee') || hasRight('edit_own_employee')">
        <span class="link-account">{{ $t('components.dn_orcid.edit_orcid') }}</span>
        <img src="../assets/images/orcid.png" class="img-fluid my-image-no-orcid" alt="ORCiD"/>
      </pcg-btn>
    </div>
    <b-modal ref="editOrcid"
             :hide-header="true"
             footer-class="my-modal-footer"
             :centered="true"
             v-bind:ok-title="$t('general.confirm')"
             ok-variant="primary"
             :no-close-on-esc="true"
             @ok="updateOrcid(employee_id, true)"
             v-bind:cancel-title="$t('general.cancel')"
             cancel-variant="default"
             content-class="my-modal-content"
    >
      <div class="change-orcid-title">{{ $t('components.dn_orcid.edit_orcid') }}</div>
      <div v-if="orcid && orcid.length > 0" class="change-orcid-confirmation">{{ $t('components.dn_orcid.change_orcid_confirmation') }}</div>
      <pcg-text-input v-model="newOrcid" :show-label="false" />
    </b-modal>
  </pcg-box>
</template>

<script>
import ApiOrcid from '../api/orcid_publications'
import hasRight from '../mixins/has_right'

export default {
  name: 'dn-orcid',
  mixins: [hasRight],
  props: {
    innerHeader: Boolean,
    orcid: String,
    employee_id: Number,
    orcid_count: Number
  },
  data: function () {
    return {
      newOrcid: null
      // employee_id: 0
    }
  },
  computed: {
    header () {
      return null
    }
  },
  methods: {
    editOrcid () {
      if (this.orcid && this.orcid.length > 0) {
        this.newOrcid = this.orcid
      }
      this.$refs.editOrcid.show()
    },
    updateOrcid (id, userClicked = false) {
      if (this.newOrcid === this.orcid) {
        this.$toastr.i(this.$t('views.orcid_integration.same_id'))
      } else {
        ApiOrcid.update_orcid_id(id, this.newOrcid, userClicked)
          .then(result => {
            this.$emit('orcidUpdated')
          })
          .catch(result => {
            const messages = []
            for (const mess in result.response.data) {
              messages.push(`${mess}: ${result.response.data[mess]}`)
            }
            this.$toastr.e(messages.join('\n'))
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .orcid-text {
    text-align: center;
    color: $pcg-gray;
    font-weight: 400;
  }

  .orcid-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .change-btn {
    color: $main-active-color;
    font-size: 1em;
    font-weight: 400;
    cursor: pointer;
  }

  .my-pen {
    font-size: 9px;
    position: relative;
    bottom: .1rem;
  }

  .orcid-btn-text {
    font-weight: 600;
    margin-right: .7rem;
  }

  .my-image {
    width: 50px;
    height: 30px;
    display: inline-block;
    position: relative;
    bottom: .2rem;
  }

  .my-image-orcid {
    height: 26px;
    display: inline-block;
    position: relative;
    bottom: .15rem;
  }

  .my-image-no-orcid {
    height: 24px;
    display: inline-block;
    position: relative;
    bottom: .15rem;
  }

  .orcid-id {
    margin-left: .7rem;
    color: $main-color;
    font-size: $font-size-m;
    font-weight: 400;
    letter-spacing: .05rem;
    cursor: pointer;
    a {
      color: unset;
    }
  }

  .synchronize {
    color: $main-active-color;
    font-size: 1em;
    font-weight: 400;
    cursor: pointer;
    a {
      color: unset;
    }
  }

  .orcid-achievements {
    color: $main-active-color;
    font-weight: 600;
    font-size: $font-size-m;
    cursor: pointer;
  }

  .orcid-space {
    margin-top: .5rem;
  }

  .link-account {
    margin-right: .5rem;
  }

  .main-class {
    /deep/ .header-title {
      font-size: $font-size-xl;
      font-weight: 600;
      letter-spacing: .1rem;
      text-transform: uppercase;
    }
  }

  .change-orcid-title {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .change-orcid-confirmation {
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1rem;
  }

  .my-sync {
    margin-right: .1rem;
    position: relative;
    top: .1rem;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .orcid-text {
      color: $pcg-gray;
    }
    .change-btn {
      color: $main-active-color;
    }
    .orcid-id {
      color: $main-color;
    }
    .orcid-achievements {
      color: $main-active-color;
    }
    .change-orcid-title {
      color: $pcg-gray;
    }
    .change-orcid-confirmation {
      color: $pcg-gray;
    }
    .synchronize {
      color: $main-active-color;
    }
  }
</style>
