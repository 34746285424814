<template>
  <div class="authors-component">
    <b-row v-if="publication.translationPl || publication.translationOther">
      <b-col class="col-auto pl-1 pr-1">
        <div class="first-column"></div>
      </b-col>
      <b-col>
        <div class="authors fitted d-flex justify-content-start">
          <div style="min-width: fit-content;">
            <img :src="imgAuthors" class="icon circle authors-icon" style="margin-right: .7rem" alt="">
            <span class="author-text">{{ $t('views.orcid_publications.original_authors') }}:</span>
          </div>
          <b-modal ref="originalAuthors"
                   :hide-header="true"
                   :centered="true"
                   v-bind:ok-title="$t('general.confirm')"
                   ok-variant="primary"
                   :no-close-on-esc="true"
                   @ok="originalAuthorsSave"
                   @hide="escOriginalAuthors"
                   v-bind:cancel-title="$t('general.cancel')"
                   cancel-variant="default"
                   v-on-clickaway = "escOriginalAuthors"
                   content-class="my-modal-content"
                   footer-class="my-modal-footer"
          >
            <orcid-authors :authors="translationOriginalAuthors" :errors="errors" :translation="true"
                           @move-up="moveUpOriginal"
                           @move-down="moveDownOriginal"
            />
          </b-modal>
          <div class="authors-list">
            <div v-for="(author, index) in publication.originalAuthors" :key="`original-author-${index}`" class="pcg-main-color">
              <div style="display: inline-block" v-if="author.employeeId == null" class="dn-gray-color author">
                <!--  Autor spoza uczelni-->
                {{ author.name }}
              </div>
              <div v-else-if="onlyUj == 1 && author.authorType === 1" class="author author-full no-discipline-list">
                <span v-if="canViewAuthor(author)">
                  <router-link :to="{ name: 'employee_path', params: {id: author.employeeId } }" >
                    {{ author.name }}
                  </router-link>
                </span>
                <span v-else>
                  <router-link v-if="hasRight('view_own_employee')" :to="{ name: 'scientist_dashboard_path' }" >
                    {{ author.name }}
                  </router-link>
                  <span v-else style="cursor: not-allowed">{{ author.name }}</span>
                </span>
                <img v-if="author.orcid" src="../assets/images/orcid.png" :id="'author-' + author.id + '-' + publication.id" class="my-image" alt="ORCiD"/>
                <b-tooltip :target="'author-' + author.id + '-' + publication.id" v-if="author.orcid">
                  <router-link :to="{ name: 'orcid_publications_path', params: {id: author.employeeId } }" >
                    {{ author.orcid }}
                  </router-link>
                </b-tooltip>
                <i v-if="author.apiId && hasRight('view_employee_api_id')" class="fas fa-user" style="margin-right: .2rem; margin-left: .2rem;" :id="'aapi-' + author.id + '-' + publication.id"></i>
                <b-tooltip :target="'aapi-' + author.id + '-' + publication.id" v-if="author.apiId && hasRight('view_employee_api_id')">
                  {{ author.apiId }}
                </b-tooltip>
                <i v-if="author.faculties && author.faculties.length > 0 && hasRight('list_unit_employee')" class="fas fa-bezier-curve" style="margin-right: .2rem; margin-left: .2rem;" :id="'faculties-' + author.id + '-' + publication.id"></i>
                <b-tooltip :target="'faculties-' + author.id + '-' + publication.id" v-if="author.faculties && author.faculties.length > 0 && hasRight('list_unit_employee')">
                  <div v-if="author.faculties.length > 0" class="faculty-text">
                            <span v-for="(faculty, index) in author.faculties" :key="faculty.id" style="margin-right: .5rem">
                              <span v-if="faculty.parents.length > 0" :class="{ 'faculty-archive': faculty.archive }">
                                <span v-if="index === (author.faculties.length - 1)">{{ faculty.parents }} -> {{ faculty.name }}</span>
                                <span v-else>{{ faculty.parents }} -> {{ faculty.name }},</span>
                              </span>
                              <span v-else :class="{ 'faculty-archive': faculty.archive }">
                                <span v-if="index === (author.faculties.length - 1)">{{ faculty.name }}</span>
                                <span v-else>{{ faculty.name }},</span>
                              </span>
                            </span>
                  </div>
                </b-tooltip>
                <img v-if="publication.publicationTypeId !== 7 && authorErrors(author)" src="../assets/images/exclamation-mark.svg" :id="'no_affiliation-' + author.id + '-' + publication.id" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                <b-tooltip :target="'no_affiliation-' + author.id + '-' + publication.id">
                  <span class="no-discipline" :style="{ whiteSpace: 'pre-line' }">{{ authorErrors(author) }}</span>
                </b-tooltip>
              </div>
              <div style="display: inline-block" class="author" v-else>
                <div class="author-full discipline-chosen">
                  <span v-if="canViewAuthor(author)">
                    <router-link :to="{ name: 'employee_path', params: {id: author.employeeId } }" >
                      {{ author.name }}
                    </router-link>
                  </span>
                  <span v-else>
                    <router-link v-if="hasRight('view_own_employee')" :to="{ name: 'scientist_dashboard_path' }" >
                      {{ author.name }}
                    </router-link>
                    <span v-else style="cursor: not-allowed">{{ author.name }}</span>
                  </span>
                  <i v-if="author.authorType === 3" class="fas fa-graduation-cap" :id="'doctoral_student-' + author.id + '-' + publication.id"></i>
                  <b-tooltip :target="'doctoral_student-' + author.id + '-' + publication.id" v-if="author.authorType === 3">
                    {{ $t('views.orcid_publications.doctoral_student') }}
                  </b-tooltip>
                  <img v-if="author.orcid" src="../assets/images/orcid.png" :id="'author-' + author.id + '-' + publication.id" class="my-image" alt="ORCiD"/>
                  <b-tooltip :target="'author-' + author.id + '-' + publication.id" v-if="author.orcid">
                    <router-link :to="{ name: 'orcid_publications_path', params: {id: author.employeeId } }" >
                      {{ author.orcid }}
                    </router-link>
                  </b-tooltip>
                  <i v-if="author.apiId && hasRight('view_employee_api_id')" class="fas fa-user" style="margin-right: .2rem; margin-left: .2rem;" :id="'aapi-' + author.id + '-' + publication.id"></i>
                  <b-tooltip :target="'aapi-' + author.id + '-' + publication.id" v-if="author.apiId && hasRight('view_employee_api_id')">
                    {{ author.apiId }}
                  </b-tooltip>
                  <i v-if="author.faculties && author.faculties.length > 0 && hasRight('list_unit_employee')" class="fas fa-bezier-curve" style="margin-right: .2rem; margin-left: .2rem;" :id="'faculties-' + author.id + '-' + publication.id"></i>
                  <b-tooltip :target="'faculties-' + author.id + '-' + publication.id" v-if="author.faculties && author.faculties.length > 0 && hasRight('list_unit_employee')">
                    <div v-if="author.faculties.length > 0" class="faculty-text">
                            <span v-for="(faculty, index) in author.faculties" :key="faculty.id" style="margin-right: .5rem">
                              <span v-if="faculty.parents.length > 0" :class="{ 'faculty-archive': faculty.archive }">
                                <span v-if="index === (author.faculties.length - 1)">{{ faculty.parents }} -> {{ faculty.name }}</span>
                                <span v-else>{{ faculty.parents }} -> {{ faculty.name }},</span>
                              </span>
                              <span v-else :class="{ 'faculty-archive': faculty.archive }">
                                <span v-if="index === (author.faculties.length - 1)">{{ faculty.name }}</span>
                                <span v-else>{{ faculty.name }},</span>
                              </span>
                            </span>
                    </div>
                  </b-tooltip>
                </div>
              </div>
            </div>
            <template v-if="originalAuthorSpinner">
              <div class="text-primary">
                <spinner  v-if="originalAuthorSpinner"/>
              </div>
            </template>
            <div v-if="canEditAuthors()">
              <template v-if="!originalAuthorSpinner">
                <a href="javascript:;" :aria-label="$t('views.orcid_publications.edit_original_authors')" style="display: inline-block" class="publisher-edit clicked my-pen" @click="originalAuthorsEdit(publication)">
                  <i class="fas fa-pen" :id="'original_authors_edit-' + publication.id"></i>
                  <b-tooltip :target="'original_authors_edit-' + publication.id">
                    {{ $t('views.orcid_publications.edit_original_authors') }}
                  </b-tooltip>
                </a>
              </template>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row style="margin-top: .3rem" v-if="publication.authors.length > 0 && publication.publicationTypeId !== 21">
      <b-col class="col-auto pl-1 pr-1">
        <div class="first-column"></div>
      </b-col>
      <b-col>
        <div class="authors fitted d-flex justify-content-start">
          <div style="min-width: fit-content;" v-if="(publication.publicationTypeId === 3 || publication.publicationTypeId === 19 || publication.publicationTypeId === 21) && (publication.translationPl || publication.translationOther)">
            <img :src="imgAuthors" class="icon circle authors-icon" style="margin-right: .7rem" alt="">
            <span class="author-text">{{ $t('views.orcid_publications.authors_translators') }}:</span>
          </div>
          <div v-else-if="publication.publicationTypeId !== 32" style="min-width: fit-content;">
            <img :src="imgAuthors" class="icon circle authors-icon" style="margin-right: .7rem" alt="">
            <span v-if="publication.publicationTypeId === 7 && publication.patentType === 'PLANT_TYPE'" class="author-text">
              <span>{{ $t('views.orcid_publications.breeders') }}:</span>
              <span v-if="fromForm" class="required-field">*</span>
              <img v-if="fromForm" src="../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
            </span>
            <span v-else-if="(publication.publicationTypeId === 7 && publication.patentType !== 'PLANT_TYPE') || publication.publicationTypeId === 35" class="author-text">
              <span>{{ $t('views.orcid_publications.creators') }}:</span>
              <span v-if="fromForm" class="required-field">*</span>
              <img v-if="fromForm" src="../assets/images/polon_logo.png" class="img-fluid polon-logo" alt="POLON"/>
            </span>
            <span v-else-if="publication.publicationTypeId === 45" class="author-text">
              <span>{{ $t('views.orcid_publications.contributors') }}:</span>
            </span>
            <span v-else class="author-text">
              <span>{{ $t('views.orcid_publications.authors') }}:</span>
              <span v-if="fromForm" class="required-field">*</span>
              <img v-if="fromForm" src="../assets/images/pbn.svg" class="img-fluid pbn-logo" alt="PBN"/>
            </span>
          </div>
          <b-modal ref="authors"
                   :hide-header="true"
                   :centered="true"
                   v-bind:ok-title="$t('general.confirm')"
                   ok-variant="primary"
                   :no-close-on-esc="true"
                   @ok="authorsSave"
                   @hide="escAuthors"
                   v-bind:cancel-title="$t('general.cancel')"
                   cancel-variant="default"
                   v-on-clickaway = "escAuthors"
                   content-class="my-modal-content"
                   footer-class="my-modal-footer"
          >
            <orcid-authors :authors="authors" :errors="errors" :publicationTypeId="publication.publicationTypeId" :managerDisciplines="managerDisciplines"
                           @move-up="moveUp"
                           @move-down="moveDown"
            />
          </b-modal>
          <div class="authors-list" v-if="publication.publicationTypeId !== 32">
            <div v-for="(author, index) in publication.authors" :key="`a-${index}`" class="pcg-main-color">
              <div style="display: inline-block" v-if="author.employeeId == null" class="dn-gray-color author">
                  <!--  Autor spoza uczelni-->
                  {{ author.name }}
              </div>
              <div v-else-if="onlyUj == 1 && author.authorType === 1" class="author author-full no-discipline-list">
                <span v-if="canViewAuthor(author)">
                  <router-link :to="{ name: 'employee_path', params: {id: author.employeeId } }" >
                    {{ author.name }}
                  </router-link>
                </span>
                <span v-else>
                  <router-link v-if="hasRight('view_own_employee')" :to="{ name: 'scientist_dashboard_path' }" >
                    {{ author.name }}
                  </router-link>
                  <span v-else style="cursor: not-allowed">{{ author.name }}</span>
                </span>
                <img v-if="author.orcid" src="../assets/images/orcid.png" :id="'author-' + author.id + '-' + publication.id" class="my-image" alt="ORCiD"/>
                <b-tooltip :target="'author-' + author.id + '-' + publication.id" v-if="author.orcid">
                  <router-link :to="{ name: 'orcid_publications_path', params: {id: author.employeeId } }" >
                    {{ author.orcid }}
                  </router-link>
                </b-tooltip>
                <i v-if="author.apiId && hasRight('view_employee_api_id')" class="fas fa-user" style="margin-right: .2rem; margin-left: .2rem;" :id="'aapi-' + author.id + '-' + publication.id"></i>
                <b-tooltip :target="'aapi-' + author.id + '-' + publication.id" v-if="author.apiId && hasRight('view_employee_api_id')">
                  {{ author.apiId }}
                </b-tooltip>
                <i v-if="author.faculties && author.faculties.length > 0 && hasRight('list_unit_employee')" class="fas fa-bezier-curve" style="margin-right: .2rem; margin-left: .2rem;" :id="'faculties-' + author.id + '-' + publication.id"></i>
                <b-tooltip :target="'faculties-' + author.id + '-' + publication.id" v-if="author.faculties && author.faculties.length > 0 && hasRight('list_unit_employee')">
                  <div v-if="author.faculties.length > 0" class="faculty-text">
                            <span v-for="(faculty, index) in author.faculties" :key="faculty.id" style="margin-right: .5rem">
                              <span v-if="faculty.parents.length > 0" :class="{ 'faculty-archive': faculty.archive }">
                                <span v-if="index === (author.faculties.length - 1)">{{ faculty.parents }} -> {{ faculty.name }}</span>
                                <span v-else>{{ faculty.parents }} -> {{ faculty.name }},</span>
                              </span>
                              <span v-else :class="{ 'faculty-archive': faculty.archive }">
                                <span v-if="index === (author.faculties.length - 1)">{{ faculty.name }}</span>
                                <span v-else>{{ faculty.name }},</span>
                              </span>
                            </span>
                  </div>
                </b-tooltip>
                <img v-if="publication.publicationTypeId !== 7 && authorErrors(author)" src="../assets/images/exclamation-mark.svg" :id="'no_affiliation-' + author.id + '-' + publication.id" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                <b-tooltip :target="'no_affiliation-' + author.id + '-' + publication.id">
                  <span class="no-discipline" :style="{ whiteSpace: 'pre-line' }">{{ authorErrors(author) }}</span>
                </b-tooltip>
              </div>
              <div style="display: inline-block" class="author" v-else>
                  <template v-if="withDiscipline && (currentUser.role !== 'naukowiec' || author.employeeId === parseInt(employeeId)) && publication.publicationTypeId !== 32">
                      <div class="author-full"
                           :class="{
                        'no-discipline-list': ((publication.year === 2017 && author.ndisciplines === null && publication.publicationTypeId !== 7) || (publication.year !== 2017 && author.disciplines === null && author.authorType !== 3) || author.employeeId === null || authorErrors(author)),
                         'discipline-not-chosen': (
                             !authorErrors(author) && author.employeeId !== null && ((publication.year === 2017 && (author.ndisciplines !== null || publication.publicationTypeId === 7) && author.disciplineId === null) || ((publication.year !== 2017 && author.disciplines !== null && author.disciplineId === null) || (publication.year !== 2017 && author.authorType === 3 && author.disciplineId === null )))
                             ),
                         'discipline-chosen': (
                             !authorErrors(author) && author.employeeId !== null && ((publication.year === 2017 && (author.ndisciplines !== null || publication.publicationTypeId === 7) && author.disciplineId !== null) || ((publication.year !== 2017 && author.disciplines !== null && author.disciplineId !== null) || (publication.year !== 2017 && author.authorType === 3 && author.disciplineId !== null)))
                             )
                          }">
                        <span class="points" :class="pointsClass(publicationPoints(author))"
                              v-if="author.submitted &&
                              !(hasRight('edit_points') || hasRight('edit_points_expert')) &&
                              publicationPoints(author) > 0 &&
                              (hasRight('view_points') || hasRight('view_own_points'))">
                          {{ publicationPoints(author) }}
                        </span>
                        <span class="points" :class="pointsClass(publicationPoints(author))"
                              v-if="author.submitted &&
                              (hasRight('edit_points') || hasRight('edit_points_expert')) &&
                              !author.alternativePoints &&
                              publicationPoints(author) > 0 &&
                              (hasRight('view_points') || hasRight('view_own_points'))"
                              @mouseover="authorMouseOver(index)" @mouseleave="authorMouseLeave(index)"
                        >
                          <span>{{ publicationPoints(author) }}</span>
                          <i v-show="authorsHover[index] && !disableEditing" class="fas fa-pen points-pen" @click="editAuthorAlternativePoints(author)" :id="'points_author-' + author.id"></i>
                          <b-tooltip :target="'points_author-' + author.id">
                            {{ $t('components.publication_element.add_alternative_points') }}
                          </b-tooltip>
                        </span>
                        <span class="points" :class="pointsClass(publicationPoints(author) + author.alternativePoints)" v-if="author.submitted && (hasRight('edit_points') || hasRight('edit_points_expert')) && author.alternativePoints && publicationPoints(author) > 0 && (hasRight('view_points') || hasRight('view_own_points'))"
                              @mouseover="authorMouseOver(index)" @mouseleave="authorMouseLeave(index)"
                        >
                          <span>{{ author.alternativePoints }} </span>
                          <span>({{ publicationPoints(author) }})</span>
                          <i v-if="authorsHover[index] && !disableEditing" class="fas fa-pen points-pen" @click="editAuthorAlternativePoints(author)" :id="'points_author-' + author.id"></i>
                          <b-tooltip v-if="authorsHover[index]" :target="'points_author-' + author.id">
                            {{ $t('components.publication_element.edit_alternative_points') }}
                          </b-tooltip>
                        </span>
                        <span v-if="canViewAuthor(author)">
                          <router-link :to="{ name: 'employee_path', params: {id: author.employeeId } }" >
                            {{ author.name }}
                          </router-link>
                        </span>
                        <span v-else>
                          <router-link v-if="hasRight('view_own_employee')" :to="{ name: 'scientist_dashboard_path' }" >
                            {{ author.name }}
                          </router-link>
                          <span v-else style="cursor: not-allowed">{{ author.name }}</span>
                        </span>
                        <i v-if="author.authorType === 3" class="fas fa-graduation-cap" :id="'doctoral_student-' + author.id + '-' + publication.id"></i>
                        <b-tooltip :target="'doctoral_student-' + author.id + '-' + publication.id" v-if="author.authorType === 3">
                            {{ $t('views.orcid_publications.doctoral_student') }}
                        </b-tooltip>
                        <img v-if="author.orcid" src="../assets/images/orcid.png" :id="'author-' + author.id + '-' + publication.id" class="my-image" alt="ORCiD"/>
                        <b-tooltip :target="'author-' + author.id + '-' + publication.id" v-if="author.orcid">
                            <router-link :to="{ name: 'orcid_publications_path', params: {id: author.employeeId } }" >
                                {{ author.orcid }}
                            </router-link>
                        </b-tooltip>
                        <i v-if="author.apiId && hasRight('view_employee_api_id')" class="fas fa-user" style="margin-right: .2rem; margin-left: .2rem;" :id="'aapi-' + author.id + '-' + publication.id"></i>
                        <b-tooltip :target="'aapi-' + author.id + '-' + publication.id" v-if="author.apiId && hasRight('view_employee_api_id')">
                          {{ author.apiId }}
                        </b-tooltip>
                        <i v-if="author.faculties && author.faculties.length > 0 && hasRight('list_unit_employee')" class="fas fa-bezier-curve" style="margin-right: .2rem; margin-left: .2rem;" :id="'faculties-' + author.id + '-' + publication.id"></i>
                        <b-tooltip :target="'faculties-' + author.id + '-' + publication.id" v-if="author.faculties && author.faculties.length > 0 && hasRight('list_unit_employee')">
                          <div v-if="author.faculties.length > 0" class="faculty-text">
                            <span v-for="(faculty, index) in author.faculties" :key="faculty.id" style="margin-right: .5rem">
                              <span v-if="faculty.parents.length > 0" :class="{ 'faculty-archive': faculty.archive }">
                                <span v-if="index === (author.faculties.length - 1)">{{ faculty.parents }} -> {{ faculty.name }}</span>
                                <span v-else>{{ faculty.parents }} -> {{ faculty.name }},</span>
                              </span>
                              <span v-else :class="{ 'faculty-archive': faculty.archive }">
                                <span v-if="index === (author.faculties.length - 1)">{{ faculty.name }}</span>
                                <span v-else>{{ faculty.name }},</span>
                              </span>
                            </span>
                          </div>
                        </b-tooltip>
                        <span v-if="hasRight('list_author_faculty')" style="margin-right: .2rem; margin-left: .2rem;">
                          <template v-if="author.unitIds && author.unitIds.length > 0">
                            <a href="javascript:;" v-if="canEditAuthorFaculty(author)" class="clicked editing-discipline" @click="authorFacultyEdit(author)">
                              <span v-for="(faculty, index) in author.unitIds" :key="faculty.id">
                                <span v-if="index === 0">{{ faculty.value }}</span>
                                <span v-else>, {{ faculty.value }}</span>
                              </span>
                            </a>
                            <span v-else style="margin-right: .2rem; cursor: not-allowed">
                              <span v-for="(faculty, index) in author.unitIds" :key="faculty.id">
                                <span v-if="index === 0">{{ faculty.value }}</span>
                                <span v-else>, {{ faculty.value }}</span>
                              </span>
                            </span>
                          </template>
                          <template v-else>
                            <i class="fas fa-question orange-color small">&nbsp;</i>
                            <a href="javascript:;" v-if="canEditAuthorFaculty(author)" class="clicked orange-color" @click="authorFacultyEdit(author)">{{ $t('views.orcid_publications.faculty') }}</a>
                            <span v-else class="orange-color" style="cursor: not-allowed">{{ $t('views.orcid_publications.faculty') }}</span>
                          </template>
                        </span>
                        <span v-if="author.disciplines || author.authorType === 3">
                              <template v-if="author.disciplineId && (author.ndisciplines || publication.publicationTypeId === 7 )">
                                <i class="fas fa-check green-color small">&nbsp;</i>
                                <a href="javascript:;" v-if="canEditAuthorDiscipline(author)" class="clicked editing-discipline" @click="authorDisciplineEdit(author)">{{ authorDisciplineName(author) }}</a>
                                <span v-else style="margin-right: .2rem; cursor: not-allowed">{{ authorDisciplineName(author) }}</span>
                                <img v-if="author.wrongDiscipline" src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark-orange" style="margin-left: .5rem; margin-right: .5rem" :id="'periodical-' + publication.id + author.id" :alt="$t('general.exclamation_mark')"/>
                                <b-tooltip v-if="author.wrongDiscipline" :target="'periodical-' + publication.id + author.id">
                                    {{ $t('views.periodicals.wrong_discipline') }}
                                </b-tooltip>
                                <img v-if="!fromAuthorStatements[index] && author.authorType !== 3" src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark-orange" style="margin-left: .5rem; margin-right: .5rem" :id="'statement-' + publication.id + author.id" :alt="$t('general.exclamation_mark')"/>
                                <b-tooltip v-if="!fromAuthorStatements[index] && author.authorType !== 3" :target="'statement-' + publication.id + author.id">
                                    {{ $t('views.orcid_publications.wrong_discipline') }}
                                </b-tooltip>
                                <img v-if="publication.publicationTypeId === 35 && publication.artDiscipline !== null && author.disciplineId && publication.artDiscipline !== author.disciplineId" src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark-orange" style="margin-left: .5rem; margin-right: .5rem" :id="'artistic-' + publication.id + author.id" :alt="$t('general.exclamation_mark')"/>
                                <b-tooltip v-if="publication.publicationTypeId === 35 && publication.artDiscipline !== null && author.disciplineId && publication.artDiscipline !== author.disciplineId" :target="'artistic-' + publication.id + author.id">
                                    {{ $t('views.orcid_publications.artistic_wrong_discipline') }}
                                </b-tooltip>
                              </template>
                              <template v-else-if="!author.disciplineId && author.ndisciplines">
                                <i class="fas fa-question orange-color small">&nbsp;</i>
                                <a href="javascript:;" v-if="canEditAuthorDiscipline(author)" class="clicked orange-color" @click="authorDisciplineEdit(author)">{{ $t('views.orcid_publications.discipline') }}</a>
                                <span v-else class="orange-color" style="cursor: not-allowed">{{ $t('views.orcid_publications.discipline') }}</span>
                              </template>
                              <template v-else-if="author.disciplineId && !author.ndisciplines">
                                <i v-if="author.authorType !== 3" class="fas fa-check red-color small">&nbsp;</i>
                                <a href="javascript:;" v-if="canEditAuthorDiscipline(author)" class="clicked editing-discipline" @click="authorDisciplineEdit(author)">{{ authorDisciplineName(author) }}</a>
                                <span v-else style="margin-right: .2rem; cursor: not-allowed">{{ authorDisciplineName(author) }}</span>
                                <img v-if="!fromAuthorStatements[index] && author.authorType !== 3" src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark-orange" style="margin-left: .5rem; margin-right: .5rem" :id="'statement-' + publication.id + author.id" :alt="$t('general.exclamation_mark')"/>
                                <b-tooltip v-if="!fromAuthorStatements[index] && author.authorType !== 3" :target="'statement-' + publication.id + author.id">
                                    {{ $t('views.orcid_publications.wrong_discipline') }}
                                </b-tooltip>
                                <img v-if="publication.publicationTypeId === 35 && publication.artDiscipline !== null && author.disciplineId && publication.artDiscipline !== author.disciplineId" src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark-orange" style="margin-left: .5rem; margin-right: .5rem" :id="'artistic-' + publication.id + author.id" :alt="$t('general.exclamation_mark')"/>
                                <b-tooltip v-if="publication.publicationTypeId === 35 && publication.artDiscipline !== null && author.disciplineId && publication.artDiscipline !== author.disciplineId" :target="'artistic-' + publication.id + author.id">
                                    {{ $t('views.orcid_publications.artistic_wrong_discipline') }}
                                </b-tooltip>
                                <img v-if="publication.publicationTypeId !== 7 && author.authorType !== 3 && onlyUj != 1" style="margin-right: .3rem;" src="../assets/images/exclamation-mark.svg" :id="'ndiscipline-' + author.id + '-' + publication.id" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                                <b-tooltip :target="'ndiscipline-' + author.id + '-' + publication.id">
                                    <span class="no-discipline">{{ $t('views.orcid_publications.no_ndiscipline') }}</span>
                                </b-tooltip>
                              </template>
                              <template v-else>
                                <i v-if="author.authorType !== 3" class="fas fa-question red-color small">&nbsp;</i>
                                <a href="javascript:;" v-if="canEditAuthorDiscipline(author)" class="clicked red-color" @click="authorDisciplineEdit(author)">{{ $t('views.orcid_publications.discipline') }}</a>
                                <span v-else class="red-color" style="cursor: not-allowed">{{ $t('views.orcid_publications.discipline') }}</span>
                                <img v-if="publication.publicationTypeId !== 7 && author.authorType !== 3 && onlyUj != 1" src="../assets/images/exclamation-mark.svg" :id="'ndiscipline-' + author.id + '-' + publication.id" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                                <b-tooltip :target="'ndiscipline-' + author.id + '-' + publication.id">
                                    <span class="no-discipline">{{ $t('views.orcid_publications.no_ndiscipline') }}</span>
                                </b-tooltip>
                              </template>
                        </span>
                        <div v-else style="display: inline">
                          <span v-if="onlyUj != 1">
                            <img src="../assets/images/exclamation-mark.svg" :id="'discipline-' + author.id + '-' + publication.id" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                            <b-tooltip :target="'discipline-' + author.id + '-' + publication.id">
                                <span class="no-discipline">{{ $t('views.orcid_publications.no_discipline') }}</span>
                            </b-tooltip>
                          </span>
                        </div>
                        <div style="display: inline-block" v-if="author.disciplineId && author.disciplines">
                            <pcg-checkbox
                              class="my-checkbox authors-checkbox"
                              style="margin-right: .4rem"
                              variant="normal"
                              v-model="author.submitted"
                              @input="changeAuthorAgreement(author)"
                              :disabled="!canEditAuthorConsent(author)"
                            >
                              <span class="my-checkbox-text" :class="{ 'orange-color': (author.submitted === false), 'green-color': (author.submitted === true) }">
                                {{ $t('views.orcid_publications.evaluation_agreement') }}
                              </span>
                            </pcg-checkbox>
                        </div>
                        <a href="javascript:;" :aria-label="$t('views.orcid_publications.change_submitted_date')" v-if="canEditAuthorConsent(author) && author.disciplineId && author.disciplines && author.submitted" @click="showAuthorModal(author)">
                          <i class="far fa-calendar-alt" style="cursor: pointer" :id="'submitted-' + author.id + '-' + publication.id"></i>
                          <b-tooltip :target="'submitted-' + author.id + '-' + publication.id">
                            <span class="no-discipline">{{ $t('views.orcid_publications.change_submitted_date') }}</span>
                          </b-tooltip>
                        </a>
                        <img v-if="publication.publicationTypeId !== 7 && authorErrors(author)" src="../assets/images/exclamation-mark.svg" :id="'no_affiliation-' + author.id + '-' + publication.id" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                        <b-tooltip :target="'no_affiliation-' + author.id + '-' + publication.id">
                          <span class="no-discipline" :style="{ whiteSpace: 'pre-line' }">{{ authorErrors(author) }}</span>
                        </b-tooltip>
                      </div>
                  </template>
                  <template v-else>
                      <span>
                          <router-link v-if="canViewAuthor(author)" :to="{ name: 'employee_path', params: {id: author.employeeId } }" >
                              {{ author.name }}
                          </router-link>
                          <router-link v-else-if="author.employeeId === parseInt(employeeId)" :to="{ name: 'scientist_dashboard_path' }" >
                              {{ author.name }}
                          </router-link>
                          <span v-else style="cursor: not-allowed">
                              {{ author.name }}
                          </span>
                      </span>
                      <i v-if="author.authorType === 3" class="fas fa-graduation-cap" :id="'doctoral_student-' + author.id + '-' + publication.id"></i>
                      <b-tooltip :target="'doctoral_student-' + author.id + '-' + publication.id" v-if="author.authorType === 3">
                          {{ $t('views.orcid_publications.doctoral_student') }}
                      </b-tooltip>
                      <img v-if="author.orcid" src="../assets/images/orcid.png" :id="'author-' + author.id + '-' + publication.id" class="my-image" alt="ORCiD"/>
                      <b-tooltip :target="'author-' + author.id + '-' + publication.id" v-if="author.orcid">
                          <router-link v-if="currentUser.role !== 'naukowiec'" :to="{ name: 'orcid_publications_path', params: {id: author.employeeId } }" >
                              {{ author.orcid }}
                          </router-link>
                          <span v-else>{{ author.orcid }}</span>
                      </b-tooltip>
                        <i v-if="author.apiId && hasRight('view_employee_api_id')" class="fas fa-user" style="margin-right: .2rem; margin-left: .2rem;" :id="'aapi-' + author.id + '-' + publication.id"></i>
                        <b-tooltip :target="'aapi-' + author.id + '-' + publication.id" v-if="author.apiId && hasRight('view_employee_api_id')">
                          {{ author.apiId }}
                        </b-tooltip>
                        <i v-if="author.faculties && author.faculties.length > 0 && hasRight('list_unit_employee')" class="fas fa-bezier-curve" style="margin-right: .2rem; margin-left: .2rem;" :id="'faculties-' + author.id + '-' + publication.id"></i>
                        <b-tooltip :target="'faculties-' + author.id + '-' + publication.id" v-if="author.faculties && author.faculties.length > 0 && hasRight('list_unit_employee')">
                          <div v-if="author.faculties.length > 0" class="faculty-text">
                            <span v-for="(faculty, index) in author.faculties" :key="faculty.id" style="margin-right: .5rem">
                              <span v-if="faculty.parents.length > 0" :class="{ 'faculty-archive': faculty.archive }">
                                <span v-if="index === (author.faculties.length - 1)">{{ faculty.parents }} -> {{ faculty.name }}</span>
                                <span v-else>{{ faculty.parents }} -> {{ faculty.name }},</span>
                              </span>
                              <span v-else :class="{ 'faculty-archive': faculty.archive }">
                                <span v-if="index === (author.faculties.length - 1)">{{ faculty.name }}</span>
                                <span v-else>{{ faculty.name }},</span>
                              </span>
                            </span>
                          </div>
                        </b-tooltip>
                  </template>
              </div>
            </div>
            <b-modal ref="discipline"
                     :hide-header="true"
                     :centered="true"
                     v-bind:ok-title="$t('general.confirm')"
                     ok-variant="primary"
                     :no-close-on-esc="true"
                     @ok="authorDisciplineSave"
                     @hide="escAuthorDiscipline"
                     v-bind:cancel-title="$t('general.cancel')"
                     cancel-variant="default"
                     v-on-clickaway="escAuthorDiscipline"
                     content-class="my-modal-content"
                     footer-class="my-modal-footer"
            >
              <div class="edit-discipline" style="display: inline-block">{{ $t('views.orcid_publications.edit_discipline') }}</div>
              <div v-if="editedAuthor !== null" class="show-all-disciplines" style="display: inline-block">
                <pcg-checkbox
                  class="my-checkbox"
                  variant="normal"
                  v-model="showAuthorAll"
                  @input="!showAuthorAll"
                ><span class="my-checkbox-text">{{ $t('views.orcid_publications.all_disciplines') }}</span>
                </pcg-checkbox>
              </div>
              <pcg-select v-if="editedAuthor !== null"
                          :options="tDictionary(myAuthorDisciplines)"
                          v-model="editedAuthorDisciplineId"
                          class="my-select my-pcg-select"
                          style="width: 80%"
              />
              <span v-if="editedAuthor !== null && editedAuthor.authorType !== 3 && !editedAuthorCorrect">
                <img src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark-orange" style="margin-left: .5rem" :id="'statement-' + publication.id + editedAuthor" :alt="$t('general.exclamation_mark')"/>
                <b-tooltip :target="'statement-' + publication.id + editedAuthor">
                  {{ $t('views.orcid_publications.wrong_discipline') }}
                </b-tooltip>
              </span>
            </b-modal>
            <b-modal ref="submittedAuthorModal"
                     :hide-header="true"
                     :centered="true"
                     v-bind:ok-title="$t('general.confirm')"
                     ok-variant="primary"
                     :no-close-on-esc="true"
                     @ok="changeSubmitted(publication, editedAuthor)"
                     @hide="escSubmittedAuthorModal"
                     v-bind:cancel-title="$t('general.cancel')"
                     cancel-variant="default"
                     v-on-clickaway = "escSubmittedAuthorModal"
                     content-class="my-modal-content"
                     footer-class="my-modal-footer"
            >
              <div class="edit-discipline" style="display: inline-block">{{ $t('views.orcid_publications.edit_submitted_date') }}</div>
              <b-col cols="12" v-if="editedAuthor" style="margin-bottom: 2rem">
                <pcg-datetime-picker
                    class="my-datetimepicker art-premiere-date"
                    :label="$t('views.orcid_publications.submitted_date')"
                    :locale="$i18n.locale"
                    variant="date"
                    v-model="editedAuthor.submittedDate"
                    @clearDate="clearDate('author')"
                    :show-clear="true"
                />
              </b-col>
            </b-modal>
            <b-modal ref="changeAuthorAlternativePoints"
                     :hide-header="true"
                     footer-class="my-modal-footer"
                     :centered="true"
                     v-bind:ok-title="$t('general.confirm')"
                     ok-variant="primary"
                     :no-close-on-esc="true"
                     @ok="changeAuthorAlternativePoints"
                     v-bind:cancel-title="$t('general.cancel')"
                     cancel-variant="default"
                     content-class="my-modal-content"
            >
              <div class="confirm-header">{{ $t('components.publication_element.alternative_points') }}</div>
              <b-row v-for="error in pointsErrors" :key="error.id">
                <b-col>
                  <img src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                  <span class="my-error">{{ error }}</span>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="8">
                  <pcg-text-input
                    :label="$t('components.publication_element.points')"
                    :showLabel="true"
                    v-model="alternativePoints"
                    class="my-input"
                    style="margin-bottom: 2rem"
                  />
                </b-col>
                <b-col cols="3" v-if="changingPointsAuthor && changingPointsAuthor.alternativePoints">
                  <pcg-btn size="small" class="delete-points" @click="deleteAuthorAlternativePoints">{{ $t('general.delete') }}</pcg-btn>
                </b-col>
              </b-row>
            </b-modal>
            <b-modal ref="faculty"
                     :hide-header="true"
                     :centered="true"
                     size="lg"
                     v-bind:ok-title="$t('general.confirm')"
                     ok-variant="primary"
                     :no-close-on-esc="true"
                     @ok="authorFacultySave"
                     @hide="escAuthorFaculty"
                     v-bind:cancel-title="$t('general.cancel')"
                     cancel-variant="default"
                     v-on-clickaway="escAuthorFaculty"
                     content-class="my-modal-content"
                     footer-class="my-modal-footer"
            >
              <div class="d-flex justify-content-between">
                <div class="my-header">
                  <span class="edit-faculties">{{ $t('views.orcid_publications.edit_faculty') }}</span>
                </div>
                <div>
                  <pcg-btn size="small" variant="additional" @click="addFacultyAuthor" style="position: relative; bottom: 1rem;">{{ $t('components.dn_field.add') }}</pcg-btn>
                </div>
              </div>
              <div v-if="editedAuthor !== null" style="margin-bottom: 3rem">
                <transition-group name="fade" mode="out-in">
                  <div v-for="(faculty, index) in editedAuthor.unitIds" :key="index + 0" style="margin-bottom: 2rem">
                    <div class="one-faculty">{{ $t('views.orcid_publications.faculty') }}</div>
                    <b-row>
                      <b-col cols="11">
                        <live-search :clear-btn="false"
                                     :show-search-icon="false"
                                     :options="facultyOptions"
                                     v-model="editedAuthor.unitIds[index]"
                        />
                      </b-col>
                      <b-col cols="1">
                        <a href="javascript:;" :aria-label="$t('general.remove')" @click="removeFacultyAuthor(index)">
                          <i class="pcg-icon-cross"></i>
                        </a>
                      </b-col>
                    </b-row>
                  </div>
                </transition-group>
              </div>
            </b-modal>
            <template v-if="authorSpinner">
              <div class="text-primary">
                <spinner  v-if="authorSpinner"/>
              </div>
            </template>
            <div v-if="canEditAuthors()">
              <template v-if="!authorSpinner">
                <a href="javascript:;" :aria-label="$t('views.orcid_publications.edit_authors')" style="display: inline-block" class="publisher-edit clicked my-pen" @click="authorsEdit(publication)">
                  <i class="fas fa-pen" :id="'authors_edit-' + publication.id"></i>
                  <b-tooltip v-if="(publication.publicationTypeId === 3 || publication.publicationTypeId === 19 || publication.publicationTypeId === 21) && (publication.translationPl || publication.translationOther)" :target="'authors_edit-' + publication.id">
                    {{ $t('views.orcid_publications.edit_authors_translators') }}
                  </b-tooltip>
                  <b-tooltip v-else-if="publication.publicationTypeId === 7 && publication.patentType === 'PLANT_TYPE'" :target="'authors_edit-' + publication.id">
                    {{ $t('views.orcid_publications.edit_breeders') }}
                  </b-tooltip>
                  <b-tooltip v-else-if="(publication.publicationTypeId === 7 && publication.patentType !== 'PLANT_TYPE') || publication.publicationTypeId === 35" :target="'authors_edit-' + publication.id">
                    {{ $t('views.orcid_publications.edit_creators') }}
                  </b-tooltip>
                  <b-tooltip v-else :target="'authors_edit-' + publication.id">
                    {{ $t('views.orcid_publications.edit_authors') }}
                  </b-tooltip>
                </a>
              </template>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row  style="margin-top: .3rem" v-if="publication.authors.length === 0 && publication.publicationTypeId !== 21">
      <!-- Brak autorów-->
      <b-col class="col-auto pl-1 pr-1">
        <div class="first-column"></div>
      </b-col>
      <b-col>
        <div class="authors fitted d-flex justify-content-start">
          <div style="min-width: fit-content;">
            <img :src="imgAuthors" class="icon circle authors-icon" style="margin-right: .7rem" alt="">
            <span v-if="publication.publicationTypeId === 7 && publication.patentType === 'PLANT_TYPE'" class="author-text">{{ $t('views.orcid_publications.breeders') }}:</span>
            <span v-else-if="(publication.publicationTypeId === 7 && publication.patentType !== 'PLANT_TYPE') || publication.publicationTypeId === 35" class="author-text">{{ $t('views.orcid_publications.creators') }}:</span>
            <span v-else-if="publication.publicationTypeId === 45" class="author-text">{{ $t('views.orcid_publications.contributors') }}:</span>
            <span class="author-text" v-else-if="(publication.publicationTypeId === 3 || publication.publicationTypeId === 19 || publication.publicationTypeId === 21) && (publication.translationPl || publication.translationOther)">
              {{ $t('views.orcid_publications.authors_translators') }}:
            </span>
            <span v-else class="author-text">{{ $t('views.orcid_publications.authors') }}:</span>
            <b-modal ref="authors"
                     :hide-header="true"
                     :centered="true"
                     v-bind:ok-title="$t('general.confirm')"
                     ok-variant="primary"
                     :no-close-on-esc="true"
                     @ok="authorsSave"
                     @hide="escAuthors"
                     v-bind:cancel-title="$t('general.cancel')"
                     cancel-variant="default"
                     v-on-clickaway = "escAuthors"
                     content-class="my-modal-content"
                     footer-class="my-modal-footer"
            >
              <orcid-authors :authors="authors" :errors="errors" :publicationTypeId="publication.publicationTypeId" :managerDisciplines="managerDisciplines"/>
            </b-modal>
          </div>
          <template v-if="authorSpinner">
            <div class="text-primary">
              <spinner  v-if="authorSpinner"/>
            </div>
          </template>
          <div v-if="canEditAuthors()">
            <template v-if="!authorSpinner">
              <a href="javascript:;" :aria-label="$t('views.orcid_publications.edit_authors')" style="display: inline-block" class="publisher-edit clicked my-pen" @click="authorsEdit(publication)">
                <i class="fas fa-pen" :id="'authors_edit-' + publication.id"></i>
                <b-tooltip v-if="(publication.publicationTypeId === 3 || publication.publicationTypeId === 19 || publication.publicationTypeId === 21) && (publication.translationPl || publication.translationOther)" :target="'authors_edit-' + publication.id">
                  {{ $t('views.orcid_publications.edit_authors_translators') }}
                </b-tooltip>
                <b-tooltip v-else-if="publication.publicationTypeId === 7 && publication.patentType === 'PLANT_TYPE'" :target="'authors_edit-' + publication.id">
                  {{ $t('views.orcid_publications.edit_breeders') }}
                </b-tooltip>
                <b-tooltip v-else-if="(publication.publicationTypeId === 7 && publication.patentType !== 'PLANT_TYPE') || publication.publicationTypeId === 35" :target="'authors_edit-' + publication.id">
                  {{ $t('views.orcid_publications.edit_creators') }}
                </b-tooltip>
                <b-tooltip v-else :target="'authors_edit-' + publication.id">
                  {{ $t('views.orcid_publications.edit_authors') }}
                </b-tooltip>
              </a>
            </template>
          </div>
        </div>
      </b-col>
    </b-row>
    <template v-if="enableRedactors">
      <b-modal ref="disciplineRedactor"
               :hide-header="true"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="redactorDisciplineSave"
               @hide="escRedactorDiscipline"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               v-on-clickaway="escRedactorDiscipline"
               content-class="my-modal-content"
               footer-class="my-modal-footer"
      >
        <div class="edit-discipline" style="display: inline-block">{{ $t('views.orcid_publications.edit_discipline') }}</div>
        <div v-if="editedRedactor !== null && editedRedactor.authorType !== 3" class="show-all-disciplines" style="display: inline-block">
          <pcg-checkbox
            class="my-checkbox"
            variant="normal"
            v-model="showRedactorAll"
            @input="!showRedactorAll"
          ><span class="my-checkbox-text">{{ $t('views.orcid_publications.all_disciplines') }}</span>
          </pcg-checkbox>
        </div>
        <pcg-select v-if="editedRedactor !== null && editedRedactor.authorType === 3"
                    :options="tDictionary(myDoctorDisciplines)"
                    v-model="editedRedactorDisciplineId"
                    class="my-select my-pcg-select"
                    style="width: 80%"
        />
        <pcg-select v-if="editedRedactor !== null && editedRedactor.authorType !== 3"
                    :options="tDictionary(myRedactorDisciplines)"
                    v-model="editedRedactorDisciplineId"
                    class="my-select my-pcg-select"
                    style="width: 80%"
        />
        <span v-if="editedRedactor !== null && editedRedactor.authorType !== 3 && !editedRedactorCorrect">
                                    <img src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark-orange" style="margin-left: .5rem" :id="'redactor_statement-' + publication.id + editedRedactor" :alt="$t('general.exclamation_mark')"/>
                                    <b-tooltip :target="'redactor_statement-' + publication.id + editedRedactor">
                                        {{ $t('views.orcid_publications.wrong_discipline') }}
                                    </b-tooltip>
                                </span>
      </b-modal>
      <b-modal ref="submittedRedactorModal"
               :hide-header="true"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="changeSubmitted(publication, editedRedactor)"
               @hide="escSubmittedRedactorModal"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               v-on-clickaway = "escSubmittedRedactorModal"
               content-class="my-modal-content"
               footer-class="my-modal-footer"
      >
        <div class="edit-discipline" style="display: inline-block">{{ $t('views.orcid_publications.edit_submitted_date') }}</div>
        <b-col cols="12" v-if="editedRedactor" style="margin-bottom: 2rem">
          <pcg-datetime-picker
              class="my-datetimepicker art-premiere-date"
              :label="$t('views.orcid_publications.submitted_date')"
              :locale="$i18n.locale"
              variant="date"
              v-model="editedRedactor.submittedDate"
              @clearDate="clearDate('redactor')"
              :show-clear="true"
          />
        </b-col>
      </b-modal>
      <b-modal ref="changeRedactorAlternativePoints"
               :hide-header="true"
               footer-class="my-modal-footer"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="changeRedactorAlternativePoints"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               content-class="my-modal-content"
      >
        <div class="confirm-header">{{ $t('components.publication_element.alternative_points') }}</div>
        <b-row v-for="error in pointsErrors" :key="error.id">
          <b-col>
            <img src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
            <span class="my-error">{{ error }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="8">
            <pcg-text-input
              :label="$t('components.publication_element.points')"
              :showLabel="true"
              v-model="alternativePoints"
              class="my-input"
              style="margin-bottom: 2rem"
            />
          </b-col>
          <b-col cols="3" v-if="changingPointsAuthor && changingPointsAuthor.alternativePoints">
            <pcg-btn size="small" class="delete-points" @click="deleteRedactorAlternativePoints">{{ $t('general.delete') }}</pcg-btn>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal ref="redactors"
               :hide-header="true"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="redactorsSave"
               @hide="escRedactors"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               v-on-clickaway = "escRedactors"
               content-class="my-modal-content"
               footer-class="my-modal-footer"
      >
        <orcid-authors :authors="redactors" :is-authors="false" :errors="errors" :publicationTypeId="21" :managerDisciplines="managerDisciplines"/>
      </b-modal>
      <b-modal ref="facultyRedactor"
               :hide-header="true"
               size="lg"
               :centered="true"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="redactorFacultySave"
               @hide="escRedactorFaculty"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               v-on-clickaway="escRedactorFaculty"
               content-class="my-modal-content"
               footer-class="my-modal-footer"
      >
        <div class="d-flex justify-content-between">
          <div class="my-header">
            <span class="edit-faculties">{{ $t('views.orcid_publications.edit_faculty') }}</span>
          </div>
          <div>
            <pcg-btn size="small" variant="additional" @click="addFacultyRedactor" style="position: relative; bottom: 1rem;">{{ $t('components.dn_field.add') }}</pcg-btn>
          </div>
        </div>
        <div v-if="editedRedactor !== null" style="margin-bottom: 3rem">
          <transition-group name="fade" mode="out-in">
            <div v-for="(faculty, index) in editedRedactor.unitIds" :key="index + 0" style="margin-bottom: 2rem">
              <div class="one-faculty">{{ $t('views.orcid_publications.faculty') }}</div>
              <b-row>
                <b-col cols="11">
                  <pcg-select :options="tDictionary(myRedactorFaculties)"
                              v-model="editedRedactor.unitIds[index].id"
                              class="modal-select"
                  />
                </b-col>
                <b-col cols="1">
                  <a href="javascript:;" :aria-label="$t('general.remove')" @click="removeFacultyRedactor(index)">
                    <i class="pcg-icon-cross my-cross"></i>
                  </a>
                </b-col>
              </b-row>
            </div>
          </transition-group>
        </div>
      </b-modal>
      <b-row style="margin-top: .3rem; margin-bottom: 1.3rem;" v-if="publication.redactors.length > 0">
        <!-- Redaktorzy -->
        <b-col class="col-auto pl-1 pr-1">
          <div class="first-column"></div>
        </b-col>
        <b-col>
          <div class="authors fitted d-flex justify-content-start">
            <div v-if="publication.translationPl || publication.translationOther" style="min-width: fit-content;">
              <img :src="imgAuthors" class="icon circle authors-icon" style="margin-right: .7rem" alt="">
              <span class="author-text">{{ $t('views.orcid_publications.redactors_translators') }}:</span>
            </div>
            <div v-else style="min-width: fit-content;">
              <img :src="imgAuthors" class="icon circle authors-icon" style="margin-right: .7rem" alt="">
              <span class="author-text">{{ $t('views.orcid_publications.redactors') }}:</span>
            </div>
            <div class="authors-list">
              <div v-for="(redactor, index) in publication.redactors" :key="`r-${index}`" class="pcg-main-color">
                <span v-if="redactor.employeeId == null" class="dn-gray-color author">
                      {{ redactor.name }}
                </span>
                <div v-else-if="onlyUj == 1 && redactor.authorType === 1" class="author author-full no-discipline-list">
                  <span v-if="canViewAuthor(redactor)">
                    <router-link :to="{ name: 'employee_path', params: {id: redactor.employeeId } }" >
                      {{ redactor.name }}
                    </router-link>
                  </span>
                    <span v-else>
                    <router-link v-if="hasRight('view_own_employee')" :to="{ name: 'scientist_dashboard_path' }" >
                      {{ redactor.name }}
                    </router-link>
                    <span v-else style="cursor: not-allowed">{{ redactor.name }}</span>
                  </span>
                  <img v-if="redactor.orcid" src="../assets/images/orcid.png" :id="'redactor-' + redactor.id + '-' + publication.id" class="my-image" alt="ORCiD"/>
                  <b-tooltip :target="'redactor-' + redactor.id + '-' + publication.id" v-if="redactor.orcid">
                    <router-link :to="{ name: 'orcid_publications_path', params: {id: redactor.employeeId } }" >
                      {{ redactor.orcid }}
                    </router-link>
                  </b-tooltip>
                  <i v-if="redactor.apiId && hasRight('view_employee_api_id')" class="fas fa-user" style="margin-right: .2rem; margin-left: .2rem;" :id="'aapi-redactor-' + redactor.id + '-' + publication.id"></i>
                  <b-tooltip :target="'aapi-redactor-' + redactor.id + '-' + publication.id" v-if="redactor.apiId && hasRight('view_employee_api_id')">
                    {{ redactor.apiId }}
                  </b-tooltip>
                  <i v-if="redactor.faculties && redactor.faculties.length > 0 && hasRight('list_unit_employee')" class="fas fa-bezier-curve" style="margin-right: .2rem; margin-left: .2rem;" :id="'faculties-redactor-' + redactor.id + '-' + publication.id"></i>
                  <b-tooltip :target="'faculties-redactor-' + redactor.id + '-' + publication.id" v-if="redactor.faculties && redactor.faculties.length > 0 && hasRight('list_unit_employee')">
                    <div v-if="redactor.faculties.length > 0" class="faculty-text">
                          <span v-for="(faculty, index) in redactor.faculties" :key="faculty.id" style="margin-right: .5rem">
                            <span v-if="faculty.parents.length > 0" :class="{ 'faculty-archive': faculty.archive }">
                              <span v-if="index === (redactor.faculties.length - 1)">{{ faculty.parents }} -> {{ faculty.name }}</span>
                              <span v-else>{{ faculty.parents }} -> {{ faculty.name }},</span>
                            </span>
                            <span v-else :class="{ 'faculty-archive': faculty.archive }">
                              <span v-if="index === (redactor.faculties.length - 1)">{{ faculty.name }}</span>
                              <span v-else>{{ faculty.name }},</span>
                            </span>
                          </span>
                    </div>
                  </b-tooltip>
                  <img v-if="publication.publicationTypeId !== 7 && authorErrors(redactor)" src="../assets/images/exclamation-mark.svg" :id="'no_affiliation-' + redactor.id + '-' + publication.id" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                  <b-tooltip :target="'no_affiliation-' + redactor.id + '-' + publication.id">
                    <span class="no-discipline" :style="{ whiteSpace: 'pre-line' }">{{ authorErrors(redactor) }}</span>
                  </b-tooltip>
                </div>
                <div class="author" v-else>
                  <template v-if="currentUser.role !== 'naukowiec' || redactor.employeeId === parseInt(employeeId)">
                    <div class="author-full" :class="{
                         'no-discipline-list': ((publication.year === 2017 && redactor.ndisciplines === null) || (publication.year !== 2017 && redactor.disciplines === null && redactor.authorType !== 3) || redactor.employeeId === null || authorErrors(redactor)),
                         'discipline-not-chosen': !authorErrors(redactor) && redactor.employeeId !== null && (((publication.year === 2017 && redactor.ndisciplines !== null && redactor.disciplineId === null) || ((publication.year !== 2017 && redactor.disciplines !== null && redactor.disciplineId === null) || (publication.year !== 2017 && redactor.authorType === 3 && redactor.disciplineId === null)))),
                         'discipline-chosen': !authorErrors(redactor) && redactor.employeeId !== null && (((publication.year === 2017 && redactor.ndisciplines !== null && redactor.disciplineId !== null) || ((publication.year !== 2017 && redactor.disciplines != null && redactor.disciplineId != null) || (publication.year !== 2017 && redactor.authorType === 3 && redactor.disciplineId !== null))))}">
                      <span class="points" :class="pointsClass(publicationPoints(redactor))"
                            v-if="redactor.submitted && publication.publicationTypeId !== 19 &&
                                 !(hasRight('edit_points') || hasRight('edit_points_expert')) &&
                                 publicationPoints(redactor) > 0 &&
                                 (hasRight('view_points') || hasRight('view_own_points'))">
                        {{ publicationPoints(redactor) }}
                      </span>
                      <span class="points" :class="pointsClass(publicationPoints(redactor))"
                            v-if="redactor.submitted && publication.publicationTypeId !== 19 &&
                                 (hasRight('edit_points') || hasRight('edit_points_expert')) &&
                                 !redactor.alternativePoints &&
                                 publicationPoints(redactor) > 0 &&
                                 (hasRight('view_points') || hasRight('view_own_points'))"
                            @mouseover="redactorMouseOver(index)" @mouseleave="redactorMouseLeave(index)"
                      >
                        <span>{{ publicationPoints(redactor) }}</span>
                        <i v-show="redactorsHover[index] && !disableEditing" class="fas fa-pen points-pen" @click="editRedactorAlternativePoints(redactor)" :id="'points_redactor-' + redactor.id"></i>
                        <b-tooltip :target="'points_redactor-' + redactor.id">
                          {{ $t('components.publication_element.add_alternative_points') }}
                        </b-tooltip>
                      </span>
                      <span class="points" :class="pointsClass( publicationPoints(redactor) + redactor.alternativePoints)"
                            v-if="redactor.submitted && publication.publicationTypeId !== 19 &&
                                   (hasRight('edit_points') || hasRight('edit_points_expert')) &&
                                   redactor.alternativePoints && publicationPoints(redactor) > 0 &&
                                   (hasRight('view_points') || hasRight('view_own_points'))"
                            @mouseover="redactorMouseOver(index)" @mouseleave="redactorMouseLeave(index)"
                      >
                        <span>{{ redactor.alternativePoints }} </span>
                        <span>({{ publicationPoints(redactor) }})</span>
                        <i v-if="redactorsHover[index] && !disableEditing" class="fas fa-pen points-pen" @click="editRedactorAlternativePoints(redactor)" :id="'points_redactor-' + redactor.id"></i>
                        <b-tooltip v-if="redactorsHover[index]" :target="'points_redactor-' + redactor.id">
                          {{ $t('components.publication_element.edit_alternative_points') }}
                        </b-tooltip>
                      </span>
                      <span v-if="currentUser.role !== 'naukowiec' && hasRight('view_employee')">
                        <router-link :to="{ name: 'employee_path', params: {id: redactor.employeeId } }" >
                          {{ redactor.name }}
                        </router-link>
                      </span>
                      <span v-else>
                        <router-link v-if="hasRight('view_own_employee')" :to="{ name: 'scientist_dashboard_path' }" >
                          {{ redactor.name }}
                        </router-link>
                        <span v-else>{{ redactor.name }}</span>
                      </span>
                      <i v-if="redactor.authorType === 3" class="fas fa-graduation-cap" :id="'doctoral_student-' + redactor.id + '-' + publication.id"></i>
                      <b-tooltip :target="'doctoral_student-' + redactor.id + '-' + publication.id" v-if="redactor.authorType === 3">
                        {{ $t('views.orcid_publications.doctoral_student') }}
                      </b-tooltip>
                      <img v-if="redactor.orcid" src="../assets/images/orcid.png" :id="'redactor-' + redactor.id + '-' + publication.id" class="my-image" alt="ORCiD"/>
                      <b-tooltip :target="'redactor-' + redactor.id + '-' + publication.id" v-if="redactor.orcid">
                        <router-link :to="{ name: 'orcid_publications_path', params: {id: redactor.employeeId } }" >
                          {{ redactor.orcid }}
                        </router-link>
                      </b-tooltip>
                      <i v-if="redactor.apiId && hasRight('view_employee_api_id')" class="fas fa-user" style="margin-right: .2rem; margin-left: .2rem;" :id="'aapi-redactor-' + redactor.id + '-' + publication.id"></i>
                      <b-tooltip :target="'aapi-redactor-' + redactor.id + '-' + publication.id" v-if="redactor.apiId && hasRight('view_employee_api_id')">
                        {{ redactor.apiId }}
                      </b-tooltip>
                      <i v-if="redactor.faculties && redactor.faculties.length > 0 && hasRight('list_unit_employee')" class="fas fa-bezier-curve" style="margin-right: .2rem; margin-left: .2rem;" :id="'faculties-redactor-' + redactor.id + '-' + publication.id"></i>
                      <b-tooltip :target="'faculties-redactor-' + redactor.id + '-' + publication.id" v-if="redactor.faculties && redactor.faculties.length > 0 && hasRight('list_unit_employee')">
                        <div v-if="redactor.faculties.length > 0" class="faculty-text">
                          <span v-for="(faculty, index) in redactor.faculties" :key="faculty.id" style="margin-right: .5rem">
                            <span v-if="faculty.parents.length > 0" :class="{ 'faculty-archive': faculty.archive }">
                              <span v-if="index === (redactor.faculties.length - 1)">{{ faculty.parents }} -> {{ faculty.name }}</span>
                              <span v-else>{{ faculty.parents }} -> {{ faculty.name }},</span>
                            </span>
                            <span v-else :class="{ 'faculty-archive': faculty.archive }">
                              <span v-if="index === (redactor.faculties.length - 1)">{{ faculty.name }}</span>
                              <span v-else>{{ faculty.name }},</span>
                            </span>
                          </span>
                        </div>
                      </b-tooltip>
                      <span v-if="hasRight('list_author_faculty')" style="margin-right: .2rem; margin-left: .2rem;">
                        <template v-if="redactor.unitIds && redactor.unitIds.length > 0">
                          <a href="javascript:;" v-if="canEditAuthorFaculty(redactor)" class="clicked editing-discipline" @click="redactorFacultyEdit(redactor)">
                            <span v-for="(faculty, index) in redactor.unitIds" :key="faculty.id">
                              <span v-if="index === 0">{{ faculty.value }}</span>
                              <span v-else>, {{ faculty.value }}</span>
                            </span>
                          </a>
                          <span v-else style="margin-right: .2rem; cursor: not-allowed">
                            <span v-for="(faculty, index) in redactor.unitIds" :key="faculty.id">
                              <span v-if="index === 0">{{ faculty.value }}</span>
                              <span v-else>, {{ faculty.value }}</span>
                            </span>
                          </span>
                        </template>
                        <template v-else>
                          <i class="fas fa-question orange-color small">&nbsp;</i>
                          <a href="javascript:;" v-if="canEditAuthorFaculty(redactor)" class="clicked orange-color" @click="redactorFacultyEdit(redactor)">{{ $t('views.orcid_publications.faculty') }}</a>
                          <span v-else class="orange-color" style="cursor: not-allowed">{{ $t('views.orcid_publications.faculty') }}</span>
                        </template>
                      </span>
                      <span v-if="redactor.disciplines || redactor.authorType === 3">
                        <template v-if="publication.year === 2017">
                          <template v-if="redactor.disciplineId && redactor.ndisciplines">
                            <i class="fas fa-check green-color small">&nbsp;</i>
                            <a href="javascript:;" v-if="canEditAuthorDiscipline(redactor)" class="clicked editing-discipline" @click="redactorDisciplineEdit(redactor)">{{ authorDisciplineName(redactor) }}</a>
                            <span v-else style="margin-right: .2rem;">{{ authorDisciplineName(redactor) }}</span>
                            <img v-if="!fromRedactorStatements[index] && redactor.authorType !== 3" src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark-orange" style="margin-left: .5rem; margin-right: .5rem" :id="'redactor_statement-' + publication.id + redactor.id" :alt="$t('general.exclamation_mark')"/>
                            <b-tooltip v-if="!fromRedactorStatements[index] && redactor.authorType !== 3" :target="'redactor_statement-' + publication.id + redactor.id">
                              {{ $t('views.orcid_publications.wrong_discipline') }}
                            </b-tooltip>
                          </template>
                          <template v-else-if="!redactor.disciplineId && redactor.ndisciplines">
                            <i class="fas fa-question orange-color small">&nbsp;</i>
                            <a href="javascript:;" v-if="canEditAuthorDiscipline(redactor)" class="clicked orange-color" @click="redactorDisciplineEdit(redactor)">{{ $t('views.orcid_publications.discipline') }}</a>
                            <span v-else class="orange-color">{{ $t('views.orcid_publications.discipline') }}</span>
                          </template>
                          <template v-else-if="redactor.disciplineId && !redactor.ndisciplines">
                            <i v-if="redactor.authorType !== 3" class="fas fa-check red-color small">&nbsp;</i>
                            <a href="javascript:;" v-if="canEditAuthorDiscipline(redactor)" class="clicked editing-discipline" @click="redactorDisciplineEdit(redactor)">{{ authorDisciplineName(redactor) }}</a>
                            <span v-else style="margin-right: .2rem;">{{ authorDisciplineName(redactor) }}</span>
                            <img v-if="!fromRedactorStatements[index] && redactor.authorType !== 3" src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark-orange" style="margin-left: .5rem; margin-right: .5rem" :id="'redactor_statement-' + publication.id + redactor.id" :alt="$t('general.exclamation_mark')"/>
                            <b-tooltip v-if="!fromRedactorStatements[index] && redactor.authorType !== 3" :target="'redactor_statement-' + publication.id + redactor.id">
                              {{ $t('views.orcid_publications.wrong_discipline') }}
                            </b-tooltip>
                            <img v-if="redactor.authorType !== 3 && onlyUj != 1" style="margin-right: .3rem;" src="../assets/images/exclamation-mark.svg" :id="'redactor_ndiscipline-' + redactor.id + '-' + publication.id" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                            <b-tooltip :target="'redactor_ndiscipline-' + redactor.id + '-' + publication.id">
                              <span class="no-discipline">{{ $t('views.orcid_publications.no_ndiscipline') }}</span>
                            </b-tooltip>
                          </template>
                          <template v-else>
                            <i v-if="redactor.authorType !== 3" class="fas fa-question red-color small">&nbsp;</i>
                            <a href="javascript:;" v-if="canEditAuthorDiscipline(redactor)" class="clicked red-color" @click="redactorDisciplineEdit(redactor)">{{ $t('views.orcid_publications.discipline') }}</a>
                            <span v-else class="red-color">{{ $t('views.orcid_publications.discipline') }}</span>
                            <img v-if="redactor.authorType !== 3 &onlyUj != 1" src="../assets/images/exclamation-mark.svg" :id="'redactor_ndiscipline-' + redactor.id + '-' + publication.id" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                            <b-tooltip :target="'redactor_ndiscipline-' + redactor.id + '-' + publication.id">
                              <span class="no-discipline">{{ $t('views.orcid_publications.no_ndiscipline') }}</span>
                            </b-tooltip>
                          </template>
                        </template>
                        <template v-else>
                          <template v-if="redactor.disciplineId">
                            <i class="fas fa-check green-color small">&nbsp;</i>
                            <a href="javascript:;" v-if="canEditAuthorDiscipline(redactor)" class="clicked editing-discipline" @click="redactorDisciplineEdit(redactor)">{{ authorDisciplineName(redactor) }}</a>
                            <span v-else style="margin-right: .2rem;">{{ authorDisciplineName(redactor) }}</span>
                            <img v-if="!fromRedactorStatements[index] && redactor.authorType !== 3" src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark-orange" style="margin-left: .5rem; margin-right: .5rem" :id="'redactor_statement-' + publication.id + redactor.id" :alt="$t('general.exclamation_mark')"/>
                            <b-tooltip v-if="!fromRedactorStatements[index] && redactor.authorType !== 3" :target="'redactor_statement-' + publication.id + redactor.id">
                              {{ $t('views.orcid_publications.wrong_discipline') }}
                            </b-tooltip>
                          </template>
                          <template v-else>
                            <i class="fas fa-question orange-color small">&nbsp;</i>
                            <a href="javascript:;" v-if="canEditAuthorDiscipline(redactor)" class="clicked orange-color" @click="redactorDisciplineEdit(redactor)">{{ $t('views.orcid_publications.discipline') }}</a>
                            <span v-else class="orange-color">{{ $t('views.orcid_publications.discipline') }}</span>
                          </template>
                        </template>
                      </span>
                      <div v-else style="display: inline">
                        <span v-if="onlyUj != 1">
                          <img src="../assets/images/exclamation-mark.svg" :id="'discipline-' + redactor.id + '-' + publication.id" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                          <b-tooltip :target="'discipline-' + redactor.id + '-' + publication.id">
                            <span class="no-discipline">{{ $t('views.orcid_publications.no_discipline') }}</span>
                          </b-tooltip>
                        </span>
                      </div>
                      <div style="display: inline-block" v-if="redactor.disciplineId && redactor.disciplines">
                        <pcg-checkbox
                            class="my-checkbox authors-checkbox"
                            style="margin-right: .4rem"
                            variant="normal"
                            v-model="redactor.submitted"
                            @input="changeRedactorAgreement(redactor)"
                            :disabled="!canEditAuthorConsent(redactor)"
                        ><span class="my-checkbox-text" :class="{ 'orange-color': (redactor.submitted === false), 'green-color': (redactor.submitted === true) }">{{ $t('views.orcid_publications.evaluation_agreement') }}</span>
                        </pcg-checkbox>
                      </div>
                      <a href="javascript:;" :aria-label="$t('views.orcid_publications.change_submitted_date')" v-if="canEditAuthorConsent(redactor) && redactor.disciplineId && redactor.disciplines && redactor.submitted" @click="showRedactorModal(redactor)">
                        <i class="far fa-calendar-alt" style="cursor: pointer" :id="'submitted-' + redactor.id + '-' + publication.id"></i>
                        <b-tooltip :target="'submitted-' + redactor.id + '-' + publication.id">
                          <span class="no-discipline">{{ $t('views.orcid_publications.change_submitted_date') }}</span>
                        </b-tooltip>
                      </a>
                      <img v-if="authorErrors(redactor)" src="../assets/images/exclamation-mark.svg" :id="'no_affiliation-' + redactor.id + '-' + publication.id" class="my-exclamation-mark" :alt="$t('general.exclamation_mark')"/>
                      <b-tooltip :target="'no_affiliation-' + redactor.id + '-' + publication.id">
                        <span class="no-discipline" :style="{ whiteSpace: 'pre-line' }">{{ authorErrors(redactor) }}</span>
                      </b-tooltip>
                    </div>
                  </template>
                  <template v-else>
                    <span>
                      <router-link v-if="currentUser.role !== 'naukowiec' && hasRight('view_employee')" :to="{ name: 'employee_path', params: {id: redactor.employeeId } }" >
                        {{ redactor.name }}
                      </router-link>
                      <span v-else>
                        {{ redactor.name }}
                      </span>
                    </span>
                    <i v-if="redactor.authorType === 3" class="fas fa-graduation-cap" :id="'doctoral_student-' + redactor.id + '-' + publication.id"></i>
                    <b-tooltip :target="'doctoral_student-' + redactor.id + '-' + publication.id" v-if="redactor.authorType === 3">
                      {{ $t('views.orcid_publications.doctoral_student') }}
                    </b-tooltip>
                    <img v-if="redactor.orcid" src="../assets/images/orcid.png" :id="'redactor-' + redactor.id + '-' + publication.id" class="my-image" alt="ORCiD"/>
                    <b-tooltip :target="'redactor-' + redactor.id + '-' + publication.id" v-if="redactor.orcid">
                      <router-link v-if="currentUser.role !== 'naukowiec'" :to="{ name: 'orcid_publications_path', params: {id: redactor.employeeId } }" >
                        {{ redactor.orcid }}
                      </router-link>
                      <span v-else>
                        {{ redactor.orcid }}
                      </span>
                    </b-tooltip>
                    <i v-if="redactor.apiId && hasRight('view_employee_api_id')" class="fas fa-user" style="margin-right: .2rem; margin-left: .2rem;" :id="'aapi-redactor-' + redactor.id + '-' + publication.id"></i>
                    <b-tooltip :target="'aapi-redactor-' + redactor.id + '-' + publication.id" v-if="redactor.apiId && hasRight('view_employee_api_id')">
                      {{ redactor.apiId }}
                    </b-tooltip>
                    <i v-if="redactor.faculties && redactor.faculties.length > 0 && hasRight('list_unit_employee')" class="fas fa-bezier-curve" style="margin-right: .2rem; margin-left: .2rem;" :id="'faculties-redactor-' + redactor.id + '-' + publication.id"></i>
                    <b-tooltip :target="'faculties-redactor-' + redactor.id + '-' + publication.id" v-if="redactor.faculties && redactor.faculties.length > 0 && hasRight('list_unit_employee')">
                      <div v-if="redactor.faculties.length > 0" class="faculty-text">
                        <span v-for="(faculty, index) in redactor.faculties" :key="faculty.id" style="margin-right: .5rem">
                          <span v-if="faculty.parents.length > 0" :class="{ 'faculty-archive': faculty.archive }">
                            <span v-if="index === (redactor.faculties.length - 1)">{{ faculty.parents }} -> {{ faculty.name }}</span>
                            <span v-else>{{ faculty.parents }} -> {{ faculty.name }},</span>
                          </span>
                          <span v-else :class="{ 'faculty-archive': faculty.archive }">
                            <span v-if="index === (redactor.faculties.length - 1)">{{ faculty.name }}</span>
                            <span v-else>{{ faculty.name }},</span>
                          </span>
                        </span>
                      </div>
                    </b-tooltip>
                  </template>
                </div>
              </div>
              <div v-if="canEditAuthors()">
                <template v-if="redactorSpinner">
                  <div class="text-primary">
                    <spinner  v-if="redactorSpinner"/>
                  </div>
                </template>
                <template v-else>
                  <a href="javascript:;" :aria-label="$t('views.orcid_publications.edit_redactors')" style="display: inline-block" class="publisher-edit clicked my-pen" @click="redactorsEdit(publication)">
                    <i class="fas fa-pen" :id="'redactors_edit-' + publication.id"></i>
                    <b-tooltip v-if="publication.translationPl || publication.translationOther" :target="'redactors_edit-' + publication.id">
                      {{ $t('views.orcid_publications.edit_redactors_translators') }}
                    </b-tooltip>
                    <b-tooltip v-else :target="'redactors_edit-' + publication.id">
                      {{ $t('views.orcid_publications.edit_redactors') }}
                    </b-tooltip>
                  </a>
                </template>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row style="margin-top: .3rem; margin-bottom: 1.3rem;" v-if="publication.redactors.length === 0 && currentUser.role !== 'naukowiec'">
        <!-- Brak redaktorów -->
        <b-col class="col-auto pl-1 pr-1">
          <div class="first-column"></div>
        </b-col>
        <b-col>
          <div class="authors fitted d-flex justify-content-start">
            <div v-if="publication.translationPl || publication.translationOther" style="min-width: fit-content;">
              <img :src="imgAuthors" class="icon circle authors-icon" style="margin-right: .7rem" alt="">
              <span class="author-text">{{ $t('views.orcid_publications.redactors_translators') }}:</span>
            </div>
            <div v-else style="min-width: fit-content;">
              <img :src="imgAuthors" class="icon circle authors-icon" style="margin-right: .7rem" alt="">
              <span class="author-text">{{ $t('views.orcid_publications.redactors') }}:</span>
            </div>
            <div class="authors-list">
              <div v-if="canEditAuthors()">
                <template v-if="redactorSpinner">
                  <div class="text-primary">
                    <spinner  v-if="redactorSpinner"/>
                  </div>
                </template>
                <template v-else>
                  <a href="javascript:;" :aria-label="$t('views.orcid_publications.edit_redactors')" style="display: inline-block" class="publisher-edit clicked my-pen" @click="redactorsEdit(publication)">
                    <i class="fas fa-pen" :id="'redactors_edit-' + publication.id"></i>
                    <b-tooltip v-if="publication.translationPl || publication.translationOther" :target="'redactors_edit-' + publication.id">
                      {{ $t('views.orcid_publications.edit_redactors_translators') }}
                    </b-tooltip>
                    <b-tooltip v-else :target="'redactors_edit-' + publication.id">
                      {{ $t('views.orcid_publications.edit_redactors') }}
                    </b-tooltip>
                  </a>
                </template>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </template>
    <b-row v-if="publication.otherContributorsSize > 0">
      <b-col class="col-auto pl-1 pr-1">
        <div class="first-column"></div>
      </b-col>
      <b-col>
        <div class="authors fitted d-flex justify-content-start">
          <div>
            <img :src="imgAuthors" class="icon circle authors-icon" style="margin-right: .7rem" alt="">
            <span class="other-contributor-text">{{ $t('views.orcid_publications.other_contributors') }}:</span>
            <span class="other-contributor-text">{{ publication.otherContributorsSize }}</span>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import OrcidAuthors from './OrcidAuthors'
import Spinner from './Spinner'
import ApiPublications from '../api/publications'
import { mixin as clickaway } from 'vue-clickaway'
import hasRight from '../mixins/has_right'
import translateDictionary from '../mixins/translate_dictionary'
import waitForJobs from '../mixins/wait_for_jobs'
import { mapGetters } from 'vuex'
import pointsClasses from '../mixins/points_classes'
import ApiEmployeePublications from '../api/employee_publications'
import LiveSearch from './LiveSearch.vue'

export default {
  name: 'Authors',
  components: { LiveSearch, OrcidAuthors, Spinner },
  mixins: [clickaway, hasRight, pointsClasses, translateDictionary, waitForJobs],
  data: function () {
    return {
      authors: [],
      authorSpinner: false,
      currentUser: {
        id: '',
        author: '',
        entity: '',
        avatar: '',
        role: ''
      },
      editedAuthor: null,
      editedRedactor: null,
      errors: [],
      fromAuthorStatements: [],
      fromRedactorStatements: [],
      imgAuthors: require('../assets/images/authors.svg'),
      redactors: [],
      redactorSpinner: false,
      owner: false,
      copiedDisciplines: [],
      showAuthorAll: false,
      showRedactorAll: false,
      myDoctorDisciplines: [],
      myAuthorDisciplines: [],
      myRedactorDisciplines: [],
      editedAuthorCorrect: false,
      editedRedactorCorrect: false,
      alternativePoints: null,
      changingPointsAuthor: null,
      authorsHover: [],
      redactorsHover: [],
      pointsErrors: [],
      translationOriginalAuthors: [],
      originalAuthorSpinner: false,
      myAuthorFaculties: [],
      myRedactorFaculties: []
    }
  },
  props: {
    publication: Object,
    autoUpdate: {
      type: Boolean,
      default: true
    },
    employeeId: String,
    enableRedactors: {
      type: Boolean,
      default: true
    },
    withDiscipline: {
      type: Boolean,
      default: true
    },
    disableEditing: {
      type: Boolean,
      default: false
    },
    fromForm: {
      type: Boolean,
      default: false
    },
    managerDisciplines: Array,
    onlyUj: String
  },
  created () {
    this.owner = (this.$store.getters['auth/employeeId'] === this.employeeId)
  },
  computed: {
    ...mapGetters('disciplines', ['disciplines']),
    disciplineId: {
      get () {
        return this.discipline_id
      },
      set (newValue) {
        this.discipline_id = parseInt(newValue)
      }
    },
    facultyOptions () {
      return {
        path: 'faculties/search',
        params: {}
      }
    },
    editedAuthorDisciplineId: {
      get () {
        if (this.editedAuthor.disciplineId === undefined || this.editedAuthor.disciplineId == null) {
          return null
        } else {
          return this.editedAuthor.disciplineId
        }
      },
      set (newValue) {
        this.editedAuthor.disciplineId = parseInt(newValue)
      }
    },
    editedRedactorDisciplineId: {
      get () {
        if (this.editedRedactor.disciplineId === undefined || this.editedRedactor.disciplineId == null) {
          return null
        } else {
          return this.editedRedactor.disciplineId
        }
      },
      set (newValue) {
        this.editedRedactor.disciplineId = parseInt(newValue)
      }
    }
  },
  watch: {
    editedAuthor: {
      deep: true,
      handler () {
        this.initializeAuthorDisciplines()
        this.checkEditedAuthorStatements()
        this.checkAuthorStatements()
        this.initializeAuthorFaculties()
      }
    },
    editedRedactor: {
      deep: true,
      handler () {
        this.initializeRedactorDisciplines()
        this.checkEditedRedactorStatements()
        this.checkRedactorStatements()
        this.initializeRedactorFaculties()
      }
    },
    publication: {
      deep: true,
      handler () {
        this.hasWrongDiscipline()
        this.checkAuthorStatements()
        this.checkRedactorStatements()
      }
    },
    showAuthorAll: {
      deep: true,
      handler () {
        this.initializeAuthorDisciplines()
      }
    },
    showRedactorAll: {
      deep: true,
      handler () {
        this.initializeRedactorDisciplines()
      }
    },
    disciplines: {
      deep: true,
      handler () {
        this.initializeDisciplines()
      }
    }
  },
  beforeMount () {
    if (this.$store.getters['disciplines/disciplines'].length === 0) {
      this.$store.dispatch('disciplines/setDisciplines', null)
    }
    this.currentUser = {
      id: this.$store.getters['auth/id'],
      author: this.$store.getters['auth/fullName'],
      entity: '',
      avatar: this.$store.getters['auth/avatar'],
      role: this.$store.getters['auth/currentRole'],
      facultyId: this.$store.getters['auth/facultyId'],
      facultyChildrenIds: this.$store.getters['auth/facultyChildrenIds']
    }
  },
  mounted () {
    this.initializeDisciplines()
    this.checkAuthorStatements()
    this.checkRedactorStatements()
    this.initializeDoctorDisciplines()
    this.hasWrongDiscipline()
    this.publication.authors.forEach(author => {
      this.authorsHover.push(false)
    })
    this.publication.redactors.forEach(redactor => {
      this.redactorsHover.push(false)
    })
  },
  methods: {
    addFacultyAuthor () {
      this.editedAuthor.unitIds.push({ id: null, value: null })
    },
    removeFacultyAuthor (id) {
      this.editedAuthor.unitIds.splice(id, 1)
    },
    addFacultyRedactor () {
      this.editedRedactor.unitIds.push({ id: null, value: null })
    },
    removeFacultyRedactor (id) {
      this.editedRedactor.unitIds.splice(id, 1)
    },
    clearDate (date) {
      switch (date) {
        case 'author':
          this.editedAuthor.submittedDate = null
          break
        case 'redactor':
          this.editedRedactor.submittedDate = null
          break
        default:
      }
    },
    changeAuthorAgreement (author) {
      this.editedAuthor = this.copyAuthor(author)
      this.editedAuthor.submittedDate = null
      this.changeSubmitted(this.publication, this.editedAuthor)
    },
    changeRedactorAgreement (redactor) {
      this.editedRedactor = this.copyAuthor(redactor)
      this.editedRedactor.submittedDate = null
      this.changeSubmitted(this.publication, this.editedRedactor)
    },
    showAuthorModal (author) {
      this.editedAuthor = this.copyAuthor(author)
      this.$refs.submittedAuthorModal.show()
    },
    showRedactorModal (redactor) {
      this.editedRedactor = this.copyAuthor(redactor)
      this.$refs.submittedRedactorModal.show()
    },
    hasWrongDiscipline () {
      this.publication.authors.forEach(author => {
        if (author.disciplineId && this.publication.allowedPeriodicalIds && !this.publication.allowedPeriodicalIds.includes(author.disciplineId)) {
          author.wrongDiscipline = true
        } else {
          author.wrongDiscipline = false
        }
      })
    },
    canEdit () {
      return this.hasRight('edit_achievement') || (this.owner && this.hasRight('edit_own_achievement'))
    },
    canEditAuthors () {
      return (this.hasRight('edit_authors') || this.hasRight('edit_own_authors') || this.hasRight('edit_authors_manager')) && !this.disableEditing
    },
    canEditAuthorDiscipline (author) {
      const disciplineId = author.disciplineId
      if (this.currentUser.role === 'pracownikjednostki') {
        return this.forUnitWorker(author)
      }
      return (this.hasRight('edit_author_discipline') || this.hasRight('edit_own_author_discipline') || (this.hasRight('edit_author_discipline_manager') && (disciplineId === null || this.managerDisciplines.includes(disciplineId)))) && !this.disableEditing
    },
    canEditAuthorConsent (author) {
      const disciplineId = author.disciplineId
      if (this.currentUser.role === 'pracownikjednostki') {
        return this.forUnitWorker(author)
      }
      return (this.hasRight('edit_author_consent_evaluation') || this.hasRight('edit_own_author_consent_evaluation') || (this.hasRight('edit_author_consent_evaluation_manager') && this.managerDisciplines.includes(disciplineId))) && !this.disableEditing
    },
    canViewAuthor (author) {
      if (this.currentUser.role === 'pracownikjednostki') {
        return this.forUnitWorker(author)
      }
      return this.hasRight('view_employee')
    },
    canEditAuthorFaculty (author) {
      if (this.currentUser.role === 'pracownikjednostki') {
        return this.forUnitWorker(author)
      }
      return (this.hasRight('edit_author_faculty') || this.hasRight('edit_own_author_faculty') || (this.hasRight('edit_author_faculty_manager'))) && !this.disableEditing
    },
    forUnitWorker (author) {
      if (!author.faculties) { return false }
      const facultiesIds = author.faculties.map((f) => f.id.toString())
      if (facultiesIds.length === 0) { return false }
      const fIds = [...this.currentUser.facultyChildrenIds]
      fIds.push(this.currentUser.facultyId)
      const userFaculties = [...new Set(fIds)]
      var difference = facultiesIds.filter(x => !userFaculties.includes(x))
      return difference.length <= 0
    },
    authorDisciplineName (author) {
      if (author.disciplineId === null) {
        return ''
      }
      const idx = this.disciplines.findIndex(b => b.id.toString() === author.disciplineId.toString())
      if (idx >= 0) {
        if (this.$i18n.locale === 'pl') {
          return this.disciplines[idx].text
        } else {
          return this.disciplines[idx].text_en
        }
      } else {
        return ''
      }
    },
    authorDisciplineEdit (author) {
      if (this.canEditAuthorDiscipline(author)) {
        this.authorCopy = author.disciplineId
        this.editedAuthor = this.copyAuthor(author)
        this.changeAuthorShowAll()
        this.checkEditedAuthorStatements()
        this.$refs.discipline.show()
      }
    },
    authorDisciplineSave () {
      this.changingDiscipline = true
      this.originalAuthors = this.publication.authors
      const idx = this.publication.authors.findIndex(author => author.name === this.editedAuthor.name)
      this.publication.authors[idx] = this.copyAuthor(this.editedAuthor)
      this.$refs.discipline.hide()
      if (this.autoUpdate) {
        this.authorSpinner = true
        ApiPublications.update_discipline(this.publication.id, this.editedAuthor)
          .then(result => {
            const jobId = result.data.jobId
            if (jobId !== undefined) {
              this.waitForJob(jobId, this.authorsUpdate, this.authorsUpdate)
            }
          })
      } else {
        this.publication.authors[idx].epoints = 0
      }
    },
    authorFacultyEdit (author) {
      if (this.canEditAuthorFaculty(author)) {
        this.authorCopy = author.unitIds
        this.editedAuthor = this.copyAuthor(author)
        this.$refs.faculty.show()
      }
    },
    authorFacultySave () {
      this.originalAuthors = this.publication.authors
      const idx = this.publication.authors.findIndex(author => author.name === this.editedAuthor.name)
      const seenIds = new Set()
      this.editedAuthor.unitIds = this.editedAuthor.unitIds.filter((item) => {
        if (!seenIds.has(item.id)) {
          seenIds.add(item.id)
          return true
        }
        return false
      })
      this.publication.authors[idx] = this.copyAuthor(this.editedAuthor)
      this.$refs.faculty.hide()
      if (this.autoUpdate) {
        this.authorSpinner = true
        ApiPublications.update_unit(this.publication.id, this.editedAuthor)
            .then(result => {
              this.authorsUpdate()
            })
      }
    },
    redactorFacultyEdit (author) {
      if (this.canEditAuthorFaculty(author)) {
        this.redactorCopy = author.unitIds
        this.editedRedactor = this.copyAuthor(author)
        this.$refs.facultyRedactor.show()
      }
    },
    redactorFacultySave () {
      this.originalRedactors = this.publication.redactors
      const idx = this.publication.redactors.findIndex(redactor => redactor.name === this.editedRedactor.name)
      const seenIds = new Set()
      this.editedRedactor.unitIds = this.editedRedactor.unitIds.filter((item) => {
        if (!seenIds.has(item.id)) {
          seenIds.add(item.id)
          return true
        }
        return false
      })
      this.publication.redactors[idx] = this.copyAuthor(this.editedRedactor)
      this.$refs.facultyRedactor.hide()
      if (this.autoUpdate) {
        this.authorSpinner = true
        ApiPublications.update_unit(this.publication.id, this.editedRedactor)
            .then(result => {
              this.redactorsUpdate()
            })
      }
    },
    authorsEdit (publication) {
      this.authors = this.copyAuthors(publication.authors)
      this.$refs.authors.show()
    },
    isNameEmpty (value) {
      return value.name
    },
    authorsSave () {
      if (this.validateAuthors(this.authors)) {
        let emptyAuthor = false
        let duplicateAuthor = false
        const myArray = []
        const employeeIds = []
        this.originalAuthors = this.publication.authors
        this.publication.authors = this.copyAuthors(this.authors)
        let sum = 0
        if (this.publication.publicationTypeId === 7) {
          this.publication.authors.forEach(author => {
            sum += Number(author.part)
          })
        }
        if (!this.autoUpdate) {
          this.publication.authors.forEach(author => {
            if (author.name === null || author.name === '') {
              emptyAuthor = true
            } else {
              myArray.push(author.name.trim())
              if (author.employeeId) {
                employeeIds.push(author.employeeId)
                const uniqueEmployeeIds = [...new Set(employeeIds)]
                if (employeeIds.length !== uniqueEmployeeIds.length) {
                  duplicateAuthor = true
                }
              }
              const uniqueArray = [...new Set(myArray)]
              if (myArray.length !== uniqueArray.length) {
                duplicateAuthor = true
              }
            }
          })
        }
        this.$refs.authors.hide()
        if (emptyAuthor) {
          setTimeout(() => {
            this.errors.push(this.$t('views.orcid_publications.empty_author'))
            this.publication.authors = this.publication.authors.filter(this.isNameEmpty)
            this.authorsEdit(this.publication)
            this.$refs.authors.show()
          }, 300)
        } else if (duplicateAuthor) {
          setTimeout(() => {
            this.errors.push(this.$t('views.orcid_publications.duplicate_author'))
            this.publication.authors = this.originalAuthors
            this.authorsEdit(this.publication)
            this.$refs.authors.show()
          }, 300)
        } else {
          if (sum <= 100) {
            if (this.autoUpdate) {
              this.authorSpinner = true
              ApiPublications.update_authors(this.publication.id, this.publication.authors, this.originalAuthors, false)
                .then(result => {
                  const jobId = result.data.jobId
                  if (jobId !== undefined) {
                    this.waitForJob(jobId, this.authorsUpdate, this.authorsUpdate)
                  }
                }).catch(error => {
                  this.publication.authors = this.originalAuthors
                  this.errors = error.response.data
                  this.authorsEdit(this.publication)
                  this.authorSpinner = false
                })
            } else {
              this.updateAuthors()
            }
          } else {
            setTimeout(() => {
              this.errors.push(this.$t('views.orcid_publications.too_big_percentage'))
              this.authorsEdit(this.publication)
            }, 300)
          }
        }
      }
    },
    originalAuthorsEdit (publication) {
      this.translationOriginalAuthors = this.copyAuthors(publication.originalAuthors)
      this.$refs.originalAuthors.show()
    },
    originalAuthorsSave () {
      if (this.validateAuthors(this.translationOriginalAuthors)) {
        let emptyAuthor = false
        let duplicateAuthor = false
        const myArray = []
        this.originalAuthors = this.publication.originalAuthors
        this.publication.originalAuthors = this.copyAuthors(this.translationOriginalAuthors)
        if (!this.autoUpdate) {
          this.publication.originalAuthors.forEach(author => {
            if (author.name === null || author.name === '') {
              emptyAuthor = true
            } else {
              myArray.push(author.name.trim())
              const uniqueArray = [...new Set(myArray)]
              if (myArray.length !== uniqueArray.length) {
                duplicateAuthor = true
              }
            }
          })
        }
        this.$refs.originalAuthors.hide()
        if (emptyAuthor) {
          setTimeout(() => {
            this.errors.push(this.$t('views.orcid_publications.empty_author'))
            this.publication.originalAuthors = this.publication.originalAuthors.filter(this.isNameEmpty)
            this.originalAuthorsEdit(this.publication)
            this.$refs.originalAuthors.show()
          }, 300)
        } else if (duplicateAuthor) {
          setTimeout(() => {
            this.errors.push(this.$t('views.orcid_publications.duplicate_author'))
            this.publication.originalAuthors = this.originalAuthors
            this.originalAuthorsEdit(this.publication)
            this.$refs.authors.show()
          }, 300)
        } else {
          if (this.autoUpdate) {
            this.originalAuthorSpinner = true
            ApiPublications.update_authors(this.publication.id, this.publication.originalAuthors, this.originalAuthors, false, true)
                .then(result => {
                  const jobId = result.data.jobId
                  if (jobId !== undefined) {
                    this.waitForJob(jobId, this.authorsUpdate, this.authorsUpdate)
                  }
                }).catch(error => {
              this.publication.originalAuthors = this.originalAuthors
              this.errors = error.response.data
              this.originalAuthorsEdit(this.publication)
              this.originalAuthorSpinner = false
            })
          } else {
            this.updateOriginalAuthors()
          }
        }
      }
    },
    authorsUpdate () {
      this.editedAuthor = null
      this.publicationUpdate()
      this.authorSpinner = false
      this.redactorSpinner = false
      this.originalAuthorSpinner = false
      this.changingDiscipline = false
      this.$emit('disciplineUpdated')
    },
    changeSubmitted (publication, author = null) {
      if (this.autoUpdate) {
        if (author === null) {
          ApiPublications.changeSubmitted(publication, author, this.employeeId)
            .then(result => {
              const jobId = result.data.jobId
              if (jobId !== undefined) {
                this.authorSpinner = true
                this.redactorSpinner = true
                this.waitForJob(jobId, this.authorsUpdate, this.authorsUpdate)
              }
            }).catch(error => {
              this.errors = error.response.data
            })
        } else {
          ApiPublications.changeSubmitted(publication, author, author.employeeId)
            .then(result => {
              const jobId = result.data.jobId
              if (jobId !== undefined) {
                this.authorSpinner = true
                this.redactorSpinner = true
                this.waitForJob(jobId, this.authorsUpdate, this.authorsUpdate)
              }
            }).catch(error => {
              this.errors = error.response.data
            })
        }
      } else {
        this.publication.authors.forEach(a => {
          if (a.employeeId === author.employeeId) {
            a.submitted = author.submitted
            a.submittedDate = author.submittedDate
          }
        })
        this.publication.redactors.forEach(r => {
          if (r.employeeId === author.employeeId) {
            r.submitted = author.submitted
            r.submittedDate = author.submittedDate
          }
        })
        this.editedAuthor = null
        this.editedRedactor = null
      }
    },
    copyAuthor (author) {
      const na = {}
      for (const key in author) {
        na[key] = author[key]
      }
      return na
    },
    copyAuthors (authors) {
      const newAuthors = []
      authors.forEach(a => {
        newAuthors.push(this.copyAuthor(a))
      })
      return newAuthors
    },
    changeAuthorShowAll () {
      if (this.editedAuthor && this.editedAuthor.authorType !== 3) {
        this.showAuthorAll = true
        for (let i = 0; i < this.editedAuthor.disciplines.length; i++) {
          if (this.editedAuthor.disciplineId === this.editedAuthor.disciplines[i].id || this.editedAuthor.disciplineId === null || isNaN(this.editedAuthor.disciplineId)) {
            this.showAuthorAll = false
          }
        }
      }
      this.initializeAuthorDisciplines()
    },
    changeRedactorShowAll () {
      if (this.editedRedactor && this.editedRedactor.authorType !== 3) {
        this.showRedactorAll = true
        for (let i = 0; i < this.editedRedactor.disciplines.length; i++) {
          if (this.editedRedactor.disciplineId === this.editedRedactor.disciplines[i].id || this.editedRedactor.disciplineId === null || isNaN(this.editedRedactor.disciplineId)) {
            this.showRedactorAll = false
          }
        }
      }
      this.initializeRedactorDisciplines()
    },
    checkEditedAuthorStatements () {
      this.editedAuthorCorrect = false
      if (this.editedAuthor && this.editedAuthor.authorType !== 3) {
        for (let i = 0; i < this.editedAuthor.disciplines.length; i++) {
          if (this.editedAuthor.disciplineId === this.editedAuthor.disciplines[i].id || this.editedAuthor.disciplineId === null || isNaN(this.editedAuthor.disciplineId)) {
            this.editedAuthorCorrect = true
          }
        }
      }
    },
    checkEditedRedactorStatements () {
      this.editedRedactorCorrect = false
      if (this.editedRedactor && this.editedRedactor.authorType !== 3) {
        for (let i = 0; i < this.editedRedactor.disciplines.length; i++) {
          if (this.editedRedactor.disciplineId === this.editedRedactor.disciplines[i].id || this.editedRedactor.disciplineId === null || isNaN(this.editedRedactor.disciplineId)) {
            this.editedRedactorCorrect = true
          }
        }
      }
    },
    checkAuthorStatements () {
      this.fromAuthorStatements = []
      if (this.publication.authors && this.publication.authors.length > 0) {
        for (let i = 0; i < this.publication.authors.length; i++) {
          let correct = false
          if (this.publication.authors[i].disciplines && this.publication.authors[i].disciplines.length > 0) {
            for (let j = 0; j < this.publication.authors[i].disciplines.length; j++) {
              if (this.publication.authors[i].disciplines[j].id === this.publication.authors[i].disciplineId || this.publication.authors[i].disciplineId === 0 || isNaN(this.publication.authors[i].disciplineId)) {
                correct = true
              }
            }
          }
          this.fromAuthorStatements.push(correct)
        }
      }
    },
    checkRedactorStatements () {
      this.fromRedactorStatements = []
      if (this.publication.redactors && this.publication.redactors.length > 0) {
        for (let i = 0; i < this.publication.redactors.length; i++) {
          let correct = false
          if (this.publication.redactors[i].disciplines && this.publication.redactors[i].disciplines.length > 0) {
            for (let j = 0; j < this.publication.redactors[i].disciplines.length; j++) {
              if (this.publication.redactors[i].disciplines[j].id === this.publication.redactors[i].disciplineId || this.publication.redactors[i].disciplineId === 0 || isNaN(this.publication.redactors[i].disciplineId)) {
                correct = true
              }
            }
          }
          this.fromRedactorStatements.push(correct)
        }
      }
    },
    escAuthors () {
      this.$refs.authors.hide()
      this.errors = []
    },
    escOriginalAuthors () {
      this.$refs.originalAuthors.hide()
      this.errors = []
    },
    escAuthorDiscipline () {
      this.editedAuthor = null
      this.$refs.discipline.hide()
    },
    escAuthorFaculty () {
      this.editedAuthor = null
      this.$refs.faculty.hide()
    },
    escRedactorFaculty () {
      this.editedRedactor = null
      this.$refs.facultyRedactor.hide()
    },
    escRedactors () {
      this.$refs.redactors.hide()
      this.errors = []
    },
    escSubmittedAuthorModal () {
      this.$refs.submittedAuthorModal.hide()
    },
    escSubmittedRedactorModal () {
      this.$refs.submittedRedactorModal.hide()
    },
    escRedactorDiscipline () {
      this.editedRedactor = null
      this.$refs.disciplineRedactor.hide()
    },
    initializeAuthorDisciplines () {
      if (this.editedAuthor) {
        this.myAuthorDisciplines = []
        this.myAuthorDisciplines.push({ text: 'wybierz', text_en: 'choose', id: '' })
        for (let i = 0; i < this.editedAuthor.disciplines.length; i++) {
          let text = ''
          let textEn = ''
          text = this.editedAuthor.disciplines[i].text
          textEn = this.editedAuthor.disciplines[i].textEn
          this.myAuthorDisciplines.push({
            id: this.editedAuthor.disciplines[i].id,
            text: text,
            textEn: textEn
          })
        }
        const disciplinesCount = this.myAuthorDisciplines.length
        if (this.showAuthorAll) {
          for (let i = 0; i < this.copiedDisciplines.length; i++) {
            if (disciplinesCount === 2) {
              if (this.myAuthorDisciplines[1].id !== this.copiedDisciplines[i].id) {
                this.myAuthorDisciplines.push(this.copiedDisciplines[i])
              }
            }
            if (disciplinesCount === 3) {
              if (this.myAuthorDisciplines[1].id !== this.copiedDisciplines[i].id && this.myAuthorDisciplines[2].id !== this.copiedDisciplines[i].id) {
                this.myAuthorDisciplines.push(this.copiedDisciplines[i])
              }
            }
          }
        }
      }
    },
    initializeAuthorFaculties () {
      if (this.editedAuthor) {
        this.myAuthorFaculties = []
        this.myAuthorFaculties.push({ text: 'wybierz', text_en: 'choose', id: '' })
        for (let i = 0; i < this.editedAuthor.faculties.length; i++) {
          let text = ''
          let textEn = ''
          text = this.editedAuthor.faculties[i].name
          textEn = this.editedAuthor.faculties[i].name
          this.myAuthorFaculties.push({
            id: this.editedAuthor.faculties[i].id,
            text: text,
            textEn: textEn
          })
        }
      }
    },
    initializeRedactorFaculties () {
      if (this.editedRedactor) {
        this.myRedactorFaculties = []
        this.myRedactorFaculties.push({ text: 'wybierz', text_en: 'choose', id: '' })
        for (let i = 0; i < this.editedRedactor.faculties.length; i++) {
          let text = ''
          let textEn = ''
          text = this.editedRedactor.faculties[i].name
          textEn = this.editedRedactor.faculties[i].name
          this.myRedactorFaculties.push({
            id: this.editedRedactor.faculties[i].id,
            text: text,
            textEn: textEn
          })
        }
      }
    },
    initializeDisciplines () {
      let newItem = {}
      this.copiedDisciplines = []
      for (let i = 0; i < this.disciplines.length; i++) {
        newItem = {
          id: Number(this.disciplines[i].id),
          science_field_id: this.disciplines[i].science_field_id,
          text: this.disciplines[i].text,
          text_en: this.disciplines[i].text_en
        }
        this.copiedDisciplines.push(newItem)
      }
    },
    initializeDoctorDisciplines () {
      this.myDoctorDisciplines = []
      this.myDoctorDisciplines.push({ text: 'wybierz', text_en: 'choose', id: '' })
      for (let i = 0; i < this.copiedDisciplines.length; i++) {
        this.myDoctorDisciplines.push(this.copiedDisciplines[i])
      }
    },
    initializeRedactorDisciplines () {
      if (this.editedRedactor) {
        this.myRedactorDisciplines = []
        this.myRedactorDisciplines.push({ text: 'wybierz', text_en: 'choose', id: '' })
        for (let i = 0; i < this.editedRedactor.disciplines.length; i++) {
          this.myRedactorDisciplines.push(this.editedRedactor.disciplines[i])
        }
        const disciplinesCount = this.myRedactorDisciplines.length
        if (this.showRedactorAll) {
          for (let i = 0; i < this.copiedDisciplines.length; i++) {
            if (disciplinesCount === 2) {
              if (this.myRedactorDisciplines[1].id !== this.copiedDisciplines[i].id) {
                this.myRedactorDisciplines.push(this.copiedDisciplines[i])
              }
            }
            if (disciplinesCount === 3) {
              if (this.myRedactorDisciplines[1].id !== this.copiedDisciplines[i].id && this.myRedactorDisciplines[2].id !== this.copiedDisciplines[i].id) {
                this.myRedactorDisciplines.push(this.copiedDisciplines[i])
              }
            }
          }
        }
      }
    },
    publicationUpdate () {
      ApiPublications.getPublication(this.publication.id, this.employeeId)
        .then(result => {
          const np = result.data
          for (const prop in np) {
            this.publication[prop] = np[prop]
          }
        })
    },
    publicationPoints (publication) {
      if (publication.epoints > 0) {
        return publication.epoints.toFixed(4) * (10 / 10)
      } else {
        return 0
      }
    },
    redactorsEdit (publication) {
      this.redactors = this.copyAuthors(publication.redactors)
      this.$refs.redactors.show()
    },
    redactorsSave () {
      if (this.validateAuthors(this.redactors)) {
        let emptyRedactor = false
        let duplicateRedactor = false
        const myArray = []
        this.originalRedactors = this.publication.redactors
        this.publication.redactors = this.copyAuthors(this.redactors)
        if (!this.autoUpdate) {
          this.publication.redactors.forEach(redactor => {
            if (redactor.name === null || redactor.name === '') {
              emptyRedactor = true
            } else {
              myArray.push(redactor.name.trim())
              const uniqueArray = [...new Set(myArray)]
              if (myArray.length !== uniqueArray.length) {
                duplicateRedactor = true
              }
            }
          })
        }
        this.$refs.redactors.hide()
        if (emptyRedactor) {
          setTimeout(() => {
            this.errors.push(this.$t('views.orcid_publications.empty_author'))
            this.publication.redactors = this.publication.redactors.filter(this.isNameEmpty)
            this.redactorsEdit(this.publication)
          }, 300)
        } else if (duplicateRedactor) {
          setTimeout(() => {
            this.errors.push(this.$t('views.orcid_publications.duplicate_author'))
            this.publication.redactors = this.originalRedactors
            this.redactorsEdit(this.publication)
          }, 300)
        } else {
          if (this.autoUpdate) {
            this.redactorSpinner = true
            ApiPublications.update_authors(this.publication.id, this.publication.redactors, this.originalRedactors, true)
              .then(result => {
                const jobId = result.data.jobId
                if (jobId !== undefined) {
                  this.waitForJob(jobId, this.redactorsUpdate, this.redactorsUpdate)
                }
              }).catch(error => {
                this.publication.redactors = this.originalRedactors
                this.errors = error.response.data
                this.redactorsEdit(this.publication)
                this.redactorSpinner = false
              })
          } else {
            this.updateRedactors()
          }
        }
      }
    },
    redactorsUpdate () {
      this.editedRedactor = null
      this.publicationUpdate()
      this.redactorSpinner = false
      this.changingDiscipline = false
    },
    validateAuthors (authors) {
      return true
    },
    redactorDisciplineEdit (author) {
      if (this.canEditAuthorDiscipline(author)) {
        this.redactorCopy = author.disciplineId
        this.editedRedactor = this.copyAuthor(author)
        this.changeRedactorShowAll()
        this.checkEditedRedactorStatements()
        this.$refs.disciplineRedactor.show()
      }
    },
    redactorDisciplineSave () {
      this.changingDiscipline = true
      this.originalRedactors = this.publication.redactors
      const idx = this.publication.redactors.findIndex(author => author.name === this.editedRedactor.name)
      this.editedRedactor.redactor = true
      this.publication.redactors[idx] = this.copyAuthor(this.editedRedactor)
      this.$refs.disciplineRedactor.hide()
      if (this.autoUpdate) {
        this.redactorSpinner = true
        ApiPublications.update_discipline(this.publication.id, this.editedRedactor)
          .then(result => {
            const jobId = result.data.jobId
            if (jobId !== undefined) {
              this.waitForJob(jobId, this.redactorsUpdate, this.redactorsUpdate)
            }
          })
      }
    },
    updateAuthors () {
      const authors = this.publication.authors
      const ids = []
      authors.forEach(author => {
        if (author.employeeId) {
          ids.push(author.employeeId)
        }
      })
      const uniqueIds = [...new Set(ids)]
      ApiPublications.getAuthors(this.publication, uniqueIds)
        .then(response => {
          const rauthors = response.data.authors
          rauthors.forEach(ra => {
            const author = authors.find(a => a.employeeId === ra.author.employeeId)
            if (author.submitted === undefined) {
              this.$set(author, 'submitted', ra.submitted)
            }
            if (author.submittedDate === undefined) {
              this.$set(author, 'submittedDate', ra.submittedDate)
            }
            if (author.disciplineId === undefined) {
              this.$set(author, 'disciplineId', ra.disciplineId)
            }
            this.$set(author, 'disciplines', ra.disciplines)
            if (author.epoints === undefined) {
              this.$set(author, 'epoints', ra.epoints)
            }
            this.$set(author, 'orcid', ra.orcid)
            if (ra.doctoralStudent && author.authorType === 0) {
              this.$set(author, 'authorType', 3)
            }
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    updateRedactors () {
      const authors = this.publication.redactors
      const ids = []
      authors.forEach(author => {
        if (author.employeeId) {
          ids.push(author.employeeId)
        }
      })
      ApiPublications.getAuthors(this.publication, ids)
        .then(response => {
          const rauthors = response.data.authors
          rauthors.forEach(ra => {
            const author = authors.find(a => a.employeeId === ra.author.employeeId)
            if (author.submitted === undefined) {
              this.$set(author, 'submitted', ra.submitted)
            }
            if (author.disciplineId === undefined) {
              this.$set(author, 'disciplineId', ra.disciplineId)
            }
            this.$set(author, 'disciplines', ra.disciplines)
            if (author.epoints === undefined) {
              this.$set(author, 'epoints', ra.epoints)
            }
            this.$set(author, 'orcid', ra.orcid)
          })
        })
        .catch(error => {
          console.log(error)
        })
    },
    updateOriginalAuthors () {
      const authors = this.publication.originalAuthors
      const ids = []
      authors.forEach(author => {
        if (author.employeeId) {
          ids.push(author.employeeId)
        }
      })
      ApiPublications.getAuthors(this.publication, ids)
          .then(response => {
            const rauthors = response.data.authors
            rauthors.forEach(ra => {
              const author = authors.find(a => a.employeeId === ra.author.employeeId)
              this.$set(author, 'orcid', ra.orcid)
              if (ra.doctoralStudent && author.authorType === 0) {
                this.$set(author, 'authorType', 3)
              }
            })
          })
          .catch(error => {
            console.log(error)
          })
    },
    editAuthorAlternativePoints (author) {
      this.pointsErrors = []
      this.$refs.changeAuthorAlternativePoints.show()
      this.changingPointsAuthor = author
      if (author.alternativePoints) {
        this.alternativePoints = author.alternativePoints
      }
    },
    editRedactorAlternativePoints (redactor) {
      this.pointsErrors = []
      this.$refs.changeRedactorAlternativePoints.show()
      this.changingPointsAuthor = redactor
      if (redactor.alternativePoints) {
        this.alternativePoints = redactor.alternativePoints
      }
    },
    changeAuthorAlternativePoints () {
      if (this.autoUpdate) {
        this.authorSpinner = true
      }
      if (this.checkPointsNumber(this.alternativePoints)) {
        const changes = {
          employeeId: this.changingPointsAuthor.employeeId,
          publicationId: this.publication.id,
          alternativePoints: Number(this.alternativePoints).toFixed(4) * (10 / 10)
        }
        if (this.autoUpdate) {
          ApiEmployeePublications.changeAlternativePoints(changes)
            .then(result => {
              if (result) {
                const idx = this.publication.authors.findIndex(x => x.id === this.changingPointsAuthor.id)
                if (idx >= 0) {
                  this.publication.authors[idx].alternativePoints = Number(this.alternativePoints).toFixed(4) * (10 / 10)
                }
              }
              this.changingPointsAuthor = null
              this.alternativePoints = null
              this.authorSpinner = false
            })
            .catch(error => {
              this.pointsErrors = error.response.data
              this.authorSpinner = false
              this.$refs.changeAuthorAlternativePoints.show()
            })
        } else {
          this.$emit('changeAuthorAlternativePoints', changes)
          this.changingPointsAuthor = null
          this.alternativePoints = null
        }
      } else {
        this.$toastr.e(this.$t('components.publication_element.wrong_points'))
        this.authorSpinner = false
      }
    },
    changeRedactorAlternativePoints () {
      if (this.autoUpdate) {
        this.redactorSpinner = true
      }
      if (this.checkPointsNumber(this.alternativePoints)) {
        const changes = {
          employeeId: this.changingPointsAuthor.employeeId,
          publicationId: this.publication.id,
          alternativePoints: Number(this.alternativePoints).toFixed(4) * (10 / 10)
        }
        if (this.autoUpdate) {
          ApiEmployeePublications.changeAlternativePoints(changes)
            .then(result => {
              if (result) {
                const idx = this.publication.redactors.findIndex(x => x.id === this.changingPointsAuthor.id)
                if (idx >= 0) {
                  this.publication.redactors[idx].alternativePoints = Number(this.alternativePoints).toFixed(4) * (10 / 10)
                }
              }
              this.changingPointsAuthor = null
              this.alternativePoints = null
              this.redactorSpinner = false
            })
            .catch(error => {
              this.pointsErrors = error.response.data
              this.redactorSpinner = false
              this.$refs.changeRedactorAlternativePoints.show()
            })
        } else {
          this.$emit('changeRedactorAlternativePoints', changes)
          this.changingPointsAuthor = null
          this.alternativePoints = null
        }
      } else {
        this.$toastr.e(this.$t('components.publication_element.wrong_points'))
        this.redactorSpinner = false
      }
    },
    deleteAuthorAlternativePoints () {
      this.$refs.changeAuthorAlternativePoints.hide()
      if (this.autoUpdate) {
        this.authorSpinner = true
      }
      const changes = {
        employeeId: this.changingPointsAuthor.employeeId,
        publicationId: this.publication.id,
        alternativePoints: null
      }
      if (this.autoUpdate) {
        ApiEmployeePublications.changeAlternativePoints(changes)
          .then(result => {
            if (result) {
              const idx = this.publication.authors.findIndex(x => x.id === this.changingPointsAuthor.id)
              if (idx >= 0) {
                this.publication.authors[idx].alternativePoints = null
              }
            }
            this.changingPointsAuthor = null
            this.alternativePoints = null
            this.authorSpinner = false
          })
          .catch(error => {
            this.pointsErrors = error.response.data
            this.authorSpinner = false
            this.$refs.changeAuthorAlternativePoints.show()
          })
      } else {
        this.$emit('changeAuthorAlternativePoints', changes)
        this.changingPointsAuthor = null
        this.alternativePoints = null
      }
    },
    deleteRedactorAlternativePoints () {
      this.$refs.changeRedactorAlternativePoints.hide()
      if (this.autoUpdate) {
        this.redactorSpinner = true
      }
      const changes = {
        employeeId: this.changingPointsAuthor.employeeId,
        publicationId: this.publication.id,
        alternativePoints: null
      }
      if (this.autoUpdate) {
        ApiEmployeePublications.changeAlternativePoints(changes)
          .then(result => {
            if (result) {
              const idx = this.publication.redactors.findIndex(x => x.id === this.changingPointsAuthor.id)
              if (idx >= 0) {
                this.publication.redactors[idx].alternativePoints = null
              }
            }
            this.changingPointsAuthor = null
            this.alternativePoints = null
            this.redactorSpinner = false
          })
          .catch(error => {
            this.pointsErrors = error.response.data
            this.redactorSpinner = false
            this.$refs.changeRedactorAlternativePoints.show()
          })
      } else {
        this.$emit('changeRedactorAlternativePoints', changes)
        this.changingPointsAuthor = null
        this.alternativePoints = null
      }
    },
    checkPointsNumber (points) {
      return typeof Number(points) === 'number' && Number.isFinite(Number(points))
    },
    authorMouseOver (index) {
      this.authorsHover.splice(index, 1, true)
    },
    authorMouseLeave (index) {
      this.authorsHover.splice(index, 1, false)
    },
    redactorMouseOver (index) {
      this.redactorsHover.splice(index, 1, true)
    },
    redactorMouseLeave (index) {
      this.redactorsHover.splice(index, 1, false)
    },
    moveUp (authorIdx) {
      const author = this.authors[authorIdx]
      if (this.authors[authorIdx - 1]) {
        this.authors[authorIdx] = this.authors[authorIdx - 1]
        this.authors[authorIdx - 1] = author
        const authors = this.copyAuthors(this.authors)
        this.authors = []
        this.$nextTick(() => {
          this.authors = authors
        })
      }
    },
    moveDown (authorIdx) {
      const author = this.authors[authorIdx]
      if (this.authors[authorIdx + 1]) {
        this.authors[authorIdx] = this.authors[authorIdx + 1]
        this.authors[authorIdx + 1] = author
        const authors = this.copyAuthors(this.authors)
        this.authors = []
        this.$nextTick(() => {
          this.authors = authors
        })
      }
    },
    moveUpOriginal (authorIdx) {
      const author = this.translationOriginalAuthors[authorIdx]
      if (this.translationOriginalAuthors[authorIdx - 1]) {
        this.translationOriginalAuthors[authorIdx] = this.translationOriginalAuthors[authorIdx - 1]
        this.translationOriginalAuthors[authorIdx - 1] = author
        const authors = this.copyAuthors(this.translationOriginalAuthors)
        this.translationOriginalAuthors = []
        this.$nextTick(() => {
          this.translationOriginalAuthors = authors
        })
      }
    },
    moveDownOriginal (authorIdx) {
      const author = this.translationOriginalAuthors[authorIdx]
      if (this.translationOriginalAuthors[authorIdx + 1]) {
        this.translationOriginalAuthors[authorIdx] = this.translationOriginalAuthors[authorIdx + 1]
        this.translationOriginalAuthors[authorIdx + 1] = author
        const authors = this.copyAuthors(this.translationOriginalAuthors)
        this.translationOriginalAuthors = []
        this.$nextTick(() => {
          this.translationOriginalAuthors = authors
        })
      }
    },
    authorErrors (author) {
      let text = ''
      if (this.onlyUj && this.onlyUj.toString() === '1') {
        if (author.authorType === 1 && author.employeeId) {
          text = this.$t('components.publication_element.no_affiliation')
        }
        if (author.authorType === 0 && author.statements && author.statements.length > 0 && author.allNstatements && author.allNstatements.length === 0) {
          if (text) {
            text += `\n${this.$t('components.publication_element.no_ndiscipline')}`
          } else {
            text = this.$t('components.publication_element.no_ndiscipline')
          }
        }
        if (author.authorType === 0 && author.statements && author.statements.length === 0 && author.allNstatements && author.allNstatements.length === 0) {
          if (text) {
            text += `\n${this.$t('components.publication_element.no_discipline')}`
          } else {
            text = this.$t('components.publication_element.no_discipline')
          }
        }
        if (author.authorType === 0 && ((author.statements && author.statements.length > 0 && !author.statements.includes(this.publication.year)) || (author.allNstatements && author.allNstatements.length > 0 && !author.allNstatements.includes(this.publication.year)))) {
          if (text) {
            text += `\n${this.$t('components.publication_element.outdated_statement')}`
          } else {
            text = this.$t('components.publication_element.outdated_statement')
          }
        }
        if (author.authorType === 0 && author.employment && author.employment.length > 0 && !author.employment.includes(this.publication.year) && this.publication.year < author.employment[0]) {
          if (text) {
            text += `\n${this.$t('components.publication_element.no_employment')}`
          } else {
            text = this.$t('components.publication_element.no_employment')
          }
        }
        if (author.authorType === 3 && author.allDoctoralSchools && author.allDoctoralSchools.length === 0) {
          if (text) {
            text += `\n${this.$t('components.publication_element.no_doctoral_school')}`
          } else {
            text = this.$t('components.publication_element.no_doctoral_school')
          }
        }
        if (author.authorType === 3 && author.allDoctoralSchools && author.allDoctoralSchools.length > 0 && !author.allDoctoralSchools.includes(this.publication.year) && this.publication.year > author.allDoctoralSchools[0]) {
          if (text) {
            text += `\n${this.$t('components.publication_element.outdated_doctoral_school')}`
          } else {
            text = this.$t('components.publication_element.outdated_doctoral_school')
          }
        }
        if (author.authorType === 3 && author.allDoctoralSchools && author.allDoctoralSchools.length > 0 && !author.allDoctoralSchools.includes(this.publication.year) && this.publication.year < author.allDoctoralSchools[0]) {
          if (text) {
            text += `\n${this.$t('components.publication_element.doctoral_school_after_publication')}`
          } else {
            text = this.$t('components.publication_element.doctoral_school_after_publication')
          }
        }
      }
      return text
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../assets/stylesheets/vars';

  .pcg-icon-cross{
    width: 0;
    color: #9D9D9D;
    cursor: pointer;
    position: relative;
    top: .5rem;
  }

  .authors {
    position: relative;
    align-items: baseline;
  }

  .authors-icon {
    position: relative;
    bottom: .1rem;
  }

  .authors-list {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: baseline;
  }

  .author {
    font-size: 1em;
    font-weight: 400;
    a {
      font-size: 1em;
      font-weight: 400;
      color: unset;
    }
  }

  .author-text {
    font-size: 1em;
    font-weight: 400;
    color: $pcg-gray;
    margin-right: .5rem;
  }

  .other-contributor-text {
    font-size: 1em;
    font-weight: 400;
    color: $pcg-gray;
    margin-right: .2rem;
  }

  .author-full {
    display: inline-block;
    background-color: $pcg-lightest-gray-background;
    padding: 5px 10px !important;
    border-radius: 10px;
    border: 1px solid $pcg-light-gray-background;
    margin-bottom: 5px;

    &.no-discipline-list {
      border-color: $pcg-red;
      color: $pcg-red;
    }

    &.discipline-not-chosen {
      border-color: $pcg-orange;
    }

    &.discipline-chosen {
      border-color: $alert-success;
    }
  }

  .fitted {
    width: fit-content;

    h3 {
      margin: 0;
      margin-right: 10px;
      padding-top: 1px;
      padding-bottom: 1px;
    }
    div {
      padding-right: 10px;
      &.extender {
        display: inline-block;
        width: 13px;
      }
    }

    div:first-of-type {
      padding-left: 0;
      min-width: 81px;
    }

    div:last-of-type {
      padding-right: 0;
      min-width: 48px;
    }
  }

  .icon {
    width: 15px;
    height: 15px;
    &.circle {
      border-radius: 10px;
      border: 1px solid $pcg-light-gray-background;
    }
    .fas {
      font-size: 1.2rem;
      color: $pcg-dark-gray;
    }
  }

  .my-checkbox {
    position: relative;
    display: inline;
    font-size: $font-size-m;
    ::v-deep .pcg-checkbox-container {
      margin-right: 0;
      margin-bottom: 0;
      top: 2px;
    }
    ::v-deep .label {
      margin-bottom: 0;
    }
    ::v-deep .pcg-checkmark.normal:after {
      border-color: $alert-success !important;
    }
  }

  .my-exclamation-mark {
    height: 14px;
    width: 14px;
    padding: .2rem .2rem;
    border-radius: 20px;
    text-align: center;
    background-color: $pcg-red;
    margin-left: .3rem;
    position: relative;
    bottom: .1rem;
  }

  .my-exclamation-mark-orange {
    height: 18px;
    width: 18px;
    padding: .2rem .2rem;
    border-radius: 20px;
    text-align: center;
    background-color: $pcg-orange;
    position: relative;
    bottom: .1rem;
  }

  .my-pen {
    color: $main-active-color;
    margin-left: .5rem;
  }

  .points {
    display: inline-block;
    padding: 0 3px;
    border-radius: 5px;
    color: $pcg-white;
    min-width: 4rem;
    width: fit-content;
    font-size: $font-size-s;
    position: relative;
    text-align: center;
    font-weight: 400;
  }

  .my-image {
    width: 20px;
    margin-right: .2rem;
    margin-left: .1rem;
    position: relative;
    bottom: .1rem;
  }

  .green-color {
    color: $alert-success;
  }

  .orange-color {
    color: $pcg-orange;
  }

  .red-color {
    color: $pcg-red;
  }

  .edit-discipline {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .show-all-disciplines {
    margin-left: 3rem;
    position: relative;
    top: .1rem;
  }

  .my-checkbox-text {
    font-size: 1em;
    position: relative;
    bottom: .1rem;
  }

  .my-select {
    /deep/ .dropdown-toggle {
      height: 28px !important;

    }
    /deep/ .bootstrap-select {
      height: 30px !important;
    }
  }

  .my-pcg-select {
    /deep/ .pcg-select {
      height: 20px;
      .dropdown-toggle {
        height: 18px;
        padding-bottom: 0;
        padding-top: 0;
        line-height: unset;
      }
    }
    /deep/ .dropdown .filter-option-inner {
      margin-top: -5px;
    }
  }

  ::v-deep .dropdown-menu {
    overflow: auto;
    max-height: 300px;

    li {

      a.dropdown-item {
        padding: .8rem 1.43rem;
        display: flex;
        align-items: center;
      }
    }
  }

  ::v-deep .form-group {
    display: inline-block;
    width: 85%;
    margin: 0 5px;
  }

  /deep/ .tooltip-inner {
    background: $pcg-white;
    color: $pcg-dark-gray;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
    font-weight: 400;
    padding: .5rem;
    border-color: $pcg-light-gray;
    border-style: solid;
    border-width: 1px;
    opacity: 1;
    a {
      color: unset;
    }
  }

  /deep/ .arrow::before {
    border-top-color: $pcg-light-gray !important;
  }

  .my-input {
    /deep/ .pcg-input {
      height: calc(1.5rem + 0.75rem + 2px) !important;
    }
    /deep/ .pcg-input-label {
      margin-bottom: 0.25rem;
      color: $pcg-gray;
    }
  }

  .points-pen {
    margin-left: .4rem;
    cursor: pointer;
  }

  .my-error {
    color: $pcg-red;
    font-weight: 400;
    margin-left: .3rem;
  }

  .delete-points {
    position: relative;
    top: 1.2rem;
  }

  .confirm-header {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .confirm-text {
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1rem;
  }

  ::v-deep .check-list {
    margin-top: .1rem;
    margin-left: .1rem;
    width: 700px !important;
    @media (max-width: 890px) {
      width: 300px !important;
    }
    @media (max-width: 620px) {
      width: 250px !important;
    }
    @media (max-width: 575.98px) {
      width: 100% !important;
    }
  }

  .editing-discipline {
    margin-right: .2rem;
    &:hover {
      text-decoration: underline;
    }
  }

  .required-field {
    color: #feae00;
    font-weight: 400;
    font-size: $font-size-s;
  }

  .pbn-logo {
    height: 18px;
    position: relative;
    bottom: 2px;
  }

  .polon-logo {
    width: 50px;
  }

  .my-header {
    margin-bottom: 2rem;
  }

  .edit-faculties {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-right: .5rem;
  }

  .modal-select {
    position: relative;
    top: .3rem;
    /deep/ .bootstrap-select .dropdown-toggle {
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
      width: 10rem;
    }
    /deep/ .filter-option-inner {
      position: relative;
      bottom: .45rem;
    }
    /deep/ .ls-element .pcg-input{
      height: calc(1.5em + 0.75em + 2px);
    }

    ::v-deep .form-group {
      display: inline-block;
      width: 100% !important;
      margin: 0 5px;
    }
  }

  .one-faculty {
    font-weight: 400;
    font-size: $font-size-s;
    color: $pcg-gray;
    position: relative;
    left: .3rem;
  }
</style>

<style lang="scss" scoped>
  @import '../assets/stylesheets/custom-contrast';
  .contrast {
    .author-text {
      color: $pcg-gray;
    }
    .other-contributor-text {
      color: $pcg-gray;
    }
    .author-full {
      background-color: $pcg-lightest-gray;
      border: 1px solid $pcg-light-gray;
      &.no-discipline-list {
        border-color: $pcg-red;
        color: $pcg-red;
      }
      &.discipline-not-chosen {
        border-color: $pcg-orange;
      }
      &.discipline-chosen {
        border-color: $alert-success;
      }
    }
    .icon {
      &.circle {
        border: 1px solid $pcg-light-gray;
      }
      .fas {
        color: $pcg-dark-gray;
      }
    }
    .my-checkbox {
      ::v-deep .pcg-checkmark.normal:after {
        border-color: $alert-success !important;
      }
    }
    .my-exclamation-mark {
      background-color: $pcg-red;
    }
    .my-exclamation-mark-orange {
      background-color: $pcg-orange;
    }
    .my-pen {
      color: $main-active-color;
    }
    .points {
      color: $pcg-white;
    }
    .green-color {
      color: $alert-success;
    }
    .orange-color {
      color: $pcg-orange;
    }
    .edit-discipline {
      color: $pcg-gray;
    }
    /deep/ .tooltip-inner {
      background: $pcg-white;
      color: $pcg-dark-gray;
      box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
      border-color: $pcg-light-gray;
      a {
        color: unset;
      }
    }
    /deep/ .arrow::before {
      border-top-color: $pcg-light-gray !important;
    }
    .my-input {
      /deep/ .pcg-input-label {
        color: $pcg-gray;
      }
    }
    .my-error {
      color: $pcg-red;
    }
    .confirm-header {
      color: $pcg-gray;
    }
    .confirm-text {
      color: $pcg-gray;
    }
    .orange-color {
      color: $pcg-orange;
    }
    .red-color {
      color: $pcg-red;
    }
    .edit-faculties {
      color: $pcg-gray;
    }
    .modal-select {
      /deep/ .bootstrap-select .dropdown-toggle {
        color: $pcg-gray;
      }
    }
    .one-faculty {
      color: $pcg-gray;
    }
    .pcg-icon-cross{
      color: #9D9D9D;
    }
  }
</style>
