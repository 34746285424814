<template>
  <pcg-box class="main-class">
    <div class="oneline">
      <div class="statistic-text">
        {{ $t('components.dn_publication_statistics.total_publications') }}
        ({{ publicationStatistics.totalPublications ? publicationStatistics.totalPublications.count : 0 }})
      </div>
      <div class="statistic-number">
        {{ publicationStatistics.totalPublications ? publicationsPoints(publicationStatistics.totalPublications.points) : 0 }}
      </div>
    </div>
    <div class="my-border"></div>
    <div class="oneline" v-if="publicationStatistics.articles && publicationStatistics.articles.count > 0">
      <div class="statistic-text">{{ $t('components.dn_publication_statistics.articles') }} ({{ publicationStatistics.articles.count }})</div>
      <div class="statistic-number">{{ publicationsPoints(publicationStatistics.articles.points) }}</div>
    </div>
    <div class="oneline" v-if="publicationStatistics.monographs && publicationStatistics.monographs.count > 0">
      <div class="statistic-text">{{ $t('components.dn_publication_statistics.monographs') }} ({{ publicationStatistics.monographs.count }})</div>
      <div class="statistic-number">{{ publicationsPoints(publicationStatistics.monographs.points) }}</div>
    </div>
    <div class="oneline" v-if="publicationStatistics.redactions && publicationStatistics.redactions.count > 0">
      <div class="statistic-text">{{ $t('components.dn_publication_statistics.redactions') }} ({{ publicationStatistics.redactions.count }})</div>
      <div class="statistic-number">{{ publicationsPoints(publicationStatistics.redactions.points) }}</div>
    </div>
    <div class="oneline" v-if="publicationStatistics.monographyRedactions && publicationStatistics.monographyRedactions.count > 0">
      <div class="statistic-text">{{ $t('components.dn_publication_statistics.monography_redactions') }} ({{ publicationStatistics.monographyRedactions.count }})</div>
      <div class="statistic-number">{{ publicationsPoints(publicationStatistics.monographyRedactions.points) }}</div>
    </div>
    <div class="oneline" v-if="publicationStatistics.chapters && publicationStatistics.chapters.count > 0">
      <div class="statistic-text">{{ $t('components.dn_publication_statistics.chapters') }} ({{ publicationStatistics.chapters.count }})</div>
      <div class="statistic-number">{{ publicationsPoints(publicationStatistics.chapters.points) }}</div>
    </div>
    <div class="oneline" v-if="publicationStatistics.artistic && publicationStatistics.artistic.count > 0">
      <div class="statistic-text">{{ $t('components.dn_publication_statistics.artistic') }} ({{ publicationStatistics.artistic.count }})</div>
      <div class="statistic-number">{{ publicationsPoints(publicationStatistics.artistic.points) }}</div>
    </div>
    <div class="oneline" v-if="publicationStatistics.patents && publicationStatistics.patents.count > 0">
      <div class="statistic-text">{{ $t('components.dn_publication_statistics.patents') }} ({{ publicationStatistics.patents.count }})</div>
      <div class="statistic-number">{{ publicationsPoints(publicationStatistics.patents.points) }}</div>
    </div>
    <div class="oneline" v-if="publicationStatistics.others && publicationStatistics.others.count > 0">
      <div class="statistic-text">{{ $t('components.dn_publication_statistics.others') }} ({{ publicationStatistics.others.count }})</div>
      <div class="statistic-number">{{ publicationsPoints(publicationStatistics.others.points) }}</div>
    </div>
    <div class="d-flex justify-content-center show-all" v-if="publicationStatistics.totalPublications && publicationStatistics.totalPublications.count > 0">
      <span v-if="userRole === 'naukowiec' && hasRight('list_own_achievement')">
        <router-link :to="{ name: 'scientist_publications_path' }">
          <span style="margin-right: .1rem">{{ $t('components.dn_publication_statistics.show_all') }}</span>
          <i class="fas fa-search"></i>
        </router-link>
      </span>
      <span v-if="userRole !== 'naukowiec' && hasRight('list_achievement')">
        <router-link :to="{ name: 'other_scientist_publications_path', params: { employeeId } }">
          <span style="margin-right: .1rem">{{ $t('components.dn_publication_statistics.show_all') }}</span>
          <i class="fas fa-search"></i>
        </router-link>
      </span>
    </div>
  </pcg-box>
</template>

<script>
import hasRight from '../mixins/has_right'
import { mapGetters } from 'vuex'
export default {
  name: 'dn-publication-statistics',
  props: {
    publicationStatistics: Object,
    innerHeader: Boolean,
    employeeId: Number
  },
  mixins: [hasRight],
  data () {
    return {
      userRole: ''
    }
  },
  computed: {
    ...mapGetters('period', ['years']),
    header () {
      return this.innerHeader ? null : this.$t('components.dn_publication_statistics.header')
    }
  },
  created () {
    this.userRole = this.$store.getters['auth/currentRole']
  },
  methods: {
    publicationsPoints (points) {
      return points.toFixed(3) * 1000 / 1000
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .oneline {
    display: inline-block;
    width: 100%;
    clear: both;
  }

  .statistic-number {
    font-weight: 400;
    color: $pcg-dark-gray;
    background-color: $pcg-lightest-gray-background;
    padding: 5px 10px;
    border-radius: 5px;
    min-width: 40px;
    margin-left: 1rem;
    text-align: center;
    display: inline;
    float: right;
  }

  .statistic-text {
    display: inline;
    float: left;
    color: $pcg-dark-gray;
    font-weight: 600;
    font-size: $font-size-m;
  }

  .statistic-text-record {
    display: inline;
    float: left;
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-m;
    margin-left: 2.5rem;
  }

  .main-class {
    /deep/ .header-title {
      font-size: $font-size-xl;
      font-weight: 600;
      letter-spacing: .1rem;
      text-transform: uppercase;
    }
  }

  .my-border {
    margin-top: .3rem;
    margin-bottom: 1.5rem;
    border-top: 1px solid $pcg-lightest-gray-background;
  }

  .my-record {
    margin-bottom: .5rem;
  }

  .show-all {
    margin-top: 1rem;
    color: $main-active-color;
    font-weight: bold;
    font-size: $font-size-m;
    cursor: pointer;
    a {
      color: unset
    }
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .statistic-number {
      color: $pcg-dark-gray;
      background-color: $pcg-lightest-gray;
    }
    .statistic-text {
      color: $pcg-dark-gray;
    }
    .statistic-text-record {
      color: $pcg-gray;
    }
    .my-border {
      border-top: 1px solid $pcg-lightest-gray;
    }
    .show-all {
      color: $main-active-color;
    }
  }
</style>
