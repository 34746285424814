<template>
<div class="entitiess-list">
  <b-row style="margin-bottom: 1.5rem">
    <b-col>
      <label :for="$t('views.entity_project.name')" class="sr-only" style="position: relative; top: .2rem;">{{ $t('views.entity_project.name') }}</label>
      <pcg-text-input
          class="no-margin my-input"
          :id="$t('views.entity_project.name')"
          :show-label="false"
          :placeholder="$t('views.entity_project.name')"
          v-model="filters.name"
          @input="getFilteredEntities"
      />
    </b-col>
  </b-row>
  <b-row>
    <b-col cols="10" style="margin-bottom: 2rem;">
      <span class="per_page">{{ $t('views.achievement.list.per_page') }}</span>
      <pcg-select
          class="my-select-short"
          style="display: inline-block"
          :options="perPageOptions"
          :default-option="{ text: perPage.toString(), value: perPage }"
          v-model="perPage"
          @input="getFilteredEntities"
      />
    </b-col>
    <b-col class="text-right">
      <pcg-btn v-if="hasRight('edit_own_dictionaries') && tabModel === 1"
               variant="additional" size="small" @click="entityAdd">
        {{$t('general.add')}}
      </pcg-btn>
    </b-col>
  </b-row>
  <b-tabs class="mt-3" v-model="tabModel">
    <b-tab :title="$t('views.conferences.list.ministerial_list')">
      <b-table v-if="entityProjects.length > 0"
               class="institution-table"
               borderless hover
               thead-class="sthead"
               tbody-tr-class="strow"
               :fields="fields"
               :items="entityProjects"
               :primary-key="entityProjects.id"
      >
        <template v-slot:cell(name)="data">
          {{ data.value }}
        </template>
        <template v-slot:cell(university)="data">
          {{ tf(data.value) }}
        </template>
        <template v-slot:cell(evaluation)="data">
          {{ tf(data.value) }}
        </template>
        <template v-slot:cell(id)="data">
          <a href="javascript:;" :aria-label="$t('general.edit')" class="my-pen button-element" style="display: inline-block" v-if="hasRight('edit_dictionaries')" @click="entityEdit(data.value)">
            <i class="fas fa-pen academy-icon" :id="`edit-entity-project` + data.value"></i>
            <b-tooltip :target="`edit-entity-project` + data.value">
              {{ $t('general.edit') }}
            </b-tooltip>
          </a>
        </template>
      </b-table>
      <nav :aria-label="$t('general.pagination')">
        <pcg-pagination
            v-model="pagination.page"
            :page-count="pagination.pages"
            :total-records="pagination.count"
            @input="getEntities"
            class="col-12 justify-content-center my-pagination"
            style="margin-top: 2rem"
            :only-event="true"
        />
      </nav>
    </b-tab>
    <b-tab :title="$t('views.conferences.list.other_list')">
      <b-table v-if="locals.length > 0"
               class="institution-table"
               borderless hover
               thead-class="sthead"
               tbody-tr-class="strow"
               :fields="fields"
               :items="locals"
               :primary-key="locals.id"
      >
        <template v-slot:cell(name)="data">
          {{ data.value }}
        </template>
        <template v-slot:cell(university)="data">
          {{ tf(data.value) }}
        </template>
        <template v-slot:cell(evaluation)="data">
          {{ tf(data.value) }}
        </template>
        <template v-slot:cell(id)="data">
          <a href="javascript:;" :aria-label="$t('general.edit')" class="my-pen button-element" style="display: inline-block" v-if="hasRight('edit_own_dictionaries')" @click="entityEdit(data.value)">
            <i class="fas fa-pen academy-icon" :id="`edit-entity-project` + data.value"></i>
            <b-tooltip :target="`edit-entity-project` + data.value">
              {{ $t('general.edit') }}
            </b-tooltip>
          </a>
        </template>
      </b-table>
      <nav :aria-label="$t('general.pagination')">
        <pcg-pagination
            v-model="localPagination.page"
            :page-count="localPagination.pages"
            :total-records="localPagination.count"
            @input="getEntities"
            class="col-12 justify-content-center my-pagination"
            style="margin-top: 2rem"
            :only-event="true"
        />
      </nav>
    </b-tab>
  </b-tabs>
  <b-modal ref="entityModal"
           :hide-header="true"
           :centered="true"
           v-bind:ok-title="$t('general.confirm')"
           ok-variant="primary"
           :no-close-on-esc="true"
           @ok="entitySave"
           @hide="entityEsc"
           v-bind:cancel-title="$t('general.cancel')"
           cancel-variant="default"
           v-on-clickaway = "entityEsc"
           footer-class="my-modal-footer"
           content-class="my-modal-content"
  >
    <div class="edit-discipline" style="display: inline-block">{{ $t('views.entity_project.modal.title') }}</div>
    <pcg-text-input
        class="my-field"
        :label="$t('views.entity_project.name')"
        v-model="mEntity.name"
    />
    <pcg-checkbox
        class="my-field"
        :label="$t('views.entity_project.university')"
        v-model="mEntity.university"
    >{{ $t('views.entity_project.university') }}</pcg-checkbox>
    <pcg-checkbox
        class="my-field"
        :label="$t('views.entity_project.evaluation')"
        v-model="mEntity.evaluation"
    >{{ $t('views.entity_project.evaluation') }}</pcg-checkbox>
    <pcg-checkbox
        class="my-field"
        :label="$t('views.entity_project.in_territory')"
        v-model="mEntity.inTerritory"
    >{{ $t('views.entity_project.in_territory') }}</pcg-checkbox>
  </b-modal>
</div>
</template>

<script>
import ApiEntities from '../../api/entity_project'
import hasRight from '../../mixins/has_right'
import { mixin as clickaway } from 'vue-clickaway'
import setTitle from '../../mixins/set_title'
import { mapGetters } from 'vuex'
import tf from '../../mixins/tf'

export default {
  name: 'EntityProjectList',
  mixins: [hasRight, setTitle, tf, clickaway],
  data: function () {
    return {
      headerTitle: 'views.entity_project.title',
      headerSubtitle: 'views.entity_project.subtitle',

      filters: {
        name: null
      },
      entityProjects: [],
      locals: [],
      mEntity: {
        id: null,
        name: null,
        university: null,
        evaluation: null,
        inTerritory: null
      },
      pagination: {
        count: 0,
        items: 0,
        last: 0,
        next: null,
        page: 0,
        pages: 0,
        prev: null
      },
      localPagination: {
        count: 0,
        items: 0,
        last: 0,
        next: null,
        page: 0,
        pages: 0,
        prev: null
      },
      perPage: 25,
      tabModel: 0
    }
  },
  computed: {
    ...mapGetters('auth', ['academyId']),
    fields: function () {
      return [
        {
          key: 'name',
          label: this.$t('views.entity_project.name'),
          class: 'td-left',
          thStyle: ''
        },
        {
          key: 'university',
          label: this.$t('views.entity_project.university'),
          thStyle: 'width: 10rem;'
        },
        {
          key: 'evaluation',
          label: this.$t('views.entity_project.evaluation'),
          thStyle: 'width: 20rem;'
        },
        {
          key: 'id',
          label: '',
          thStyle: 'width: 5rem;'
        }
      ]
    },
    perPageOptions () {
      return [
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ]
    }
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.setTitles()
    ApiEntities.getEntities(this.$route.query)
      .then(result => {
        const query = this.$route.query
        if (Object.prototype.hasOwnProperty.call(query, 'name')) {
          this.filters.name = query.name
        }
        this.entityProjects = result.data.datas
        this.locals = result.data.locals
        this.pagination = result.data.pagination
        this.localPagination = result.data.localPagination
        this.perPage = this.pagination.items || 25
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    entityAdd () {
      this.mEntity = {
        id: null,
        name: null,
        university: null,
        evaluation: null,
        inTerritory: null
      }
      this.$refs.entityModal.show()
    },
    entityEdit (id) {
      let entity = this.entityProjects.find(el => {
        return el.id === id
      })
      if (entity === undefined) {
        entity = this.locals.find(el => {
          return el.id === id
        })
      }
      Object.assign(this.mEntity, entity)
      this.$refs.entityModal.show()
    },
    entityEsc () {
      this.mEntity = {
        id: null,
        name: null,
        university: null,
        evaluation: null,
        inTerritory: null
      }
    },
    entitySave (modalEvt) {
      modalEvt.preventDefault()
      if (this.mEntity.id) {
        const p = {}
        Object.assign(p, this.mEntity)
        const idx = this.entityProjects.findIndex(el => {
          return this.mEntity.id === el.id
        })
        const lidx = this.locals.findIndex(el => {
          return this.mEntity.id === el.id
        })
        ApiEntities.updateEntity(this.mEntity)
          .then(result => {
            if (idx > -1) {
              Object.assign(this.entityProjects[idx], p)
            }
            if (lidx > -1) {
              Object.assign(this.locals[lidx], p)
            }
            this.$toastr.s('Success')
            this.$refs.entityModal.hide()
            this.mEntity = {
              id: null,
              name: null,
              university: null,
              evaluation: null,
              inTerritory: null
            }
          })
          .catch(err => {
            const datas = err.response.data
            const message = []
            for (const key in datas) {
              message.push(`${datas[key]}`)
            }
            this.$toastr.e(message.join('\n'))
            Object.assign(this.mEntity, p)
          })
      } else {
        ApiEntities.createEntity(this.mEntity)
          .then(result => {
            if (result) {
              this.$refs.entityModal.hide()
              this.mEntity = {
                id: null,
                name: null,
                university: null,
                evaluation: null,
                inTerritory: null
              }
              setTimeout(() => {
                ApiEntities.getEntities(this.$route.query)
                    .then(result => {
                      const query = this.$route.query
                      if (Object.prototype.hasOwnProperty.call(query, 'name')) {
                        this.filters.name = query.name
                      }
                      this.entityProjects = result.data.datas
                      this.locals = result.data.locals
                      this.pagination = result.data.pagination
                      this.localPagination = result.data.localPagination
                      this.perPage = this.pagination.items || 25
                    })
                    .catch(error => {
                      console.log(error)
                    })
              }, 1000)
            }
          })
          .catch(err => {
            const datas = err.response.data
            const message = []
            for (const key in datas) {
              message.push(`${datas[key]}`)
            }
            this.$toastr.e(message.join('\n'))
          })
      }
    },
    getEntities () {
      let query = {}
      query = Object.assign(query, this.filters)
      if (this.pagination.page !== 0) {
        query.page = this.pagination.page
      }
      if (this.localPagination.page !== 0) {
        query.local_page = this.localPagination.page
      }
      query.per_page = this.perPage
      this.$router.push({ name: 'entity_projects_list_path', query: query })

      ApiEntities.getEntities(query)
        .then(result => {
          const query = this.$route.query
          if (Object.prototype.hasOwnProperty.call(query, 'name')) {
            this.filters.name = query.name
          }
          this.entityProjects = result.data.datas
          this.locals = result.data.locals
          this.pagination = result.data.pagination
          this.localPagination = result.data.localPagination
        })
        .catch(error => {
          console.log(error)
        })
    },
    getFilteredEntities () {
      this.pagination.page = 1
      this.localPagination.page = 1
      this.getEntities()
    }

  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/stylesheets/vars';

  .institution-table {
    margin-top: 1rem;
    background-color: $pcg-white;
  }

  /deep/ table.institution-table {
    background-color: $pcg-white;
    border-radius: 15px;

    thead {
      background-color: $main-color;
      color: $pcg-white;

      th {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        vertical-align: middle;

        &.td-center {
          text-align: center;
        }
      }

      th:first-child {
        padding-left: 15px;
        border-top-left-radius: 15px;
      }

      th:last-child {
        padding-right: 15px;
        border-top-right-radius: 15px;
      }
    }

    tbody {
      padding-left: 15px;
      padding-right: 15px;
      background-color: $pcg-white;

      td {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        vertical-align: top;
        color: $pcg-dark-gray;

        &.td-left {
          text-align: left;
          color: $main-color;
          font-weight: 400;
        }
      }

      td:first-child {
        padding-left: 15px;
      }

      td:last-child {
        padding-right: 15px;
      }

      tr {
        height: 42px;
      }

      tr:last-child {
        td {
          padding-bottom: 15px;
        }

        td:first-child {
          padding-left: 15px;
          border-bottom-left-radius: 15px;
        }

        td:last-child {
          padding-right: 15px;
          border-bottom-right-radius: 15px;
        }
      }
    }
  }

  .edit-discipline {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-l;
    margin-bottom: 1rem;
  }

  .my-field {
    margin-bottom: 1rem;
    position: relative;
    /deep/ .pcg-input-label {
      margin-bottom: 0.25rem;
      color: $pcg-gray;
    }
    /deep/ .pcg-input {
      height: calc(1.5em + 0.75rem + 2px);
    }
  }

  .institution-edit {
    color: $main-active-color;
  }

  .my-select-short {
    margin-right: .8rem;
    margin-left: .7rem;
    position: relative;
    width: 100px;
    display: inline-block;
    ::v-deep .bootstrap-select .dropdown-toggle {
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
    }
    ::v-deep .filter-option-inner {
      position: relative;
      bottom: .45rem;
    }
  }

  .per_page {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-s;
  }

  .button-element {
    cursor: pointer;
  }

  .my-pen {
    color: $main-active-color;
  }

  .academy-icon {
    padding: 12px;
    border-radius: 50%;
    background-color: #ecf9fd;
  }

  /deep/ .tooltip-inner {
    background: $pcg-white;
    color: $pcg-dark-gray;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
    font-weight: 400;
    padding: .5rem;
    border-color: $pcg-light-gray-background;
    border-style: solid;
    border-width: 1px;
    opacity: 1;
  }

  /deep/ .arrow::before {
    border-top-color: $pcg-light-gray-background !important;
  }

</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .edit-discipline {
      color: $pcg-gray;
    }
    .my-field {
      /deep/ .pcg-input-label {
        color: $pcg-gray;
      }
    }
    .institution-edit {
      color: $main-active-color;
    }
    .my-pagination {
      /deep/ .page-item {
        &, &.disabled {
          .page-link {
            color: $pcg-the-darkest-gray !important;
            &:hover {
              color: $hover-color !important;
            }
          }
        }
        &.active {
          .page-link {
            background-color: $main-active-color !important;
            color: $pcg-white !important;
          }
        }
        &:first-child, &:last-child {
          .page-link {
            color: $main-active-color !important;
          }
        }
      }
    }
    .my-pen {
      color: $main-active-color;
    }
    .academy-icon {
      background-color: #ecf9fd;
    }
    /deep/ .tooltip-inner {
      background: $pcg-white;
      color: $pcg-dark-gray;
      box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
      border-color: $pcg-light-gray;
    }
    /deep/ .arrow::before {
      border-top-color: $pcg-light-gray !important;
    }
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .institution-table {
      background-color: $pcg-white !important;
      thead {
        background-color: $main-color !important;
        color: $pcg-white !important;
      }
      tbody {
        background-color: $pcg-white !important;
        td {
          color: $pcg-dark-gray !important;
          &.td-left {
            color: $main-color !important;
          }
        }
      }
    }
  }
</style>
