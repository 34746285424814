<template>
  <div>
    <b-row>
      <b-col cols="12" xl="8">
        <div class="form-container separator">

          <pcg-text-input class="col-12"
                          :label="$t('views.dictionary_acceptance_words.labels.name')"
                          :error-text="getError('name')"
                          :show-error="errorExist('name')"
                          v-model="dictionaryWord.name"/>
          <pcg-text-input class="col-12" type="textarea"
                          :label="$t('views.dictionary_acceptance_words.labels.value')"
                          :error-text="getError('value')"
                          :show-error="errorExist('value')"
                          v-model="dictionaryWord.value"/>
          <pcg-checkbox class="col-12"
                        :error-text="getError('status')"
                        :show-error="errorExist('status')"
                        v-model="dictionaryWord.statusBoolean">
            {{$t('views.dictionary_acceptance_words.labels.status')}}
          </pcg-checkbox>

          <pcg-btn v-if="dictionaryWord" size="small" @click="createDictionaryAcceptanceWord()" variant="additional" class="action-btn">
            {{ $t('views.common.save') }}
          </pcg-btn>
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import ApiDictionaryAcceptanceWords from '../../api/dictionary_acceptance_words'

export default {
  name: 'DictionaryAcceptanceWordsEdit',
  components: {
  },
  data () {
    return {
      dictionaryId: this.$route.params.dictionaryId,
      id: this.$route.params.id,
      errors: {},
      dictionaryWord: {
        value: null,
        status: null,
        statusBoolean: null
      }
    }
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    // this.getDictionaryAcceptanceWord()
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.dictionary_acceptance_words.new.title'),
        subtitle: this.$t('views.dictionary_acceptance_words.new.subtitle'),
        capitalizeSubtitle: false
      })
    },
    createDictionaryAcceptanceWord () {
      this.loading = true
      const dictionaryWordDup = this.dictionaryWord
      if (this.dictionaryWord.statusBoolean) {
        dictionaryWordDup.status = 'required'
      } else {
        dictionaryWordDup.status = 'optional'
      }
      ApiDictionaryAcceptanceWords.createDictionaryAcceptanceWord(this.dictionaryId,
        { dictionaryAcceptanceWord: dictionaryWordDup })
        .then(response => {
          this.errors = {}
          this.$router.push({
            name: 'dictionary_acceptance_words_edit_path',
            params: { dictionaryId: response.data.data.attributes.dictionaryAcceptanceId, id: response.data.data.id }
          })
          setTimeout(() => { this.$toastr.s(this.$t('general.dictionary_word_updated')) }, 1)
        }).catch(error => {
          this.errors = error.response.data
          setTimeout(() => { this.$toastr.e(this.$t('general.check_errors')) }, 1)
        }).finally(() => {
          this.loading = false
        })
    },
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>
