import api from './index'

const resource = 'employee_identities'

export default {
  changeActive (identity) {
    let url = resource
    url += '/' + identity.id + '/change_active'
    const body = {
      employee_identity: {
        id: identity.id,
        active: identity.active
      }
    }
    api.put(url, body)
  },

  changePreferred (identity) {
    let url = resource
    url += '/' + identity.id + '/change_preferred'
    const body = {
      employee_identity: {
        id: identity.id,
        preferred: identity.preferred
      }
    }
    api.put(url, body)
  },

  removeIdentity (id, userClicked = false) {
    let url = resource
    url += '/' + id
    if (userClicked) url += '?user_clicked=true'
    return api.delete(url)
  },

  createEmployeeIdentity (employeeId, identity, title, userClicked = false) {
    let employeeIdentity = {}
    employeeIdentity = {
      employee_id: employeeId,
      first_name: identity.name,
      last_name: identity.surname,
      title: title
    }
    const body = {
      employee_identity: employeeIdentity
    }
    let url = resource
    if (userClicked) url += '?user_clicked=true'
    return api.post(`${url}`, body)
  }
}
