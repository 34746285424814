<template>
  <div>
    <vue-slider
      :value="sliderValue"
      @change="$emit('input', $event)"
      :data="sliderData"
      :tooltip="'none'"
      :process="false"
      :disabled="true"
      class="main-class">
    </vue-slider>
    <div class="fake-slider"></div>
    <div class="my-container">
      <div class="first-number">25</div>
      <div class="number-between">50</div>
      <div class="number-between">75</div>
      <div class="last-number">100</div>
    </div>
  </div>
</template>
<script>
import VueSlider from 'vue-slider-component'
export default {
  components: {
    VueSlider
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      sliderValue: '100',
      sliderData: ['25', '50', '75', '100']
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';
  .main-class {
    position: relative;
    /deep/ .vue-slider-rail {
      background: $pcg-light-gray-background;
      border-radius: 10px;
      height: 12px;
      width: calc(100% - 30px);
      margin: 0 auto;
      pointer-events: none;
    }
    /deep/ .vue-slider-dot-handle {
      pointer-events: none;
      width: 30px;
      position: relative;
      z-index: 2;
      height: 30px;
      border-radius: 50%;
      background-color: $main-color;
    }
    /deep/ .vue-slider-dot {
      position: relative;
      width: 30px !important;
      height: 30px !important;
      pointer-events: none;
    }
  }
  .fake-slider {
    position: relative;
    background: $pcg-light-gray-background;
    border-radius: 10px;
    height: 14px;
    bottom: 12px;
    pointer-events: none;
    width: 100%;
  }
  .my-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    width: 100%;
  }
  .first-number {
    position: relative;
    left: 7.5px;
    bottom: 2.1rem;
    color: $pcg-the-darkest-gray;
    font-weight: 400;
    pointer-events: none;
  }
  .number-between {
    position: relative;
    color: $pcg-the-darkest-gray;
    font-weight: 400;
    bottom: 2.1rem;
    pointer-events: none;
  }
  .last-number {
    position: relative;
    bottom: 2.1rem;
    right: 4px;
    text-align: center;
    color: $pcg-white;
    font-weight: 400;
    z-index: 6;
    pointer-events: none;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .main-class {
      /deep/ .vue-slider-rail {
        background: $pcg-light-gray;
      }
      /deep/ .vue-slider-dot-handle {
        background-color: $main-color;
      }
    }
    .fake-slider {
      background: $pcg-light-gray;
    }
    .first-number {
      color: $pcg-the-darkest-gray;
    }
    .number-between {
      color: $pcg-the-darkest-gray;
    }
    .last-number {
      color: $pcg-white;
    }
  }
</style>
