<template>
  <div>
    <b-table v-if="reports.length > 0"
             class="reports-table"
             borderless hover
             thead-class="sthead"
             tbody-tr-class="strow"
             :fields="fields"
             :items="reports">
      <template v-slot:cell(report_name)="data">
        <span class="my-text">
          {{ reportName(data.item) }}
        </span>
      </template>
      <template v-slot:cell(report_count)="data">
        <span v-if="data.item.count > 0" class="my-text-link">
          <router-link v-if="scientistList" class="clicked" :to="{ name: 'other_scientist_publications_path', params: { employeeId }, query: getFilters(data.item.id) }" target="_blank" >
            {{ data.item.count }}
          </router-link>
          <router-link v-else class="clicked" :to="{ name: 'achievement_list_path', query: getFilters(data.item.id) }" target="_blank"  >
            {{ data.item.count }}
          </router-link>
        </span>
        <span v-else class="my-text">{{ data.item.count }}</span>
      </template>
    </b-table>
  </div>
</template>

<script>

export default {
  name: 'AcademyReport',
  props: {
    reports: Array,
    scientistList: {
      type: Boolean,
      default: false
    },
    employeeId: Number,
    filters: Object,
    reportType: String
  },
  data () {
    return {
    }
  },
  computed: {
    fields: function () {
      return [
        {
          key: 'report_name',
          label: this.$t('components.publication_table_element.report_name'),
          thStyle: 'width: 60%;'
        },
        {
          key: 'report_count',
          label: this.$t('components.publication_table_element.report_count')
        }
      ]
    }
  },
  methods: {
    reportName (report) {
      if (this.$i18n.locale === 'pl') {
        return report.name
      } else {
        return report.nameEn
      }
    },
    getFilters (id) {
      const oFilters = {}
      Object.keys(this.filters).forEach(key => {
        if (key !== 'disciplin' && key !== 'financingSource' && key !== 'artKind' && key !== 'artType') {
          if (key === 'submited' || key === 'commercialization' || key === 'qualifiesForEvaluation' || key === 'conferenceMaterials' || key === 'contributors' || key === 'wosScopus' || key === 'webOfScience' || key === 'hasDuplicates' || key === 'hasFee' || key === 'openAccess' || key === 'doctoralSchool') {
            // eslint-disable-next-line eqeqeq
            if (this.filters[key] != null && this.filters[key].toString() !== '') {
              oFilters[key] = this.filters[key]
            }
          } else {
            if (this.filters[key] != null && this.filters[key].toString() !== '' && this.filters[key]) {
              oFilters[key] = this.filters[key]
            }
          }
        } else {
          if (key === 'disciplin' && this.filters[key] !== null && this.filters[key].value !== null && this.filters[key].value !== '') {
            oFilters.disciplinName = this.filters.disciplin.value
          } else if (key === 'financingSource' && this.filters[key] !== null && this.filters[key].value !== null && this.filters[key].value !== '') {
            oFilters.financingSourceName = this.filters.financingSource.value
          }
        }
      })
      const idx = this.reports.findIndex(b => b.id === id)
      if (idx >= 0) {
        if (this.reportType === 'status') {
          oFilters.status = this.reports[idx].id
        } else if (this.reportType === 'statusPbn') {
          oFilters.statusPbn = this.reports[idx].id
        } else if (this.reportType === 'disciplineId') {
          oFilters.disciplineId = this.reports[idx].id
        } else if (this.reportType === 'publicationTypeId') {
          oFilters.publicationType = this.reports[idx].id
        } else if (this.reportType === 'authorUnitId') {
          oFilters.authorUnit = this.reports[idx].id
        }
      }
      return oFilters
    }
  },
  watch: {
    filters: {
      deep: true,
      handler (newValue, oldValue) {
        this.getFilters()
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/stylesheets/vars';
.reports-table {
  margin-top: 1rem;
}
/deep/ table.reports-table {
  background-color: $pcg-white;
  border-radius: 15px;

  thead {
    background-color: $main-color;
    color: $pcg-white;

    th {
      padding-top: 15px;
      padding-bottom: 15px;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      vertical-align: middle;
      font-size: small;

      &.td-center {
        text-align: center;
      }
    }

    th:first-child {
      padding-left: 15px;
      border-top-left-radius: 15px;
    }

    th:last-child {
      padding-right: 15px;
      border-top-right-radius: 15px;
    }
  }

  tbody {
    padding-left: 15px;
    padding-right: 15px;
    background-color: $pcg-white;

    td {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      vertical-align: middle;

      &.td-center {
        text-align: center;
      }
    }

    td:first-child {
      padding-left: 15px;
    }

    td:last-child {
      padding-right: 15px;
    }

    tr {
      height: 52px;
    }

    tr:last-child {
      td {
        padding-bottom: 15px;
      }

      td:first-child {
        padding-left: 15px;
        border-bottom-left-radius: 15px;
      }

      td:last-child {
        padding-right: 15px;
        border-bottom-right-radius: 15px;
      }
    }
  }
}

.my-text {
  font-weight: 400;
  color: $pcg-gray;
  a {
    color: unset;
  }
}

.my-text-link {
  font-weight: bold;
  color: $main-color;
  a {
    color: unset;
  }
}

.clicked {
  cursor: pointer;
}

.filter-reports {
  font-weight: 400;
  color: $pcg-gray;
  margin-left: .7rem;
  margin-right: .8rem;
}

.my-select {
  display: inline-block;
  min-width: 300px;
  margin-right: 2rem;
  /deep/ .bootstrap-select .dropdown-toggle {
    height: calc(1.5rem + 0.75rem + 2px)
  }
  /deep/ .filter-option-inner {
    position: relative;
    bottom: .3rem;
  }
}
</style>
