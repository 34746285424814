import api from './index'

const resource = 'employee_import'

export default {
  getXlsFiles (page = null) {
    let url = ''
    if (page) {
      url = resource + '?page=' + page
    } else {
      url = resource
    }
    return api.get(url)
  },
  putXlsFile (form) {
    return api.post(resource, form)
  },
  getXlsFile (id) {
    let url = resource
    url += '/' + id
    return api.get(url)
  },
  deleteXls (id) {
    let url = resource
    url += '/' + id
    return api.delete(url)
  },
  parseXlsFile (id) {
    let url = resource
    url += '/' + id + '/parse'
    return api.get(url)
  },
  importPbn (params) {
    const url = `${resource}/import?${params}`
    return api.post(url)
  }
}
