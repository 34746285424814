<template>
    <div class="import-pcg">
        {{ $t('views.achievements.import_pcg_import') }}
        <spinner v-if="importSpinner"/>
        <div class="slots-bar">
            <div class="progress" :style="{ width: `${progress}%` }"></div>
        </div>

    </div>
</template>

<script>
import ApiImportPcg from '../../api/import_pcg'
import Spinner from '../../components/Spinner'
import waitForJobs from '../../mixins/wait_for_jobs'

export default {
  name: 'ImportPCG',
  components: { Spinner },
  mixins: [waitForJobs],
  data () {
    return {
      jobId: null,
      progress: 0,
      importSpinner: false
    }
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    ApiImportPcg.import()
      .then(result => {
        const jobId = result.data.jobId
        if (jobId !== undefined) {
          this.importSpinner = true
          this.waitForJob(jobId, this.publicationsUpdate, this.publicationError)
        }
      })
      .catch(error => {
        console.log(error)
      })
  },
  watch: {
    progress (newValue) {
      return newValue
    }
  },
  methods: {
    publicationsUpdate () {
      this.importSpinner = false
      // this.$router.go(0)
    },
    publicationError () {
      this.importSpinner = false
      this.unexpectedError()
    }

  }
}
</script>

<style scoped lang="scss">
    @import '../../assets/stylesheets/vars';
    .slots-bar {
        background-color: $pcg-lightest-gray-background;
        height: 15px;
        width: 100%;
        border-radius: 15px;
        box-shadow: 0 5px 10px rgba(200, 200, 200, 0.1);
        overflow: hidden;
        cursor: default;
    }
    .progress {
        display: inline-block;
        background-color: $main-color;
        border-radius: unset;
    }
</style>

<style lang="scss" scoped>
    @import "../../assets/stylesheets/vars-contrast";
    .contrast {
        .slots-bar {
            background-color: $pcg-lightest-gray;
        }
        .progress {
            background-color: $main-color;
        }
    }
</style>
