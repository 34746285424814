// import Users from '../../api/users'

const user = {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  }
}

export {
  user
}
