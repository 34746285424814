<template>
  <div>
    <div class="manager">{{ $t('components.dn_field.manager') }}</div>
    <div v-if="checkManagers(discipline.managers)" class="manager-info">
      <span v-for="manager in discipline.managers" :key="manager.id" class="manager-name">
        <template v-if="manager.employeeId">
          <router-link :to="{ name: 'employee_path', params: { id: manager.employeeId } }" >
            {{ manager.managerName }}
          </router-link>
        </template>
        <template v-else>
          {{ manager.managerName }}
        </template>
<!--        <router-link :to="{ name: 'employee_path', params: { id: manager.employeeId } }" >-->
<!--          {{ manager.managerName }}-->
<!--        </router-link>-->
      </span>
      <a href="javascript:;" v-if="hasRight('edit_discipline_manager')" class="change" @click="managersEdit(discipline)">{{ $t('components.dn_field.change') }}</a>
    </div>
    <div v-else class="manager-info">
      <span class="no-manager">{{ $t('components.dn_field.no_manager') }}</span>
      <a href="javascript:;" v-if="hasRight('edit_discipline_manager')" class="change" @click="managersEdit(discipline)">{{ $t('components.dn_field.choose') }}</a>
    </div>
    <b-modal ref="managers"
             :hide-header="true"
             v-bind:ok-title="$t('general.confirm')"
             ok-variant="primary"
             :centered="true"
             :no-close-on-esc="true"
             @ok="managersSave"
             v-bind:cancel-title="$t('general.cancel')"
             cancel-variant="default"
             @hide="cleanManagers"
             content-class="my-modal-content"
             footer-class="my-modal-footer"
    >
      <dn-discipline-managers v-model="managers" :errors="errors"></dn-discipline-managers>
    </b-modal>
  </div>
</template>

<script>
import DnDisciplineManagers from './DnDisciplineManagers'
import ApiDiscipline from '../api/disciplins'
import hasRight from '../mixins/has_right'

export default {
  name: 'DnDisciplineElement',
  components: { DnDisciplineManagers },
  mixins: [hasRight],
  props: {
    discipline: Object,
    admin: Boolean
  },
  data: function () {
    return {
      managers: [],
      managerSpinner: false,
      lastDiscipline: '',
      errors: []
    }
  },
  methods: {
    checkManagers (managers) {
      if (managers && managers.length && managers.length > 0) {
        return true
      } else {
        return false
      }
    },
    managersEdit (discipline) {
      this.lastDiscipline = discipline
      this.managers = this.copyManagers(discipline.managers)
      if (this.managers.length === 0) {
        this.managers.push({
          id: null,
          managerName: null
        })
      }
      this.$refs.managers.show()
    },
    copyManagers (managers) {
      const newManagers = []
      managers.forEach(a => {
        const na = {}
        for (const key in a) {
          na[key] = a[key]
        }
        newManagers.push(na)
      })
      return newManagers
    },
    managersSave () {
      for (let i = 0; i < this.managers.length; i++) {
        if (!this.managers[i].userId && !this.managers[i].employeeId) {
          this.managers[i].newId = this.managers[i].id
          this.managers[i].id = null
        }
      }
      if (this.validateManagers(this.managers)) {
        ApiDiscipline.updateManagers(this.discipline.id, this.managers)
          .then(response => {
            const managers = response.data.managers
            this.discipline.managers = this.copyManagers(managers)
            this.$refs.managers.hide()
          }).catch(error => {
            this.errors = error.response.data
            this.managers = this.discipline.managers
            this.managersEdit(this.lastDiscipline)
          })
      }
    },
    cleanManagers () {
      this.errors = []
    },
    validateManagers (managers) {
      // TODO
      return true
    }
    // checkRoles () {
    //   for (let i = 0; i < this.roles.length; i++) {
    //     if (this.roles[i] === 'kierownikdyscypliny') {
    //       this.admin = false
    //     } else if (this.roles[i] === 'pracownik' || this.roles[i] === 'analityknaukowy') {
    //       this.admin = true
    //       break
    //     }
    //   }
    // }
  },
  created () {
    this.roles = this.$store.getters['auth/roles']
  },
  mounted () {
    // this.checkRoles()
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .manager {
    font-size: $font-size-s;
    font-weight: 400;
    color: $pcg-gray;
    margin-right: .2rem;
    display: inline-block;
  }
  .manager-name {
    color: $main-color;
    font-size: $font-size-s;
    font-weight: 600;
    margin-right: 1rem;
    a {
      color: unset;
    }
  }
  .change {
    color: $main-active-color;
    font-size: $font-size-s;
    font-weight: 400;
    cursor: pointer;
  }

  .no-manager {
    font-size: $font-size-s;
    font-weight: 400;
    color: $dn-orange;
    margin-right: 1rem;
  }

  .manager-info {
    display: inline-block;
  }

  /deep/ .pcg-field {
    height: inherit;
    padding-bottom: 0;
    padding-top: 0;
    line-height: unset;
    color: $main-color;
    font-weight: 400;
  }

  /deep/ .form-group {
    display: inline-block;
    width: 85%;
    margin: 0 5px;
  }

  /deep/ .btn-default {
    color: $main-active-color
  }

  /deep/ .btn-primary {
    border-radius: 30px;
    background-color: $main-active-color;
    padding: .2rem 2rem;
    border: none;
  }

  /deep/ .modal-title {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-l;
  }

  /deep/ .pcg-input:focus {
    color: $pcg-gray;
  }

  /deep/ .check-list {
    color: $pcg-gray;
    font-weight: 400 !important;
    margin-left: .3rem;

    ul {
      font-size: $font-size-s !important;
    }

    li {
      margin-bottom: .3rem !important;
    }

    li:last-of-type {
      margin-bottom: 0 !important;
    }
  }

</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .manager {
      color: $pcg-gray;
    }
    .manager-name {
      color: $main-color;
    }
    .change {
      color: $main-active-color;
    }
    .no-manager {
      color: $dn-orange;
    }
    /deep/ .pcg-field {
      color: $main-color;
    }
    /deep/ .pcg-field {
      color: $main-color;
    }
    /deep/ .btn-default {
      color: $main-active-color
    }
    /deep/ .btn-primary {
      background-color: $main-active-color;
    }
    /deep/ .modal-title {
      color: $pcg-gray;
    }
    /deep/ .pcg-input:focus {
      color: $pcg-gray;
    }
    /deep/ .check-list {
      color: $pcg-gray;
    }
  }
</style>
