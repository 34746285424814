<template>
<div class="conferences-list">
  <b-row style="margin-bottom: 1.5rem">
    <b-col>
      <label :for="$t('views.conferences.list.filters.name')" class="sr-only" style="position: relative; top: .2rem;">{{ $t('views.conferences.list.filters.name') }}</label>
      <pcg-text-input
          class="no-margin my-input"
          :id="$t('views.conferences.list.filters.name')"
          :show-label="false"
          :placeholder="$t('views.conferences.list.filters.name')"
          v-model="filters.name"
          @input="getFilteredConferences"
      />
    </b-col>
  </b-row>
  <b-row style="margin-bottom: 1.5rem">
    <b-col>
      <pcg-text-input
          class="no-margin my-input"
          :show-label="false"
          :placeholder="$t('views.conferences.list.filters.short')"
          v-model="filters.short"
          @input="getFilteredConferences"
      />
    </b-col>
  </b-row>
  <b-row>
    <b-col cols="10">
      <span class="per_page">{{ $t('views.achievement.list.per_page') }}</span>
      <pcg-select
          class="my-select-short"
          style="display: inline-block"
          :options="perPageOptions"
          :default-option="{ text: perPage.toString(), value: perPage }"
          v-model="perPage"
          @input="getFilteredConferences"
      />
    </b-col>
    <b-col class="text-right">
      <pcg-btn v-if="(hasRight('edit_dictionaries') && tabModel === 0) || (hasRight('edit_own_dictionaries') && tabModel === 1)" variant="additional" size="small" @click="conferenceAdd">
        {{$t('general.add')}}
      </pcg-btn>
    </b-col>
  </b-row>
  <b-tabs class="mt-3" v-model="tabModel">
    <b-tab :title="$t('views.conferences.list.ministerial_list')">
      <b-table v-if="conferences.length > 0"
               class="conferences-table"
               borderless hover
               thead-class="sthead"
               tbody-tr-class="strow"
               :fields="fields"
               :items="conferences"
               :primary-key="conferences.id"
      >
        <template v-slot:cell(name)="data">
          {{ data.value }}
        </template>
        <template v-slot:cell(short)="data">
          {{ data.value }}
        </template>
        <template v-for="(confPoints, index) in conferences[0].conferencePoints" v-slot:[`cell(points${confPoints.period})`]="data">
          {{ data.item.conferencePoints[index].points}}
        </template>
        <template v-slot:cell(id)="data">
          <span v-if="hasRight('edit_dictionaries') && !data.item.removed" class="clicked small ml-1 publisher-edit" @click="conferenceEdit(data.value)">
            <i class="fas fa-pen small"></i>
            {{ $t('general.edit') }}
          </span>
          <span v-if="data.item.removed" class="small ml-1 item-deleted">
            {{ $t('general.deleted') }}
          </span>
        </template>
      </b-table>
      <nav :aria-label="$t('general.pagination')">
        <pcg-pagination
            v-model="pagination.page"
            :page-count="pagination.pages"
            :total-records="pagination.count"
            @input="getConferences"
            class="col-12 justify-content-center my-pagination"
            style="margin-top: 2rem"
            :only-event="true"
        ></pcg-pagination>
      </nav>
    </b-tab>
    <b-tab :title="$t('views.conferences.list.other_list')" v-if="academyId !== 'undefined' && academyId !== undefined">
      <b-table v-if="locals.length > 0"
               class="conferences-table"
               borderless hover
               thead-class="sthead"
               tbody-tr-class="strow"
               :fields="otherFields"
               :items="locals"
               :primary-key="locals.id"
      >
        <template v-slot:cell(name)="data">
          {{ data.value }}
        </template>
        <template v-slot:cell(short)="data">
          {{ data.value }}
        </template>
        <template v-slot:cell(id)="data">
          <a href="javascript:;" :aria-label="$t('general.edit')" class="my-pen button-element" style="display: inline-block" v-if="hasRight('edit_own_dictionaries')" @click="conferenceEdit(data.value)">
            <i class="fas fa-pen academy-icon" :id="`edit-conference-` + data.value"></i>
            <b-tooltip :target="`edit-conference-` + data.value">
              {{ $t('general.edit') }}
            </b-tooltip>
          </a>
          <a href="javascript:;" :aria-label="$t('general.delete')" class="text-danger button-element" style="display: inline-block" v-if="hasRight('edit_own_dictionaries')" @click="conferenceRemove(data.value)">
            <i class="fas fa-trash academy-icon" :id="`delete-conference-` + data.value"></i>
            <b-tooltip :target="`delete-conference-` + data.value">
              {{ $t('general.delete') }}
            </b-tooltip>
          </a>
        </template>
      </b-table>
      <nav :aria-label="$t('general.pagination')">
        <pcg-pagination
            v-model="localPagination.page"
            :page-count="localPagination.pages"
            :total-records="localPagination.count"
            @input="getConferences"
            class="col-12 justify-content-center my-pagination"
            style="margin-top: 2rem"
            :only-event="true"
        ></pcg-pagination>
      </nav>
    </b-tab>
  </b-tabs>
  <b-modal ref="conferenceModal"
           :hide-header="true"
           :centered="true"
           v-bind:ok-title="$t('general.confirm')"
           ok-variant="primary"
           :no-close-on-esc="true"
           @ok="conferenceSave"
           @hide="conferenceEsc"
           v-bind:cancel-title="$t('general.cancel')"
           cancel-variant="default"
           v-on-clickaway = "conferenceEsc"
           footer-class="my-modal-footer"
           content-class="my-modal-content"
  >
    <div class="edit-discipline" style="display: inline-block">{{ $t('views.conferences.list.modal.title') }}</div>
    <pcg-text-input
        class="my-field"
        :label="$t('views.conferences.list.modal.name')"
        v-model="mConference.name"
    />
    <pcg-text-input
        class="my-field"
        :label="$t('views.conferences.list.modal.short')"
        v-model="mConference.short"
    />
    <pcg-text-input
        class="my-field"
        :label="$t('views.conferences.list.modal.website')"
        v-model="mConference.website"
    />
    <div v-if="!mConference.academyId">
      <pcg-input-number
          v-for="(conferencePoint, index) in mConference.conferencePoints"
          :key="conferencePoint.id"
          class="my-field"
          style="margin-bottom: 1.5rem"
          :label="`${$t('views.publishers.list.modal.points')} ${mConference.conferencePoints[index].period}`"
          v-model="mConference.conferencePoints[index].points"
      />
    </div>
  </b-modal>
  <b-modal ref="localConferenceModal"
           :hide-header="true"
           :centered="true"
           v-bind:ok-title="$t('general.confirm')"
           ok-variant="primary"
           :no-close-on-esc="true"
           @ok="conferenceSave"
           @hide="conferenceEsc"
           v-bind:cancel-title="$t('general.cancel')"
           cancel-variant="default"
           v-on-clickaway = "conferenceEsc"
           footer-class="my-modal-footer"
           content-class="my-modal-content"
  >
    <div class="edit-discipline" style="display: inline-block">{{ $t('views.conferences.list.modal.title') }}</div>
    <pcg-text-input
      class="my-field"
      :label="$t('views.conferences.list.modal.name')"
      v-model="mConference.name"
    />
    <pcg-text-input
      class="my-field"
      :label="$t('views.conferences.list.modal.short')"
      v-model="mConference.short"
    />
    <pcg-text-input
        class="my-field"
        :label="$t('views.conferences.list.modal.website')"
        v-model="mConference.website"
    />
  </b-modal>
  <b-modal ref="clearModal"
           :hide-header="true"
           footer-class="my-modal-footer"
           :centered="true"
           v-bind:ok-title="$t('general.confirm')"
           ok-variant="primary"
           :no-close-on-esc="true"
           @ok="conferenceDestroy"
           v-bind:cancel-title="$t('general.cancel')"
           cancel-variant="default"
           content-class="my-modal-content"
  >
    <div class="confirm-header">{{ $t('views.conferences.list.modal.cleaning_title') }}</div>
    <div class="confirm-text">
      <span>{{ $t('views.conferences.list.modal.publications') }}: {{ this.publications }}</span>
    </div>
    <div class="confirm-text">
      <span>{{ $t('views.conferences.list.modal.duplicates') }}: {{ this.duplicates }}</span>
    </div>
    <div class="confirm-text">{{ $t('views.conferences.list.modal.cleaning_confirmation') }}</div>
  </b-modal>
  <b-modal ref="alternativeClearModal"
           :hide-header="true"
           footer-class="my-modal-footer"
           :centered="true"
           v-bind:ok-title="$t('general.confirm')"
           ok-variant="primary"
           :no-close-on-esc="true"
           @ok="conferenceHide"
           v-bind:cancel-title="$t('general.cancel')"
           cancel-variant="default"
           content-class="my-modal-content"
  >
    <div class="confirm-header">{{ $t('views.conferences.list.modal.cleaning_title') }}</div>
    <div class="confirm-text">
      <span>{{ $t('views.conferences.list.modal.publications') }}: {{ this.publications }}</span>
    </div>
    <div class="confirm-text">
      <span>{{ $t('views.conferences.list.modal.duplicates') }}: {{ this.duplicates }}</span>
    </div>
    <div class="confirm-text">{{ $t('views.conferences.list.modal.cannot_delete') }}</div>
  </b-modal>
</div>
</template>

<script>
import ApiConferences from '../../api/conferences'
import { mixin as clickaway } from 'vue-clickaway'
import setTitle from '../../mixins/set_title'
import hasRight from '../../mixins/has_right'
import { mapGetters } from 'vuex'

export default {
  name: 'conferencesList',
  mixins: [hasRight, setTitle, clickaway],
  data: function () {
    return {
      headerTitle: 'views.conferences.list.title',
      headerSubtitle: 'views.conferences.list.subtitle',

      filters: {
        name: null,
        short: null
      },
      conferences: [],
      locals: [],
      mConference: {
        id: null,
        name: null,
        short: null,
        website: null,
        conferencePoints: []
      },
      pagination: {
        count: 0,
        items: 0,
        last: 0,
        next: null,
        page: 0,
        pages: 0,
        prev: null
      },
      localPagination: {
        count: 0,
        items: 0,
        last: 0,
        next: null,
        page: 0,
        pages: 0,
        prev: null
      },
      perPage: 25,
      tabModel: 0,
      publications: 0,
      duplicates: 0,
      acceptedPub: false
    }
  },
  computed: {
    ...mapGetters('auth', ['academyId']),
    fields: function () {
      return [
        {
          key: 'name',
          label: '',
          class: 'td-left',
          thStyle: ''
        },
        {
          key: 'short',
          label: '',
          class: 'td-left',
          thStyle: ''
        }
      ]
    },
    otherFields: function () {
      return [
        {
          key: 'name',
          label: '',
          class: 'td-left',
          thStyle: ''
        },
        {
          key: 'short',
          label: '',
          class: 'td-left',
          thStyle: ''
        },
        {
          key: 'id',
          label: '',
          thStyle: 'width: 8rem;'
        }
      ]
    },
    perPageOptions () {
      return [
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ]
    }
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.setTitles()
    ApiConferences.getConferences(this.$route.query)
      .then(result => {
        const query = this.$route.query
        if (Object.prototype.hasOwnProperty.call(query, 'name')) {
          this.filters.name = query.name
        }
        if (Object.prototype.hasOwnProperty.call(query, 'short')) {
          this.filters.short = query.short
        }
        this.conferences = result.data.datas
        this.locals = result.data.locals
        this.pagination = result.data.pagination
        this.localPagination = result.data.localPagination
        this.perPage = this.pagination.items || 25
        this.conferences[0].conferencePoints.forEach(conferencePoint => {
          this.fields.push(
              {
                key: `points${conferencePoint.period}`,
                label: `${this.$t('views.publishers.list.modal.points')} ${conferencePoint.period}`,
                thStyle: 'width: 7rem;'
              }
          )
        })
        this.fields.push(
            {
              key: 'id',
              label: '',
              thStyle: 'width: 6rem;'
            }
        )
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    conferenceAdd () {
      this.mConference = {
        id: null,
        name: null,
        short: null,
        website: null,
        conferencePoints: []
      }
      if (this.tabModel === 0) {
        this.mConference.academyId = null
        this.conferences[0].conferencePoints.forEach(conferencePoint => {
          this.mConference.conferencePoints.push(
              {
                period: conferencePoint.period,
                points: 0
              }
          )
        })
        this.$refs.conferenceModal.show()
      } else {
        this.mConference.academyId = this.academyId
        this.$refs.localConferenceModal.show()
      }
    },
    conferenceEdit (id) {
      let conference = this.conferences.find(el => {
        return el.id === id
      })
      if (conference === undefined) {
        conference = this.locals.find(el => {
          return el.id === id
        })
      }
      Object.assign(this.mConference, conference)
      if (this.tabModel === 0) {
        this.$refs.conferenceModal.show()
      } else {
        this.$refs.localConferenceModal.show()
      }
    },
    conferenceEsc () {
      this.mConference = {
        id: null,
        name: null,
        short: null,
        website: null,
        conferencePoints: []
      }
    },
    conferenceSave (modalEvt) {
      modalEvt.preventDefault()
      if (this.mConference.id) {
        const p = {}
        Object.assign(p, this.mConference)
        const idx = this.conferences.findIndex(el => {
          return this.mConference.id === el.id
        })
        const lidx = this.locals.findIndex(el => {
          return this.mConference.id === el.id
        })
        ApiConferences.updateConference(this.mConference)
          .then(result => {
            if (idx > -1) {
              Object.assign(this.conferences[idx], p)
            }
            if (lidx > -1) {
              Object.assign(this.locals[lidx], p)
            }
            this.$toastr.s('Success')
            if (this.tabModel === 0) {
              this.$refs.conferenceModal.hide()
            } else {
              this.$refs.localConferenceModal.hide()
            }
            this.mConference = {
              id: null,
              name: null,
              short: null,
              website: null,
              conferencePoints: []
            }
          })
          .catch(err => {
            const datas = err.response.data
            const message = []
            for (const key in datas) {
              message.push(`${datas[key]}`)
            }
            this.$toastr.e(message.join('\n'))
            Object.assign(this.mConference, p)
          })
      } else {
        ApiConferences.createConference(this.mConference)
          .then(result => {
            this.$toastr.s('Success')
            if (this.tabModel === 0) {
              this.$refs.conferenceModal.hide()
            } else {
              this.$refs.localConferenceModal.hide()
            }
            this.mConference = {
              id: null,
              name: null,
              short: null,
              website: null,
              conferencePoints: []
            }
            setTimeout(() => {
              ApiConferences.getConferences(this.$route.query)
                .then(result => {
                  const query = this.$route.query
                  if (Object.prototype.hasOwnProperty.call(query, 'name')) {
                    this.filters.name = query.name
                  }
                  if (Object.prototype.hasOwnProperty.call(query, 'short')) {
                    this.filters.short = query.short
                  }
                  this.conferences = result.data.datas
                  this.locals = result.data.locals
                  this.pagination = result.data.pagination
                  this.localPagination = result.data.localPagination
                  this.perPage = this.pagination.items || 25
                })
                .catch(error => {
                  console.log(error)
                })
            }, 1000)
          })
          .catch(err => {
            const datas = err.response.data
            const message = []
            for (const key in datas) {
              message.push(`${datas[key]}`)
            }
            this.$toastr.e(message.join('\n'))
            // Object.assign(this.mPublisher, p)
          })
      }
    },
    conferenceRemove (id) {
      this.forDestroy = this.locals.find(el => {
        return el.id === id
      })
      ApiConferences.getPublications(id)
        .then(result => {
          this.publications = result.data.pubs
          this.duplicates = result.data.duplicates
          this.acceptedPub = result.data.acceptedPub
          if (this.acceptedPub) {
            this.$refs.alternativeClearModal.show()
          } else {
            this.$refs.clearModal.show()
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    conferenceDestroy () {
      ApiConferences.deleteConference(this.forDestroy.id)
        .then(result => {
          this.$refs.clearModal.hide()
          setTimeout(() => {
            ApiConferences.getConferences(this.$route.query)
              .then(result => {
                const query = this.$route.query
                if (Object.prototype.hasOwnProperty.call(query, 'name')) {
                  this.filters.name = query.name
                }
                if (Object.prototype.hasOwnProperty.call(query, 'short')) {
                  this.filters.short = query.short
                }
                this.conferences = result.data.datas
                this.locals = result.data.locals
                this.pagination = result.data.pagination
                this.localPagination = result.data.localPagination
                this.perPage = this.pagination.items || 25
              })
              .catch(error => {
                console.log(error)
              })
          }, 300)
        })
        .catch(error => {
          console.log(error)
        })
    },
    conferenceHide () {
      this.$refs.alternativeClearModal.hide()
    },
    getConferences () {
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
      let query = {}
      query = Object.assign(query, this.filters)
      if (this.pagination.page !== 0) {
        query.page = this.pagination.page
      }
      if (this.localPagination.page !== 0) {
        query.local_page = this.localPagination.page
      }
      query.per_page = this.perPage
      this.$router.push({ name: 'conferences_list_path', query: query })
      ApiConferences.getConferences(query)
        .then(result => {
          const query = this.$route.query
          if (Object.prototype.hasOwnProperty.call(query, 'name')) {
            this.filters.name = query.name
          }
          if (Object.prototype.hasOwnProperty.call(query, 'short')) {
            this.filters.short = query.short
          }
          this.conferences = result.data.datas
          this.locals = result.data.locals
          this.pagination = result.data.pagination
          this.localPagination = result.data.localPagination
        })
        .catch(error => {
          console.log(error)
        })
    },
    getFilteredConferences () {
      this.pagination.page = 1
      this.localPagination.page = 1
      this.getConferences()
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/stylesheets/vars';

  .conferences-table {
    margin-top: 1rem;
    background-color: $pcg-white;
  }

  /deep/ table.conferences-table {
    background-color: $pcg-white;
    border-radius: 15px;

    thead {
      background-color: $main-color;
      color: $pcg-white;

      th {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        vertical-align: middle;

        &.td-center {
          text-align: center;
        }
      }

      th:first-child {
        padding-left: 15px;
        border-top-left-radius: 15px;
      }

      th:last-child {
        padding-right: 15px;
        border-top-right-radius: 15px;
      }
    }

    tbody {
      padding-left: 15px;
      padding-right: 15px;
      background-color: $pcg-white;

      td {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        vertical-align: top;
        color: $pcg-dark-gray;

        &.td-left {
          text-align: left;
          color: $main-color;
          font-weight: 400;
        }
      }

      td:first-child {
        padding-left: 15px;
      }

      td:last-child {
        padding-right: 15px;
      }

      tr {
        height: 42px;
      }

      tr:last-child {
        td {
          padding-bottom: 15px;
        }

        td:first-child {
          padding-left: 15px;
          border-bottom-left-radius: 15px;
        }

        td:last-child {
          padding-right: 15px;
          border-bottom-right-radius: 15px;
        }
      }
    }
  }

  .edit-discipline {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-l;
    margin-bottom: 1rem;
  }

  .my-field {
    margin-bottom: 1rem;
    position: relative;
    /deep/ .pcg-input-label {
      margin-bottom: 0.25rem;
      color: $pcg-gray;
    }
    /deep/ .pcg-input {
      height: calc(1.5em + 0.75rem + 2px);
    }
  }

  .publisher-edit {
    color: $main-active-color;
  }

  .my-select-short {
    margin-right: .8rem;
    margin-left: .7rem;
    position: relative;
    width: 100px;
    display: inline-block;
    ::v-deep .bootstrap-select .dropdown-toggle {
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
    }
    ::v-deep .filter-option-inner {
      position: relative;
      bottom: .45rem;
    }
  }

  .per_page {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-s;
  }

  .button-element {
    cursor: pointer;
  }

  .my-pen {
    color: $main-active-color;
  }

  .academy-icon {
    padding: 12px;
    border-radius: 50%;
    background-color: #ecf9fd;
  }

  /deep/ .tooltip-inner {
    background: $pcg-the-lightest-gray-background;
    color: $pcg-gray;
    font-weight: 400;
    padding: .5rem;
    border-color: $pcg-light-gray-background;
    border-style: solid;
    border-width: 1px;
    opacity: 1;
  }

  /deep/ .arrow::before {
    border-top-color: $pcg-light-gray-background !important;
  }

  .confirm-header {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .confirm-text {
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1rem;
  }

  .item-deleted {
    color: $pcg-gray;
  }

</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .edit-discipline {
      color: $pcg-gray;
    }
    .my-field {
      /deep/ .pcg-input-label {
        color: $pcg-gray;
      }
    }
    .publisher-edit {
      color: $main-active-color;
    }
    .my-pagination {
      /deep/ .page-item {
        &, &.disabled {
          .page-link {
            color: $pcg-the-darkest-gray !important;
            &:hover {
              color: $hover-color !important;
            }
          }
        }
        &.active {
          .page-link {
            background-color: $main-active-color !important;
            color: $pcg-white !important;
          }
        }
        &:first-child, &:last-child {
          .page-link {
            color: $main-active-color !important;
          }
        }
      }
    }
    .my-pen {
      color: $main-active-color;
    }
    .academy-icon {
      background-color: #ecf9fd;
    }
    /deep/ .tooltip-inner {
      background: $pcg-the-lightest-gray;
      color: $pcg-gray;
      border-color: $pcg-light-gray;
    }
    /deep/ .arrow::before {
      border-top-color: $pcg-light-gray !important;
    }
    .confirm-header {
      color: $pcg-gray;
    }
    .confirm-text {
      color: $pcg-gray;
    }
    .item-deleted {
      color: $pcg-gray;
    }
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .conferences-table {
      background-color: $pcg-white !important;

      thead {
        background-color: $main-color !important;
        color: $pcg-white !important;
      }

      tbody {
        background-color: $pcg-white !important;

        td {
          color: $pcg-dark-gray !important;

          &.td-left {
            color: $main-color !important;
          }
        }
      }
    }
  }
</style>
