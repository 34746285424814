import { render, staticRenderFns } from "./PublicationStatementElement.vue?vue&type=template&id=655484ea&scoped=true&"
import script from "./PublicationStatementElement.vue?vue&type=script&lang=js&"
export * from "./PublicationStatementElement.vue?vue&type=script&lang=js&"
import style0 from "./PublicationStatementElement.vue?vue&type=style&index=0&id=655484ea&scoped=true&lang=scss&"
import style1 from "./PublicationStatementElement.vue?vue&type=style&index=1&id=655484ea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "655484ea",
  null
  
)

export default component.exports