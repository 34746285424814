<template>
  <div>
    <dn-loader v-if="showLoader" class="with-margins"/>
    <div v-else>
      <div class="faculties-box">
        <div v-for="faculty in faculties" :key="faculty.id" class="item">
          <faculty-element :faculty="faculty" @moved="facultyMoved" @edit="facultyEdit" @drop="facultyDrop" @add="addingFaculty"/>
        </div>
      </div>
      <pcg-btn v-if="hasRight('edit_unit')" style="margin-top: 4rem" slot="footer" @click="addFaculty">{{ $t('views.faculties.list.new')}}</pcg-btn>
      <b-modal ref="facultyModal"
               :hide-header="true"
               :centered="true"
               footer-class="my-modal-footer"
               v-bind:ok-title="$t('general.confirm')"
               ok-variant="primary"
               :no-close-on-esc="true"
               @ok="facultySave"
               v-bind:cancel-title="$t('general.cancel')"
               cancel-variant="default"
               v-on-clickaway = "facultyEsc"
               content-class="my-modal-content"
      >
        <pcg-text-input class="my-input" v-model="rFaculty.name" :label="$t('views.faculties.list.name')" :showLabel="true"/>
        <pcg-text-input class="my-input" v-model="rFaculty.short" :label="$t('views.faculties.list.short')" :showLabel="true"/>
        <pcg-text-input class="my-input" v-model="rFaculty.apiId" :label="$t('views.faculties.list.api_id')" :showLabel="true"/>
        <pcg-select class="my-input"
                    :options="parentOptions"
                    :default-option="parentDefaults"
                    v-model="rFaculty.parent_id"
                    :label="$t('views.faculties.list.parent')"
                    :showLabel="true"
        />
      </b-modal>
    </div>
  </div>
</template>

<script>
import loaderDelay from '../../mixins/loader_delay'
import DnLoader from '../../components/DnLoader'
import hasRight from '../../mixins/has_right'
import setTitle from '../../mixins/set_title'
import { mixin as clickaway } from 'vue-clickaway'
import { mapGetters } from 'vuex'
import FacultyElement from './FacultyElement'
import ApiFaculties from '../../api/faculties'

export default {
  name: 'Facultieslist',
  mixins: [clickaway, hasRight, setTitle, loaderDelay],
  components: { DnLoader, FacultyElement },
  data: function () {
    return {
      headerTitle: 'views.faculties.list.title',
      headerSubtitle: 'views.faculties.list.subtitle',

      drag: false,
      faculties: [],
      rFaculty: {}
    }
  },
  computed: {
    ...mapGetters('auth', ['signedIn', 'currentRole']),
    parentDefaults () {
      const defaults = {}
      if (this.faculties.length > 0) {
        if (this.rFaculty.parentId) {
          const faculty = this.faculties.find(f => {
            return f.id === this.rFaculty.parentId
          })
          if (faculty.depth > 0) {
            defaults.text = faculty.parents + ' -> ' + faculty.name
          } else {
            defaults.text = faculty.name
          }
          defaults.value = faculty.id
        } else {
          defaults.text = this.faculties[0].name
          defaults.value = this.faculties[0].id
        }
      } else {
        defaults.text = ''
        defaults.value = null
      }
      return defaults
    },
    parentOptions () {
      const pFacultis = []
      this.faculties.forEach(f => {
        if (f.lft < this.rFaculty.lft || f.rgt > this.rFaculty.rgt) {
          let rName = ''
          if (f.depth > 0) {
            rName += f.parents + ' -> '
          }
          rName += f.name
          pFacultis.push({
            text: rName,
            value: f.id
          })
        }
      })
      return pFacultis
    }
  },
  created () {
    this.getFaculties()
  },
  mounted () {
    this.$store.dispatch('header/setHeader', {
      title: this.$t(this.headerTitle),
      subtitle: this.$t(this.headerSubtitle),
      capitalizeSubtitle: false
    })
  },
  methods: {
    addFaculty () {
      this.rFaculty = {
        id: null,
        name: null,
        short: null,
        apiId: null,
        parentId: this.faculties[0].id,
        lft: 0,
        rgt: 0
      }
      this.$refs.facultyModal.show()
    },
    addingFaculty (faculty) {
      this.rFaculty = {
        id: null,
        name: null,
        short: null,
        apiId: null,
        parentId: faculty.id,
        lft: 0,
        rgt: 0
      }
      this.$refs.facultyModal.show()
    },
    facultyEsc () {
      this.rFaculty = {
        name: null,
        short: null,
        apiId: null,
        parentId: null
      }
      this.$refs.facultyModal.hide()
    },
    facultySave () {
      if (this.rFaculty.id === null) {
        ApiFaculties.createFaculty(this.rFaculty)
          .then(result => {
            this.$refs.facultyModal.hide()
            this.getFaculties()
          })
          .catch(err => {
            // console.log(err)
            const datas = err.response.data
            const message = []
            for (const key in datas) {
              message.push(`${datas[key]}`)
            }
            this.$toastr.e(message.join('\n'))
          })
      } else {
        ApiFaculties.updateFaculty(this.rFaculty)
          .then(result => {
            this.$refs.facultyModal.hide()
            this.getFaculties()
          })
          .catch(err => {
            // console.log(err)
            const datas = err.response.data
            const message = []
            for (const key in datas) {
              message.push(`${datas[key]}`)
            }
            this.$toastr.e(message.join('\n'))
          })
      }
    },
    facultyEdit (faculty) {
      this.rFaculty = {
        id: faculty.id,
        name: faculty.name,
        short: faculty.short,
        apiId: faculty.apiId,
        parentId: faculty.parentId,
        lft: faculty.lft,
        rgt: faculty.rgt
      }
      this.$refs.facultyModal.show()
    },
    facultyDropping (faculty) {

    },
    facultyDrop (faculty) {
      ApiFaculties.dropFaculty(faculty)
        .then(result => {
          this.getFaculties()
        })
        .catch(err => {
          // console.log(err)
          const datas = err.response.data
          const message = []
          for (const key in datas) {
            message.push(`${key.toString()} ${datas[key]}`)
          }
          this.$toastr.e(message.join('\n'))
        })
    },
    // facultyMoving (evt) {
    //   const element = evt.moved.element
    //   const newIndex = evt.moved.newIndex
    //   const leftElement = this.faculties[newIndex - 1]
    //   ApiFaculties.moveRightOf(element, leftElement)
    //     .then(result => {
    //       this.getFaculties()
    //     })
    //     .catch(err => {
    //       // console.log(err)
    //       const datas = err.response.data
    //       const message = []
    //       for (const key in datas) {
    //         message.push(`${key.toString()} ${datas[key]}`)
    //       }
    //       this.$toastr.e(message.join('\n'))
    //     })
    // },
    facultyMoved () {
      this.getFaculties()
    },
    getFaculties () {
      ApiFaculties.getFaculties()
        .then(result => {
          if (result) {
            this.faculties = result.data.datas
          } else {
            // result jest false gdy brak uprawień
            this.$router.push({ name: 'login_path' }).catch(error => {
              console.log(error.message)
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loadingDone = true
        })
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/stylesheets/vars';
  .faculties-box {
    margin-top: 4rem;
    min-width: 825px;
  }

  .item {
    background: $pcg-white;
    padding: 20px 30px;
    height: 70px;
    &:hover {
      background: $pcg-the-lightest-gray-background;
    }
    &:first-child {
      border-top-right-radius: 25px;
      border-top-left-radius: 25px;
      border: 1px $pcg-light-gray-background solid;
    }
    &:not(:first-child) {
      border-left: 1px $pcg-lightest-gray-background solid;
      border-right: 1px $pcg-lightest-gray-background solid;
      border-bottom: 1px $pcg-lightest-gray-background solid;
    }
  }

  .confirm-header {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .my-input {
    /deep/ .pcg-input {
      height: calc(1.5rem + 0.75rem + 2px)
    }
    position: relative;
    /deep/ .pcg-input-label {
      margin-bottom: 0.25rem;
      color: $pcg-gray;
    }
  }
</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .item {
      background: $pcg-white;
      &:hover {
        background: $pcg-the-lightest-gray;
      }
      &:first-child {
        border: 1px $pcg-light-gray solid;
      }
      &:not(:first-child) {
        border-left: 1px $pcg-lightest-gray solid;
        border-right: 1px $pcg-lightest-gray solid;
        border-bottom: 1px $pcg-lightest-gray solid;
      }
    }
    .confirm-header {
      color: $pcg-gray;
    }
    .my-input {
      /deep/ .pcg-input-label {
        color: $pcg-gray;
      }
    }
  }
</style>
