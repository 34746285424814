<template>
  <div>
    <label for="Publications" class="import-text" style="margin-top: 2rem">{{ this.$t('views.import_publication.import_publications_from_api') }}</label>
    <b-row class="d-flex align-items-center" style="margin-bottom: 3rem">
      <b-col cols="4" style="margin-right: .5rem">
        <pcg-text-input :showLabel="false" v-model="publicationParameters" id="Publications" />
      </b-col>
      <pcg-btn size="small" class="import-btn" @click="importPublication" :disabled="employeeSpinner || publicationSpinner">
        <span>{{ this.$t('views.import_publication.import') }}</span>
        <spinner v-if="publicationSpinner"/>
        <span v-if="publicationSpinner && cableStatusLive">({{ cableStatusLive }})</span>
      </pcg-btn>
    </b-row>
    <label for="Employees" class="import-text">{{ this.$t('views.import_publication.import_employees_from_api') }}</label>
    <b-row class="d-flex align-items-center">
      <b-col cols="4" style="margin-right: .5rem">
        <pcg-text-input :showLabel="false" v-model="employeeParameters" id="Employees" />
      </b-col>
      <pcg-btn size="small" class="import-btn" @click="importEmployee" :disabled="employeeSpinner || publicationSpinner">
        <span>{{ this.$t('views.import_publication.import') }}</span>
        <spinner v-if="employeeSpinner"/>
        <span v-if="employeeSpinner && cableStatusLive">({{ cableStatusLive }})</span>
      </pcg-btn>
    </b-row>
  </div>
</template>

<script>
import ApiPbn from '../../api/pbn'
import ApiEmployeeImport from '../../api/employee_import'
import setTitle from '../../mixins/set_title'
import waitForJobs from '../../mixins/wait_for_jobs'
import Spinner from '../../components/Spinner'

export default {
  name: 'ImportPublication',
  mixins: [setTitle, waitForJobs],
  components: { Spinner },
  channels: {
    ChatChannel: {
      connected () {
        console.log('connected')
      },
      rejected () {
        console.log('rejected')
      },
      received (data) {
        console.log(data)
        this.cableStatus = data.body
      },
      disconnected () {
        console.log('dc')
      }
    }
  },
  data () {
    return {
      headerTitle: 'views.import_publication.title',
      headerSubtitle: '',
      employeeParameters: '',
      publicationParameters: '',
      employeeSpinner: false,
      publicationSpinner: false,
      cableStatus: null,
      currentUser: null
    }
  },
  computed: {
    cableStatusLive () {
      return this.cableStatus
    }
  },
  beforeMount () {
    this.currentUser = this.$store.getters['auth/id']
  },
  mounted () {
    this.$cable.subscribe({
      channel: 'ChatChannel',
      userId: this.currentUser
    })
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.$store.dispatch('header/setHeader', {
      title: this.$t(this.headerTitle),
      subtitle: this.$t(this.headerSubtitle),
      capitalizeSubtitle: false
    })
  },
  beforeDestroy () {
    this.$cable.unsubscribe({
      channel: 'ChatChannel',
      userId: this.currentUser
    })
  },
  methods: {
    importPublication () {
      ApiPbn.importPbn(this.publicationParameters)
        .then((result) => {
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.publicationSpinner = true
            this.waitForJob(jobId, this.publicationSuccess, this.publicationFailure)
          }
        })
    },
    publicationSuccess () {
      this.publicationParameters = ''
      this.publicationSpinner = false
      this.$toastr.s(this.$t('general.success'))
    },
    publicationFailure () {
      this.publicationSpinner = false
      this.$toastr.e(this.$t('general.error'))
    },
    importEmployee () {
      ApiEmployeeImport.importPbn(this.employeeParameters)
        .then((result) => {
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.employeeSpinner = true
            this.waitForJob(jobId, this.employeeSuccess, this.employeeFailure)
          }
        })
    },
    employeeSuccess () {
      this.employeeParameters = ''
      this.employeeSpinner = false
      this.$toastr.s(this.$t('general.success'))
    },
    employeeFailure () {
      this.employeeSpinner = false
      this.$toastr.e(this.$t('general.error'))
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/stylesheets/vars';

  .import-btn {
    position: relative;
    top: .3rem;
  }

  .import-text {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-m;
  }
</style>
