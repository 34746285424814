<template>
  <div class="container">
    <pcg-table :items="dictionaries" :fields="fields">
      <template v-slot:header>{{$t('views.dictionaries.index.table_header')}}</template>
    </pcg-table>
    <nav :aria-label="$t('general.pagination')">
      <pcg-pagination v-model="pagination.currentPage"
                      @input="getDictionaries"
                      :page-count="pagination.pages"
                      :total-records="pagination.totalRecords"
                      class="col-12 justify-content-center my-pagination"/>
    </nav>
  </div>
</template>

<script>
import ApiDictionaries from '../../api/dictionaries'
import PaginableResourceMixin from '../../mixins/paginable_resource_mixin'
import { decamelize } from 'humps'
import hasRight from '../../mixins/has_right'

export default {
  name: 'DictionaryIndex',
  components: { },
  mixins: [hasRight, PaginableResourceMixin],
  data () {
    return {
      dictionaries: [],
      fields: []
    }
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.getDictionaries()
  },
  watch: {
    '$i18n.locale': function (newLocale, oldLocale) {
      if (newLocale !== oldLocale) {
        this.$store.dispatch('header/setHeader', {
          title: this.$t('views.dictionaries.index.title'),
          subtitle: this.$t('views.dictionaries.index.subtitle'),
          capitalizeSubtitle: false
        })

        this.rebuildFieldsTable()
      }
    }
  },
  methods: {
    getDictionaries () {
      ApiDictionaries.getDictionaries({ page: this.pagination.currentPage })
        .then(response => {
          this.setPagination(response)
          this.fields = this.fieldsTable(response.data.data)
          this.dictionaries = this.dataTable(response.data.data)
        }).catch(error => {
          this.$toastr.e(error)
          console.log(error)
        })
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.dictionaries.index.title'),
        subtitle: this.$t('views.dictionaries.index.subtitle'),
        capitalizeSubtitle: false
      })
    },
    dataTable (data) {
      const result = []
      data.forEach((dictionary, index) => {
        const attributes = dictionary.attributes
        attributes.description = this.$t(`views.dictionaries.index.${decamelize(attributes.name)}`)
        const actions = {
          actions: []
        }
        if (attributes.dictionaryType === 'global') {
          if (this.hasRight('list_dictionaries')) {
            actions.actions.push({
              id: `${dictionary.id}-a`,
              link: () => this.$router.push({ name: 'dictionary_words_path', params: { dictionaryId: dictionary.id } }),
              type: 'button',
              text: this.$t('general.words')
            })
            actions.actions.push({
              id: `${dictionary.id}-b`,
              link: () => this.$router.push({ name: 'dictionaries_edit_path', params: { id: dictionary.id } }),
              type: 'button',
              text: this.$t('general.edit')
            })
          }
        } else {
          actions.actions.push({
            id: `${dictionary.id}-a`,
            link: () => this.$router.push({ name: 'dictionary_words_path', params: { dictionaryId: dictionary.id } }),
            type: 'button',
            text: this.$t('general.words')
          })
          actions.actions.push({
            id: `${dictionary.id}-b`,
            link: () => this.$router.push({ name: 'dictionaries_edit_path', params: { id: dictionary.id } }),
            type: 'button',
            text: this.$t('general.edit')
          })
        }
        Object.assign(attributes, actions)
        result.push(attributes)
      })
      return result
    },
    rebuildFieldsTable () {
      this.fields.forEach(f => {
        f.label = this.$t(`views.dictionaries.labels.${decamelize(f.key)}`)
      })
      console.log(this.dictionaries)
      this.dictionaries.forEach(d => {
        if (d.actions.length > 0) {
          d.actions[0].text = this.$t('general.words')
          d.actions[1].text = this.$t('general.edit')
        }
        d.description = this.$t(`views.dictionaries.index.${decamelize(d.name)}`)
      })
    },
    fieldsTable (data) {
      const fields = []
      data.forEach((dictionary, index) => {
        if (index === 0) {
          _.forEach(dictionary.attributes, (val, key) => {
            if (key === 'dictionaryType') {
              fields.push({
                key: key,
                label: this.$t(`views.dictionaries.labels.${decamelize(key)}`),
                formatter: value => {
                  return this.$t(`views.dictionaries.dictionary_types.${value}`)
                }
              })
            } else {
              fields.push({
                key: key,
                label: this.$t(`views.dictionaries.labels.${decamelize(key)}`)
              })
            }
          })
        }
      })
      fields.push({
        key: 'actions',
        label: this.$t('views.dictionaries.labels.actions')
      })
      return fields
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .my-pagination {
      /deep/ .page-item {
        &, &.disabled {
          .page-link {
            color: $pcg-the-darkest-gray !important;
            &:hover {
              color: $hover-color !important;
            }
          }
        }
        &.active {
          .page-link {
            background-color: $main-active-color !important;
            color: $pcg-white !important;
          }
        }
        &:first-child, &:last-child {
          .page-link {
            color: $main-active-color !important;
          }
        }
      }
    }
  }
</style>
