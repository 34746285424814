<template>
    <div>
        <dn-loader v-if="showLoader" class="with-margins"/>
        <div v-else class="academies-list">
            <b-row>
                <b-col>
                    <pcg-btn v-if="hasRight('add_academy')" size="small" style="margin-bottom: 2rem; margin-top: 1rem;" @click="createAcademy"  :disabled="createSpinner">
                      {{ $t('views.academies.list.new') }}
                      <spinner v-if="createSpinner"/>
                    </pcg-btn>
                </b-col>
                <b-modal ref="academyModal"
                         :hide-header="true"
                         :centered="true"
                         footer-class="my-modal-footer"
                         v-bind:ok-title="$t('general.confirm')"
                         ok-variant="primary"
                         :no-close-on-esc="true"
                         @ok="academySave"
                         v-bind:cancel-title="$t('general.cancel')"
                         cancel-variant="default"
                         v-on-clickaway = "escModal"
                         content-class="my-modal-content"
                >
                    <pcg-text-input class="my-input" v-model="rAcademy.name" :label="$t('views.academies.list.name')" :showLabel="true"/>
                    <pcg-text-input class="my-input" v-model="rAcademy.subDomain" label="Sub domain" :showLabel="true"/>
<!--                    <pcg-text-input class="my-input" v-model="rAcademy.dbUser" label="Db user" :showLabel="true"/>-->
<!--                    <pcg-text-input class="my-input" v-model="rAcademy.dbPassword" label="Db password" :showLabel="true"/>-->
                    <pcg-text-input class="my-input" v-model="rAcademy.tenantId" label="Tenant" :showLabel="true"/>
                    <pcg-text-input class="my-input" v-model="rAcademy.managerEmail" label="Manager" :showLabel="true"/>
                </b-modal>
            </b-row>
            <div class="academies-elements" v-if="academies.length > 0">
                <b-row class="text-label list-header">
                    <b-col class="th">
                        {{ $t('views.academies.list.name') }}
                    </b-col>
                  <b-col class="th">
                    {{ $t('views.academies.list.replication_date') }}
                  </b-col>
                    <b-col cols='1' class="pub-type th">
                        {{ $t('views.academies.list.locked') }}
                    </b-col>
                    <b-col cols='4' class="buttons th">
                        &nbsp;
                    </b-col>
                </b-row>
                <div v-for="academy in academies" :key="academy.id" class="academy">
                    <institution-element :academy="academy" @updated="refreshList"/>
                </div>
            </div>
            <pcg-box v-else>
                <div class="d-flex justify-content-center no-records">{{ $t('views.achievement.list.no_records') }}</div>
            </pcg-box>
        </div>
    </div>
</template>

<script>
import setTitle from '../../mixins/set_title'
import { mixin as clickaway } from 'vue-clickaway'
import InstitutionElement from '../../components/InstitutionElement'
import ApiAcademies from '../../api/institutions'
import hasRight from '../../mixins/has_right'
import waitForJobs from '../../mixins/wait_for_jobs'
import { mapGetters } from 'vuex'
import loaderDelay from '../../mixins/loader_delay'
import DnLoader from '../../components/DnLoader'
import Spinner from '../../components/Spinner'

export default {
  name: 'InstitutionsList',
  mixins: [hasRight, setTitle, clickaway, loaderDelay, waitForJobs],
  components: { InstitutionElement, DnLoader, Spinner },
  data: function () {
    return {
      headerTitle: 'views.academies.list.title',
      headerSubtitle: 'views.academies.list.subtitle',

      academies: [],
      createSpinner: false,
      rAcademy: {
        aid: null,
        name: null,
        subDomain: null,
        // dbUser: null,
        // dbPassword: null,
        tenantId: null,
        managerEmail: null
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['signedIn', 'currentRole'])
  },
  created () {
    this.getAcademies()
  },
  mounted () {
    this.$store.dispatch('header/setHeader', {
      title: this.$t(this.headerTitle),
      subtitle: this.$t(this.headerSubtitle),
      capitalizeSubtitle: false
    })
  },
  methods: {
    getAcademies () {
      ApiAcademies.getAcademies()
        .then(result => {
          if (result) {
            this.academies = result.data.datas
            if (this.currentRole === 'manager') {
              this.loadingDone = true
            } else {
              if (this.academies.length > 1) {
                this.$router.go(0)
              } else {
                this.loadingDone = true
              }
            }
          } else {
            // result jest false gdy brak uprawień
            this.$router.push({ name: 'login_path' }).catch(error => {
              console.log(error.message)
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    createAcademy () {
      for (const key in this.rAcademy) {
        this.rAcademy[key] = null
      }
      this.$refs.academyModal.show()
    },
    escModal () {
      this.$refs.academyModal.hide()
    },
    academySave () {
      this.createSpinner = true
      ApiAcademies.createAcademy(this.rAcademy)
        .then(result => {
          this.$refs.academyModal.hide()
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.waitForJob(jobId, this.updateAcademy, this.updateAcademy)
          } else {
            this.createSpinner = false
          }
        })
        .catch(err => {
          // console.log(err)
          const datas = err.response.data
          const message = []
          for (const key in datas) {
            message.push(`${key.toString()} ${datas[key]}`)
          }
          this.$toastr.e(message.join('\n'))
          this.createSpinner = false
        })
    },
    refreshList () {
      this.getAcademies()
    },
    updateAcademy () {
      ApiAcademies.restartApp()
        .then(result => {
          this.createSpinner = false
          this.$toastr.s(this.$t('views.academies.created'))
          setTimeout(() => {
            this.$router.go(0)
          }, 1000)
        })
        .catch(error => {
          const datas = error.response.data
          const message = []
          for (const key in datas) {
            message.push(`${key.toString()} ${datas[key]}`)
          }
          this.$toastr.e(message.join('\n'))
          this.createSpinner = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
    @import '../../assets/stylesheets/vars';

    .text-label {
        font-weight: bold;
    }

    .my-input {
        /deep/ .pcg-input {
            height: calc(1.5rem + 0.75rem + 2px)
        }
        /deep/ .pcg-input-label {
            margin-bottom: 0.25rem;
            color: $pcg-gray;
        }
    }

    .academies-elements {
      margin-bottom: 2rem;
      margin-top: 1rem;
      background-color: $pcg-white;
      border-radius: 15px;
      min-width: 625px;
      .list-header {
        background-color: $main-color;
        color: $pcg-white;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        .th {
          padding-top: 15px;
          padding-bottom: 15px;
          padding-right: 0.5rem;
          padding-left: 0.5rem;
          vertical-align: middle;
        }

        .th:first-child {
          padding-left: 15px;
          border-top-left-radius: 15px;
        }

        .th:last-child {
          padding-right: 15px;
          border-top-right-radius: 15px;
        }
      }
    }

    .academy {
      //border-bottom: 1px solid $pcg-lightest-gray;
      margin-right: -10px;
      margin-left: -10px;
      padding-top: 10px;
      background-color: $pcg-white;
      /deep/ .academy {
        background-color: $pcg-white;
        &.row {
          margin-right: 0;
          margin-left: 0;
          //height: 52px;
        }
      }

      &:last-child {
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        /deep/ .academy {
          background-color: $pcg-white;
          &.row {
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            .col {
              padding-bottom: 15px;
            }

            .col:first-child {
              border-bottom-left-radius: 15px;
            }

            .col:last-child {
              border-bottom-right-radius: 15px;
            }
          }
        }
      }
    }
</style>

<style lang="scss" scoped>
    @import "../../assets/stylesheets/vars-contrast";
    .contrast {
      .my-input {
        /deep/ .pcg-input-label {
          color: $pcg-gray;
        }
      }
      .academies-elements {
        background-color: $pcg-white;
        .list-header {
          background-color: $main-color;
          color: $pcg-white;
        }
      }

      .academy {
        background-color: $pcg-white;
        /deep/ .academy {
          background-color: $pcg-white;
        }

        &:last-child {
          /deep/ .academy {
            background-color: $pcg-white;
          }
        }
      }
    }
</style>
