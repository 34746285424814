<template>
    <div>
        <dn-loader v-if="showLoader" class="with-margins"/>
        <div v-else class="academies-list">
            <b-row>
                <b-col>
                    <pcg-btn v-if="hasRight('add_academy')" size="small" style="margin-bottom: 2rem; margin-top: 1rem;" @click="createAcademy">{{ $t('views.instances.list.new') }}</pcg-btn>
                </b-col>
                <b-modal ref="academyModal"
                         :hide-header="true"
                         :centered="true"
                         footer-class="my-modal-footer"
                         v-bind:ok-title="$t('general.confirm')"
                         ok-variant="primary"
                         :no-close-on-esc="true"
                         @ok="academySave"
                         v-bind:cancel-title="$t('general.cancel')"
                         cancel-variant="default"
                         v-on-clickaway = "escModal"
                         content-class="my-modal-content"
                >
                    <pcg-text-input class="my-input" v-model="rAcademy.name" :label="$t('views.instances.list.name')" :showLabel="true"/>
                    <pcg-text-input class="my-input" v-model="rAcademy.aid" type="number" label="OpenID" :showLabel="true"/>
                    <pcg-text-input class="my-input" v-model="rAcademy.pbnId" label="PBN Id" :showLabel="true"/>
                    <pcg-text-input class="my-input" v-model="rAcademy.polonAppId" label="Polon App-Id" :showLabel="true"/>
                    <pcg-text-input class="my-input" v-model="rAcademy.polonAppToken" label="Polon App-Token" :showLabel="true"/>
                </b-modal>
            </b-row>
            <div class="academies-elements" v-if="academies.length > 0">
                <b-row class="text-label list-header">
                    <b-col class="th">
                        {{ $t('views.instances.list.name') }}
                    </b-col>
                    <b-col cols='1' class="pub-type th">
                        OID
                    </b-col>
                    <b-col cols='1' class="pub-type th">
                        {{ $t('views.instances.list.locked') }}
                    </b-col>
                    <b-col cols='2' class="pub-type th">
                      {{ $t('views.instances.list.change_language') }}
                    </b-col>
                    <b-col cols='2' class="pub-type th">
                        {{ $t('views.instances.list.cyclical_evaluation') }}
                    </b-col>
                    <b-col cols='2' class="buttons th">
                        &nbsp;
                    </b-col>
                </b-row>
                <div v-for="academy in academies" :key="academy.id" class="academy">
                    <academy-element :academy="academy" :valid-roles="validRoles" :available-evaluations="availableEvaluations"/>
                </div>
            </div>
            <pcg-box v-else>
                <div class="d-flex justify-content-center no-records">{{ $t('views.achievement.list.no_records') }}</div>
            </pcg-box>
        </div>
    </div>
</template>

<script>
import setTitle from '../../mixins/set_title'
import { mixin as clickaway } from 'vue-clickaway'
import AcademyElement from '../../components/AcademyElement'
import ApiAcademies from '../../api/academies'
import hasRight from '../../mixins/has_right'
import { mapGetters } from 'vuex'
import loaderDelay from '../../mixins/loader_delay'
import DnLoader from '../../components/DnLoader'
import ApiEvaluations from '../../api/evaluations'
// Komponent listy uczelni - listowanie, dodawanie uczelni
// @group Uczelnie
export default {
  name: 'AcademiesList',
  mixins: [hasRight, setTitle, clickaway, loaderDelay],
  components: { AcademyElement, DnLoader },
  data: function () {
    return {
      headerTitle: 'views.instances.list.title',
      headerSubtitle: 'views.instances.list.subtitle',

      academies: [],
      rAcademy: {
        aid: null,
        name: null,
        polonAppToken: null,
        pbnId: null,
        polonAppId: null
      },
      validRoles: [],
      availableEvaluations: []
    }
  },
  computed: {
    ...mapGetters('auth', ['signedIn', 'currentRole'])
  },
  created () {
    this.getAcademies()
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
  },
  mounted () {
    this.$store.dispatch('header/setHeader', {
      title: this.$t(this.headerTitle),
      subtitle: this.$t(this.headerSubtitle),
      capitalizeSubtitle: false
    })
    ApiEvaluations.getEvaluations()
        .then(result => {
          result.data.datas.forEach(e => {
            this.availableEvaluations.push({ text: e.name, value: e.id })
          })
        })
        .catch(error => {
          console.log(error)
        })
  },
  methods: {
    /**
       * @vuese
       * Metoda służąca do pobrania listy uczelni. Kontroler - AcademiesController#index
       * @arg Brak parametrow
       */
    getAcademies () {
      ApiAcademies.getAcademies()
        .then(result => {
          if (result) {
            this.academies = result.data.datas
            this.validRoles = result.data.validRoles
            if (this.currentRole === 'manager') {
              this.loadingDone = true
            } else {
              if (this.academies.length > 1) {
                this.$router.go(0)
              } else {
                this.loadingDone = true
              }
            }
          } else {
            // result jest false gdy brak uprawień
            this.$router.push({ name: 'login_path' }).catch(error => {
              console.log(error.message)
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    /**
       * @vuese
       * Metoda służąca do otworzenia modala tworzenia uczelni
       * @arg Brak parametrow
       */
    createAcademy () {
      for (const key in this.rAcademy) {
        this.rAcademy[key] = null
      }
      this.$refs.academyModal.show()
    },
    /**
       * @vuese
       * Metoda służąca do zamykania modala tworzenia uczelni
       * @arg Brak parametrow
       */
    escModal () {
      this.$refs.academyModal.hide()
    },
    /**
       * @vuese
       * Metoda służąca do tworzenia nowej uczelni. Kontroler - AcademiesController#create
       * @arg OpenID, Nazwa uczelni, Polon App-Id, Polon App-Token
       */
    academySave () {
      ApiAcademies.createAcademy(this.rAcademy)
        .then(result => {
          this.$refs.academyModal.hide()
          this.getAcademies()
        })
        .catch(err => {
          // console.log(err)
          const datas = err.response.data
          const message = []
          for (const key in datas) {
            message.push(`${key.toString()} ${datas[key]}`)
          }
          this.$toastr.e(message.join('\n'))
        })
    }
  }
}
</script>

<style scoped lang="scss">
    @import '../../assets/stylesheets/vars';

    .text-label {
        font-weight: bold;
        color: $pcg-the-darkest-gray;
    }

    .my-input {
        /deep/ .pcg-input {
            height: calc(1.5rem + 0.75rem + 2px)
        }
        /deep/ .pcg-input-label {
            margin-bottom: 0.25rem;
            color: $pcg-gray;
        }
    }

    .academies-elements {
        margin-bottom: 2rem;
        margin-top: 1rem;
        background-color: $pcg-white;
        border-radius: 15px;
        min-width: 650px;
        .list-header {
            background-color: $main-color;
            color: $pcg-white;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
            .th {
                padding-top: 15px;
                padding-bottom: 15px;
                padding-right: 0.5rem;
                padding-left: 0.5rem;
                vertical-align: middle;
            }

            .th:first-child {
                padding-left: 15px;
                border-top-left-radius: 15px;
            }

            .th:last-child {
                padding-right: 15px;
                border-top-right-radius: 15px;
            }
        }
    }

    .academy {
        margin-right: -10px;
        margin-left: -10px;
        padding-top: 10px;
        background-color: $pcg-white;
        /deep/ .academy {
            background-color: $pcg-white;
            &.row {
                margin-right: 0;
                margin-left: 0;
                //height: 52px;
            }
        }

        &:last-child {
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            /deep/ .academy {
                background-color: $pcg-white;
                &.row {
                    border-bottom-left-radius: 15px;
                    border-bottom-right-radius: 15px;
                    .col {
                        padding-bottom: 15px;
                    }

                    .col:first-child {
                        border-bottom-left-radius: 15px;
                    }

                    .col:last-child {
                        border-bottom-right-radius: 15px;
                    }
                }
            }
        }
    }
</style>

<style lang="scss" scoped>
    @import "../../assets/stylesheets/vars-contrast";
    .contrast {
        .text-label {
            font-weight: bold;
            color: $pcg-the-darkest-gray;
        }
        .my-input {
            /deep/ .pcg-input-label {
                color: $pcg-gray;
            }
        }
        .academies-elements {
            background-color: $pcg-white;
            .list-header {
                background-color: $main-color;
                color: $pcg-white;
            }
        }
        .academy {
            background-color: $pcg-white;
            /deep/ .academy {
                background-color: $pcg-white;
            }
            &:last-child {
                /deep/ .academy {
                    background-color: $pcg-white;
                }
            }
        }
    }
</style>
