<template>
  <div class="footer-container">
    <b-row>
      <b-col class="col-12 col-sm-3 one-column justify-content-center text-center">
        <div class="my-text blue-text">{{ $t('components.dn_footer.million') }}</div>
        <div class="my-text">{{ $t('components.dn_footer.achievements') }}</div>
      </b-col>
      <b-col class="col-12 col-sm-3 one-column justify-content-center text-center">
        <div class="my-text blue-text">{{ $t('components.dn_footer.periodical_count') }}</div>
        <div class="my-text">{{ $t('components.dn_footer.periodicals') }}</div>
      </b-col>
      <b-col class="col-12 col-sm-3 one-column justify-content-center text-center">
        <div class="my-text blue-text">{{ $t('components.dn_footer.achievement') }} {{ $t('components.dn_footer.thousands') }}</div>
        <div class="my-text">{{ $t('components.dn_footer.researchers') }}</div>
      </b-col>
      <b-col class="col-12 col-sm-3 one-column justify-content-center text-center">
        <div class="my-text">{{ $t('components.dn_footer.integration') }}</div>
        <div class="my-text">{{ $t('components.dn_footer.orcid') }}</div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'DnFooter'
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .footer-container {
    width: 95%;
    margin-bottom: 1rem;
  }

  .my-text {
    font-weight: 600;
  }

  .blue-text {
    color: $main-active-color;
  }

  .one-column {
    @media (max-width: 575.98px) {
      margin-bottom: .5rem;
    }
  }
</style>

<style lang="scss" scoped>
  @import '../assets/stylesheets/custom-contrast';
  .contrast {
    .blue-text {
      color: $main-active-color;
    }
  }
</style>
