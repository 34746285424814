<template>
  <div class="component-container">
    <dn-loader v-if="showLoader" class="with-margins"/>
    <div v-else>
      <div v-if="!scientist" class="profile-description d-flex align-items-center">
        <div class="profile-part">
          <!--        <div class="academic-degree">{{ scTitle }}</div>-->
          <div class="name" style="margin-top: 1.5rem; margin-bottom: 1rem;">
            <router-link :to="{ name: 'employee_path', params: {id: employee.id } }" >
              {{ employee.fullName }}
            </router-link>
          </div>
          <div class="orcid" v-if="employee.orcidId">
            <img src="../../assets/images/orcid.png" class="img-fluid my-image" alt="ORCiD"/>
            <span>
            <router-link :to="{ name: 'orcid_publications_path', params: {id: employee.id } }" >
              {{ employee.orcidId }}
            </router-link>
          </span>
          </div>
        </div>
      </div>
      <b-row v-if="mainStatements.length > 0">
        <b-col class="col-12 col-lg-8">
          <div v-for="(statement, index) in mainStatements" :key="statement.id" class="oneline">
            <div class="dn-header my-header">{{ $t('views.discipline_statements.statement') }} {{ index + 1 }}</div>
            <pcg-btn v-if="mainStatements.length > 1" size="small" variant="additional" class="add-btn" style="margin-bottom: .5rem" v-on:click="removeDiscipline(index)" :disabled="removeStatementSpinner">
              {{ $t('views.discipline_statements.remove_statement') }}
              <spinner v-if="removeStatementSpinner"/>
            </pcg-btn>
            <pcg-btn size="small" variant="additional" class="add-btn" v-on:click="addDiscipline()">
              {{ $t('views.discipline_statements.add_statement') }}
            </pcg-btn>
            <b-row class="no-gutters">
              <pcg-select
                v-if="mainDisciplineOptions.length > 0"
                class="my-discipline col-12"
                :label="$t('components.dn_discipline_statements.discipline')"
                :options="tDictionary(mainDisciplineOptions)"
                v-model="chosenDiscipline[index]"
              />
            </b-row>
            <b-row style="margin-bottom: 2rem" v-if="chosenDiscipline[index]">
              <b-col class="col-12 col-sm-6">
                <pcg-select class="my-validity"
                            :label="$t('components.dn_discipline_statements.validity_from')"
                            :options="tDictionary(mainValidityFrom[index].validityFrom)"
                            v-model="validityFrom[index]"
                />
                <pcg-datetime-picker
                  class="my-datetimepicker"
                  :label="$t('components.dn_discipline_statements.exact_date')"
                  :locale="$i18n.locale"
                  variant="date"
                  v-model="validityFromDate[index]"
                />
              </b-col>
              <b-col class="col-12 col-sm-6">
                <pcg-select class="my-validity"
                            :label="$t('components.dn_discipline_statements.validity_to')"
                            :options="tDictionary(mainValidityTo[index].validityTo)"
                            v-model="validityTo[index]"
                />
                <pcg-datetime-picker
                  v-if="validityTo[index] !== 9999"
                  class="my-datetimepicker"
                  :label="$t('components.dn_discipline_statements.exact_date')"
                  :locale="$i18n.locale"
                  variant="date"
                  v-model="validityToDate[index]"
                />
              </b-col>
            </b-row>
          </div>
          <pcg-btn size="small" v-on:click="createEmployeeStatement(employee_id, mainStatements, true)" :disabled="recalcSpinner">
            {{ $t('views.common.save') }}
            <spinner v-if="recalcSpinner" />
          </pcg-btn>
          <b-modal ref="removeDisciplineStatement"
                   :hide-header="true"
                   footer-class="my-modal-footer"
                   :centered="true"
                   v-bind:ok-title="$t('general.confirm')"
                   ok-variant="primary"
                   :no-close-on-esc="true"
                   @ok="removeStatement(statementId)"
                   v-bind:cancel-title="$t('general.cancel')"
                   cancel-variant="default"
                   content-class="my-modal-content"
          >
            <div class="remove-statement-title">{{ $t('views.statements.list.remove_statement') }}</div>
            <div class="remove-statement-confirmation">{{ $t('views.statements.list.remove_statement_confirmation') }}</div>
          </b-modal>
        </b-col>
        <b-col class="col-12 col-lg-4 previous-statement">
          <dn-previous-statement v-if="previousStatements.length > 0" :previousStatements="previousStatements" :dateAdded="dateAdded" :nStatement="true" @disciplineUpdated="updateStatements"></dn-previous-statement>
        </b-col>
      </b-row>
      <b-row v-else>
        <pcg-btn size="small" variant="additional" class="add-btn" v-on:click="addDiscipline()">
          {{ $t('views.discipline_statements.add_statement') }}
        </pcg-btn>
      </b-row>
    </div>
  </div>
</template>

<script>
import Spinner from '../../components/Spinner'
import setTitle from '../../mixins/set_title'
import waitForJobs from '../../mixins/wait_for_jobs'
import unexpectedError from '../../mixins/unexpected_error'
import ApiEmployeeStatements from '../../api/employee_statements'
import ApiEmployees from '../../api/employees'
import translateDictionary from '../../mixins/translate_dictionary'
import DnLoader from '../../components/DnLoader'
import loaderDelay from '../../mixins/loader_delay'
import DnPreviousStatement from '../../components/DnPreviousStatement'
import { mapGetters } from 'vuex'
export default {
  name: 'NumberNStatements',
  mixins: [setTitle, unexpectedError, waitForJobs, translateDictionary, loaderDelay],
  components: {
    Spinner,
    DnLoader,
    DnPreviousStatement
  },
  data: function () {
    return {
      headerTitle: 'views.number_n_statement.title',
      headerSubtitle: '',
      employee_id: 0,
      employee: {},
      errors: [],
      scientist: false,
      mainStatements: [],
      previousStatements: [],
      dateAdded: null,
      recalcSpinner: false,
      disciplineStatements: [],
      chosenDiscipline: [],
      mainDisciplineOptions: [],
      validityFrom: [],
      validityTo: [],
      mainValidityFrom: [],
      mainValidityTo: [],
      statementId: null,
      statementIndex: null,
      removeStatementSpinner: false,
      validityFromDate: [],
      validityToDate: [],
      copyStatements: []
    }
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.$store.dispatch('header/setHeader', {
      title: this.$t('views.number_n_statement.title')
    })
    this.employee_id = this.$route.params.id
    ApiEmployees.getEmployee(this.employee_id)
      .then(result => {
        this.employee = result.data.employee
        this.headerSubtitle = this.employee.fullName
        this.setTitles()
        this.checkAccess(this.employee_id)
        ApiEmployeeStatements.getDisciplines(this.employee_id)
          .then(result => {
            this.previousStatements = result.data.numberNStatements
            this.disciplineStatements = result.data.statements
            this.copyStatements = result.data.statements
            let i = 0
            let j = 0
            for (i; i < this.disciplineStatements.length; i++) {
              for (j = i + 1; j < this.disciplineStatements.length; j++) {
                if (this.copyStatements[i].disciplineId === this.copyStatements[j].disciplineId && this.copyStatements[i].share === this.copyStatements[j].share && (Date.parse(this.copyStatements[j].validityFromDate) - Date.parse(this.copyStatements[i].validityToDate) === 86400000)) {
                  this.copyStatements[i].validityToDate = this.copyStatements[j].validityToDate
                  this.copyStatements[i].validityTo = this.copyStatements[j].validityTo
                  this.copyStatements.splice(j, 1)
                }
              }
            }
            this.disciplineStatements = this.copyStatements
            this.dateAdded = result.data.dateAdded
            if (this.mainStatements.length === 0) {
              this.mainStatements.push({
                statements: {
                  discipline_participation: '0',
                  discipline_type: null,
                  validity_from: null,
                  validity_from_date: null,
                  validity_to: null,
                  validity_to_date: null,
                  is_leading: false,
                  kind: 2
                }
              })
            }
            this.loadingDone = true
          })
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.$toastr.i(this.$t('views.employee.no_employee'))
        } else {
          this.$toastr.e(error)
        }
      })
    this.checkScientist()
  },
  computed: {
    ...mapGetters('period', ['allYears']),
    scTitle () {
      if (this.$i18n.locale === 'pl') {
        return this.employee.title
      } else {
        return this.employee.titleEn
      }
    },
    years () {
      return this.allYears
    }
  },
  watch: {
    mainStatements: {
      deep: true,
      handler () {
        this.checkScientist()
        this.initializeDisciplinesOptions()
        this.setValidityFromValues()
        this.setValidityToValues()
      }
    },
    disciplineStatements: {
      deep: true,
      handler () {
        this.initializeDisciplinesOptions()
      }
    },
    chosenDiscipline: {
      deep: true,
      handler () {
        if (this.years && this.years.length > 0 && this.years[0] === 2017) {
          this.years.unshift(2016)
        }
        this.initializeDisciplinesOptions()
        if (this.chosenDiscipline && this.chosenDiscipline.length > 0) {
          for (let i = 0; i < this.chosenDiscipline.length; i++) {
            if (!this.validityFrom[i]) {
              this.validityFrom.push(this.mainValidityFrom[i].validityFrom[0].id)
              this.validityTo.push(this.mainValidityTo[i].validityTo[this.mainValidityTo[i].validityTo.length - 1].id)
              const idxFrom = this.copyStatements.findIndex(s => s.id === this.chosenDiscipline[i])
              let varDateFrom = null
              if (idxFrom >= 0) {
                varDateFrom = this.copyStatements[idxFrom].validityFromDate
              }
              this.validityFromDate[i] = varDateFrom
              const idxTo = this.copyStatements.findIndex(s => s.id === this.chosenDiscipline[i])
              let varDateTo = null
              if (idxTo >= 0) {
                varDateTo = this.copyStatements[idxTo].validityToDate
              }
              if (this.copyStatements[idxTo].validityTo !== 9999) {
                this.validityToDate[i] = varDateTo
              }
            } else {
              if (this.validityFrom[i] < this.mainValidityFrom[i].validityFrom[0].id || this.validityFrom[i] > this.mainValidityFrom[i].validityFrom[this.mainValidityFrom[i].validityFrom.length - 1].id) {
                this.validityFrom[i] = this.mainValidityFrom[i].validityFrom[0].id
                const idx = this.copyStatements.findIndex(s => s.id === this.chosenDiscipline[i])
                let varDate = null
                if (idx >= 0) {
                  varDate = this.copyStatements[idx].validityFromDate
                }
                this.validityFromDate[i] = varDate
              }
              if (this.validityTo[i] < this.mainValidityTo[i].validityTo[0].id || this.validityTo[i] > this.mainValidityTo[i].validityTo[this.mainValidityTo[i].validityTo.length - 1].id || this.validityTo[i] > this.years[this.years.length - 1]) {
                this.validityTo[i] = this.mainValidityTo[i].validityTo[this.mainValidityTo[i].validityTo.length - 1].id
                const idx = this.copyStatements.findIndex(s => s.id === this.chosenDiscipline[i])
                let varDate = null
                if (idx >= 0) {
                  varDate = this.copyStatements[idx].validityToDate
                }
                this.validityToDate[i] = varDate
              }
            }
          }
        }
      }
    },
    validityFrom: {
      deep: true,
      handler () {
        this.setValidityFromValues()
        this.setValidityToValues()
        for (let i = 0; i < this.validityFrom.length; i++) {
          if (this.validityFromDate[i]) {
            if (this.validityFromDate[i] instanceof Date) {
              const validityFromYear = this.validityFromDate[i].getFullYear()
              if (Number(validityFromYear) !== Number(this.validityFrom[i])) {
                const dateFrom = new Date()
                dateFrom.setFullYear(Number(this.validityFrom[i]), 0, 1)
                this.validityFromDate[i] = dateFrom
              }
            }
          } else {
            const dateFrom = new Date()
            dateFrom.setFullYear(Number(this.validityFrom[i]), 0, 1)
            this.validityFromDate[i] = dateFrom
          }
        }
      }
    },
    validityTo: {
      deep: true,
      handler () {
        this.setValidityToValues()
        this.setValidityFromValues()
        for (let i = 0; i < this.validityTo.length; i++) {
          if (this.validityTo[i] !== 9999) {
            if (this.validityToDate[i]) {
              if (this.validityToDate[i] instanceof Date) {
                const validityToYear = this.validityToDate[i].getFullYear()
                if (Number(validityToYear) !== Number(this.validityTo[i])) {
                  const dateTo = new Date()
                  dateTo.setFullYear(Number(this.validityTo[i]), 11, 31)
                  this.validityToDate[i] = dateTo
                }
              }
            } else {
              const dateTo = new Date()
              dateTo.setFullYear(Number(this.validityTo[i]), 11, 31)
              this.validityToDate[i] = dateTo
            }
          }
        }
      }
    },
    validityFromDate: {
      deep: true,
      handler () {
        if (this.validityFrom && this.validityFrom.length > 0) {
          for (let i = 0; i < this.validityFrom.length; i++) {
            if (this.validityFromDate[i] instanceof Date) {
              const validityFromYear = this.validityFromDate[i].getFullYear()
              if (this.validityTo[i] === 9999) {
                if ((validityFromYear >= this.mainValidityFrom[i].validityFrom[0].id && validityFromYear <= this.years[this.years.length - 1]) || validityFromYear === this.years[0]) {
                  this.validityFrom[i] = validityFromYear
                } else {
                  const dateFrom = new Date()
                  dateFrom.setFullYear(Number(this.validityFrom[i]), 0, 1)
                  this.validityFromDate[i] = dateFrom
                  this.$toastr.i(this.$t('views.discipline_statements.wrong_year'))
                }
              } else {
                if ((validityFromYear >= this.mainValidityFrom[i].validityFrom[0].id && validityFromYear <= this.validityTo[i]) || validityFromYear === this.years[0]) {
                  this.validityFrom[i] = validityFromYear
                } else {
                  const dateFrom = new Date()
                  dateFrom.setFullYear(Number(this.validityFrom[i]), 0, 1)
                  this.validityFromDate[i] = dateFrom
                  this.$toastr.i(this.$t('views.discipline_statements.wrong_year'))
                }
              }
            }
          }
        }
      }
    },
    validityToDate: {
      deep: true,
      handler () {
        if (this.validityTo && this.validityTo.length > 0) {
          for (let i = 0; i < this.validityTo.length; i++) {
            if (this.validityToDate[i] instanceof Date) {
              const validityToYear = this.validityToDate[i].getFullYear()
              if (this.validityTo[i] === 9999) {
                if (validityToYear !== 9999) {
                  if (validityToYear >= this.validityFrom[i] && validityToYear <= this.years[this.years.length - 1]) {
                    this.validityTo[i] = validityToYear
                  } else {
                    const dateTo = new Date()
                    dateTo.setFullYear(Number(this.validityTo[i]), 11, 31)
                    this.validityToDate[i] = dateTo
                    this.$toastr.i(this.$t('views.discipline_statements.wrong_year'))
                  }
                }
              } else {
                if (validityToYear >= this.validityFrom[i] && validityToYear <= this.mainValidityTo[i].validityTo[this.mainValidityTo[i].validityTo.length - 1].id) {
                  this.validityTo[i] = validityToYear
                } else {
                  const dateTo = new Date()
                  dateTo.setFullYear(Number(this.validityTo[i]), 11, 31)
                  this.validityToDate[i] = dateTo
                  this.$toastr.i(this.$t('views.discipline_statements.wrong_year'))
                }
              }
            }
          }
        }
      }
    }
  },
  methods: {
    initializeDisciplinesOptions () {
      if (this.disciplineStatements && this.disciplineStatements.length > 0) {
        this.mainDisciplineOptions = []
        this.mainValidityFrom = []
        this.mainValidityTo = []
        for (let i = 0; i < this.disciplineStatements.length; i++) {
          if (this.chosenDiscipline.length > 0) {
            for (let k = 0; k < this.chosenDiscipline.length; k++) {
              this.mainValidityFrom.push({ validityFrom: [] })
              this.mainValidityTo.push({ validityTo: [] })
              for (let j = this.disciplineStatements[i].validityFrom; j <= this.disciplineStatements[i].validityTo; j++) {
                if (this.chosenDiscipline[k] === this.disciplineStatements[i].id) {
                  if (j > this.years[this.years.length - 1]) {
                    const active = { text: 'aktywne', text_en: 'active', id: 9999 }
                    this.mainValidityTo[k].validityTo.push(active)
                    break
                  }

                  const validityTo = { text: j, text_en: j, id: j }
                  this.mainValidityTo[k].validityTo.push(validityTo)
                }
              }
              for (let j = this.years[i]; j <= this.disciplineStatements[i].validityTo; j++) {
                if (this.chosenDiscipline[k] === this.disciplineStatements[i].id) {
                  const validityFrom = { text: j, text_en: j, id: j }
                  this.mainValidityFrom[k].validityFrom.push(validityFrom)
                }
              }
            }
          }
          if (this.disciplineStatements[i].validityTo === 9999) {
            if (this.disciplineStatements[i].validityFromDate) {
              const discipline = {
                text: this.disciplineStatements[i].name + ' (' + this.$d(new Date(this.disciplineStatements[i].validityFromDate), 'short') + '-aktywne)',
                text_en: this.disciplineStatements[i].nameEn + ' (' + this.$d(new Date(this.disciplineStatements[i].validityFromDate), 'short') + '-active)',
                id: this.disciplineStatements[i].id
              }
              this.mainDisciplineOptions.push(discipline)
            } else {
              const discipline = {
                text: this.disciplineStatements[i].name + ' (' + this.disciplineStatements[i].validityFrom + '-aktywne)',
                text_en: this.disciplineStatements[i].nameEn + ' (' + this.disciplineStatements[i].validityFrom + '-active)',
                id: this.disciplineStatements[i].id
              }
              this.mainDisciplineOptions.push(discipline)
            }
          } else {
            if (this.disciplineStatements[i].validityFromDate) {
              const discipline = {
                text: this.disciplineStatements[i].name + ' (' + this.$d(new Date(this.disciplineStatements[i].validityFromDate), 'short') + '-' + this.$d(new Date(this.disciplineStatements[i].validityToDate), 'short') + ')',
                text_en: this.disciplineStatements[i].nameEn + ' (' + this.$d(new Date(this.disciplineStatements[i].validityFromDate), 'short') + '-' + this.$d(new Date(this.disciplineStatements[i].validityToDate), 'short') + ')',
                id: this.disciplineStatements[i].id
              }
              this.mainDisciplineOptions.push(discipline)
            } else {
              const discipline = {
                text: this.disciplineStatements[i].name + ' (' + this.disciplineStatements[i].validityFrom + '-' + this.disciplineStatements[i].validityTo + ')',
                text_en: this.disciplineStatements[i].nameEn + ' (' + this.disciplineStatements[i].validityFrom + '-' + this.disciplineStatements[i].validityTo + ')',
                id: this.disciplineStatements[i].id
              }
              this.mainDisciplineOptions.push(discipline)
            }
          }
        }
      }
    },
    addDiscipline () {
      this.mainStatements.push({
        statements: {
          discipline_participation: '0',
          discipline_type: null,
          validity_from: null,
          validity_from_date: null,
          validity_to: null,
          validity_to_date: null,
          is_leading: false,
          kind: 2
        }
      })
    },
    checkScientist () {
      const userRole = this.$store.getters['auth/currentRole']
      const employeeId = this.$store.getters['auth/employeeId']
      this.scientist = userRole === 'naukowiec'
      if (this.scientist && Number(employeeId) !== Number(this.employee_id)) {
        this.$router.push({ name: 'scientist_dashboard_path' }).catch((err) => {
          throw new Error(this.$t('general.error_handler') + ': ' + err + '.')
        })
      }
    },
    removeDiscipline (index) {
      if (this.mainStatements.length > 0) {
        if (this.mainStatements[index].statements.discipline_type) {
          this.statementId = this.mainStatements[index].statements.id
          this.statementIndex = index
          this.$refs.removeDisciplineStatement.show()
        } else {
          this.mainStatements.splice(index, 1)
          this.chosenDiscipline.splice(index, 1)
          this.validityFrom.splice(index, 1)
          this.validityTo.splice(index, 1)
          this.validityFromDate.splice(index, 1)
          this.validityToDate.splice(index, 1)
        }
      }
    },
    createEmployeeStatement (employeeId, form, userClicked = false) {
      for (let i = 0; i < form.length; i++) {
        form[i].statements.validity_from = this.validityFrom[i]
        form[i].statements.validity_to = this.validityTo[i]
        form[i].statements.discipline_type = this.chosenDiscipline[i]
        form[i].statements.validity_from_date = this.validityFromDate[i]
        form[i].statements.validity_to_date = this.validityToDate[i]
      }
      ApiEmployeeStatements.createEmployeeStatement(employeeId, form, userClicked)
        .then(result => {
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.recalcSpinner = true
            this.waitForJob(jobId, this.disciplineUpdated, this.disciplineError)
          }
        })
        .catch(error => {
          console.log(error.response.data)
          this.errors = error.response.data
          this.$toastr.e(this.$t('views.discipline_statements.wrong_number_n') + '\n' + this.errors.toString())
          this.recalcSpinner = false
        })
    },
    disciplineError () {
      this.recalcSpinner = false
      this.unexpectedError()
    },
    disciplineUpdated () {
      this.recalcSpinner = false
      this.$toastr.s(this.$t('views.discipline_statements.correct_n_statement'))
      const userRole = this.$store.getters['auth/currentRole']
      this.scientist = userRole === 'naukowiec'
      if (this.scientist) {
        this.$router.push({ name: 'scientist_dashboard_path' }).catch((err) => {
          throw new Error(this.$t('general.error_handler') + ': ' + err + '.')
        })
      } else {
        const id = this.employee_id.toString()
        this.$router.push({ name: 'employee_path', params: { id } }).catch((err) => {
          throw new Error(this.$t('general.error_handler') + ': ' + err + '.')
        })
      }
    },
    checkAccess (id) {
      if (this.employee.authorType === 3) {
        const userRole = this.$store.getters['auth/currentRole']
        this.scientist = userRole === 'naukowiec'
        if (this.scientist) {
          this.$router.push({ name: 'scientist_dashboard_path' }).catch((err) => {
            throw new Error(this.$t('general.error_handler') + ': ' + err + '.')
          })
        } else {
          this.$router.push({ name: 'employee_path', params: { id } }).catch((err) => {
            throw new Error(this.$t('general.error_handler') + ': ' + err + '.')
          })
        }
      }
    },
    setValidityFromValues () {
      if (this.chosenDiscipline.length > 0) {
        for (let i = 0; i < this.chosenDiscipline.length; i++) {
          if (this.validityTo[i]) {
            const startingYear = this.mainValidityFrom[i].validityFrom[0].id
            this.mainValidityFrom[i].validityFrom = []
            for (let j = startingYear; j <= this.validityTo[i]; j++) {
              if (j > this.years[this.years.length - 1]) {
                break
              }
              const year = { text: j, text_en: j, id: j }
              this.mainValidityFrom[i].validityFrom.push(year)
            }
          }
        }
      }
    },
    setValidityToValues () {
      if (this.chosenDiscipline.length > 0) {
        for (let i = 0; i < this.chosenDiscipline.length; i++) {
          if (this.validityFrom[i]) {
            let endingYear = this.mainValidityTo[i].validityTo[this.mainValidityTo[i].validityTo.length - 1].id
            this.mainValidityTo[i].validityTo = []
            if (endingYear > this.years[this.years.length - 1]) {
              endingYear = this.years[this.years.length - 1]
              const active = { text: 'aktywne', text_en: 'active', id: 9999 }
              this.mainValidityTo[i].validityTo.unshift(active)
            }
            for (let j = endingYear; j >= this.validityFrom[i]; j--) {
              const year = { text: j, text_en: j, id: j }
              this.mainValidityTo[i].validityTo.unshift(year)
            }
          }
        }
      }
    },
    removeStatement (id) {
      ApiEmployeeStatements.removeStatement(id)
        .then(result => {
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.removeStatementSpinner = true
            this.waitForJob(jobId, this.statementsUpdated, this.statementError)
          }
        })
    },
    statementsUpdated () {
      this.removeStatementSpinner = false
      this.mainStatements.splice(this.statementIndex, 1)
      this.chosenDiscipline.splice(this.statementIndex, 1)
      this.validityFrom.splice(this.statementIndex, 1)
      this.validityTo.splice(this.statementIndex, 1)
      this.validityFromDate.splice(this.statementIndex, 1)
      this.validityToDate.splice(this.statementIndex, 1)
    },
    statementError () {
      this.removeStatementSpinner = false
      this.unexpectedError()
    },
    updateStatements () {
      ApiEmployeeStatements.getDisciplines(this.employee_id)
        .then(result => {
          this.previousStatements = result.data.numberNStatements
          this.dateAdded = result.data.dateAdded
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/stylesheets/vars';
  .component-container {
    padding-top: 20px;
  }

  .oneline {
    margin-bottom: 2rem;
  }

  .previous-statement {
    @media (max-width: 991.98px) {
      margin-top: 1.5rem;
    }
  }

  .my-remove {
    position: relative;
    left: 1rem;
    bottom: .3rem;
    font-weight: 400;
    font-size: $font-size-m;
    color: $pcg-gray;
    cursor: pointer;
  }

  .extender {
    display: inline-block;
    width: 13px;
  }
  /deep/ .spinner {
    margin-left: 1px;
  }

  .profile-description {
    margin-bottom: 1.5rem;
  }

  .profile-part {
    display: inline-block;
    margin-left: .5rem;
  }

  .academic-degree {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-m;
  }

  .name {
    color: $main-color;
    font-weight: 600;
    font-size: $font-size-l;
    margin-bottom: .4rem;
    a {
      color: unset;
    }
  }

  .orcid {
    color: $main-color;
    font-weight: 400;
    font-size: $font-size-m;
    span {
      cursor: pointer;
    }
    a {
      color: unset;
    }
  }

  .my-image {
    height: 24px;
    display: inline-block;
    margin-right: .5rem;
    position: relative;
    bottom: .15rem;
  }

  .add-btn {
    margin-left: 1rem;
    position: relative;
    bottom: .3rem;
  }

  /deep/ .pcg-button {
    margin-bottom: 0 !important;
    margin-top: 0 !important;
  }

  .my-header {
    margin-bottom: .3rem;
    display: inline-block;
  }

  .my-validity {
    position: relative;
    /deep/ .pcg-input-label {
      margin-bottom: 0.25rem;
      color: $pcg-gray;
    }
  }

  .my-discipline {
    position: relative;
    /deep/ .pcg-input-label {
      margin-bottom: 0.25rem;
      color: $pcg-gray;
    }
  }

  .remove-statement-title {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .remove-statement-confirmation {
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1rem;
  }

  .delete-spinner {
    color: $pcg-red;
  }

  .my-datetimepicker {
    /deep/ .pcg-datetimepicker-input {
      font-weight: 400;
      color: $pcg-dark-gray;
      height: 30px;
    }
    /deep/ .pcg-datetimepicker-btn {
      height: auto;
    }
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .my-remove {
      color: $pcg-gray;
    }
    .profile-part {
      display: inline-block;
      margin-left: .5rem;
    }
    .academic-degree {
      color: $pcg-gray;
    }
    .name {
      color: $main-color;
    }
    .orcid {
      color: $main-color;
    }
    .my-validity {
      /deep/ .pcg-input-label {
        color: $pcg-gray;
      }
    }
    .my-discipline {
      /deep/ .pcg-input-label {
        color: $pcg-gray;
      }
    }
    .remove-statement-title {
      color: $pcg-gray;
    }
    .remove-statement-confirmation {
      color: $pcg-gray;
    }
  }
</style>
