<template>
  <div class="pcg-footer-container">
    <router-link v-if="showAccessibleDeclaration" :to="{ name: 'accessibility_declaration_path' }" class="col-12 col-lg-6 text-center text-lg-left pl-lg-0 accessibility-declaration">
      {{ $t('components.dn_layout_footer.accessibility_declaration') }}
    </router-link>
    <span v-else class="col-12 col-lg-6 text-center text-lg-left pl-lg-0"></span>
    <span class="pcg-footer-text col-12 col-lg-6 text-center text-lg-right pr-lg-0">Copyright © PCG Academia</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LayoutFooter',
  props: {
    upperText: String,
    bottomText: String,
    logo: String,
    negativeLogo: String,
    showAccessibleDeclaration: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('auth', ['signedIn', 'currentRole', 'employeeId', 'expire'])
  }
}
</script>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars";

  .configurable-text-container {
    line-height: 1;
    text-align: center;
    span {
      width: 100%;
      font-size: $font-size-s;
    }
  }
  .pcg-footer-container {
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    z-index: 1;
    background-color: transparent;
    height: fit-content;
  }
  .logo-negatyw {
    display: none;
  }

  .accessibility-declaration {
    color: $main-color;
    font-weight: bold;
  }

  @media(max-width: $screen-sm-max) {
    .pcg-footer-container {
      flex-direction: column;
    }
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";

  .contrast{
    .logo-negatyw {
      display: inline-block;
    }
    .logo {
      display: none;
    }
    .accessibility-declaration {
      color: $main-color;
      position: relative;
      bottom: .1rem;
    }
  }
</style>
