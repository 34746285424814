<template>
    <div class="orcid-publications">
        <dn-loader v-if="showLoader" class="with-margins"/>
        <div v-else>
            <template v-if="orcidId">
                <pcg-box style="margin-top: 1rem">
                    <dn-orcid-integration :orcid-information="orcidInformation"></dn-orcid-integration>
                </pcg-box>
                <pcg-btn class="my-btn" size="small" @click="importPublications" :disabled="importSpinner">
                    {{ $t('views.orcid_integration.import_publications') }}
                    <spinner v-if="importSpinner" />
                </pcg-btn>
                <pcg-btn class="my-btn" size="small" @click="editOrcid">{{ $t('components.dn_orcid.edit_orcid') }}</pcg-btn>
                <b-modal ref="editOrcid"
                         :hide-header="true"
                         footer-class="my-modal-footer"
                         :centered="true"
                         v-bind:ok-title="$t('general.confirm')"
                         ok-variant="primary"
                         :no-close-on-esc="true"
                         @ok="updateOrcid(employeeId)"
                         v-bind:cancel-title="$t('general.cancel')"
                         cancel-variant="default"
                         content-class="my-modal-content"
                >
                    <div class="change-orcid-title">{{ $t('components.dn_orcid.edit_orcid') }}</div>
                    <div v-if="orcidId && orcidId.length > 0" class="change-orcid-confirmation">{{ $t('components.dn_orcid.change_orcid_confirmation') }}</div>
                    <pcg-text-input v-model="newOrcidId" :show-label="false" />
                </b-modal>
                <div v-if="newPublications && newPublications.length > 0" class="publication-elements mt-5">
                    <div ref="newPublications" class="box-title">{{ $t('views.orcid_publications.new_publications') }}</div>
                    <div class="new-publications-success">
                        <span>{{ $t('views.orcid_publications.on_day') }} {{ $d(new Date(orcidInformation.lastSynchronization), 'short') }}</span>
                        <span>{{ $tc('views.orcid_publications.new_publications_count', orcidInformation.newPublicationsCount) }}</span>
                        <span v-if="orcidInformation.relatedPublicationsCount > 0">{{ $t('general.and') }}  {{ $tc('views.orcid_publications.related_publications_count', orcidInformation.relatedPublicationsCount) }}</span>
                    </div>
                    <div class="achievement-elements">
                        <div v-for="publication in newPublications" :key="publication.id" class="achievement mb-3">
                            <publication-element
                              :publication="publication"
                              :employee-id="employeeId"
                              @reload="$router.go(0)"
                              :orcid="true"
                              :managerDisciplines="managerDisciplines"
                              @changePublicationStatus="changePublicationStatus"
                            />
                        </div>
                        <nav :aria-label="$t('general.pagination')">
                          <pcg-pagination
                              class="d-flex justify-content-center my-pagination"
                              style="margin-top: 2rem"
                              v-model="paginationNew.page"
                              :page-count="paginationNew.pages"
                              :total-records="paginationNew.count"
                              :only-event="true"
                              @input="getPublications('new')"
                          ></pcg-pagination>
                        </nav>
                    </div>
                </div>
                <div v-if="newPublications && newPublications.length === 0 && orcidInformation.relatedPublicationsCount > 0" class="publication-elements mt-5">
                    <div class="box-title">{{ $t('views.orcid_publications.new_publications') }}</div>
                    <div class="new-publications-success">
                        <span>{{ $t('views.orcid_publications.on_day') }} {{ $d(new Date(orcidInformation.lastSynchronization), 'short') }}</span>
                        <span>{{ $tc('views.orcid_publications.related_publications_count', orcidInformation.relatedPublicationsCount) }}</span>
                    </div>
                </div>
                <div v-if="newPublications && newPublications.length === 0 && orcidInformation.lastSynchronization !== 0 && orcidInformation.relatedPublicationsCount === 0 && orcidInformation.synchronizationTime < 1" class="publication-elements mt-5">
                    <div class="box-title">{{ $t('views.orcid_publications.new_publications') }}</div>
                    <div class="new-publications">{{ $t('views.orcid_publications.no_new_publications') }}</div>
                </div>
                <div v-show="oldPublications && oldPublications.length > 0" class="previous-publication-elements">
                    <div ref="oldPublications" class="box-title">{{ $t('views.orcid_publications.old_publications') }}</div>
                    <div class="achievement-elements">
                        <div v-for="publication in oldPublications" :key="publication.id" class="achievement mb-3">
                            <publication-element
                              :publication="publication"
                              :employee-id="employeeId"
                              @reload="$router.go(0)"
                              :orcid="true"
                              :managerDisciplines="managerDisciplines"
                              @changePublicationStatus="changePublicationStatus"
                            />
                        </div>
                        <nav :aria-label="$t('general.pagination')">
                          <pcg-pagination
                              class="d-flex justify-content-center my-pagination"
                              style="margin-top: 2rem"
                              v-model="paginationOld.page"
                              :page-count="paginationOld.pages"
                              :total-records="paginationOld.count"
                              :only-event="true"
                              @input="getPublications('old')"
                          ></pcg-pagination>
                        </nav>
                    </div>
                </div>
            </template>
            <template v-else>
                <pcg-text-input
                  label="ORCID ID"
                  v-model="newOrcidId"
                  :show-error="textError !== null"
                  :error-text="textError"
                />
                <pcg-btn @click="updateOrcidId">{{ $t('views.common.save') }}</pcg-btn>
            </template>
        </div>
    </div>
</template>

<script>
import setTitle from '../../mixins/set_title'
import waitForJobs from '../../mixins/wait_for_jobs'
import unexpectedError from '../../mixins/unexpected_error'
import translateDictionary from '../../mixins/translate_dictionary'
import DnOrcidIntegration from '../../components/DnOrcidIntegration'
import ApiOrcid from '../../api/orcid_publications'
import PublicationElement from '../../components/PublicationElement'
import Spinner from '../../components/Spinner'
import loaderDelay from '../../mixins/loader_delay'
import DnLoader from '../../components/DnLoader'
import ApiPublications from '../../api/publications'
import ApiEmployees from '../../api/employees'
import ApiDisciplineManagers from '../../api/discipline_mangers'

export default {
  name: 'OrcidPublications',
  mixins: [loaderDelay, setTitle, translateDictionary, unexpectedError, waitForJobs],
  components: { DnOrcidIntegration, PublicationElement, Spinner, DnLoader },
  data: function () {
    return {
      headerTitle: 'views.orcid_integration.title',
      headerSubtitle: '',
      employeeId: 0,
      importSpinner: false,
      paginationNew: {
        count: 0,
        items: 0,
        last: 0,
        next: null,
        page: 0,
        pages: 0,
        prev: null
      },
      paginationOld: {
        count: 0,
        items: 0,
        last: 0,
        next: null,
        page: 0,
        pages: 0,
        prev: null
      },
      orcidId: null,
      jid: null,
      newOrcidId: null,
      textError: null,
      orcidInformation: {},
      newPublications: [],
      oldPublications: [],
      managerDisciplines: [],
      currentUser: {
        id: '',
        author: '',
        entity: '',
        avatar: '',
        role: ''
      }
    }
  },
  created () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.employeeId = this.$route.params.id.toString()
    ApiOrcid.getPublications(this.employeeId)
      .then(result => {
        this.orcidId = result.data.orcidId
        this.jid = result.data.jid
        this.headerSubtitle = result.data.orcidId
        this.$store.dispatch('header/setHeader', {
          title: this.$t(this.headerTitle),
          subtitle: this.headerSubtitle,
          capitalizeSubtitle: false
        })
        this.orcidInformation = result.data.orcidInformation
        this.newPublications = result.data.newPublications
        this.oldPublications = result.data.oldPublications
        this.paginationNew = result.data.paginationNew
        this.paginationOld = result.data.paginationOld
        this.loadingDone = true
      })
    ApiEmployees.getEmployee(this.employeeId)
      .then(result => {
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.$toastr.i(this.$t('views.employee.no_employee'))
        } else {
          this.$toastr.e(error)
        }
      })
    this.currentUser = {
      id: this.$store.getters['auth/id'],
      author: this.$store.getters['auth/fullName'],
      entity: '',
      avatar: this.$store.getters['auth/avatar'],
      role: this.$store.getters['auth/currentRole']
    }
    ApiDisciplineManagers.getDisciplines()
      .then(result => {
        const fs = result.data
        this.managerDisciplines = fs
      })
      .catch(error => {
        console.log(error)
      })
  },
  beforeMount () {
    if (this.$store.getters['publicationTypes/publicationTypes'].length === 0) {
      this.$store.dispatch('publicationTypes/setPublicationTypes', null)
    }
    if (this.$store.getters['disciplines/disciplines'].length === 0) {
      this.$store.dispatch('disciplines/setDisciplines', null)
    }
  },
  mounted () {
    this.$store.dispatch('header/setHeader', {
      title: this.$t(this.headerTitle),
      subtitle: this.headerSubtitle,
      capitalizeSubtitle: false
    })
  },
  watch: {
    jid (newValue) {
      if (newValue !== null) {
        this.importSpinner = true
        this.waitForJob(this.jid, this.publicationsUpdate, this.publicationError)
      }
    }
  },
  methods: {
    importPublications () {
      ApiOrcid.getOrcid(this.employeeId)
        .then(result => {
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.importSpinner = true
            this.waitForJob(jobId, this.publicationsUpdate, this.publicationError)
          }
        })
    },
    publicationsUpdate () {
      this.importSpinner = false
      this.$router.go(0)
    },
    publicationError () {
      ApiOrcid.clearJid(this.employeeId)
        .then((result) => {
          this.importSpinner = false
          this.unexpectedError()
        })
    },
    updateOrcidId () {
      ApiOrcid.update_orcid_id(this.employeeId, this.newOrcidId)
        .then(result => {
          this.$router.go()
        })
        .catch(result => {
          const messages = []
          for (const mess in result.response.data) {
            messages.push(`${mess}: ${result.response.data[mess]}`)
          }
          this.$toastr.e(messages.join('\n'))
        })
    },
    getPublications (part) {
      let page = 1
      if (part === 'new') {
        page = this.paginationNew.page
        window.scrollTo({ left: 0, top: (this.$refs.newPublications.offsetTop - 50), behavior: 'smooth' })
      } else {
        page = this.paginationOld.page
        window.scrollTo({ left: 0, top: (this.$refs.oldPublications.offsetTop - 50), behavior: 'smooth' })
      }
      ApiOrcid.getPublications(this.employeeId, part, page)
        .then(result => {
          this.orcidId = result.data.orcidId
          this.headerSubtitle = result.data.orcidId
          this.$store.dispatch('header/setHeader', {
            title: this.$t(this.headerTitle),
            subtitle: this.headerSubtitle,
            capitalizeSubtitle: false
          })
          this.orcidInformation = result.data.orcidInformation
          this.newPublications = result.data.newPublications
          this.oldPublications = result.data.oldPublications
          this.pagination = result.data.pagination
        })
    },
    editOrcid () {
      if (this.orcidId && this.orcidId.length > 0) {
        this.newOrcidId = this.orcidId
      }
      this.$refs.editOrcid.show()
    },
    updateOrcid (id) {
      if (this.newOrcidId === this.orcidId) {
        this.$toastr.i(this.$t('views.orcid_integration.same_id'))
      } else {
        ApiOrcid.update_orcid_id(id, this.newOrcidId)
          .then(result => {
            this.$router.go(0)
          })
          .catch(result => {
            const messages = []
            for (const mess in result.response.data) {
              messages.push(`${mess}: ${result.response.data[mess]}`)
            }
            this.$toastr.e(messages.join('\n'))
          })
      }
    },
    changePublicationStatus (publication) {
      ApiPublications.changeStatus(publication.id, publication.status)
        .then(result => {})
      // eslint-disable-next-line handle-callback-err
        .catch(error => {
          this.$toastr.e(error.response.data.message)
        })
    }
  }
}
</script>

<style scoped lang="scss">
    @import '../../assets/stylesheets/vars';

    .box-title {
        color: $main-color;
        font-weight: 600;
        font-size: $font-size-l;
        text-transform: uppercase;
        margin-bottom: 1rem;
        display: inline-block;
        margin-right: 1rem;
    }

    /deep/ .box-container {
        padding-right: 0;
        padding-left: 0;
    }

    .previous-publication-elements {
        margin-top: 3rem;
    }
    .extender {
        display: inline-block;
        width: 13px;
    }

    /deep/ .spinner {
        margin-left: 1px;
    }

    .new-publications {
        font-weight: 400;
        color: $pcg-orange;
        display: inline-block;
    }

    .new-publications-success {
        font-weight: 400;
        color: $pcg-green;
        display: inline-block;
    }

    .achievement-elements {
        margin-top: 1rem;
        border-radius: 15px;
        background-color: $pcg-white;
        padding: 1rem;

        .achievement {
            border-bottom: 1px solid $pcg-lightest-gray-background;
            &:last-of-type {
                border-bottom: none;
            }
        }
    }

    .change-orcid-title {
        font-size: $font-size-m;
        font-weight: 400;
        color: $pcg-gray;
        margin-bottom: 1.5rem;
    }

    .change-orcid-confirmation {
        font-weight: 400;
        color: $pcg-gray;
        margin-bottom: 1rem;
    }

    /deep/ .filter-option-inner {
        position: relative;
        bottom: .3rem;
    }
</style>

<style lang="scss" scoped>
    @import "../../assets/stylesheets/vars-contrast";
    .contrast {
        .box-title {
            color: $main-color;
        }
        .new-publications {
            color: $pcg-orange;
        }
        .new-publications-success {
            color: $pcg-green;
        }
        .achievement-elements {
            background-color: $pcg-white;

            .achievement {
                border-bottom: 1px solid $pcg-lightest-gray;
                &:last-of-type {
                    border-bottom: none;
                }
            }
        }
        .change-orcid-title {
            color: $pcg-gray;
        }
        .change-orcid-confirmation {
            color: $pcg-gray;
        }
        .my-pagination {
            /deep/ .page-item {
                &, &.disabled {
                    .page-link {
                        color: $pcg-the-darkest-gray !important;
                        &:hover {
                            color: $hover-color !important;
                        }
                    }
                }
                &.active {
                    .page-link {
                        background-color: $main-active-color !important;
                        color: $pcg-white !important;
                    }
                }
                &:first-child, &:last-child {
                    .page-link {
                        color: $main-active-color !important;
                    }
                }
            }
        }
    }
</style>
