const LOCAL_STORAGE_CONTRAST = 'dnCon'

const page = {
  namespaced: true,
  state: {
    contrast: false,
    subDomain: null
  },
  getters: {
    contrast: state => {
      if (state.contrast === true || state.contrast === 'true') {
        return true
      } else {
        return false
      }
    },
    subDomain: state => {
      return state.subDomain
    }
  },
  mutations: {
    setContrast (state, contrast) {
      if (contrast !== undefined) {
        localStorage.setItem(LOCAL_STORAGE_CONTRAST, contrast)
        state.contrast = contrast
      } else {
        state.contrast = localStorage.getItem(LOCAL_STORAGE_CONTRAST)
      }
    },
    setSubDomain (state, subDomain) {
      state.subDomain = subDomain
    }
  },
  actions: {
    setContrast ({ commit }, payload) {
      commit('setContrast', payload)
    },
    setSubDomain ({ commit }, payload) {
      commit('setSubDomain', payload)
    }
  }
}

export {
  page
}
