import api from './index'

const resource = 'check_server'

export default {
  checkServer () {
    let url = resource
    url += '/check_server'
    return api.get(url)
  }
}
