import axios from 'axios'
import objectToFormData from 'object-to-formdata'
import { decamelize } from '@ridi/object-case-converter'
import humps from 'humps'
import { store } from '../store/index'
import router from '../router/index'
import { app } from '../index'

const decamelizeThatDontBreaksFile = (object) => {
  if (object && !(object instanceof File)) {
    if (object instanceof Array) {
      return object.map(item => decamelizeThatDontBreaksFile(item))
    }
    if (typeof object === 'object') {
      return Object.keys(object).reduce((acc, next) => ({
        ...acc,
        [decamelize(next)]: decamelizeThatDontBreaksFile(object[next])
      }), {})
    }
  }
  return object
}

const baseURL = '/api/v1/'
const headers = {
  'Content-Type': 'application/json'
}
const transformResponse = [
  ...axios.defaults.transformResponse,
  (data, headers) => {
    // if (data && headers['Content-Type'].includes('application/json')) {
    return humps.camelizeKeys(data)
    // }
  }
]
const transformRequest = [
  (data, headers) => {
    if (data && headers['Content-Type'].includes('application/json')) {
      return decamelize(data, { recursive: true })
    } else if (data && headers['Content-Type'].includes('multipart/form-data')) {
      return objectToFormData(decamelize(data, { recursive: true }))
    } else {
      return data
    }
  },
  ...axios.defaults.transformRequest
]

const api = axios.create({
  baseURL,
  headers,
  transformResponse,
  transformRequest
})

api.interceptors.request.use(config => {
  // if (store.getters['auth/signedIn']) {
  //   config.headers.common.Authorization = `Bearer ${store.getters['auth/token']}`
  // }
  if (app) { app.$Progress.start() }
  return config
}, function (error) {
  return Promise.reject(error)
})

api.interceptors.response.use(function (response) {
  app.$Progress.finish()
  return response
}, function (error) {
  app.$Progress.fail()
  if (error.response && error.response.status && error.response.status === 401) {
    store.dispatch('auth/signOut')
      .then(response => {
        // eslint-disable-next-line eqeqeq
        if (process.env.LOCAL_LOGIN == '1') {
          window.location = '/login'
        } else {
          // const idToken = response.data.idToken
          if (!store.getters['auth/expire']) {
            window.location = `${process.env.DN_OP_END_SESSION_ENDPOINT}?post_logout_redirect_uri=${process.env.SERWER_ADDRESS_WITH_PROTOCOL}`
          } else {
            window.location = '/'
          }
        }
      })
      .catch(error => {
        console.log(error.message)
        this.$toastr.e(error.message)
      })
    // router.push({ name: 'login_path' })
    // return false
    error.message = 'Brak autoryzacji'
    return Promise.reject(error)
  } else if (error.response && error.response.status && error.response.status === 403) {
    app.$toastr.e(app.$t('general.forbidden'))
    // return new Promise(() => {})
    // return false
    error.message = 'Brak uprawnień'
    return Promise.reject(error)
  } else if (error.response && error.response.status && error.response.status === 422 &&
    error.response.data && error.response.data.error &&
    error.response.data.error === 'Missing role') {
    router.push({ name: 'no_rights_path' })
    return false
  }
  return Promise.reject(error)
})

export default api
