export default {
  data () {
    return {
      pagination: {
        currentPage: this.$route.query.page ? parseInt(this.$route.query.page) : 1,
        pages: 0,
        totalRecords: 0
      }
    }
  },
  methods: {
    setPagination (response) {
      const pagination = response.data.meta.pagination
      this.pagination.pages = pagination.pages
      this.pagination.totalRecords = pagination.count
    },
    resetPaginationPage () {
      this.pagination.currentPage = 1
      if ((this.$route.query.page && this.$route.query.page.toString()) !== this.pagination.currentPage.toString()) {
        this.$router.push({
          query: {
            ...this.$route.query,
            page: this.pagination.currentPage
          }
        })
      }
    }
  }
}
