import api from './index'

const resource = 'faculties'

export default {
  getFaculties () {
    const url = resource
    return api.get(url)
  },
  createFaculty (faculty) {
    const url = resource
    const body = {
      faculty: faculty
    }
    return api.post(url, body)
  },
  moveLeft (faculty) {
    let url = resource
    url += '/' + faculty.id + '/move_left'
    return api.put(url)
  },
  moveRightOf (faculty, leftFaculty) {
    let url = resource
    url += '/' + faculty.id + '/move_right_of'
    const body = {
      left: leftFaculty.id
    }
    return api.put(url, body)
  },
  moveRight (faculty) {
    let url = resource
    url += '/' + faculty.id + '/move_right'
    return api.put(url)
  },
  updateFaculty (faculty) {
    let url = resource
    url += '/' + faculty.id
    const body = {
      faculty: faculty
    }
    return api.put(url, body)
  },
  dropFaculty (faculty) {
    let url = resource
    url += '/' + faculty.id
    return api.delete(url)
  }
}
