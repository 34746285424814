<template>
  <b-row class="position-relative">
    <b-col cols="6">
      <pcg-select
          v-if="dictionary.dictionaryType !== 'global' && academies.length > 1"
          :label="$t('views.dictionary_words.academy')"
          :options="academiesOptions"
          v-model="academyId"
          @input="changeAcademy"
      />
    </b-col>
    <div class="new-word-btn-container">
      <pcg-btn size="small" @click="goToNewWordForm">
        + {{ $t('views.dictionary_words.index.add') }}
      </pcg-btn>
    </div>
    <b-tabs v-model="tab" class="col-12">
      <b-tab :title="$t('views.dictionary_words.index.all')">
        <dictionary-words-index :status="null" :dictionary="dictionary" :academy-id="this.academyId" v-on:updated="updated" ref="tab-all" />
      </b-tab>
      <b-tab :title="$t('views.dictionary_words.index.recent')">
        <dictionary-words-recent :status="'recent'" :dictionary="dictionary" :academy-id="this.academyId" v-on:updated="updated"  ref="tab-recent"/>
      </b-tab>
      <b-tab :title="$t('views.dictionary_words.index.accepted')">
        <dictionary-words-index :status="'accepted'" :dictionary="dictionary" :academy-id="this.academyId"  v-on:updated="updated"  ref="tab-accepted"/>
      </b-tab>
      <b-tab :title="$t('views.dictionary_words.index.rejected')">
        <dictionary-words-index :status="'rejected'" :dictionary="dictionary" :academy-id="this.academyId"  v-on:updated="updated"  ref="tab-rejected"/>
      </b-tab>
    </b-tabs>
  </b-row>
</template>

<script>
import hasRight from '../../mixins/has_right'
import ApiAcademies from '../../api/academies'
import ApiDictionary from '../../api/dictionaries'
import DictionaryWordsIndex from './DictionaryWordsIndex'
import DictionaryWordsRecent from './DictionaryWordsRecent'
import setTitle from '../../mixins/set_title'
import { decamelize } from '@ridi/object-case-converter'

export default {
  name: 'DictionaryWordsTabs',
  mixins: [hasRight, setTitle],
  components: { DictionaryWordsIndex, DictionaryWordsRecent },
  data () {
    return {
      headerTitle: 'views.dictionary_words.index.title',
      headerSubtitle: '',
      academies: [],
      academyId: null,
      dictionary: {},
      dictionaryId: this.$route.params.dictionaryId,
      status: null,
      tab: 0
    }
  },
  computed: {
    academiesOptions () {
      const academies = []
      this.academies.forEach(academy => {
        academies.push({
          text: academy.name,
          value: academy.id
        })
      })
      return academies
    }
  },
  watch: {
    '$i18n.locale': function (oldLocale, newLocale) {
      if (oldLocale !== newLocale) {
        this.$store.dispatch('header/setHeader', {
          title: this.$t(this.headerTitle),
          subtitle: this.$t(`views.dictionaries.index.${decamelize(this.dictionary.key)}`),
          capitalizeSubtitle: false
        })
      }
    }
  },
  methods: {
    changeAcademy () {
      const query = Object.assign({}, this.$route.query)
      if (this.academyId) {
        query.academy_id = this.academyId
      } else {
        delete query.academy_id
      }
      const name = 'dictionary_words_path'
      const params = { dictionaryId: this.dictionaryId }
      this.$router.push({ name: name, params: params, query: query })
    },
    goToNewWordForm () {
      if (this.academyId) {
        this.$router.push({ name: 'dictionary_words_new_path', params: { dictionaryId: this.dictionaryId }, query: { academy_id: this.academyId } })
      } else {
        this.$router.push({ name: 'dictionary_words_new_path', params: { dictionaryId: this.dictionaryId } })
      }
    },
    updated () {
      Object.entries(this.$refs).forEach(c => {
        c[1].getDictionaryWords()
      })
    }
  },
  created () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.academyId = this.$route.query.academy_id
  },
  mounted () {
    if (!this.academyId && this.hasRight('edit_own_dictionaries')) {
      this.academyId = parseInt(this.$store.getters['auth/academyId'])
    }
    // this.tab = this.tabs.indexOf(this.$route.name)
    ApiAcademies.getAcademies()
      .then(result => {
        this.academies = result.data.datas
        ApiDictionary.getDictionary(this.dictionaryId)
          .then(result => {
            this.dictionary = result.data.data.attributes
            this.headerSubtitle = this.$t(`views.dictionaries.index.${decamelize(this.dictionary.key)}`)
            this.setTitles()
            if (this.dictionary.dictionaryType === 'global') {
              if (this.hasRight('edit_dictionaries')) {
                this.academyId = null
              }
            } else {
              const query = this.$route.query
              if (Object.prototype.hasOwnProperty.call(query, 'academy_id')) {
                this.academyId = parseInt(query.academy_id)
              }
              if (this.academyId) {
                if (this.academies.length > 1) {
                  query.academy_id = this.academyId
                  // eslint-disable-next-line eqeqeq
                } else if (this.academyId != this.academies[0].id) {
                  this.$toastr.e(this.$t('general.no_rights'))
                }
              } else {
                this.academyId = this.academies[0].id
              }
            }
          })
          .catch(err => {
            console.log(err)
          })
      })
      .catch(err => {
        console.log(err)
      })
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .new-word-btn-container {
    z-index: 10;
    right: 0;
    position: absolute;
    width: fit-content;
    background-color: $pcg-the-lightest-gray;
    padding-left: $pcg-m-md;
  }

  @media(max-width: $screen-lg-min) {
    .new-word-btn-container {
      background-color: transparent;
      position: relative;
      margin-left: auto;
    }
  }
</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .new-word-btn-container {
      background-color: $pcg-the-lightest-gray;
    }
  }
</style>
