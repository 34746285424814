import Vue from 'vue'
import Vuex from 'vuex'

// import { HeaderStoreModule, LocaleStoreModule, MenuStoreModule, PageCoverStoreModule } from 'pcg-core-vue'
import { locale } from 'pcg-core-vue/src/store/modules/locale'
import { menu } from 'pcg-core-vue/src/store/modules/menu'
import { header } from 'pcg-core-vue/src/store/modules/header'
import { pageCover } from 'pcg-core-vue/src/store/modules/pageCover'

import { auth } from './modules/auth'
import { user } from './modules/user'
import { page } from './modules/page'
import { sidebarMenu } from './modules/sidebarMenu'
import { disciplines } from './modules/disciplines'
import { publicationTypes } from './modules/publication_types'
import { scienceFields } from './modules/science_fields'
import { period } from './modules/periods'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    disciplines,
    user,
    page,
    publicationTypes,
    scienceFields,
    sidebarMenu,
    // MenuStoreModule,
    // HeaderStoreModule,
    // LocaleStoreModule,
    // PageCoverStoreModule
    locale,
    menu,
    header,
    pageCover,
    period
  }
})

export {
  store
}
