<template>
  <div class="history-list">
    <div class="ml-3" style="align-items: baseline; margin-bottom: 3rem;">
      <div class="my-label mr-4">
        <label>{{ $t('views.histories.dates') }}</label>
      </div>
        <pcg-datetime-picker
            class="my-datetimepicker mr-5"
            :locale="$i18n.locale"
            :show-label="false"
            variant="date"
            v-model="filters.datesFrom"
        />
        <pcg-datetime-picker
            class="my-datetimepicker mr-5"
            :locale="$i18n.locale"
            :show-label="false"
            variant="date"
            v-model="filters.datesTo"
        />
        <pcg-btn @click="getFiltered" size="small" :disabled="achievementsSpinner">{{ $t('views.histories.perform') }}</pcg-btn>
    </div>
    <dn-loader v-if="achievementsSpinner" class="my-loader"></dn-loader>
    <pcg-box v-if="!achievementsSpinner && employees.length === 0" class="mt-3">
      <div class="d-flex justify-content-center no-records">{{ $t('views.histories.no_records') }}</div>
    </pcg-box>
    <b-table v-if="!achievementsSpinner && employees.length > 0"
             :items="employees"
             :fields="fields"
             sort-icon-left
             class="history-table"
             borderless hover
             thead-class="sthead"
             tbody-tr-class="strow"
    >
    </b-table>
  </div>
</template>

<script>
import setTitle from '../mixins/set_title'
import makeKey from '../mixins/make_key'
import loaderDelay from '../mixins/loader_delay'
import DnLoader from '../components/DnLoader'
import ApiHistories from '../api/histories'
import waitForJobs from '../mixins/wait_for_jobs'

export default {
  name: 'Histories',
  mixins: [makeKey, setTitle, loaderDelay, waitForJobs],
  components: {
    DnLoader
  },
  data: () => {
    return {
      achievements: [],
      achievementsSpinner: false,
      filters: {
        datesFrom: new Date(),
        datesTo: new Date()
      },
      headerTitle: 'views.histories.title',
      headerSubtitle: 'views.histories.subtitle',
      jobId: null,
      employees: []
    }
  },
  created () {
  },
  beforeMount () {
  },
  mounted () {
    this.$store.dispatch('header/setHeader', {
      title: this.$t(this.headerTitle),
      subtitle: this.$t(this.headerSubtitle),
      capitalizeSubtitle: false
    })
  },
  computed: {
    fields: function () {
      return [
        {
          key: 'user',
          label: this.$t('views.histories.user'),
          sortable: true
        },
        {
          key: 'createdPublications',
          label: this.$t('views.histories.created_publications'),
          sortable: true
        },
        {
          key: 'changedPublications',
          label: this.$t('views.histories.changed_publications'),
          sortable: true
        },
        {
          key: 'changes',
          label: this.$t('views.histories.changes'),
          sortable: true
        }
      ]
    }
  },
  methods: {
    getFiltered () {
      ApiHistories.getHistories(this.filters.datesFrom, this.filters.datesTo)
        .then((result) => {
          this.jobId = result.data.bwId
          if (this.jobId !== undefined) {
            this.achievementsSpinner = true
            this.waitForBigWorker(this.jobId, this.getReport, this.reportError)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getReport () {
      ApiHistories.getReport(this.jobId)
        .then((result) => {
          this.employees = result.data
        })
        .catch((error) => {
          console.log(error)
        })
      this.achievementsSpinner = false
    },
    reportError () {
      this.achievementsSpinner = false
      this.$toastr.e(this.$t('views.histories.report_error'))
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../assets/stylesheets/vars';

  .history-list {
    margin-top: 2rem;
    /deep/ table.b-table > thead > tr > [aria-sort].b-table-sort-icon-left {
      background-position: left .3em center;
      padding-left: 1.3em;
    }
  }

  .my-datetimepicker {
    /deep/ .pcg-datetimepicker-input {
      font-weight: 400;
      color: $pcg-dark-gray;
      height: 30px;
    }
    display: inline-block;
    max-width: 200px;
    /deep/ .pcg-datetimepicker-btn {
      position: relative;
      bottom: .1rem;
      height: auto;
    }
  }

  .my-label {
    display: inline-block;
    color: $pcg-dark-gray;
    font-weight: 400;
  }

  .no-records {
    color: $pcg-dark-gray;
    font-weight: 400;
    font-size: $font-size-m;
  }

  .history-table {
    margin-top: 1rem;
    background-color: $pcg-white;
  }

  /deep/ table.history-table {
    background-color: $pcg-white;
    border-radius: 15px;

    thead {
      background-color: $main-color;
      color: $pcg-white;

      th {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        vertical-align: middle;

        &.td-center {
          text-align: center;
        }
      }

      th:first-child {
        padding-left: 15px;
        border-top-left-radius: 15px;
      }

      th:last-child {
        padding-right: 15px;
        border-top-right-radius: 15px;
      }
    }

    tbody {
      padding-left: 15px;
      padding-right: 15px;
      background-color: $pcg-white;

      td {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        vertical-align: top;
        color: $pcg-dark-gray;

        &.td-left {
          text-align: left;
          color: $main-color;
          font-weight: 400;
        }
      }

      td:first-child {
        padding-left: 15px;
      }

      td:last-child {
        padding-right: 15px;
      }

      tr {
        height: 42px;
      }

      tr:last-child {
        td {
          padding-bottom: 15px;
        }

        td:first-child {
          padding-left: 15px;
          border-bottom-left-radius: 15px;
        }

        td:last-child {
          padding-right: 15px;
          border-bottom-right-radius: 15px;
        }
      }
    }
  }

  /deep/ .table.b-table {
    thead>tr>th[aria-sort=none] {
      background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='%239D9D9D' opacity='.5' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='%239D9D9D' opacity='.5' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e")!important;
    }
    thead>tr>th[aria-sort=ascending] {
      background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='%239D9D9D' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='%239D9D9D' opacity='.5' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e")!important;
    }
    thead>tr>[aria-sort=descending] {
      background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='101' height='101' view-box='0 0 101 101' preserveAspectRatio='none'%3e%3cpath fill='%239D9D9D' opacity='.5' d='M51 1l25 23 24 22H1l25-22z'/%3e%3cpath fill='%239D9D9D' d='M51 101l25-23 24-22H1l25 22z'/%3e%3c/svg%3e")!important;
    }
  }

</style>

<style lang="scss" scoped>
@import "../assets/stylesheets/vars-contrast";
  .contrast {
    .my-datetimepicker {
      /deep/ .pcg-datetimepicker-input {
        color: $pcg-dark-gray;
      }
    }
    .my-label {
      color: $pcg-dark-gray;
    }
    .no-records {
      color: $pcg-dark-gray;
    }
  }
</style>

<style lang="scss">
@import "../assets/stylesheets/vars-contrast";
.contrast {
  .history-table {
    background-color: $pcg-white !important;

    thead {
      background-color: $main-color !important;
      color: $pcg-white !important;
    }

    tbody {
      background-color: $pcg-white !important;

      td {
        color: $pcg-dark-gray !important;

        &.td-left {
          color: $main-color !important;
        }
      }
    }
  }
}
</style>
