<template>
<div class="institutions-list">
  <b-row style="margin-bottom: 1.5rem">
    <b-col>
      <label :for="$t('views.f_source.name')" class="sr-only" style="position: relative; top: .2rem;">{{ $t('views.f_source.name') }}</label>
      <pcg-text-input
          class="no-margin my-input"
          :id="$t('views.f_source.name')"
          :show-label="false"
          :placeholder="$t('views.f_source.name')"
          v-model="filters.name"
          @input="getFilteredSource"
      />
    </b-col>
  </b-row>
  <b-row>
    <b-col cols="10" style="margin-bottom: 2rem;">
      <span class="per_page">{{ $t('views.achievement.list.per_page') }}</span>
      <pcg-select
          class="my-select-short"
          style="display: inline-block"
          :options="perPageOptions"
          :default-option="{ text: perPage.toString(), value: perPage }"
          v-model="perPage"
          @input="getFilteredSource"
      />
    </b-col>
    <b-col class="text-right">
      <pcg-btn v-if="hasRight('edit_own_dictionaries') && tabModel === 1"
               variant="additional" size="small" @click="institutionAdd">
        {{$t('general.add')}}
      </pcg-btn>
    </b-col>
  </b-row>
  <b-tabs class="mt-3" v-model="tabModel">
    <b-tab :title="$t('views.conferences.list.ministerial_list')">
      <b-table v-if="financialSources.length > 0"
               class="institution-table"
               borderless hover
               thead-class="sthead"
               tbody-tr-class="strow"
               :fields="fields"
               :items="financialSources"
               :primary-key="financialSources.id"
      >
        <template v-slot:cell(name)="data">
          {{ data.value }}
        </template>
        <template v-slot:cell(evaluation)="data">
          {{ tf(data.value) }}
        </template>
        <template v-slot:cell(modifier)="data">
          <span v-if="data.value > 0">{{ data.value }}%</span>
          <span v-else>{{ $t('general.lack') }}</span>
        </template>
        <template v-slot:cell(id)="data">
          <a href="javascript:;" :aria-label="$t('general.edit')" class="my-pen button-element" style="display: inline-block" v-if="hasRight('edit_dictionaries')" @click="institutionEdit(data.value)">
            <i class="fas fa-pen academy-icon" :id="`edit-financing-source` + data.value"></i>
            <b-tooltip :target="`edit-financing-source` + data.value">
              {{ $t('general.edit') }}
            </b-tooltip>
          </a>
        </template>
      </b-table>
      <nav :aria-label="$t('general.pagination')">
        <pcg-pagination
            v-model="pagination.page"
            :page-count="pagination.pages"
            :total-records="pagination.count"
            @input="getSources"
            class="col-12 justify-content-center my-pagination"
            style="margin-top: 2rem"
            :only-event="true"
        />
      </nav>
    </b-tab>
    <b-tab :title="$t('views.conferences.list.other_list')">
      <b-table v-if="locals.length > 0"
               class="institution-table"
               borderless hover
               thead-class="sthead"
               tbody-tr-class="strow"
               :fields="fields"
               :items="locals"
               :primary-key="locals.id"
      >
        <template v-slot:cell(name)="data">
          {{ data.value }}
        </template>
        <template v-slot:cell(evaluation)="data">
          {{ tf(data.value) }}
        </template>
        <template v-slot:cell(modifier)="data">
          <span v-if="data.value > 0">{{ data.value }}%</span>
          <span v-else>{{ $t('general.lack') }}</span>
        </template>
        <template v-slot:cell(id)="data">
          <a href="javascript:;" :aria-label="$t('general.edit')" class="my-pen button-element" style="display: inline-block" v-if="hasRight('edit_own_dictionaries')" @click="institutionEdit(data.value)">
            <i class="fas fa-pen academy-icon" :id="`edit-financing-source` + data.value"></i>
            <b-tooltip :target="`edit-financing-source` + data.value">
              {{ $t('general.edit') }}
            </b-tooltip>
          </a>
        </template>
      </b-table>
      <nav :aria-label="$t('general.pagination')">
        <pcg-pagination
            v-model="localPagination.page"
            :page-count="localPagination.pages"
            :total-records="localPagination.count"
            @input="getSources"
            class="col-12 justify-content-center my-pagination"
            style="margin-top: 2rem"
            :only-event="true"
        />
      </nav>
    </b-tab>
  </b-tabs>
  <b-modal ref="institutionModal"
           :hide-header="true"
           :centered="true"
           v-bind:ok-title="$t('general.confirm')"
           ok-variant="primary"
           :no-close-on-esc="true"
           @ok="institutionSave"
           @cancel="institutionEsc"
           v-bind:cancel-title="$t('general.cancel')"
           cancel-variant="default"
           v-on-clickaway = "institutionEsc"
           footer-class="my-modal-footer"
           content-class="my-modal-content"
  >
    <div class="edit-discipline" style="display: inline-block">{{ $t('views.f_source.modal.title') }}</div>
    <pcg-text-input
        class="my-field"
        :label="$t('views.f_source.name')"
        v-model="mSource.name"
    />
    <pcg-checkbox
        class="my-field"
        :label="$t('views.f_source.evaluation')"
        v-model="mSource.evaluation"
    >{{ $t('views.f_source.evaluation') }}</pcg-checkbox>
    <pcg-text-input
        class="my-field"
        style="margin-bottom: 1.5rem"
        :label="$t('views.f_source.modifier')"
        v-model="mSource.modifier"
    />
  </b-modal>
</div>
</template>

<script>
import ApiSource from '../../api/financing_source'
import hasRight from '../../mixins/has_right'
import { mixin as clickaway } from 'vue-clickaway'
import setTitle from '../../mixins/set_title'
import tf from '../../mixins/tf'
import { mapGetters } from 'vuex'

export default {
  name: 'FinancingSourceList',
  mixins: [hasRight, setTitle, clickaway, tf],
  data: function () {
    return {
      headerTitle: 'views.f_source.title',
      headerSubtitle: 'views.f_source.subtitle',

      filters: {
        name: null
      },
      financialSources: [],
      locals: [],
      mSource: {
        id: null,
        name: null,
        evaluation: null,
        modifier: null
      },
      pagination: {
        count: 0,
        items: 0,
        last: 0,
        next: null,
        page: 0,
        pages: 0,
        prev: null
      },
      localPagination: {
        count: 0,
        items: 0,
        last: 0,
        next: null,
        page: 0,
        pages: 0,
        prev: null
      },
      perPage: 25,
      tabModel: 0
    }
  },
  computed: {
    ...mapGetters('auth', ['academyId']),
    fields: function () {
      return [
        {
          key: 'name',
          label: this.$t('views.f_source.name'),
          class: 'td-left',
          thStyle: ''
        },
        {
          key: 'evaluation',
          label: this.$t('views.f_source.evaluation'),
          thStyle: 'width: 10rem;'
        },
        {
          key: 'modifier',
          label: this.$t('views.f_source.modifier'),
          thStyle: 'width: 20rem;'
        },
        {
          key: 'id',
          label: '',
          thStyle: 'width: 5rem;'
        }
      ]
    },
    perPageOptions () {
      return [
        { text: '10', value: 10 },
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ]
    }
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.setTitles()
    ApiSource.getSources(this.$route.query)
      .then(result => {
        const query = this.$route.query
        if (Object.prototype.hasOwnProperty.call(query, 'name')) {
          this.filters.name = query.name
        }
        if (Object.prototype.hasOwnProperty.call(query, 'per_page')) {
          this.perPage = query.per_page
        } else {
          this.perPage = 25
        }
        this.financialSources = result.data.datas
        this.locals = result.data.locals
        this.pagination = result.data.pagination
        this.localPagination = result.data.localPagination
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    institutionAdd () {
      this.mSource = {
        id: null,
        name: null,
        evaluation: null,
        modifier: null
      }
      this.$refs.institutionModal.show()
    },
    institutionEdit (id) {
      let institution = this.financialSources.find(el => {
        return el.id === id
      })
      if (institution === undefined) {
        institution = this.locals.find(el => {
          return el.id === id
        })
      }
      Object.assign(this.mSource, institution)
      this.$refs.institutionModal.show()
    },
    institutionEsc () {
      this.mSource = {
        id: null,
        name: null,
        evaluation: null,
        modifier: null
      }
    },
    institutionSave (modalEvt) {
      modalEvt.preventDefault()
      if (this.mSource.id) {
        const p = {}
        Object.assign(p, this.mSource)
        const idx = this.financialSources.findIndex(el => {
          return this.mSource.id === el.id
        })
        const lidx = this.locals.findIndex(el => {
          return this.mSource.id === el.id
        })
        ApiSource.updateSource(this.mSource)
          .then(result => {
            if (idx > -1) {
              Object.assign(this.financialSources[idx], p)
            }
            if (lidx > -1) {
              Object.assign(this.locals[lidx], p)
            }
            this.$toastr.s(this.$t('general.success'))
            this.$refs.institutionModal.hide()
            this.mSource = {
              id: null,
              name: null,
              evaluation: null,
              modifier: null
            }
          })
          .catch(err => {
            const datas = err.response.data
            const message = []
            for (const key in datas) {
              message.push(`${datas[key]}`)
            }
            this.$toastr.e(message.join('\n'))
            Object.assign(this.mSource, p)
          })
      } else {
        ApiSource.createSource(this.mSource)
          .then(result => {
            if (result) {
              this.$refs.institutionModal.hide()
              this.mSource = {
                id: null,
                name: null,
                evaluation: null,
                modifier: null
              }
              setTimeout(() => {
                ApiSource.getSources(this.$route.query)
                    .then(result => {
                      const query = this.$route.query
                      if (Object.prototype.hasOwnProperty.call(query, 'name')) {
                        this.filters.name = query.name
                      }
                      if (Object.prototype.hasOwnProperty.call(query, 'per_page')) {
                        this.perPage = query.per_page
                      } else {
                        this.perPage = 25
                      }
                      this.financialSources = result.data.datas
                      this.locals = result.data.locals
                      this.pagination = result.data.pagination
                      this.localPagination = result.data.localPagination
                    })
                    .catch(error => {
                      console.log(error)
                    })
              }, 1000)
            }
          })
          .catch(err => {
            const datas = err.response.data
            const message = []
            for (const key in datas) {
              message.push(`${datas[key]}`)
            }
            this.$toastr.e(message.join('\n'))
          })
      }
    },
    getSources () {
      let query = {}
      query = Object.assign(query, this.filters)
      if (this.pagination.page !== 0) {
        query.page = this.pagination.page
      }
      if (this.localPagination.page !== 0) {
        query.local_page = this.localPagination.page
      }
      query.per_page = this.perPage
      this.$router.push({ name: 'financing_sources_list_path', query: query })

      ApiSource.getSources(query)
        .then(result => {
          const query = this.$route.query
          if (Object.prototype.hasOwnProperty.call(query, 'name')) {
            this.filters.name = query.name
          }
          this.financialSources = result.data.datas
          this.locals = result.data.locals
          this.pagination = result.data.pagination
          this.localPagination = result.data.localPagination
        })
        .catch(error => {
          console.log(error)
        })
    },
    getFilteredSource () {
      this.pagination.page = 1
      this.localPagination.page = 1
      this.getSources()
    }

  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/stylesheets/vars';

  .institution-table {
    margin-top: 1rem;
    background-color: $pcg-white;
  }

  /deep/ table.institution-table {
    background-color: $pcg-white;
    border-radius: 15px;

    thead {
      background-color: $main-color;
      color: $pcg-white;

      th {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        vertical-align: middle;

        &.td-center {
          text-align: center;
        }
      }

      th:first-child {
        padding-left: 15px;
        border-top-left-radius: 15px;
      }

      th:last-child {
        padding-right: 15px;
        border-top-right-radius: 15px;
      }
    }

    tbody {
      padding-left: 15px;
      padding-right: 15px;
      background-color: $pcg-white;

      td {
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        vertical-align: top;
        color: $pcg-dark-gray;

        &.td-left {
          text-align: left;
          color: $main-color;
          font-weight: 400;
        }
      }

      td:first-child {
        padding-left: 15px;
      }

      td:last-child {
        padding-right: 15px;
      }

      tr {
        height: 42px;
      }

      tr:last-child {
        td {
          padding-bottom: 15px;
        }

        td:first-child {
          padding-left: 15px;
          border-bottom-left-radius: 15px;
        }

        td:last-child {
          padding-right: 15px;
          border-bottom-right-radius: 15px;
        }
      }
    }
  }

  .edit-discipline {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-l;
    margin-bottom: 1rem;
  }

  .my-field {
    margin-bottom: 1rem;
    position: relative;
    /deep/ .pcg-input-label {
      margin-bottom: 0.25rem;
      color: $pcg-gray;
    }
    /deep/ .pcg-input {
      height: calc(1.5em + 0.75rem + 2px);
    }
  }

  .institution-edit {
    color: $main-active-color;
  }

  .my-select-short {
    margin-right: .8rem;
    margin-left: .7rem;
    position: relative;
    width: 100px;
    display: inline-block;
    ::v-deep .bootstrap-select .dropdown-toggle {
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
    }
    ::v-deep .filter-option-inner {
      position: relative;
      bottom: .45rem;
    }
  }

  .per_page {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-s;
  }

  .button-element {
    cursor: pointer;
  }

  .my-pen {
    color: $main-active-color;
  }

  .academy-icon {
    padding: 12px;
    border-radius: 50%;
    background-color: #ecf9fd;
  }

  /deep/ .tooltip-inner {
    background: $pcg-white;
    color: $pcg-dark-gray;
    box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
    font-weight: 400;
    padding: .5rem;
    border-color: $pcg-light-gray-background;
    border-style: solid;
    border-width: 1px;
    opacity: 1;
  }

  /deep/ .arrow::before {
    border-top-color: $pcg-light-gray-background !important;
  }

</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .edit-discipline {
      color: $pcg-gray;
    }
    .my-field {
      /deep/ .pcg-input-label {
        color: $pcg-gray;
      }
    }
    .institution-edit {
      color: $main-active-color;
    }
    .my-pagination {
      /deep/ .page-item {
        &, &.disabled {
          .page-link {
            color: $pcg-the-darkest-gray !important;
            &:hover {
              color: $hover-color !important;
            }
          }
        }
        &.active {
          .page-link {
            background-color: $main-active-color !important;
            color: $pcg-white !important;
          }
        }
        &:first-child, &:last-child {
          .page-link {
            color: $main-active-color !important;
          }
        }
      }
    }
    .my-pen {
      color: $main-active-color;
    }
    .academy-icon {
      background-color: #ecf9fd;
    }
    /deep/ .tooltip-inner {
      background: $pcg-white;
      color: $pcg-dark-gray;
      box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
      border-color: $pcg-light-gray;
    }
    /deep/ .arrow::before {
      border-top-color: $pcg-light-gray !important;
    }
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .institution-table {
      background-color: $pcg-white !important;
      thead {
        background-color: $main-color !important;
        color: $pcg-white !important;
      }
      tbody {
        background-color: $pcg-white !important;
        td {
          color: $pcg-dark-gray !important;
          &.td-left {
            color: $main-color !important;
          }
        }
      }
    }
  }
</style>
