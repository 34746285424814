import api from './index'
import axios from 'axios'

const resource = 'dictionaries'

export default {
  getDictionaries (params = {}) {
    return api.get(`${resource}`, { params: params })
  },
  getSelectedDictionaries (dictionaries) {
    const promises = dictionaries.map(dictionary => api.get(`${resource}/${dictionary}/words`))
    return axios.all(promises)
  },
  getDictionary (dictionaryId) {
    return api.get(`${resource}/${dictionaryId}`)
  },
  updateDictionary (dictionaryId, payload) {
    return api.patch(`${resource}/${dictionaryId}`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
}
