import api from './index'

const resource = '/users'

export default {
  signIn (params) {
    return api.get(`${resource}/auth/openid_connect/callback`, {
      params: params,
      baseURL: '/'
    })
  },
  localAuth (password, userId) {
    const body = {
      pass: password,
      user_id: userId
    }
    return api.post(`${resource}/local_pass`, body)
  },
  passwordAuth (password, email) {
    const body = {
      pass: password,
      email: email
    }
    return api.post(`${resource}/email_pass`, body)
  },
  devSignIn (payload) {
    return api.post('dev_sessions', payload)
  },
  redirSignIn (payload) {
    return api.post('redir_sessions', payload)
  },
  signOut () {
    return api.delete(`${resource}/sign_out`, {
      baseURL: '/'
    })
  },
  fetchUser () {
    return api.get(`${resource}/initial_check`)
  },
  fetchRights (currentRole) {
    return api.get(`${resource}/rights?role=${currentRole}`)
  },
  setCurrentRole (currentRole) {
    const body = {
      current_role: currentRole
    }
    return api.post(`${resource}/set_current_role`, body)
  }
}
