<template>
<div class="art-achievements">
  <div v-for="discipline in artDisciplines()" :key="discipline.value" class="discipline-row">
    <b-row class="discipline-title">
      <b-col>
        {{ $i18n.locale === 'pl' ? discipline.text : discipline.text_en }}
      </b-col>
    </b-row>
    <b-row class="group-row" v-for="group in groups(parseInt(discipline.value))" :key="`g${group.key}`">
      <b-col cols="1" class="group-key">{{ group.key }}</b-col>
      <b-col>
        <div v-for="kind in kinds(group.id)" :key="`k${kind.id}`">
          <b-row class="kind-row">
            <b-col>
              {{ kind.notice }}
            </b-col>
            <b-col cols="1">
              <span v-if="hasRight('edit_dictionaries')" class="clicked small ml-1 publisher-edit" @click="editKind(kind.id)">
                  <i class="fas fa-pen small"></i>
                  {{ $t('general.edit') }}
              </span>
              <b-modal :id="`modalKind-${kind.id}`"
                       :hide-header="true"
                       :centered="true"
                       v-bind:ok-title="$t('general.confirm')"
                       ok-variant="primary"
                       :no-close-on-esc="true"
                       @ok="kindSave(kind)"
                       @hide="kindEsc(kind.id)"
                       v-bind:cancel-title="$t('general.cancel')"
                       cancel-variant="default"
                       v-on-clickaway = "kindEsc"
                       content-class="my-modal-content"
                       footer-class="my-modal-footer"
              >
                <div class="edit-kind">
                  <span class="pcg-input-label">Dyscyplina:</span>
                  <span class="font-weight-bold info-text">
                    {{ discipline.text }}
                  </span>
                </div>
                <div class="edit-kind">
                  <span class="pcg-input-label">Grupa:</span>
                  <span class="font-weight-bold info-text">
                    {{ group.key }}
                  </span>
                </div>
                <div class="edit-kind">
                  <pcg-text-input
                      class="my-textarea"
                      type="textarea"
                      label="Rodzaj"
                      v-model="kind.notice"
                  />
                </div>
              </b-modal>
            </b-col>
          </b-row>
        </div>
        <pcg-btn  v-if="hasRight('edit_dictionaries')" @click="addKind(group.id)" size="small">{{ $t('views.art_achievements.add_kind') }}</pcg-btn>
      </b-col>
    </b-row>
    <b-row class="group-row">
      <b-col>
        <pcg-btn v-if="hasRight('edit_dictionaries')" @click="addGroup(parseInt(discipline.value))" size="small">{{ $t('views.art_achievements.add_group') }}</pcg-btn>
      </b-col>
    </b-row>
  </div>
</div>
</template>

<script>
import hasRight from '../../mixins/has_right'
import setTitle from '../../mixins/set_title'
import ApiArts from '../../api/arts'
import translateDictionary from '../../mixins/translate_dictionary'
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'ArtAchievements',
  mixins: [hasRight, setTitle, translateDictionary, clickaway],
  data: function () {
    return {
      headerTitle: 'views.art_achievements.title',
      headerSubtitle: '',
      artGroups: [],
      artKinds: []
    }
  },
  beforeMount () {
    if (this.$store.getters['disciplines/disciplines'].length === 0) {
      this.$store.dispatch('disciplines/setDisciplines', null)
    }
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.setTitles()
    ApiArts.getArts()
      .then(result => {
        this.artGroups = result.data.groups
        this.artKinds = result.data.kinds
      })
      .catch(error => {
        console.log(error)
      })
  },
  methods: {
    artDisciplines () {
      const disciplines = this.$store.getters['disciplines/disciplines'].filter(d => {
        return d.science_field_id === 8
      })
      return this.tDictionary(disciplines)
    },
    groups (disciplineId) {
      return this.artGroups.filter(g => {
        return g.disciplineId === disciplineId
      })
    },
    kinds (groupId) {
      return this.artKinds.filter(k => {
        return k.groupId === groupId
      })
    },
    addGroup (discId) {
      const groups = this.artGroups.filter(g => {
        return g.disciplineId === discId
      })
      const gs = groups.sort((a, b) => {
        return b.key - a.key
      })
      const maxGs = gs[0]
      let group = {}
      if (maxGs) {
        group = {
          key: maxGs.key + 1,
          discipline_id: discId
        }
      } else {
        group = {
          key: 1,
          discipline_id: discId
        }
      }
      ApiArts.createGroup(group)
        .then(result => {
          this.artGroups.push(
            result.data
          )
        })
        .catch(error => {
          console.log(error)
        })
    },
    addKind (groupId) {
      const kind = {
        group_id: groupId,
        notice: 'Uzupełnij'
      }
      ApiArts.createKind(kind)
        .then(result => {
          this.artKinds.push(
            result.data
          )
        })
        .catch(error => {
          console.log(error)
        })
    },
    editKind (kindId) {
      this.$bvModal.show(`modalKind-${kindId}`)
    },
    kindSave (kind) {
      ApiArts.updateKind(kind)
        .then(result => {
          this.$bvModal.hide(`modalKind-${kind.id}`)
        })
        .catch(error => {
          console.log(error)
        })
    },
    kindEsc (kindId) {
      this.$bvModal.hide(`modalKind-${kindId}`)
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/stylesheets/vars';

  .discipline-row {
    background-color: $main-color;
    color: $pcg-white;
    padding: 0.5rem 10px 0 10px;
    margin-left: -10px;
    //padding-left: 10px;
    margin-right: -10px;
    //padding-right: 10px;
    &:first-of-type {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background-color: $main-color;
    }
    &:last-of-type {
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      .group-row {
        &:last-of-type {
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
        }
      }
    }
    .discipline-title {
      margin-left: -10px;
      padding-left: 10px;
      margin-right: -10px;
      padding-right: 10px;
      padding-bottom: 0.5rem;
    }
  }

  .group-row {
    color: $main-color;
    background-color: $pcg-white;
    padding-top: 0.5rem;
  }

  .group-key {
    padding-top: 0.5rem;
  }

  .kind-row {
    color: $main-color;
    background-color: $pcg-white;
    padding-top: 0.5rem;
  }

  ::v-deep .pcg-main-btn {
    box-shadow: 5px 5px 15px rgba(0, 180, 235, 0.4);
  }

  .my-textarea {
    ::v-deep .pcg-textarea {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-radius: 10px;
    }
    ::v-deep .pcg-field {
      height: 7rem;
      font-size: 1em;
      font-weight: 400;
      color: $pcg-dark-gray;
    }
  }

  .publisher-edit {
    color: $main-active-color;
  }
</style>

<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .discipline-row {
      background-color: $main-color;
      color: $pcg-white;
      &:first-of-type {
        background-color: $main-color;
      }
    }
    .group-row {
      color: $main-color;
      background-color: $pcg-white;
    }
    .kind-row {
      color: $main-color;
      background-color: $pcg-white;
    }
    ::v-deep .pcg-main-btn {
      box-shadow: 5px 5px 15px rgba(0, 180, 235, 0.4);
    }
    .my-textarea {
      ::v-deep .pcg-field {
        color: $pcg-dark-gray;
      }
    }
    .publisher-edit {
      color: $main-active-color;
    }
    .info-text {
      color: $pcg-black;
    }
  }
</style>
