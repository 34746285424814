<template>
  <b-modal ref="addUser"
           :id="modalId"
           :hide-header="true"
           footer-class="my-modal-footer"
           :centered="true"
           v-bind:ok-title="$t('general.confirm')"
           ok-variant="primary"
           :no-close-on-esc="true"
           @ok="updateUser"
           v-bind:cancel-title="$t('general.cancel')"
           cancel-variant="default"
           content-class="my-modal-content"
  >
    <div v-if="editingUser" class="confirm-header">{{ $t('views.users.list.editing_user') }}</div>
    <div v-else class="confirm-header">{{ $t('views.users.list.adding_user') }}</div>
    <b-row v-for="error in userErrors" :key="error.id">
      <b-col>
        <img src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark-user"/>
        <span class="my-error">{{ error }}</span>
      </b-col>
    </b-row>
    <pcg-text-input
      :label="$t('views.users.list.first_name')"
      :showLabel="true"
      v-model="currentUser.firstName"
      class="my-input"
    />
    <pcg-text-input
      :label="$t('views.users.list.surname')"
      :showLabel="true"
      v-model="currentUser.lastName"
      class="my-input"
    />
    <pcg-text-input
      :label="$t('views.users.list.email')"
      :showLabel="true"
      v-model="currentUser.email"
      class="my-input"
    />
    <pcg-text-input
      :label="$t('views.users.list.uid')"
      :showLabel="true"
      v-model="currentUser.uid"
      class="my-input"
    />
    <pcg-select
      class="my-select"
      :include-blank="true"
      :label="$t('views.users.list.fields.academy')"
      :options="tDictionary(academiesList)"
      :default-option="{ text: $t('general.select'), id: '' }"
      v-model="currentUser.academyId"
    />
  </b-modal>
</template>

<script>
import ApiUsers from '../api/users'
import ApiAcademies from '../api/academies'
import translateDictionary from '../mixins/translate_dictionary'

export default {
  name: 'NewUserModal',
  mixins: [translateDictionary],
  props: {
    modalId: String,
    userErrors: Array,
    currentUser: Object,
    editingUser: Boolean
  },
  data: function () {
    return {
      academiesList: []
    }
  },
  created () {
    ApiAcademies.getAcademies()
      .then(result => {
        this.academiesList = result.data.datas
        this.academiesList = this.academiesList.map(academy => {
          return {
            id: academy.id.toString(),
            text: academy.name,
            text_en: academy.name
          }
        })
      })
  },
  methods: {
    // Jedna metoda do dodawania i edycji użytkownika
    updateUser () {
      if (this.editingUser) {
        ApiUsers.updateUser(this.currentUser)
          .then(result => {
            if (result.data.data.id === this.currentUser.id) {
              this.$store.dispatch('auth/fetchUser')
            }
            setTimeout(() => {
              this.$emit('updateUsersList')
              this.$toastr.s(this.$t('views.users.list.edit_user_success'))
            }, 500)
          })
          .catch(error => {
            this.$toastr.e(this.$t('views.users.list.edit_user_failure'))
            this.userErrors = error.response.data
            this.$refs.addUser.show()
          })
      } else {
        ApiUsers.createUser(this.currentUser)
          .then(result => {
            this.$emit('addUserSpinner')
            setTimeout(() => {
              this.$emit('updateUsersList')
              this.$toastr.s(this.$t('views.users.list.user_success'))
            }, 2000)
          })
          .catch(error => {
            this.$toastr.e(this.$t('views.users.list.wrong_user'))
            this.userErrors = error.response.data
            this.$refs.addUser.show()
          })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../assets/stylesheets/vars';
  .confirm-header {
    font-size: $font-size-m;
    font-weight: 400;
    color: $pcg-gray;
    margin-bottom: 1.5rem;
  }

  .my-input {
    /deep/ .pcg-input {
      height: calc(1.5rem + 0.75rem + 2px)
    }
    position: relative;
    /deep/ .pcg-input-label {
      margin-bottom: 0.25rem;
      color: $pcg-gray;
    }
  }

  .my-exclamation-mark-user {
    height: 14px;
    width: 14px;
    padding: .2rem .2rem;
    border-radius: 20px;
    text-align: center;
    background-color: $pcg-red;
    opacity: 0.8;
    position: relative;
    bottom: .2rem;
  }

  .my-error {
    color: $pcg-red;
    font-weight: 400;
    margin-left: .3rem;
  }

  /deep/ .dropdown-menu {
    overflow: auto;
    max-height: 300px;
  }

  .my-select {
    margin-bottom: 3rem;
    /deep/ .bootstrap-select .dropdown-toggle {
      height: calc(1.5em + 0.75em + 2px);
    }
    /deep/ .filter-option-inner {
      position: relative;
      bottom: .45rem;
    }
    /deep/ .pcg-input-label {
      color: $pcg-gray;
      position: relative;
      top: .3rem;
    }
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .confirm-header {
      color: $pcg-gray;
    }
    /deep/ .pcg-input-label {
      color: $pcg-gray;
    }
  }
</style>
