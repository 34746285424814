import api from './index'
import qs from 'qs'

const resource = 'entity_projects'

export default {
  getEntities (query = null) {
    let url = ''
    const params = qs.stringify(query)
    url = resource + '?' + params
    return api.get(url)
  },
  createEntity (entity) {
    const uri = `${resource}`
    const body = {
      entity_project: entity
    }
    return api.post(uri, body)
  },
  updateEntity (entity) {
    const uri = `${resource}/${entity.id}`
    const body = {
      entity_project: entity
    }
    return api.put(uri, body)
  }

}
