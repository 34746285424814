<template>
  <div class="redirect-view">
    <dn-loader v-if="showLoader" class="with-margins"/>
    <div v-else>
      <div class="no-rights">Nie udało się się przekierować. Spróbuj ponownie.</div>
    </div>
  </div>
</template>

<script>
import loaderDelay from '../mixins/loader_delay'
import DnLoader from '../components/DnLoader'
export default {
  name: 'RedirectView',
  mixins: [loaderDelay],
  components: { DnLoader },
  data () {
    return {
      headerTitle: 'Przekierowywanie',
      headerSubtitle: ''
    }
  },

  mounted () {
    setTimeout(() => {
      this.loadingDone = true
    }, 60000)
    this.$store.dispatch('header/setHeader', {
      title: this.$t(this.headerTitle),
      subtitle: this.$t(this.headerSubtitle),
      capitalizeSubtitle: false
    })
  }
}
</script>

<style scoped lang="scss">
  @import '../assets/stylesheets/vars';
  .contrast{
    /deep/ .redirect-view{
      background-color: $pcg-the-lightest-gray;
    }
  }
</style>
