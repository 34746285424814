<template>
  <div class="dictionary-acceptance-words">
    <transition name="slide" mode="out-in">
      <router-view/>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'dicitionary-acceptance-words-view'
}
</script>

<style lang="scss" scoped>

</style>
