import api from './index'

const resource = 'rights'

export default {
  getRights (academyId) {
    let url
    if (academyId) {
      url = `${resource}?academy=${academyId}`
    } else {
      url = `${resource}`
    }
    return api.get(url)
  },
  updateRight (academy, right) {
    let url = resource
    url += '/' + right.id
    const body = {
      academy: academy,
      right: right
    }
    return api.put(url, body)
  }
}
