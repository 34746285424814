<template>
  <div>
    <b-row style="margin-bottom: 2rem">
      <b-col cols="12" class="back-list">
        <router-link :to="{ name: 'dictionary_words_path', params: { dictionary_id: id } }" >
          <i class="fas fa-arrow-left"></i>
          <span>{{ $t('views.dictionary_words.back_list') }}</span>
        </router-link>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xl="8">
        <div class="form-container separator">
          <pcg-text-input class="col-12"
                          :label="$t('views.dictionary_words.labels.key')"
                          :error-text="getError('key')"
                          :show-error="errorExist('key')"
                          v-model="dictionaryWord.key"/>
          <pcg-text-input class="col-12"
                          :label="$t('views.dictionary_words.labels.value')"
                          :error-text="getError('value')"
                          :show-error="errorExist('value')"
                          v-model="dictionaryWord.value"/>
          <pcg-text-input class="col-12"
                          :label="$t('views.dictionary_words.labels.lang')"
                          :error-text="getError('lang')"
                          :show-error="errorExist('lang')"
                          v-model="dictionaryWord.lang"
                          v-if="dictionary.key === 'keywords'"/>
          <!--          <pcg-text-input class="col-12"-->
<!--                          :label="$t('views.dictionary_words.labels.valueEn')"-->
<!--                          :error-text="getError('valueEn')"-->
<!--                          :show-error="errorExist('valueEn')"-->
<!--                          v-model="dictionaryWord.valueEn"/>-->
          <pcg-checkbox class="col-12"
                        :error-text="getError('status')"
                        :show-error="errorExist('status')"
                        v-model="dictionaryWord.statusBoolean">
            {{$t('views.dictionary_words.labels.accept')}}
          </pcg-checkbox>

          <pcg-btn v-if="dictionaryWord" size="small" @click="updateDictionaryWord()" variant="additional" class="action-btn">
            {{ $t('views.common.save') }}
          </pcg-btn>
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import ApiDictionaryWords from '../../api/dictionary_words'
import ApiDictionary from '../../api/dictionaries'

export default {
  name: 'DictionaryWordsEdit',
  components: {
  },
  data () {
    return {
      dictionaryId: this.$route.params.dictionaryId,
      id: this.$route.params.id,
      errors: {},
      dictionary: {},
      dictionaryWord: {
        key: null,
        lang: null,
        value: null,
        valueEn: null,
        status: null,
        statusBoolean: null
      }
    }
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.getDictionary()
    this.getDictionaryWord()
  },
  methods: {
    getDictionary () {
      ApiDictionary.getDictionary(this.dictionaryId)
        .then(result => {
          this.dictionary = result.data.data.attributes
        })
    },
    getDictionaryWord () {
      ApiDictionaryWords.getDictionaryWord(this.dictionaryId, this.id)
        .then(response => {
          this.dictionaryWord = response.data.data.attributes
          if (this.dictionaryWord.status === 'accepted') {
            this.dictionaryWord.statusBoolean = true
          } else {
            this.dictionaryWord.statusBoolean = false
          }
        }).catch(error => {
          this.$toastr.e(error)
          console.log(error)
        })
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.dictionary_words.edit.title'),
        subtitle: this.$t('views.dictionary_words.edit.subtitle'),
        capitalizeSubtitle: false
      })
    },
    updateDictionaryWord () {
      this.loading = true
      this.dictionaryWord.valueEn = this.dictionaryWord.value
      const dictionaryWordDup = this.dictionaryWord
      if (this.dictionaryWord.statusBoolean) {
        dictionaryWordDup.status = 'accepted'
      } else {
        dictionaryWordDup.status = 'rejected'
      }
      ApiDictionaryWords.updateDictionaryWord(this.dictionaryId, this.id, { dictionaryWord: dictionaryWordDup })
        .then(response => {
          this.errors = {}
          this.getDictionaryWord()
          setTimeout(() => { this.$toastr.s(this.$t('general.dictionary_word_updated')) }, 1)
        }).catch(error => {
          this.errors = error.response.data
          setTimeout(() => { this.$toastr.e(this.$t('general.check_errors')) }, 1)
        }).finally(() => {
          this.loading = false
        })
    },
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/stylesheets/vars';

  .back-list {
    margin-left: 1rem;
    font-size: $font-size-m;
    color: $main-active-color;
    font-weight: 400;
    a {
      color: unset
    }
  }
</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .back-list {
      color: $main-active-color;
    }
  }
</style>
