<template>
  <b-col cols="12">
    <pcg-table :items="dictionaryWords" :fields="fields">
      <template v-slot:header>{{$t('views.dictionary_words.index.table_header')}}</template>
    </pcg-table>
    <nav :aria-label="$t('general.pagination')">
      <pcg-pagination v-model="pagination.currentPage"
                      @input="getDictionaryWords"
                      :page-count="pagination.pages"
                      :total-records="pagination.totalRecords"
                      class="col-12 justify-content-center my-pagination"/>
    </nav>
  </b-col>
</template>

<script>
import ApiDictionaryWords from '../../api/dictionary_words'
import PaginableResourceMixin from '../../mixins/paginable_resource_mixin'
import { decamelize } from 'humps'

export default {
  name: 'DictionaryWordsIndex',
  components: { },
  mixins: [PaginableResourceMixin],
  data () {
    return {
      dictionaryId: this.$route.params.dictionaryId,
      dictionaryWords: [],
      fields: [],
      status: 'recent'
    }
  },
  props: ['academyId'],
  watch: {
    academyId () {
      this.getDictionaryWords()
    }
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    // this.getDictionaryWords()
  },
  methods: {
    getDictionaryWords () {
      ApiDictionaryWords.getDictionaryWordsRecent(this.dictionaryId, { page: this.pagination.currentPage, academy_id: this.academyId })
        .then(response => {
          this.setPagination(response)
          this.fields = this.fieldsTable(response.data.data)
          this.dictionaryWords = this.dataTable(response.data.data)
        }).catch(error => {
          this.$toastr.e(error)
          console.log(error)
        })
    },
    acceptDictionaryWord (dictionaryWord) {
      this.loading = true
      ApiDictionaryWords.updateDictionaryWord(this.dictionaryId, dictionaryWord.id, { dictionaryWord: { status: 'accepted' } })
        .then(response => {
          this.errors = {}
          this.dictionaryWords = this.dictionaryWords.filter((word) => word.hidden_id !== dictionaryWord.id)
          setTimeout(() => { this.$toastr.s(this.$t('general.dictionary_word_updated')) }, 1)
        }).catch(error => {
          this.errors = error.response.data
          setTimeout(() => { this.$toastr.e(this.$t('general.check_errors')) }, 1)
        }).finally(() => {
          this.loading = false
          this.$emit('updated')
        })
    },
    rejectDictionaryWord (dictionaryWord) {
      this.loading = true
      ApiDictionaryWords.updateDictionaryWord(this.dictionaryId, dictionaryWord.id, { dictionaryWord: { status: 'rejected' } })
        .then(response => {
          this.errors = {}
          this.dictionaryWords = this.dictionaryWords.filter((word) => word.hidden_id !== dictionaryWord.id)
          setTimeout(() => { this.$toastr.s(this.$t('general.dictionary_word_updated')) }, 1)
        }).catch(error => {
          this.errors = error.response.data
          setTimeout(() => { this.$toastr.e(this.$t('general.check_errors')) }, 1)
        }).finally(() => {
          this.loading = false
          this.$emit('updated')
        })
    },
    // setHeader () {
    //   this.$store.dispatch('header/setHeader', {
    //     title: this.$t('views.dictionary_words.index.title'),
    //     subtitle: this.$t('views.dictionary_words.index.subtitle')
    //   })
    // },
    dataTable (data) {
      const result = []
      data.forEach((dictionary, index) => {
        const attributes = dictionary.attributes
        const actions = {
          actions: [
            {
              id: `${dictionary.id}-a1`,
              link: () => this.acceptDictionaryWord(dictionary),
              type: 'button',
              text: this.$t('general.accept')
            },
            {
              id: `${dictionary.id}-a2`,
              link: () => this.rejectDictionaryWord(dictionary),
              type: 'button',
              text: this.$t('general.reject')
            },
            {
              id: `${dictionary.id}-a3`,
              link: () => this.$router.push({ name: 'dictionary_words_edit_path', params: { dictionaryId: this.dictionaryId, id: dictionary.id } }),
              type: 'button',
              text: this.$t('general.edit')
            }]
        }
        Object.assign(attributes, actions)
        Object.assign(attributes, { hidden_id: dictionary.id })
        result.push(attributes)
      })
      return result
    },
    fieldsTable (data) {
      const fields = []
      data.forEach((dictionary, index) => {
        if (index === 0) {
          _.forEach(dictionary.attributes, (val, key) => {
            if (key === 'status') {
              fields.push({
                key: key,
                label: this.$t(`views.dictionary_words.labels.${decamelize(key)}`),
                formatter: value => {
                  return this.$t(`views.dictionary_words.statuses.${value}`)
                }
              })
            } else {
              fields.push({
                key: key,
                label: this.$t(`views.dictionary_words.labels.${decamelize(key)}`)
              })
            }
          })
        }
      })
      fields.push({
        key: 'actions',
        label: this.$t('views.dictionary_words.labels.actions')
      })
      return fields
    }
  }
  // beforeRouteEnter (to, from, next) {
  //   next(vm => {
  //     vm.setHeader()
  //   })
  // }
}
</script>
<style scoped lang="scss">
  .list-enter-active, .list-leave-active {
    transition: all 1s;
  }
  .list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(30px);
  }
</style>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars-contrast";
  .contrast {
    .my-pagination {
      /deep/ .page-item {
        &, &.disabled {
          .page-link {
            color: $pcg-the-darkest-gray !important;
            &:hover {
              color: $hover-color !important;
            }
          }
        }
        &.active {
          .page-link {
            background-color: $main-active-color !important;
            color: $pcg-white !important;
          }
        }
        &:first-child, &:last-child {
          .page-link {
            color: $main-active-color !important;
          }
        }
      }
    }
  }
</style>
