<template>
  <div>
    <div class="d-flex justify-content-between" style="margin-bottom: 2.5rem;">
      <div>
        <i class="fas fa-bezier-curve my-user"></i>
        <span class="faculties-title">{{ $t('views.employee.faculties') }}</span>
      </div>
      <div>
        <i class="pcg-icon-cross my-cross" @click="escFaculties"></i>
      </div>
    </div>
    <div class="one-faculty" v-for="faculty in ownFaculties" :key="faculty.id">
      <b-row>
        <b-col cols="8">
          <div class="faculty-name">{{ faculty.name }}</div>
        </b-col>
        <b-col cols="2">
          <pcg-checkbox
            v-model="faculty.archive"
            @input="changeArchive(faculty)"
            :class="{'my-checkbox': !faculty.archive, 'my-checkbox-alternative': faculty.archive}"
          >{{ $t('views.employee.archived') }}</pcg-checkbox>
        </b-col>
        <b-col cols="2" class="d-flex justify-content-end">
          <pcg-btn class="remove-btn" @click="removeFaculty(faculty.id)" round :icon="removeIcon" :text="$t('views.employee.remove_faculty')" variant="danger"></pcg-btn>
        </b-col>
      </b-row>
      <div class="my-border"></div>
    </div>
    <b-row style="margin-bottom: .3rem" v-for="error in errors" :key="error.id">
      <b-col>
        <img src="../assets/images/exclamation-mark.svg" class="my-exclamation-mark"/>
        <span class="my-error">{{ error }}</span>
      </b-col>
    </b-row>
    <b-row style="margin-bottom: 1rem">
      <b-col cols="6">
<!--        <pcg-select-->
<!--          :options="myFaculties"-->
<!--          v-model="newFaculty.id"-->
<!--          class="my-input"-->
<!--        />-->
        <live-search
            class="publisher-search modal-select col-11"
            :clear-btn="false"
            :options="facultyOptions"
            v-model="currentFaculty"
            v-on:ls-checked="facultyChecked()"
        />
      </b-col>
      <b-col cols="4">
        <pcg-btn size="small" style="position: relative; bottom: .2rem;" @click="addEmployeeFaculty(employee.id, newFaculty.id)">+ {{ $t('views.employee.add_faculty') }}</pcg-btn>
      </b-col>
    </b-row>
    <div class="my-border" style="margin-bottom: 1rem;"></div>
    <b-row class="d-flex justify-content-center">
      <pcg-btn @click="createEmployeeFaculties(employee.id, newFaculty.id)">+ {{ $t('views.employee.save_and_close') }}</pcg-btn>
    </b-row>
  </div>
</template>

<script>
import ApiEmployees from '../api/employees'
import LiveSearch from './LiveSearch'
import ApiEmployeeFaculties from '../api/employee_faculties'
import hasRight from '../mixins/has_right'

export default {
  name: 'DnFaculty',
  props: {
    employee: Object
  },
  mixins: [hasRight],
  components: { LiveSearch },
  data: function () {
    return {
      currentFaculty: {
        id: null,
        value: null
      },
      ownFaculties: [],
      errors: [],
      newFaculty: {
        id: null,
        name: null
      },
      removeIcon: {
        type: 'component',
        value: 'icon-remove',
        width: '11',
        height: '13',
        color: '#00B4EB',
        hoverColor: '#a90000'
      }
    }
  },
  computed: {
    // myFaculties () {
    //   const pFacultis = []
    //   this.faculties.forEach(f => {
    //     let rName = ''
    //     if (f.depth > 0) {
    //       rName += f.parents + ' -> '
    //     }
    //     rName += f.name
    //     pFacultis.push({
    //       text: rName,
    //       value: f.id
    //     })
    //   })
    //   return pFacultis
    // }
    facultyOptions () {
      return {
        path: 'faculties/search',
        params: {}
      }
    }
  },
  created () {
  },
  mounted () {
    this.employee.faculties.forEach(f => {
      this.ownFaculties.push({
        id: f.id,
        name: f.parents ? `${f.parents} -> ${f.name}` : f.name,
        archive: f.archive
      })
    })
  },
  methods: {
    escFaculties () {
      this.$emit('escFaculties')
    },
    changeArchive (faculty) {
      return ApiEmployeeFaculties.changeArchive(faculty)
    },
    facultyChecked () {
      this.newFaculty = { id: this.currentFaculty.id, name: this.currentFaculty.value }
    },
    removeFaculty (id) {
      ApiEmployeeFaculties.removeFaculty(id)
        .then((result) => {
          // ApiEmployees.getFaculties(this.employee.id)
          //   .then((result) => {
          //     this.ownFaculties = result.data.faculties
          //   })
          const idx = this.ownFaculties.findIndex(f => {
            return f.id === id
          })
          this.ownFaculties.splice(idx, 1)
        })
    },
    addEmployeeFaculty (employeeId, facultyId) {
      this.errors = []
      if (facultyId) {
        ApiEmployeeFaculties.createEmployeeFaculty(employeeId, facultyId)
          .then(result => {
            this.ownFaculties.push(result.data.faculty)
            this.newFaculty.id = null
            this.newFaculty.name = null
          })
          .catch(error => {
            this.errors.push(this.$t('views.employee.faculty_error'))
            this.newFaculty.id = null
            this.newFaculty.name = null
          })
      }
    },
    createEmployeeFaculties (employeeId, facultyId) {
      this.errors = []
      if (facultyId) {
        ApiEmployeeFaculties.createEmployeeFaculty(employeeId, facultyId)
          .then(result => {
            this.ownFaculties.push(result.data.faculty)
            this.$emit('escFaculties')
          })
          .catch(error => {
            this.errors.push(this.$t('views.employee.faculty_error'))
            this.newFaculty.id = null
            this.newFaculty.name = null
          })
      } else {
        this.$emit('escFaculties')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .faculties {
    color: $main-active-color;
    font-weight: 600;
    font-size: $font-size-l;
    cursor: pointer;
  }

  .my-user {
    color: $main-color;
    margin-right: .3rem;
  }

  .faculties-title {
    color: $main-color;
    font-weight: 600;
    font-size: $font-size-m;
    text-transform: uppercase;
  }

  .my-cross {
    color: $pcg-gray;
    cursor: pointer;
  }

  .remove-btn {
    position: relative;
    bottom: .6rem;
  }

  .one-faculty {
    margin-bottom: 2rem;
  }

  .my-border {
    border-bottom: 1px solid $pcg-lightest-gray-background;
    margin-left: -30px;
    margin-right: -30px;
  }

  .faculty-name {
    margin-bottom: 2rem;
    color: $pcg-gray;
    font-weight: 400;
  }

  .my-input {
    /deep/ .pcg-input {
      height: calc(1.5rem + 0.75rem + 2px)
    }
  }

  .my-error {
    color: $pcg-red;
    font-weight: 400;
    margin-left: .3rem;
    position: relative;
    bottom: .1rem;
  }

  .my-exclamation-mark {
    height: 14px;
    width: 14px;
    padding: .2rem .2rem;
    border-radius: 20px;
    text-align: center;
    background-color: $pcg-red;
    opacity: 0.8;
    position: relative;
    bottom: .2rem;
  }

  .my-checkbox {
    /deep/ .checkbox-text {
      color: $pcg-gray;
      font-weight: 600;
    }
  }

  .my-checkbox-alternative {
    /deep/ .checkbox-text {
      color: $main-active-color;
      font-weight: 600;
    }
  }

  .my-radio {
    /deep/ .pcg-radio-container {
      color: $pcg-gray;
      font-weight: 600;
    }
  }

  .my-radio-alternative {
    /deep/ .pcg-radio-container {
      color: $main-active-color;
      font-weight: 600;
    }
  }

  .faculties-list {
    color: $main-color;
    font-weight: 400;
    font-size: $font-size-m;
    margin-bottom: 2rem;
  }

  .my-checkbox {
    /deep/ .checkbox-text {
      color: $pcg-gray;
      font-weight: 600;
    }
  }

  .my-checkbox-alternative {
    /deep/ .checkbox-text {
      color: $main-active-color;
      font-weight: 600;
    }
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .faculties {
      color: $main-active-color;
    }
    .my-user {
      color: $main-color;
    }
    .faculties-title {
      color: $main-color;
    }
    .my-cross {
      color: $pcg-gray;
    }
    .my-border {
      border-bottom: 1px solid $pcg-lightest-gray;
    }
    .faculty-name {
      color: $pcg-gray;
    }
    .my-error {
      color: $pcg-red;
    }
    .my-exclamation-mark {
      background-color: $pcg-red;
    }
    .my-checkbox {
      /deep/ .checkbox-text {
        color: $pcg-gray;
      }
    }
    .my-checkbox-alternative {
      /deep/ .checkbox-text {
        color: $main-active-color;
      }
    }
    .my-radio {
      /deep/ .pcg-radio-container {
        color: $pcg-gray;
      }
    }
    .my-radio-alternative {
      /deep/ .pcg-radio-container {
        color: $main-active-color;
      }
    }
    .faculties-list {
      color: $main-color;
    }
    .my-checkbox {
      /deep/ .checkbox-text {
        color: $pcg-gray;
      }
    }
    .my-checkbox-alternative {
      /deep/ .checkbox-text {
        color: $main-active-color;
      }
    }
  }
</style>
