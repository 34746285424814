import api from './index'

const resource = 'publication_types'

export default {
  getPublicationTypes () {
    return api.get(`${resource}`)
  },
  dictPublicationTypes () {
    let result = resource
    result += '/dict'
    return api.get(result)
  },
  chosenPublicationTypes () {
    let result = resource
    result += '/chosen_types'
    return api.get(result)
  }
}
