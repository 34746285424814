import api from './index'

const resource = 'export_xmls'

export default {
  getXmlFiles (page = null) {
    let url = ''
    if (page) {
      url = resource + '?page=' + page
    } else {
      url = resource
    }
    return api.get(url)
  },
  createXmlFile (fileName) {
    const body = { file_name: fileName }
    return api.post(resource, body)
  },
  getXmlFile (id) {
    let url = resource
    url += '/' + id
    return api.get(url)
  },
  deleteXml (id) {
    let url = resource
    url += '/' + id
    return api.delete(url)
  }
}
