export default {
  methods: {
    rejectionReasons (reason, disciplineId, isDoctorant) {
      let result = ''
      if (reason === 1) {
        if (disciplineId === 45 || disciplineId === 46 || disciplineId === 47) {
          if (this.$i18n.locale === 'pl') {
            result = 'Przekroczona liczba osiągnięć artystycznych.\n\nLimit dla osoby równy 4.'
          } else {
            result = 'Number of artistic achievements exceeded.\n\nThe limit for a person is 4.'
          }
        } else {
          if (this.$i18n.locale === 'pl') {
            result = 'Przekroczona suma udziałów jednostkowych za publikacje naukowe.\n\nLimit dla pracownika równy 4-krotność średniej wartości iloczynu wymiaru czasu pracy i udziału czasu pracy w dyscyplinie zadeklarowanego w oświadczeniu o dyscyplinach.\nLimit dla doktoranta szkoły doktorskiej równy 4.'
          } else {
            result = 'Total unit shares for scientific publications exceeded.\n\nThe limit for an employee is equal to 4 times the average value of the product of the working time and the share of working time in the discipline declared in the declaration on disciplines.\nThe limit for a doctoral student at a doctoral school is 4.'
          }
        }
      } else if (reason === 2) {
        if (disciplineId === 45 || disciplineId === 46 || disciplineId === 47) {
          if (this.$i18n.locale === 'pl') {
            result = 'Przekroczona liczba osiągnięć artystycznych.\n\nLimit dla dyscypliny równy 3N.'
          } else {
            result = 'Number of artistic achievements exceeded.\n\nThe discipline limit is 3N.'
          }
        } else {
          if (this.$i18n.locale === 'pl') {
            result = 'Przekroczona suma udziałów jednostkowych w publikacjach naukowych.\n\nLimit dla dyscypliny równy 3N.'
          } else {
            result = 'Total unit shares in scientific publications exceeded.\n\nThe discipline limit is 3N.'
          }
        }
      } else if (reason === 3) {
        if (this.$i18n.locale === 'pl') {
          result = 'Przekroczona suma udziałów jednostkowych za\n1) artykuły naukowe opublikowane w latach 2019-2021,\n2) monografie naukowe, rozdziały w monografiach naukowych i redakcje naukowe monografii naukowych, opublikowane w latach 2017-2021.\n\nLimit dla dyscypliny równy 220% z N.'
        } else {
          result = 'Total unit shares for\n1) scientific articles published in 2019-2021,\n2) scientific monographs, chapters in scientific monographs and scientific editions of scientific monographs, published in 2017-2021.\n\nThe discipline limit is 220% of N.'
        }
      } else if (reason === 5) {
        if (this.$i18n.locale === 'pl') {
          result = 'Przekroczona liczba przyznanych patentów na wynalazki, praw ochronnych na wzory użytkowe i wyłącznych praw hodowców do odmian roślin.\nLimit dla dyscypliny równy liczbie N zaokrąglonej w górę do liczby całkowitej.'
        } else {
          result = 'Exceeded number of granted patents for inventions, protection rights for utility models and exclusive rights of breeders to plant varieties.\nThe limit for the discipline is equal to the number N rounded up to the nearest integer.'
        }
      } else if (reason === 6) {
        if (this.$i18n.locale === 'pl') {
          result = 'Przekroczona suma udziałów jednostkowych w monografiach naukowych, których całkowita wartość punktowa wynosi nie więcej niż 120 pkt, i redakcjach naukowych takich monografii.\n\nLimit dla dyscyplin naukowych należących do dziedziny nauk humanistycznych, dziedziny nauk społecznych i dziedziny nauk teologicznych równy 20% z 3N.\nLimit dla dyscyplin naukowych należących do pozostałych dziedzin równy 5% z 3N.'
        } else {
          result = 'Exceeded sum of unit shares in scientific monographs, the total point value of which is not more than 120 points, and in scientific editorial offices of such monographs.\n\nThe limit for scientific disciplines belonging to the field of humanities, social sciences and theological sciences equal to 20% of 3N.\nThe limit for scientific disciplines belonging to other fields is 5% of 3N.'
        }
      } else if (reason === 7) {
        if (this.$i18n.locale === 'pl') {
          result = 'Przekroczona suma udziałów jednostkowych na monografie naukowe, których całkowita wartość punktowa wynosi nie więcej niż 100 pkt, redakcje naukowe takich monografii i rozdziały w takich monografiach.\n\nLimit dla pracownika równy 2-krotność średniej wartości iloczynu wymiaru czasu pracy i udziału czasu pracy w dyscyplinie zadeklarowanego w oświadczeniu o dyscyplinach.'
        } else {
          result = 'Exceeded sum of unit shares for scientific monographs, the total point value of which is not more than 100 points, scientific editorials of such monographs and chapters in such monographs.\n\nThe limit for an employee is equal to twice the average value of the product of working time and the share of working time in the discipline declared in the declaration on disciplines.'
        }
      } else if (reason === 8) {
        if (this.$i18n.locale === 'pl') {
          result = 'Przekroczona suma udziałów jednostkowych w publikacjach naukowych autorstwa lub współautorstwa doktorantów szkoły doktorskiej oraz pracowników niezaliczonych do liczby N w okresie ewaluacji.\n\nLimit dla dyscypliny równy 20% z 3N.'
        } else {
          result = 'The sum of unit shares in scientific publications by or co-authored by doctoral students of the doctoral school and employees not included in the number N during the evaluation period exceeded.\n\nThe discipline limit is 20% of 3N.'
        }
      } else if (reason === 9) {
        if (this.$i18n.locale === 'pl') {
          result = 'Pracownik niezaliczony do osób uczestniczących w realizacji badań naukowych lub prac rozwojowych w roku 2017.'
        } else {
          result = 'An employee not counted among the persons participating in the implementation of scientific research or development works in 2017.'
        }
      } else if (reason === 10) {
        if (isDoctorant) {
          if (this.$i18n.locale === 'pl') {
            result = 'Osiągnięcie sprzed okresu rozpoczęcia kształcenia w szkole doktorskiej prowadzonej przez podmiot.'
          } else {
            result = 'Achievement prior to the commencement of education at the doctoral school run by the entity.'
          }
        } else {
          if (this.$i18n.locale === 'pl') {
            result = 'Osiągnięcie sprzed okresu zatrudnienia w podmiocie.'
          } else {
            result = 'Achievement prior to employment in the entity.'
          }
        }
      } else if (reason === 11) {
        if (this.$i18n.locale === 'pl') {
          result = 'Pracownik zatrudniony w roku 2017 na stanowisku niepodlegającym ewaluacji.\n\nNa przykład naukowo - technicznym lub inżynieryjno - technicznym'
        } else {
          result = 'An employee employed in 2017 in a position not subject to evaluation.\n\nFor example, scientific - technical or engineering - technical'
        }
      } else if (reason === 12) {
        if (this.$i18n.locale === 'pl') {
          result = 'Brak oświadczenia o N w dyscyplinie artystycznej.'
        } else {
          result = 'There is no declaration of N in the artistic discipline.'
        }
      } else {
        if (this.$i18n.locale === 'pl') {
          result = 'Nie podano powodu odrzucenia.'
        } else {
          result = 'No reason for rejection.'
        }
      }
      return result
    }
  }
}
