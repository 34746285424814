<template>
  <div>
    <div class="import-text" style="margin-top: 2rem; margin-bottom: 3rem;">{{ this.$t('views.import_polon.import_publications_from_polon') }}</div>
    <b-row class="d-flex align-items-center" style="margin-bottom: 1rem">
      <b-col cols="6">
        <div class="available-in" style="position: relative; top: .4rem;">
          <label style="position: relative; bottom: .1rem;">{{ this.$t('views.import_polon.import_type') }}</label>
        </div>
        <pcg-select
          class="my-select-short"
          style="display: inline-block"
          :options="tDictionary(importOptions)"
          :default-option="{ text: $t('general.select'), id: '' }"
          v-model="params.importType"
        />
      </b-col>
      <b-col cols="6">
        <div class="available-in" style="position: relative; top: .4rem;">
          <label style="position: relative; bottom: .1rem;">{{ this.$t('views.import_polon.import_mode') }}</label>
        </div>
        <pcg-select
          class="my-select-short"
          style="display: inline-block"
          :options="tDictionary(updateModes)"
          :default-option="{ text: $t('general.select'), id: '' }"
          v-model="params.updateMode"
        />
      </b-col>
    </b-row>
    <b-row class="d-flex align-items-center" style="margin-bottom: 1rem">
      <b-col cols="6">
        <pcg-checkbox v-model="params.addEmployees">
          {{ $t('views.import_polon.add_employees') }}
        </pcg-checkbox>
      </b-col>
      <b-col cols="6" v-if="params.addEmployees">
        <div class="available-in" style="position: relative; top: .4rem;">
          <label style="position: relative; bottom: .1rem;">{{ this.$t('views.import_polon_employees.data_type') }}</label>
        </div>
        <pcg-select
            class="my-select-short"
            style="display: inline-block"
            :options="tDictionary(dataTypes)"
            :default-option="{ text: $t('general.select'), id: '' }"
            v-model="params.dataType"
        />
      </b-col>
    </b-row>
    <b-row class="d-flex align-items-center" style="margin-bottom: 1rem">
      <b-col cols="6" style="margin-right: .5rem">
        <label for="username" class="my-label">{{ this.$t('views.import_polon.username') }}</label>
        <pcg-text-input id="username" v-model="params.username" :showLabel="false" :showInfo="false" :showError="false" :placeholder="this.$t('views.import_polon.username')" />
      </b-col>
    </b-row>
    <b-row class="d-flex align-items-center" style="margin-bottom: 1rem">
      <b-col cols="6" style="margin-right: .5rem">
        <label for="password" class="my-label">{{ this.$t('views.import_polon.password') }}</label>
        <pcg-text-input id="password" v-model="params.password" type="password" :showLabel="false" :showInfo="false" :showError="false" :placeholder="this.$t('views.import_polon.password')" />
      </b-col>
    </b-row>
    <b-row class="d-flex align-items-center" style="margin-bottom: 2rem">
      <b-col cols="6" style="margin-right: .5rem">
        <label for="institution" class="my-label">{{ this.$t('views.import_polon.institution_id') }}</label>
        <pcg-text-input id="institution" v-model="params.institution" :showLabel="false" :showInfo="false" :showError="false" :placeholder="this.$t('views.import_polon.institution_id')" />
      </b-col>
    </b-row>
    <b-row>
      <pcg-btn size="small" class="import-btn" @click="importPublication" :disabled="publicationSpinner">
        <span>{{ this.$t('views.import_polon.import') }}</span>
        <spinner v-if="publicationSpinner"/>
      </pcg-btn>
    </b-row>
  </div>
</template>

<script>
import ApiPbn from '../../api/pbn'
import setTitle from '../../mixins/set_title'
import waitForJobs from '../../mixins/wait_for_jobs'
import Spinner from '../../components/Spinner'
import translateDictionary from '../../mixins/translate_dictionary'

export default {
  name: 'ImportPolon',
  mixins: [setTitle, waitForJobs, translateDictionary],
  components: { Spinner },
  data () {
    return {
      headerTitle: 'views.import_polon.title',
      headerSubtitle: '',
      params: {
        username: null,
        password: null,
        institution: null,
        importType: null,
        updateMode: null,
        addEmployees: false,
        dataType: null
      },
      publicationSpinner: false,
      importOptions: [
        { text: 'Artystyczne', text_en: 'Artistic', id: 'artistic' },
        { text: 'Patenty', text_en: 'Patents', id: 'patents' },
        { text: 'Projekty', text_en: 'Projects', id: 'projects' },
        { text: 'Usługi badawcze', text_en: 'Research services', id: 'services' },
        { text: 'Komercjalizacja wyników badań', text_en: 'Commercialization of research results', id: 'commercialization' }
      ],
      updateModes: [
        { text: 'Tylko nowe', text_en: 'Only new', id: 'only_new' },
        { text: 'Aktualizacja', text_en: 'Update', id: 'only_update' },
        { text: 'Nowe i aktualizacja', text_en: 'New and update', id: 'new_and_update' }
      ],
      dataTypes: [
        { text: 'Podstawowe dane', text_en: 'Basic data', id: 'basic' },
        { text: 'Podstawowe dane + zatrudnienie i oświadczenia', text_en: 'Basic data + employment and statements', id: 'all' }
      ]
    }
  },
  mounted () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    this.$store.dispatch('header/setHeader', {
      title: this.$t(this.headerTitle),
      subtitle: this.$t(this.headerSubtitle),
      capitalizeSubtitle: false
    })
  },
  methods: {
    importPublication () {
      if (!this.params.username || !this.params.password || !this.params.institution || !this.params.importType || !this.params.updateMode || (this.params.addEmployees && !this.params.dataType)) {
        this.$toastr.i('Nie wypełniono wszystkich parametrów')
      } else {
        ApiPbn.importPolon(this.params)
          .then((result) => {
            const jobId = result.data.jobId
            if (jobId !== undefined) {
              this.publicationSpinner = true
              this.waitForJob(jobId, this.publicationSuccess, this.publicationFailure)
            }
          })
      }
    },
    publicationSuccess () {
      this.publicationSpinner = false
      this.$toastr.s('Import przebiegł pomyślnie')
    },
    publicationFailure () {
      this.publicationSpinner = false
      this.$toastr.e('Podczas importu wystąpił błąd')
    }
  }
}
</script>

<style scoped lang="scss">
  @import '../../assets/stylesheets/vars';

  .import-btn {
    position: relative;
    top: .3rem;
  }

  .import-text {
    color: $pcg-gray;
    font-weight: 400;
    font-size: $font-size-m;
  }

  .my-label {
    color: $pcg-gray;
    font-weight: 400;
  }

  .available-in {
    font-size: 1em;
    color: $pcg-gray;
    font-weight: 400;
    margin-right: 1.5rem;
    display: inline;
    float: left;
  }

  .my-select-short {
    margin-right: .8rem;
    margin-left: .7rem;
    width: 200px;
    position: relative;
    display: inline-block;
    /deep/ .bootstrap-select .dropdown-toggle {
      height: calc(1.5em + 0.75em + 2px);
      color: $pcg-gray;
    }
    /deep/ .filter-option-inner {
      position: relative;
      bottom: .45rem;
    }
  }
</style>

<style lang="scss">
@import "../../assets/stylesheets/vars-contrast";
.contrast {
  .import-text {
    color: $pcg-gray;
  }
  .my-label {
    color: $pcg-gray;
  }
  .available-in {
    color: $pcg-gray;
  }
  .my-select-short {
    /deep/ .bootstrap-select .dropdown-toggle {
      color: $pcg-gray;
    }
  }
}
</style>
