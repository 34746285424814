<template>
    <div class="export-report">
      <div class="report-elements" v-if="reportsFiles.length > 0">
        <b-row class="text-label list-header">
          <template v-if="currentRole === 'manager'">
            <b-col cols="4" class="th">
              {{ $t('views.report_files.file_name') }}
            </b-col>
            <b-col cols="2" class="th">
              {{ $t('views.report_files.file_date') }}
            </b-col>
            <b-col cols="1" class="th">
              {{ $t('views.report_files.academy') }}
            </b-col>
            <b-col cols="2" class="th">
              {{ $t('views.report_files.user') }}
            </b-col>
            <b-col cols='3' class="buttons th">
              &nbsp;
            </b-col>
          </template>
          <template v-else>
            <b-col cols="5" class="th">
              {{ $t('views.report_files.file_name') }}
            </b-col>
            <b-col cols="2" class="th">
              {{ $t('views.report_files.file_date') }}
            </b-col>
            <b-col class="buttons th">
              &nbsp;
            </b-col>
          </template>
        </b-row>
        <div v-for="file in reportsFiles" :key="file.id" class="report-element">
          <report-element :element="file" @deleteReport="deleteReport" @downloadReport="downloadReport"/>
        </div>
      </div>
      <nav :aria-label="$t('general.pagination')">
        <pcg-pagination
            v-model="pagination.page"
            :page-count="pagination.pages"
            :total-records="pagination.count"
            :only-event="true"
            @input="getReports"
            class="my-pagination"
        ></pcg-pagination>
      </nav>
    </div>
</template>

<script>
import ReportElement from '../../components/ReportElement'
import ApiReports from '../../api/reports'
import axios from 'axios'
import { mapGetters } from 'vuex'

export default {
  name: 'ExportReport',
  components: { ReportElement },
  data () {
    return {
      headerTitle: 'views.report_files.list.title',
      headerSubtitle: 'views.report_files.list.subtitle',
      reportsFiles: [],
      pagination: {
        count: 0,
        items: 0,
        last: 0,
        next: null,
        page: 0,
        pages: 0,
        prev: null
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['currentRole'])
  },
  created () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    const query = this.$route.query
    if (Object.prototype.hasOwnProperty.call(query, 'page')) {
      this.pagination.page = parseInt(query.page)
    } else {
      this.pagination.page = 1
    }
    ApiReports.getReportsFiles(this.pagination.page)
      .then(result => {
        this.reportsFiles = result.data.datas
        this.pagination = result.data.pagination
      })
  },
  mounted () {
    this.$store.dispatch('header/setHeader', {
      title: this.$t(this.headerTitle),
      subtitle: this.$t(this.headerSubtitle),
      capitalizeSubtitle: false
    })
  },
  methods: {
    deleteReport (id) {
      ApiReports.deleteFile(id)
        .then(result => {
          // this.$emit('deleted')
          this.getReports()
        })
        .catch(error => {
          console.log(error)
        })
    },
    downloadReport (id, fileName) {
      axios({
        url: '/api/v1/reports/' + id + '/download',
        method: 'GET',
        responseType: 'blob'
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', fileName)
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    },
    getReports () {
      const query = {}
      if (this.pagination.page !== 0) {
        query.page = this.pagination.page
      }
      this.$router.push({ name: 'reports_path', query: query })
      ApiReports.getReportsFiles(this.pagination.page)
        .then(result => {
          this.reportsFiles = result.data.datas
          this.pagination = result.data.pagination
        })
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/stylesheets/vars';

.my-pagination {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.text-label {
  font-weight: bold;
  color: $pcg-the-darkest-gray;
}
.report-elements {
  margin-bottom: 2rem;
  margin-top: 1rem;
  background-color: $pcg-white;
  border-radius: 15px;
  min-width: 700px;
  .list-header {
    background-color: $main-color;
    color: $pcg-white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    .th {
      padding-top: 15px;
      padding-bottom: 15px;
      padding-right: 0.5rem;
      padding-left: 0.5rem;
      vertical-align: middle;
    }

    .th:first-child {
      padding-left: 15px;
      border-top-left-radius: 15px;
    }

    .th:last-child {
      padding-right: 15px;
      border-top-right-radius: 15px;
    }
  }
}

.report-element {
  background-color: $pcg-white;
  /deep/ .xml-element {
    background-color: $pcg-white;
    &.row {
      margin-right: 0;
      margin-left: 0;
    }
  }

  &:last-child {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    /deep/ .report-element {
      background-color: $pcg-white;
      &.row {
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        .col {
          padding-bottom: 15px;
        }

        .col:first-child {
          border-bottom-left-radius: 15px;
        }

        .col:last-child {
          border-bottom-right-radius: 15px;
        }
      }
    }
  }
}
</style>

<style lang="scss" scoped>
@import "../../assets/stylesheets/vars-contrast";
.contrast {
  .my-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
  .text-label {
    color: $pcg-the-darkest-gray;
  }
  .report-elements {
    background-color: $pcg-white;
    .list-header {
      background-color: $main-color;
      color: $pcg-white;
    }
  }
  .report-element {
    background-color: $pcg-white;
    /deep/ .xml-element {
      background-color: $pcg-white;
    }

    &:last-child {
      /deep/ .report-element {
        background-color: $pcg-white;
      }
    }
  }
}
</style>
