import api from './index'
import qs from 'qs'

const resource = 'employees'

export default {
  getEmployees (query, key = null) {
    let url = ''
    if (key) {
      query.filtersKey = key
    }
    const params = qs.stringify(query, { arrayFormat: 'brackets' })
    url = resource + '?' + params
    return api.get(url, query)
  },

  getEmployee (id) {
    let url = resource
    url += '/' + id
    return api.get(url)
  },

  getEmployeeShort (id) {
    let url = resource
    url += '/' + id + '/show_short'
    return api.get(url)
  },

  getEvaluation (id) {
    let url = resource
    url += '/' + id + '/evaluation'
    return api.get(url)
  },

  getStatistics (id) {
    let url = resource
    url += '/' + id + '/statistics'
    return api.get(url)
  },

  getStatements (id) {
    let url = resource
    url += '/' + id + '/statements'
    return api.get(url)
  },

  getPublications (id) {
    let url = resource
    url += '/' + id + '/get_publications'
    return api.get(url)
  },

  getIdentities (id) {
    let url = resource
    url += '/' + id + '/get_identities'
    return api.get(url)
  },

  // getScientistPublications (id, page = null, filters = null, perPage = null) {
  getScientistPublications (id, query, key = null) {
    let url = ''
    if (key) {
      query.filtersKey = key
    }
    const params = qs.stringify(query, { arrayFormat: 'brackets' })
    url = resource + '/' + id + '/get_scientist_publications' + '?' + params
    return api.get(url)
  },

  getScientistArchivedPublications (id, query, key = null) {
    let url = ''
    if (key) {
      query.filtersKey = key
    }
    const params = qs.stringify(query, { arrayFormat: 'brackets' })
    url = resource + '/' + id + '/scientist_archived_publications' + '?' + params
    return api.get(url)
  },

  setDisciplines (employeeId, userClicked = false) {
    let url = resource + '/' + employeeId + '/set_leading_discipline'
    if (userClicked) url += '?user_clicked=true'
    return api.post(url)
  },

  setStudent (employeeId, discipline) {
    const url = resource + '/' + employeeId + '/set_student'
    const body = discipline
    return api.post(url, body)
  },

  setEmployeeFromStudent (employeeId) {
    const url = resource + '/' + employeeId + '/set_employee_from_student'
    return api.post(url)
  },

  assignDiscipline (employeeId, publicationsIds, unselectedPublicationsIds) {
    let url = resource
    url += '/' + employeeId + '/assign_discipline'
    return api.post(url, { params: { publicationsIds: publicationsIds, unselectedPublicationsIds: unselectedPublicationsIds } })
  },

  consentToEvaluation (employeeId, publicationsIds, unselectedPublicationsIds, evaluation) {
    let url = resource
    url += '/' + employeeId + '/consent_to_evaluation'
    return api.post(url, { params: { publicationsIds: publicationsIds, unselectedPublicationsIds: unselectedPublicationsIds, evaluation: evaluation } })
  },

  createEmployee (employee) {
    return api.post(resource, employee)
  },

  // exportEmployees (employeesIds, unselectedEmployeesIds) {
  //   let url = resource
  //   url += '/export_employees'
  //   return api.post(url, { params: { employeesIds: employeesIds, unselectedEmployeesIds: unselectedEmployeesIds } })
  // }
  exportEmployees (query) {
    let url = resource
    url += '/export_employees'
    return api.post(url, query)
  },
  calculateSlots (query) {
    let url = resource
    url += '/calculate_slots'
    return api.post(url, query)
  },
  deleteEmployee (id) {
    let url = resource
    url += '/' + id
    return api.delete(url)
  },
  updateEmployeeApiId (id, apiId, userClicked = false) {
    let url = resource
    url += '/' + id + '/employee_api_id'
    if (userClicked) url += '?user_clicked=true'
    return api.put(url, { apiId: apiId })
  },
  updateEmployeeFlags (id, flags, userClicked = false) {
    let url = resource
    url += '/' + id + '/employee_flags'
    if (userClicked) url += '?user_clicked=true'
    return api.put(url, { flags: flags })
  },
  getAdditionalIdentifiers (id) {
    let url = resource
    url += '/' + id + '/get_additional_identifiers'
    return api.get(url)
  },
  employeeRedirect (query) {
    let url = resource
    url += '/redirect'
    return api.post(url, { params: query })
  },
  authorInformations (id) {
    let url = resource
    url += '/' + id + '/author_informations'
    return api.get(url)
  },
  delFilter (id) {
    let url = resource
    url += `/del_filters/${id}`
    return api.delete(url)
  },
  saveFilter (name, set) {
    let url = resource
    url += '/save_filters'
    return api.post(url, { name: name, set: set })
  },
  updateSkipRestrictions (id, skipRestrictions) {
    let url = resource
    url += '/' + id + '/skip_restrictions'
    return api.put(url, { skip_restrictions: skipRestrictions })
  }
}
