import api from './index'
import qs from 'qs'

const resource = 'employee_statements'

export default {
  getStatementsList (query = null) {
    let url = ''
    const params = qs.stringify(query)
    url = resource + '?' + params
    return api.get(url)
  },
  createEmployeeStatement (employeeId, statements, userClicked = false) {
    const employeeStatement = []
    const dateObj = new Date()
    const day = dateObj.getUTCDate()
    const month = dateObj.getUTCMonth() + 1
    const year = dateObj.getUTCFullYear()
    const newDate = day + '/' + month + '/' + year
    if (statements[0].statements) {
      statements.forEach(statement => {
        if (statement.statements.id) {
          employeeStatement.push(
            {
              id: statement.statements.id,
              employee_id: employeeId,
              discipline_id: statement.statements.discipline_type,
              is_leading: statement.statements.is_leading,
              submit_date: newDate,
              validity_from: statement.statements.validity_from,
              validity_from_date: statement.statements.validity_from_date,
              validity_to: statement.statements.validity_to,
              validity_to_date: statement.statements.validity_to_date,
              share: statement.statements.discipline_participation,
              kind: statement.statements.kind,
              doctoral_school: statement.statements.kind === 2 ? false : statement.statements.doctoral_school
            }
          )
        } else {
          employeeStatement.push(
            {
              employee_id: employeeId,
              discipline_id: statement.statements.discipline_type,
              is_leading: statement.statements.is_leading,
              submit_date: newDate,
              validity_from: statement.statements.validity_from,
              validity_from_date: statement.statements.validity_from_date,
              validity_to: statement.statements.validity_to,
              validity_to_date: statement.statements.validity_to_date,
              share: statement.statements.discipline_participation,
              kind: statement.statements.kind,
              doctoral_school: statement.statements.kind === 2 ? false : statement.statements.doctoral_school
            }
          )
        }
      })
    } else {
      employeeStatement.push(
        {
          employee_id: employeeId,
          discipline_id: statements[0].disciplineStatements.discipline_type,
          is_leading: statements[0].disciplineStatements.is_leading,
          submit_date: newDate,
          validity_from: statements[0].disciplineStatements.validity_from,
          validity_from_date: statements[0].disciplineStatements.validity_from_date,
          validity_to: statements[0].disciplineStatements.validity_to,
          validity_to_date: statements[0].disciplineStatements.validity_to_date,
          share: statements[0].disciplineStatements.discipline_participation,
          kind: 1,
          doctoral_school: statements[0].disciplineStatements.doctoral_school
        }
      )
      if (statements.length > 1) {
        employeeStatement.push(
          {
            employee_id: employeeId,
            discipline_id: statements[1].disciplineStatements.discipline_type,
            is_leading: statements[1].disciplineStatements.is_leading,
            submit_date: newDate,
            validity_from: statements[1].disciplineStatements.validity_from,
            validity_from_date: statements[1].disciplineStatements.validity_from_date,
            validity_to: statements[1].disciplineStatements.validity_to,
            validity_to_date: statements[1].disciplineStatements.validity_to_date,
            share: statements[1].disciplineStatements.discipline_participation,
            kind: 1,
            doctoral_school: statements[1].disciplineStatements.doctoral_school
          }
        )
      }
    }
    const body = {
      employee_statement: employeeStatement
    }
    let url = resource
    if (userClicked) url += '?user_clicked=true'
    return api.post(`${url}`, body)
  },

  getDisciplines (id) {
    let url = resource
    url += '/' + id + '/get_disciplines'
    return api.get(url)
  },

  removeStatement (id, userClicked = false) {
    let url = resource
    url += '/' + id
    if (userClicked) url += '?user_clicked=true'
    return api.delete(url)
  },

  editStatement (id, endDate) {
    let url = resource
    url += '/' + id
    return api.put(url, { validity_to_date: endDate })
  }
}
