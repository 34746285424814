export default {
  methods: {
    jobParts (partTime) {
      let result = ''
      if (partTime === 0) {
        result = '0'
        if (this.$i18n.locale === 'pl') {
          result += ' etatu'
        } else {
          result += ' part time'
        }
      } else if (partTime === 0.125) {
        result = '1/8'
        if (this.$i18n.locale === 'pl') {
          result += ' etatu'
        } else {
          result += ' part time'
        }
      } else if (partTime === 0.25) {
        result = '1/4'
        if (this.$i18n.locale === 'pl') {
          result += ' etatu'
        } else {
          result += ' part time'
        }
      } else if (partTime === 0.5) {
        result = '1/2'
        if (this.$i18n.locale === 'pl') {
          result += ' etatu'
        } else {
          result += ' part time'
        }
      } else if (partTime === 0.75) {
        result = '3/4'
        if (this.$i18n.locale === 'pl') {
          result += ' etatu'
        } else {
          result += ' part time'
        }
      } else if (partTime === 1) {
        if (this.$i18n.locale === 'pl') {
          result = '1 etat'
        } else {
          result = 'full time'
        }
      } else if (partTime === 1.25) {
        if (this.$i18n.locale === 'pl') {
          result = '1 i 1/4 etatu'
        } else {
          result = '1 and 1/4 part time'
        }
      } else if (partTime === 1.5) {
        if (this.$i18n.locale === 'pl') {
          result = '1 i 1/2 etatu'
        } else {
          result = '1 and 1/2 part time'
        }
      } else {
        result = partTime.toFixed(3).toString()
        if (this.$i18n.locale === 'pl') {
          result += ' etatu'
        } else {
          result += ' part time'
        }
      }
      return result
    }
  }
}
