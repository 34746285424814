<template>
  <div>
    <pcg-select class="my-field"
                :label="$t('components.dn_discipline_statements.field')"
                :options="tDictionary(myFieldsOptions)"
                v-model="disciplineStatements.field_type"
    />
<!--    <pcg-select v-if="!disciplineStatements.field_type"-->
<!--                class="my-discipline"-->
<!--                :label="$t('components.dn_discipline_statements.discipline')"-->
<!--                :options="tDictionary(disciplines)"-->
<!--                v-model="disciplineStatements.discipline_type"-->
<!--    />-->
<!--    <pcg-select v-if="disciplineStatements.field_type"-->
<!--                class="my-discipline"-->
<!--                :label="$t('components.dn_discipline_statements.discipline')"-->
<!--                :options="tDictionary(filteredDisciplines)"-->
<!--                v-model="disciplineStatements.discipline_type"-->
<!--    />-->
    <b-row allign-v="center" class="oneline">
      <b-col class="col-12">
        <live-search
            class="publisher-search"
            :label="$t('components.dn_discipline_statements.discipline')"
            :id="$t('components.dn_discipline_statements.discipline')"
            :clear-btn="false"
            :options="disciplinOptions"
            v-model="disciplin"
            v-on:ls-checked="disciplinChecked()"
        />
      </b-col>
    </b-row>
    <div class="discipline-participation" v-if="sliderVisible">
      <b-row>
        <b-col class="discipline-text col-12 col-sm-3">{{ $t('components.dn_discipline_statements.discipline_participation') }}</b-col>
        <b-col class="col-12 col-sm-9">
          <dn-slider-alternative v-if="disabled"></dn-slider-alternative>
          <dn-slider v-else v-model="disciplineStatements.discipline_participation"></dn-slider>
        </b-col>
      </b-row>
    </div>
    <div class="checkbox-container" v-if="!disabled">
      <pcg-checkbox
        class="my-checkbox"
        variant="normal"
        v-model="disciplineStatements.is_leading"
      >{{ $t('components.dn_discipline_statements.is_leading') }}</pcg-checkbox>
    </div>
  </div>
</template>

<script>
import DnSlider from './DnSlider'
import DnSliderAlternative from './DnSliderAlternative'
import translateDictionary from '../mixins/translate_dictionary'
import LiveSearch from './LiveSearch'
import { mapGetters } from 'vuex'
export default {
  name: 'dn-discipline-statements',
  mixins: [translateDictionary],
  components: {
    DnSlider,
    DnSliderAlternative,
    LiveSearch
  },
  props: {
    disciplineStatements: {
      type: Object,
      default: () => {}
    },
    disabled: Boolean,
    taken: Array,
    index: Number,
    sliderVisible: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      filteredDisciplines: [],
      validityFrom: [],
      validityTo: [],
      myFieldsOptions: [],
      searchedDiscipline: '',
      searchedDisciplines: [],
      disciplin: {
        id: null,
        value: null,
        valueEn: null
      }
    }
  },
  computed: {
    ...mapGetters('disciplines', ['disciplines']),
    ...mapGetters('scienceFields', ['scienceFields']),
    onFieldChange () {
      return this.disciplineStatements.field_type
    },
    disciplinOptions () {
      const result = {
        filter: this.disciplineStatements.field_type
      }
      return {
        path: 'disciplins/search_disciplins',
        params: result
      }
    }
  },
  beforeMount () {
    if (this.$store.getters['disciplines/disciplines'].length === 0) {
      this.$store.dispatch('disciplines/setDisciplines', null)
    }
    if (this.$store.getters['scienceFields/scienceFields'].length === 0) {
      this.$store.dispatch('scienceFields/setScienceFields', null)
    }
  },
  methods: {
    disciplinChecked () {
      if (this.disciplin.id === null) {
        if (!this.disciplin.value) {
          this.disciplineStatements.discipline_type = ''
        }
      } else {
        const name = this.disciplin.value.split(' | ')[0]
        this.disciplin.value = name
        this.disciplineStatements.discipline_type = this.disciplin.id.toString()
      }
    },
    getFilteredDisciplines () {
      if (this.disciplineStatements.field_type) {
        this.filteredDisciplines = []
        for (let i = 0; i < this.disciplines.length; i++) {
          if (this.disciplines[i].science_field_id === Number(this.disciplineStatements.field_type)) {
            if (Number(this.index) === 0 && (this.taken[1] !== this.disciplines[i].id)) {
              this.filteredDisciplines.push(this.disciplines[i])
            }
            if (Number(this.index) === 1 && (this.taken[0] !== this.disciplines[i].id)) {
              this.filteredDisciplines.push(this.disciplines[i])
            }
          }
          if (Number(this.index) === 0 && (this.taken[1] === this.disciplines[i].id)) {
            this.filteredDisciplines.splice(this.disciplines[i].id, 1)
          }
          if (Number(this.index) === 1 && (this.taken[0] === this.disciplines[i].id)) {
            this.filteredDisciplines.splice(this.disciplines[i].id, 1)
          }
        }
        return this.filteredDisciplines
      } else {
        if (Number(this.index) === 1 && this.taken[0] === this.disciplineStatements.discipline_type) {
          this.disciplineStatements.discipline_type = ''
        }
      }
    },
    setScienceField () {
      if (this.disciplineStatements.discipline_type && !this.disciplineStatements.field_type) {
        const discipline = this.disciplines.find((d) => {
          return d.id === this.disciplineStatements.discipline_type
        })
        this.disciplineStatements.field_type = discipline.science_field_id.toString()
        if (Number(this.index) === 0 && this.taken[1] !== discipline.id) {
          this.disciplineStatements.discipline_type = discipline.id.toString()
        }
        if (Number(this.index) === 1 && this.taken[0] !== discipline.id) {
          this.disciplineStatements.discipline_type = discipline.id.toString()
        }
      }
    },
    initializeFieldsOptions () {
      this.myFieldsOptions = []
      this.myFieldsOptions.push({ text: 'Wszystkie', text_en: 'All', id: '' })
      for (let i = 0; i < this.scienceFields.length; i++) {
        this.myFieldsOptions.push(this.scienceFields[i])
      }
    }
  },
  watch: {
    onFieldChange (newValue, oldValue) {
      if (newValue !== oldValue && oldValue !== '') {
        this.getFilteredDisciplines()
        if (!this.filteredDisciplines.map((r) => { return r.id }).includes(this.disciplineStatements.discipline_type)) {
          this.disciplineStatements.discipline_type = ''
        }
      }
    },
    disciplineStatements: {
      deep: true,
      handler () {
        this.getFilteredDisciplines()
        this.setScienceField()
      }
    },
    scienceFields: {
      deep: true,
      handler () {
        this.initializeFieldsOptions()
      }
    }
  },
  mounted () {
    this.initializeFieldsOptions()
  }
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .my-field {
    margin-bottom: 1rem;
    position: relative;
    /deep/ .pcg-input-label {
      margin-bottom: 0.25rem;
      color: $pcg-gray;
    }
  }

  .publisher-search {
    /deep/ .pcg-input-label{
      margin-bottom: 0.25rem;
      color: $pcg-gray;
    }

    /deep/ .form-group input{
      padding: 1rem 1.5rem 0.86rem;
      height: auto;
    }
  }

  /deep/ .dropdown-menu {
    overflow: auto;
    max-height: 300px;
  }

  .my-discipline {
    position: relative;
    /deep/ .pcg-input-label {
      margin-bottom: 0.25rem;
      color: $pcg-gray;
    }
  }

  .my-validity {
    position: relative;
    /deep/ .pcg-input-label {
      margin-bottom: 0.25rem;
      color: $pcg-gray;
    }
  }

  .discipline-participation {
    margin-top: 1.8rem;
    z-index: 0;
    position: relative;
  }

  .discipline-text {
    color: $pcg-gray;
    font-size: $font-size-m;
    font-weight: 400;
  }

  .wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .my-checkbox {
    position: relative;
    top: 2rem;
    left: .3rem;
    font-size: $font-size-m;
    @media (max-width: 575.98px) {
      top: .3rem;
      left: 0;
    }
  }

  .checkbox-container {
    position: relative;
    bottom: 1.3rem;
  }
</style>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .my-field {
      /deep/ .pcg-input-label {
        color: $pcg-gray;
      }
    }
    .my-discipline {
      /deep/ .pcg-input-label {
        color: $pcg-gray;
      }
    }
    .my-validity {
      /deep/ .pcg-input-label {
        color: $pcg-gray;
      }
    }
    .discipline-text {
      color: $pcg-gray;
    }
  }
</style>
