import api from './index'

const resource = 'additional_ids'

export default {
  removeAdditionalId (id, userClicked = false) {
    let url = resource
    url += '/' + id
    if (userClicked) url += '?user_clicked=true'
    return api.delete(url)
  },

  createadditionalId (employeeId, additional, userClicked = false) {
    let additionalId = {}
    additionalId = {
      employee_id: employeeId,
      key: additional.key,
      value: additional.value
    }
    const body = {
      additional_id: additionalId
    }
    let url = resource
    if (userClicked) url += '?user_clicked=true'
    return api.post(`${url}`, body)
  }
}
