import api from './index'

const resource = 'arts'

export default {
  getArts () {
    return api.get(resource)
  },
  createGroup (group) {
    const url = resource + '/create_group'
    const body = {
      group: {
        discipline_id: group.discipline_id,
        key: group.key
      }
    }
    return api.post(url, body)
  },
  createKind (kind) {
    const url = resource + '/create_kind'
    const body = {
      kind: {
        group_id: kind.group_id,
        notice: kind.notice
      }
    }
    return api.post(url, body)
  },
  updateKind (kind) {
    const url = resource + `/${kind.id}/update_kind`
    const body = {
      kind: {
        id: kind.id,
        discipline_id: kind.discipline_id,
        group_key: kind.group_key,
        notice: kind.notice
      }
    }
    return api.put(url, body)
  }
}
